import React from 'react';
import styles from './PartnersPayment.module.css';
import PartnersPaymentLi from "./partnersPaymentLi/PartnersPaymentLi";

const PartnersPayment = ({ data }) => {
  const filteredList = data && data.billings.filter(item => item.status === 2);

  return (
    <div className={styles.Payments}>
      <div className={styles.Title}><h3>Выплаты</h3></div>
      <div className={styles.ListHeader}>
        <div className={styles.Num}>ID</div>
        <div className={styles.Sum}>Оплачено</div>
        <div className={styles.Debit}>Задолженность</div>
        <div className={styles.Date}>Дата</div>
      </div>
      {filteredList.length !== 0 ? (
        <ul className={styles.ListBody}>
          {filteredList.map((item) => (
            <PartnersPaymentLi key={item.id} item={item} data={data} />
          ))}
        </ul>
      ) : (
        <div className={styles.NoData}>
          <h1>Выплаты отсутствуют</h1>
        </div>
      )}
    </div>
  );
};

export default PartnersPayment;
