import { format, parse } from "date-fns";
import ru from 'date-fns/locale/ru';
import React, { useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import Button from "../../button_v2/ButtonV2";
import InputMasked from "../InputMasked/InputMasked";
import styles from './InputRangeBilling.module.css';
import './inputRangeBilling.css';
import { useDispatch } from "react-redux";
import { setDatePickerOpen } from "../../../../reducers/billingReducer";

const InputRangeBilling = ({ value, dopValue, setValue, minDate, maxDate, label, options, onClick }) => {
    const dispatch = useDispatch()
    registerLocale('ru', ru);
    const [dateRange, setDateRange] = useState(value);
    const [active, setActive] = useState();
    const [data, setData] = useState({ startDate: null, endDate: null });
    const [startDateValue, setStartDateValue] = useState('');
    const [endEndValue, setEndDateValue] = useState('');

    const minDateLimit = new Date(2022, 4)

    useMemo(() => {
        if (dopValue.period) {
            setStartDateValue(dopValue.period[0]);
            setEndDateValue(dopValue.period[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dopValue]);
    const [param, setParam] = useState();
    options = options.filter(option => option.value !== 'range');
    let buttonList = useMemo(() => {
        if (options && options.length !== 0) {
            return options.map((option, index) => {
                return <li key={index}
                    className={active && active === option.value ? `${styles.RangeItem} ${styles.Active}` : styles.RangeItem}
                    onClick={() => {
                        addPeriod(option)
                    }}>{option.label}</li>;
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }


    }, [active, options]);
    useMemo(() => {
        if (dateRange[0] && Date.parse(dateRange[0])) {
            setStartDateValue(format(dateRange[0], 'dd-MM-yyyy'));
        }
        if (dateRange[1] && Date.parse(dateRange[1])) {
            setEndDateValue(format(dateRange[1], 'dd-MM-yyyy'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [dateRange]);

    useMemo(() => {
        setDateRange([parse(startDateValue, 'dd-MM-yyyy', new Date()), parse(endEndValue, 'dd-MM-yyyy', new Date())]);
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [startDateValue, endEndValue])

    function addPeriod (option) {

        const date = new Date();
        const dateformat = format(date, 'dd-MM-yyyy');
        switch (option.value) {
            case 'all': {
                setStartDateValue('');
                setEndDateValue('');
                setParam('all');
                break;
            }
            case 'today': {
                setStartDateValue(dateformat);
                setEndDateValue(dateformat);
                setDateRange([date, date]);
                setParam('today');
                break;
            }
            case 'yesterday': {
                const yesterday = new Date().setDate(new Date().getDate() - 1);
                setStartDateValue(format(yesterday, 'dd-MM-yyyy'));
                setEndDateValue(format(yesterday, 'dd-MM-yyyy'));
                setDateRange([yesterday, yesterday]);
                setParam('yesterday');
                break;
            }
            case 'day7': {
                const date7 = new Date().setDate(new Date().getDate() - 7);
                setStartDateValue(format(date7, 'dd-MM-yyyy'));
                setEndDateValue(dateformat);
                setDateRange([date7, date]);
                setParam('day7');
                break;
            }
            case 'day30': {
                const date30 = new Date().setDate(new Date().getDate() - 30);
                setStartDateValue(format(date30, 'dd-MM-yyyy'));
                setEndDateValue(dateformat);
                setDateRange([date30, date]);
                setParam('day30');
                break;
            }
            case 'currentMonth': {
                const currentMonth = new Date().setDate(1);
                setStartDateValue(format(currentMonth, 'dd-MM-yyyy'));
                setEndDateValue(dateformat);
                setDateRange([currentMonth, date]);
                setParam('currentMonth');
                break;
            }
            case 'previousMonth': {
                const dateNw = new Date(date.setDate(1));
                const dataStart = new Date(date).setMonth(new Date(dateNw).getMonth() - 1);
                const dataEnd = new Date(dateNw.setDate(dateNw.getDate() - 1));
                setStartDateValue(format(dataStart, 'dd-MM-yyyy'));
                setEndDateValue(format(dataEnd, 'dd-MM-yyyy'));
                setDateRange([dataStart, dataEnd]);
                setParam('previousMonth');
                break;
            }
            case 'previousWeek': {
                const today = new Date();
                const startOfWeek = today.getDate() - today.getDay() + 1;
                const endOfWeek = startOfWeek + 6;
                const prevWeekStart = new Date(today.setDate(startOfWeek - 7));
                const prevWeekEnd = new Date(today.setDate(endOfWeek - 7));
                setStartDateValue(format(prevWeekStart, 'dd-MM-yyyy'));
                setEndDateValue(format(prevWeekEnd, 'dd-MM-yyyy'));
                setDateRange([prevWeekStart, prevWeekEnd]);
                setParam('previousWeek');
                break;
            }
            case 'currentWeek': {
                const today = new Date();
                const startOfWeek = today.getDate() - today.getDay() + 1;
                const endOfWeek = startOfWeek + 6;
                const currentWeekStart = new Date(today.setDate(startOfWeek));
                const currentWeekEnd = new Date(today.setDate(endOfWeek));
                setStartDateValue(format(currentWeekStart, 'dd-MM-yyyy'));
                setEndDateValue(format(currentWeekEnd, 'dd-MM-yyyy'));
                setDateRange([currentWeekStart, currentWeekEnd]);
                setParam('currentWeek');
                break;
            }
            default: {

            }
        }
        setActive(option.value);
    }

    const sortArrayByDate = (array) => {
        return array.sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateA - dateB;
        });
    }

    return (
        <div className={`${styles.InputBillingRange} inputBillingRange`} >
            {<div className={styles.bg} onClick={() => {
                dispatch(setDatePickerOpen(false))
            }}>

            </div>}
            <div className={styles.Left}>
                <ul className={styles.List}>
                    {buttonList}
                </ul>
            </div>
            <div className={styles.Right}>
                <div className={styles.HeadRight}>
                    <div className={styles.InputBlock}>
                        <InputMasked name='startDate' value={startDateValue} setValue={setStartDateValue} />
                        <InputMasked name='endDate' value={endEndValue} setValue={setEndDateValue} />
                    </div>

                    <Button type={'primary'} onClick={() => {

                        if (startDateValue.length === 10 && endEndValue.length === 10) {
                            const date = sortArrayByDate([startDateValue, endEndValue]);
                            setValue({ period: date, value: param ? param : 'range' });
                            dispatch(setDatePickerOpen(false))
                        }

                    }}>Применить фильтры</Button>
                </div>
                <div className={styles.Calendar}>
                    <DatePicker selectsRange={true}
                        startDate={Date.parse(data.startDate) ? data.startDate : null}
                        endDate={Date.parse(data.endDate) ? data.endDate : null}
                        onChange={(update) => {
                            setData({ ...data, startDate: update[0], endDate: update[1] });
                            setDateRange(update);
                            //setValue(update);
                        }}
                        className={`${styles.Input} inputField`}
                        dateFormat="dd-MM-yyyy"
                        isClearable={true}
                        locale={ru}
                        monthsShown={2}
                        minDate={minDateLimit}
                        maxDate={maxDate.setMinutes(maxDate.getMinutes() + 10)}
                        inline
                    />
                </div>
            </div>


        </div>
    );
};

export default InputRangeBilling;
