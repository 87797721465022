import $api from "../core/api";
import {processError} from "../core/helpers";
import {setMonthsCurrencyRates} from "../reducers/reportsReducer";
import {setCurrencyRates} from "../reducers/billingReducer";
import {createNotification} from "./app";

export const getMonthsCurrencyRates = () => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/currency/get-months-average-rates`);
            if (response && response.data && response.data.success) {
                dispatch(setMonthsCurrencyRates(response.data.rates))
            }
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getUserCurrencyRates = () => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/currency/get-user-currency-rates`);
            dispatch(setCurrencyRates(response.data));
        } catch (e) {
            dispatch(createNotification({
                type: 'error',
                children: e.request ? 'Ошибка подключения' : 'Непредвиденная ошибка'
            }));
        }
    }
}
