import  {useEffect, useState} from 'react';
import styles from './Notification.module.css';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from "react-redux";
import { deleteNotificationAction } from '../../../reducers/appReducer'

const Notification = ({id, type,  className, children }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    const [css] = useState(false);

    const notificationStyle = type === 'primary' ? styles.Primary : styles.Error;
    const notificationClasses = [styles.Notification, notificationStyle];

    if(className && styles[className]){
        notificationClasses.push(styles[className]);
    }

    useEffect(()=>{
        let timeOutClose = setTimeout(() => {
            closeNotification();
        }, 6000);

        return ()=>{
            clearTimeout(timeOutClose);
        };
        // eslint-disable-next-line
    },[]);

    useEffect(()=>{
        if(!show){
            let timeOutClose = setTimeout(() => {
                dispatch(deleteNotificationAction(id));
            }, 600);

            return ()=>{
                clearTimeout(timeOutClose);
            };
        }
    },[show, dispatch, id]);



    const closeNotification = () => {
        const notification = document.getElementById(id);

        setShow(false);

        notification.style = `
            transform:translateY(-100px);
            opacity: 0;
            height: 0;
            margin-bottom: 0;
            z-index: 0;
        `;
    };
    return (
        <CSSTransition
            in={show}
            timeout={0}
            classNames={{
                enterActive: styles.NotificationActive,
                enterDone: styles.NotificationDone,
                exit: styles.NotificationExit,
                exitDone: styles.NotificationExitDone
            }}
            appear={true}
        >
            <div id={id}
                 className={`${notificationClasses.join(' ')}`}
                 {...css && {style: css}}
                 >
                <div className={styles.NotificationContent}>
                    {children}
                </div>
                <span onClick={()=>{
                    closeNotification();

                }}
                      className={styles.NotificationClose}>
                    &times;
                </span>
            </div>
        </CSSTransition>
    );


};

export default Notification;