import React from "react";
import styles from "./SectionArticleItem.module.css";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";

const SectionArticleItem = ({ data }) => {
    return (
        <div>
            <NavLink
                to={`/knowledge/article/${data?._id}`}
                className={styles.container}
            >
                <div className={styles.title_zone} style={{ paddingLeft: data.sectionId && data.isSub ? "15px" : '0px' }}>
                    {data.sectionId && data.isSub && <div className={styles.dot}></div>}
                    <span className={styles.title}>{data?.title}</span>
                </div>
                {data?.updatedAt && (
                    <span className={styles.date}>
                        {format(new Date(data?.updatedAt), "dd.MM.yyyy")}
                    </span>
                )}
            </NavLink>
            {data?.articles?.length > 0 &&
                data?.articles.map((item) => {
                    return <SectionArticleItem key={item._id} data={item} />;
                })}
        </div>
    );
};

export default SectionArticleItem;
