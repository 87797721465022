import React, {useEffect} from 'react';
import CurrencyRatesDashboard from '../../../landing/dashboards/currencyRatesDashboard/CurrencyRatesDashboard';
import AppContent from "../../../appContent/AppContent";
import {useDispatch} from "react-redux";
import {getMonthsCurrencyRates} from "../../../../actions/currency";
import CurrencyRatesPickerComponent from './currencyRatesPicker/CurrencyRatesPicker';

const CurrencyRates = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMonthsCurrencyRates());
    }, [dispatch]);

    return (
        <AppContent>
            <CurrencyRatesDashboard />
            <CurrencyRatesPickerComponent />
        </AppContent>
    )
}

export default CurrencyRates;