/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import styles from "./KnowledgeBase.module.css";
import KnowledgeBaseSearch from "./knowledgeBaseSearch/KnowledgeBaseSearch";
import { useDispatch } from "react-redux";
import { setSearchText } from "../../reducers/knowledgeReducer";
import SearchBg from "./searchBg/SearchBg";

const KnowledgeBase = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(false);


    const onSearchBgHandler = () => {
        setValue('');
        dispatch(setSearchText(''));
        setSearchData([]);
    };

    const onSetSearchText = (value) => {
        setValue(value);
        dispatch(setSearchText(value));
    };
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Поиск по Базе знаний</h2>
            <KnowledgeBaseSearch
                isPos={true}
                value={value}
                loading={loading}
                setValue={onSetSearchText}
                placeholder="Поиск"
                searchData={searchData}
                setSearchData={setSearchData}
                style={{
                    width: "370px",
                    borderRadius: "16px",
                    border: "none",
                    zIndex: 3,
                }}
                focusStyle={{ width: "680px", background: "#fff" }}
                onSearchBgHandler={onSearchBgHandler}
            />
            <SearchBg 
                value={value} 
                setSearchData={setSearchData} 
                setLoading={setLoading} 
                onSearchBgHandler={onSearchBgHandler} 
            />
        </div>
    );
};

export default KnowledgeBase;
