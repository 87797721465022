/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import '../User.css';
import styles from "../User.module.css";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, registerUser } from "../../../../actions/user";
import AppContent from "../../../appContent/AppContent";
import Button from "../../../ui/button_v2/ButtonV2";
import UsersContent from "../../users/usersContent/UsersContent";
import EditForm from "../editForm/EditForm";
import CloseIcon from "../../../ui/icons/CloseIcon";
import DragnDrop from "../DragnDrop/DragnDrop";
import { RulesData } from "../../../../core/config";
import { MODULE_TYPE, getModuleRule, isEdit } from "../../../../core/helpers";
import ContentLoader from "../../../ui/contentLoader/ContentLoader";

const CreateUser = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const users = useSelector(state => state.user.items);
    const currentUser = useSelector(state => state.user.currentUser);
    const [tab, setTabs] = useState('InfoTab');
    const [userData, setUserData] = useState({
        firstName: { errors: false, value: '' },
        lastName: { errors: false, value: '' },
        email: { errors: false, value: '' },
        phone: { errors: false, value: '' },
        password: { errors: false, value: '' },
        capability: 2,
        rules: RulesData.default.manager
    });
    const [loading, setLoading] = useState(true);
    const userErrors = useSelector(state => state.user.errors);
    const rule = getModuleRule(currentUser.rules, MODULE_TYPE.USERS);
    const [formClicked, setFormClicked] = useState(false);


    useEffect(() => {
        dispatch(fetchUsers(setLoading));
    }, [dispatch]);

    useMemo(() => {
        if (userData.capability === 1) {
            setUserData({ ...userData, rules: RulesData.default.admin });
        } else if (userData.capability === 2) {
            setUserData({ ...userData, rules: RulesData.default.manager });
        } else if (userData.capability === 3) {
            setUserData({ ...userData, rules: RulesData.default.guest });
        }
    }, [formClicked]);

    useEffect(() => {
        if (userErrors) {
            let theFields = { ...userData };
            userErrors.map((error) => {
                if (error.param in userData) {
                    theFields[error.param] = {
                        value: userData[error.param].value,
                        error: error.msg
                    };
                }
                return error;
            });
            setUserData({ ...theFields });
        }
    }, [userErrors]);

    const onSaveHandler = () => {
        const body = {
            firstName: userData.firstName.value,
            lastName: userData.lastName.value,
            email: userData.email.value,
            phone: userData.phone.value,
            password: userData.password.value,
            rules: userData.rules,
            capability: userData.capability
        };

        dispatch(registerUser(body, onSuccess));
    };

    const onSuccess = (link) => {
        history.push(link);
    };

    return (
        <AppContent>
            {loading ? <ContentLoader /> : (
                <div className="container wrapper">
                    <div className="row userSetting">
                        <div className={styles.LeftBlock}>
                            <UsersContent users={users} current="create" />
                        </div>
                        <div className={styles.RightBlock}>

                            <div className={styles.BtnSection}>

                            </div>
                            {isEdit(rule) ?
                                <div className={styles.UserContentBlock}>
                                    <div className={`${styles.ControlPanel} row`}>
                                        <div className={`${styles.TabBlock} col-7`}>
                                            <Button className={(tab === 'InfoTab') ? 'tab active' : 'tab'}
                                                onClick={async () => {
                                                    setTabs('InfoTab');
                                                }}>Профиль пользователя</Button>
                                            <Button className={(tab === 'AccessTab') ? 'tab active' : 'tab'}
                                                onClick={(event) => {
                                                    setTabs('AccessTab');
                                                }}>Права доступа</Button>
                                        </div>
                                        <div className={`${styles.BtnBlock} col-5`}>
                                            <NavLink className='delete' to='/users'>Отмена <CloseIcon /></NavLink>
                                            <Button type={'primary'}
                                                onClick={onSaveHandler}
                                                className='save'>
                                                Сохранить
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={styles.TitleBlock}>
                                        <h1>Новый пользователь</h1>
                                    </div>
                                    <div
                                        className={(tab === 'InfoTab') ? ` ${styles.UserFieldBlock} ${styles.Active}` : `${styles.UserFieldBlock}`}>
                                        {userData && (<EditForm
                                            user={userData}
                                            rule={rule}
                                            formClicked={formClicked}
                                            setFormClicked={setFormClicked}
                                            onChange={(data) => {
                                                setUserData({ ...data });
                                            }} />)}
                                    </div>
                                    <div
                                        className={(tab === 'AccessTab') ? ` ${styles.UserFieldBlock} ${styles.Active}` : `${styles.UserFieldBlock}`}>
                                        <DragnDrop userData={userData} setUserData={(data) => {
                                            setUserData({ ...data });
                                        }} />
                                    </div>

                                </div>
                                :
                                <div className={styles.UserContentBlock}>
                                    <div className={`${styles.ControlPanel} row`}>

                                    </div>
                                    <div className={styles.TitleBlock}>
                                        <h1>Нет прав доступа</h1>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </AppContent>
    );
};

export default CreateUser;
