import React, { useEffect, useState } from 'react';
import styles from './OfferCard.module.css';
import ContentLoader from "../../../ui/contentLoader/ContentLoader";
import { useDispatch, useSelector } from "react-redux";
import AppContent from "../../../appContent/AppContent";
import { fetchOfferDataById } from "../../../../actions/offer";
import { NavLink } from "react-router-dom";
import PaymentsTab from "./offerCardTabs/paymentTab/PaymentsTab";
import PaymentsHistoryTab from "./offerCardTabs/paymentHistoryTab/PaymentsHistoryTab";
import { setCurrentOffer } from "../../../../reducers/offerReduser";
import { delayByLoader } from '../../../../utils/deleyByLoader';

const OfferCard = ({ match }) => {
    const dispatch = useDispatch();
    const offerId = match.params.offerId;
    const offer = useSelector(state => state.offers.current);
    const [loading, setLoading] = useState(true);
    const [contentLoader, setContentLoader] = useState(false);
    const [activeTab, setActiveTab] = useState('rates');

    useEffect(() => {
        dispatch(fetchOfferDataById(offerId, setLoading));
        return () => {
            dispatch(setCurrentOffer(null));
        }
    }, [dispatch, offerId]);

    const onTabClickHandler = (value) => {
        delayByLoader(true, setContentLoader, 300);
        setActiveTab(value);
    };

    return (
        <AppContent>
            <div className={`container wrapper`}>
                {loading && <ContentLoader />}
                {offer &&
                    <div className='row'>
                        <div className='col-12'>
                            <div className='m-3'><NavLink className={styles.BackLink} to="/offers">Назад</NavLink></div>
                            <div className={styles.OfferCardContent}>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <h1 className={styles.Title}>Оффер {offer.title}</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={`col-2 pr-0 ${styles.BorderRight}`}>
                                        <ul className={styles.TabLinks}>
                                            <li className={activeTab === 'rates' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('rates')}>Ставки по офферу</li>
                                            <li className={activeTab === 'history' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('history')}>История изменений ставок</li>
                                        </ul>
                                    </div>
                                    <div className={`col-10 ${styles.offersCard}`} >
                                        {
                                            contentLoader && <div className={styles.contentLoader}>
                                                <ContentLoader />
                                            </div>
                                        }
                                        {activeTab === 'rates' &&
                                            <PaymentsTab offer={offer} />
                                        }
                                        {activeTab === 'history' &&
                                            <PaymentsHistoryTab offer={offer} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppContent>
    );
};

export default OfferCard;
