import React, { useMemo, useState } from "react";
import { getCurrencySymbol } from "../../../../core/helpers";
import styles from "./BillingContent.module.css";
import BillingContentLi from "./BillingContentLi/BillingContentLi";

const BillingContent = ({
  billingData,
  setSortData,
  sortData,
  page,
  sumTax,
  sumRevenue,
}) => {
  const [dropDown, setDropDown] = useState("");
  const billingList = useMemo(() => {
    if (billingData.length === 0) return [];

    return billingData.map((billing, index) => (
      <BillingContentLi
        key={billing.id}
        billing={billing}
        index={index}
        dropDown={dropDown}
        setDropDown={(val) => setDropDown(val)}
        // eslint-disable-next-line react-hooks/exhaustive-deps
        page={page}
        isActive={dropDown === index}
      />
    ));
  }, [billingData, dropDown, page]);

  const billingSumRevenue = useMemo(() => {
    if (sumRevenue.length === 0) return [];
    return sumRevenue
      .sort((a, b) => (a._id > b._id ? 1 : -1))
      .map((item, index) => (
        <span key={index}>
          {item.totalQuantity
            .toString()
            .replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ")}{" "}
          {getCurrencySymbol(item._id)}
        </span>
      ));
  }, [sumRevenue]);

  function sortField(param) {
    if (param === sortData.name) {
      if (sortData.value < 2) {
        return { name: param, value: sortData.value + 1 };
      } else {
        return { name: param, value: 1 };
      }
    } else {
      return { name: param, value: 1 };
    }
  }

  return (
    <div className={styles.ListContainer}>
      <div className={`${styles.ListHeader} row`}>
        <div
            className={
              sortData.name === "affiseId"
                  ? `${styles.Num} ${styles.Active}`
                  : styles.Num
            }
            onClick={() => setSortData(sortField("affiseId"))}
        >
          ID
          <div
              className={
                sortData.value === 1
                    ? `${styles.Icon} ${styles.Down}`
                    : `${styles.Icon} ${styles.Up}`
              }
          ></div>
        </div>
        <div className={styles.Partner}>Партнер</div>
        <div className={styles.Manager}>Менеджер</div>
        <div className={styles.Type}>Тип транзакции</div>
        <div
            className={
              sortData.name === "status"
                  ? `${styles.Status} ${styles.ActiveDouble}`
                  : `${styles.Status}`
            }
            onClick={() => setSortData(sortField("status"))}
        >
          Статус выплаты
          <div
              className={
                sortData.value === 1
                    ? `${styles.Icon} ${styles.Down}`
                    : `${styles.Icon} ${styles.Up}`
              }
          ></div>
        </div>

        <div
            className={
              sortData.name === "paySystemName"
                  ? `${styles.PaymentType} ${styles.Active}`
                  : styles.PaymentType
            }
            onClick={() => setSortData(sortField("paySystemName"))}
        >
          Способ выплаты
          <div
              className={
                sortData.value === 1
                    ? `${styles.Icon} ${styles.Down}`
                    : `${styles.Icon} ${styles.Up}`
              }
          ></div>
        </div>

        <div className={styles.Recipient}>Получатель</div>
        <div
            className={
              sortData.name === "amount"
                  ? `${styles.Sum} ${styles.Active}`
                  : `${styles.Sum}`
            }
            onClick={() => setSortData(sortField("amount"))}
        >
          Сумма
          <div
              className={
                sortData.value === 1
                    ? `${styles.Icon} ${styles.Down}`
                    : `${styles.Icon} ${styles.Up}`
              }
          ></div>
        </div>

        <div
            className={
              sortData.name === "convertAmount"
                  ? `${styles.ConvertSum} ${styles.ActiveDouble}`
                  : `${styles.ConvertSum}`
            }
            onClick={() => setSortData(sortField("convertAmount"))}
        >
          Сумма после конвертации
          <div
              className={
                sortData.value === 1
                    ? `${styles.Icon} ${styles.Down}`
                    : `${styles.Icon} ${styles.Up}`
              }
          ></div>
        </div>
        <div
            className={
              sortData.name === "tax"
                  ? `${styles.Tax} ${styles.Active}`
                  : styles.Tax
            }
            onClick={() => setSortData(sortField("tax"))}
        >
          Комиссия
          <div
              className={
                sortData.value === 1
                    ? `${styles.Icon} ${styles.Down}`
                    : `${styles.Icon} ${styles.Up}`
              }
          ></div>
        </div>
        <div
            className={
              sortData.name === "postedDate"
                  ? `${styles.Date} ${styles.Active}`
                  : `${styles.Date}`
            }
            onClick={() => setSortData(sortField("postedDate"))}
        >
          Дата
          <div
              className={
                sortData.value === 1
                    ? `${styles.Icon} ${styles.Down}`
                    : `${styles.Icon} ${styles.Up}`
              }
          ></div>
        </div>
        <div className={`${styles.BtnBlock} `}></div>
      </div>
      <ul className={styles.ListBody}>{billingList}</ul>

      <div className={`${styles.ListHeader} ${styles.TaxCountContainer} row`}>
        <p className={styles.ListHeaderTitle}>Всего за период: </p>
        <div className={`${styles.Num}`}>
        </div>
        <div className={`${styles.Partner}`}></div>
        <div className={`${styles.Type}`}></div>
        <div className={styles.Status}></div>
        <div className={styles.PaySystem}></div>

        <div className={styles.SumBlock}>
          <p className={styles.BillingSumRevenueTitle}>Сумма транзакций:</p> 
            {billingSumRevenue}
        </div>
        
        <div className={styles.TaxCount}>
          <p className={styles.TaxCountTitle}>Сумма комиссий:</p>
          {sumTax.length > 0 &&
            sumTax
              .sort((a, b) => (a._id > b._id ? 1 : -1))
              .map((item, index) => (
                <span key={index} className={styles.TaxCountItem}>
                  {item.totalQuantity
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ") +
                    " " +
                    getCurrencySymbol(item._id)}
                </span>
              ))}
        </div>
        <div className={styles.Date}></div>
        <div className={`${styles.BtnBlock} `}></div>
      </div>

    </div>
  );
};

export default BillingContent;
