import React from 'react';
import Input from "../input/Input";
import SearchIcon from "../icons/SearchIcon";
import styles from './Search.module.css';

const Search = ({ value, filterData, requestFilterData, setValue, placeholder, label, onClick, maxWidth = "", style = {}, fontSize = '' }) => {
    return (
        <div {...style} className={styles.SearchContainer} onClick={onClick}>
            {label &&
                <label>{label}</label>
            }

            <div className={styles.SearchBlock} style={{ maxWidth: maxWidth }}>
                <SearchIcon />
                <Input 
                    fontSize={fontSize} 
                    value={value} 
                    setValue={setValue} 
                    type="search" 
                    placeholder={placeholder} 
                    style={{ paddingRight: "10px" }} 
                    requestFilterData={requestFilterData}
                    filterData={filterData}
                />
            </div>

        </div>
    );
};

export default Search;