import React from 'react';

const BillingStatusFinished = () => {
    return (
        <svg  width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 10H1V4.44444H4V5.55556H8V1.11111H4.5V0H0V10ZM4 1.11111H1V3.33333H4.5V4.44444H7V2.22222H4V1.11111Z" fill="#0ECDB6"/>
            <path d="M1 1.11111V3.33333H4.5V4.44444H7V2.22222H4V1.11111H1Z" fill="#0ECDB6"/>
        </svg>
    );
};

export default BillingStatusFinished;