import AppContent from "../appContent/AppContent";
import styles from './Landing.module.css'
import { useSelector } from "react-redux";
import { MODULE_TYPE, getModuleRule, isRead } from "../../core/helpers";
import React from "react";
import BillingDashboard from "./dashboards/billingDashboard/BillingDashboard";
import CurrencyRatesDashboard from "./dashboards/currencyRatesDashboard/CurrencyRatesDashboard";

const Landing = () => {
    const user = useSelector(state => state.user.currentUser);
    const ruleBilling = getModuleRule(user.rules, MODULE_TYPE.BILLING);
    return (
        <AppContent>
            <div className="container wrapper">
                <div className={styles.TitleBlock}>
                    <h2>Административная панель X-Partners</h2>
                </div>
                <div className='row'>
                    {isRead(ruleBilling) &&
                        <div className='col-6'>
                            <BillingDashboard link='/billing' />
                        </div>
                    }
                    {isRead(ruleBilling) &&
                        <div className='col-6'>
                            <CurrencyRatesDashboard title='Курс валют' />
                        </div>
                    }
                </div>
            </div>

        </AppContent>
    );
};

export default Landing;
