import React, { lazy, Suspense } from 'react';
import { useDispatch } from "react-redux";
import { addModal, removeModal } from "../../../../../reducers/appReducer";
import Button from "../../../../ui/button_v2/ButtonV2";
import ContentLoader from "../../../../ui/contentLoader/ContentLoader";
import styles from "../AdvertiserCard.module.css";
import AdvertisersInvoiceList from '../advertisersInvoiceList/AdvertisersinvoiceList';
const AdvertisersCreateInvoice = lazy(()=>import('../../../../modals/advertisersCreateInvoice/AdvertisersCreateInvoice'));

const InvoicesTab = ({ advertiser }) => {
    const dispatch = useDispatch();

    const createAct = ({advertiser, data}) => {
        dispatch(addModal({
            id: 'createInvoice',
            size: 'big',
            children:
                <Suspense fallback={<ContentLoader/>}>
                    {AdvertisersCreateInvoice &&
                        <AdvertisersCreateInvoice
                            onError={() => {
                                dispatch(removeModal('createInvoice'))
                            }}
                            data={data}
                            advertiser={advertiser}
                        />}
                </Suspense>
        }));
    }

    return (
        <div className={styles.AdvTabContent}>
            <div className="p-2">
                <Button type={'primary'} onClick={() => createAct({ advertiser:advertiser, data:'' } ) }>Создать акт</Button>
            </div>
            <div className={styles.DocList}>
                <AdvertisersInvoiceList invoices={advertiser.invoices} data={advertiser} />
            </div>
        </div>
    );
};

export default InvoicesTab;
