import React, { useRef, useState } from 'react';
import styles from "./OptionColoredLabel.module.css";

const OptionColoredLabel = ({ text, color, classes, comment="" }) => {
    let currentClasses = [styles.Label];
    const [tooltip,setTooltip] = useState(false)
    const commentRef = useRef(null)

    if ( classes ) {
        currentClasses.push( classes );
    }

    switch ( color ) {
        case 'green':
            currentClasses.push( styles.Green );
            break;
        case 'blue':
            currentClasses.push( styles.Blue );
            break;
        case 'orange':
            currentClasses.push( styles.Orange );
            break;
        case 'greenBorder':
            currentClasses.push( styles.GreenBorder );
            break;
        case 'grey':
            currentClasses.push( styles.Grey );
            break;
        case 'yellow':
            currentClasses.push( styles.Yellow );
            break;
        case 'red':
            currentClasses.push( styles.Red );
            break;
        default:
            break;
    }

    return (
          <span style={{position:'relative'}} className={ currentClasses.join(' ') }>
          {text}
          {comment && <div onMouseEnter={()=>setTooltip(true)} onMouseLeave={()=>setTooltip(false) } className={styles.info_icon}>
              {comment &&  <div ref={commentRef} className={styles.tooltip} style={{
              top:`${-commentRef?.current?.getBoundingClientRect()?.height * 0.99}px`,
              visibility:tooltip ? 'visible' : 'hidden'
              }}>{comment}</div> }
            </div>}
        </span>
    );
};

export default OptionColoredLabel;
