import {setConversionsItems,setConversionsErrors} from '../reducers/conversionsReducer';
import $api from "../core/api";
import {createNotification} from "./app";
import {processError} from '../core/helpers';

export const searchConversions = (params,setLoading) => {
    setLoading(true)
    return async dispatch => {
        try {
            const response = await $api.post(`/api/conversions`, params);
            dispatch(setConversionsItems(response.data));
        } catch (e) {
            dispatch(setConversionsItems([]));
            processError(dispatch, e);
        }finally{
          setLoading(false)
        }
    }

}
export const searchConversionsReconciliation = (params,setLoading) => {
    setLoading(true)
    return async dispatch => {
        try {
            const response = await $api.post(`/api/conversions/searchreconciliation`, params);
            dispatch(setConversionsItems(response.data));
        } catch (e) {
            dispatch(setConversionsItems([]));
            processError(dispatch, e);
        }finally{
         setLoading(false)
        }
    }

}
export const setStatus = (params) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/conversions/savestatus`, params);
            if (response.data) {
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Статус изменен',
                }));
            } else {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Произошла ошибка сохранения',
                }));
            }
            dispatch(setConversionsItems([]));
        } catch (e) {
            processError(dispatch, e);
        }
    }

}

export const saveComment = (params) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/conversions/savecomment`, params);
            if (response.data) {
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Комментарий изменен',
                }));
            }
            dispatch(setConversionsItems([]));
        } catch (e) {
            processError(dispatch, e);
        }
    }

}

export const saveSum = (params) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/conversions/savesum`, params);
            if (response.data) {
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Конверсии изменены',
                }));
            } else {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Произошла ошибка сохранения',
                }));
            }
            dispatch(setConversionsItems([]));
        } catch (e) {
            processError(dispatch, e);
        }
    }

}
export const update = (params) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/conversions/update`, params);
            if (response.data) {
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Конверсия изменена',
                }));
            } else {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Произошла ошибка сохранения',
                }));
            }
            dispatch(setConversionsItems([]));
        } catch (e) {
            processError(dispatch, e);
        }
    }

}

export const generateClickId = (params) => {
    return async dispatch => {
        try {
             const response = await $api.post(`/api/conversions/generateclickid`, params);
             return response.data;
        } catch (e) {
            processError(dispatch, e);
        }
    }

}
export const create = (params) => {
    return async dispatch => {
        try {
             const response = await $api.post(`/api/conversions/create`, params);
             if(response.data){
                 dispatch(createNotification({
                     type: 'primary',
                     children: 'Конверсия изменена',
                 }));
             }else{
                 dispatch(createNotification({
                     type: 'error',
                     children: 'Произошла ошибка сохранения',
                 }));
             }

            return response.data;
        } catch (e) {

            if (e.response) {
                const response = e.response;
                const errors = response.data.errors;
                if (errors && Array.isArray(errors) && errors.length > 0) {
                    dispatch(setConversionsErrors(errors));
                }else{
                    if (response.status === 403) {
                        dispatch(createNotification({
                            type: 'error',
                            children: response.data.message
                        }));
                    } else {
                        const msgText = e.response.data.message ? e.response.data.message : 'Что-то пошло не так!';
                        dispatch(createNotification({
                            type: 'error',
                            children: msgText
                        }));
                    }
                }


            } else {
                dispatch(createNotification({
                    type: 'error',
                    children: e.request ? 'Ошибка подключения' : 'Непредвиденная ошибка'
                }));
            }
        }
    }

}

export const saveReconciliation = (params) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/conversions/savereconciliation`, params);
            if (response.data) {
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Комментарий изменен',
                }));
            }
            dispatch(setConversionsItems([]));
        } catch (e) {
            processError(dispatch, e);
        }
    }

}

export const importCsvData = async ( dispatch, file, idType, delimiter, confirm ) => {
    try {
        const formData = new FormData();

        formData.append('idType', idType );
        formData.append('delimiter', delimiter );

        if ( confirm ) {
            formData.append('csv', file );
            formData.append('confirm', confirm );
        } else {
            formData.append('csv', file, file.name );
        }

        const response = await $api.post(`/api/conversions/process-csv`, formData,  {withCredentials: true});
        return response.data;

    } catch (e) {
        console.log(e.message);
        processError( dispatch, e );
    }
}

export const deleteImportedCsv = async ( dispatch, file ) => {
    try {
        await $api.post(`/api/conversions/delete-csv`, { csv: file },  {withCredentials: true});
    } catch (e) {
        console.log(e.message);
        processError( dispatch, e );
    }
}
