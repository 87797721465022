import React, {useEffect, useState} from 'react';
import ru from "date-fns/locale/ru";
import DatePicker, {registerLocale} from "react-datepicker";
import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import styles from "./SingleProject.module.css";
import AppContent from "../../../appContent/AppContent";
import {getExpenseCategoryList, getProjectExpenses} from "../../../../actions/partners-expenses";
import {getIncomeCategoryList, getProjectIncome} from "../../../../actions/partners-income";
import MultiSelect from "../../../ui/multiSelect/MultiSelect";
import Button from "../../../ui/button_v2/ButtonV2";
import {setYearAgoDate} from "../../../../core/helpers";
import ProjectRow from "./projectRow/ProjectRow";

const SingleProject = ({ location }) => {
    const dispatch = useDispatch();
    const project = location.state.project;
    const [expensesCategoryOptions, setExpensesCategoryOptions] = useState([]);
    const [incomeCategoryOptions, setIncomeCategoryOptions] = useState([]);
    const [searchParams, setSearchParams] = useState({
        projectId: project.id,
        startDate: setYearAgoDate(),
        endDate: new Date(),
        expenseCategories: [],
        incomeCategories: []
    });
    // eslint-disable-next-line no-unused-vars
    const [expenses, setExpenses] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [income, setIncome] = useState([]);
    const [expensesIncome, setExpensesIncome] = useState([]);

    registerLocale('ru', ru);

    useEffect(() => {
        const getExpenseCategories = async () => {
            const categoryList = await dispatch(getExpenseCategoryList());
            if (categoryList) {
                setExpensesCategoryOptions(categoryList.map(category => { return {
                    value: category.id,
                    label: category.name
                }}));
            }
        };
        const getIncomeCategories = async () => {
            const categoryList = await dispatch(getIncomeCategoryList());
            if (categoryList) {
                setIncomeCategoryOptions(categoryList.map(category => { return {
                    value: category.id,
                    label: category.name
                }}));
            }
        };
        getExpenseCategories().then(() => console.log('Expense categories list loaded'));
        getIncomeCategories().then(() => console.log('Income categories list loaded'));
        getExpenses().then(() => console.log('Expenses loaded'));
        getIncome().then(() => console.log('Income loaded'));
        getExpensesIncome().then(() => console.log('ExpensesIncome loaded'));
        // eslint-disable-next-line
    }, [dispatch]);

    const getExpenses = async () => {
        const expenses = await dispatch(getProjectExpenses( searchParams ));
        if (expenses) {
            setExpenses(expenses);
        }
    };

    const getIncome = async () => {
        const income = await dispatch(getProjectIncome( searchParams ));
        if (income) {
            setIncome(income);
        }
    };

    const getExpensesIncome = async () => {
        const expenses = await dispatch(getProjectExpenses( searchParams ));
        const income = await dispatch(getProjectIncome( searchParams ));
        if (expenses) {
            // setIncome(income);
            setExpenses(expenses);
        }
        if (income) {
            setIncome(income);
            // setExpenses(expenses);
        }

        const expensesIncomeArr = [...expenses, ...income].sort((a, b) => {
                if ( (b.year - a.year) !== 0 ) {
                    return b.year - a.year;
                } else {
                    return  b.month - a.month;
                }
            });
        setExpensesIncome(expensesIncomeArr);
    };

    const onDataPickerChange = (dates) => {
        const [start, end] = dates;
        setSearchParams({
            ...searchParams,
            startDate: start,
            endDate: end
        });
    };

    return (
        <AppContent>
            <div className="container wrapper">
                <div className="mb-3 ml-1">
                    <NavLink to={'/mb-projects'}>Назад</NavLink>
                </div>
                {project &&
                        <div className={styles.Title}>
                            <h2>{project.name}</h2>
                            <div className={styles.Divider}></div>
                            <div className={styles.PartnerData}>
                                <div className="mb-1">{project.partner.email}</div>
                                <div className={styles.ID}>ID: {project.partner.affiseId}</div>
                            </div>
                            <NavLink
                                className={styles.NavLink}
                                to={{
                                    pathname:`/mb-projects/project/add-expenses`,
                                    state: {project: project}
                                }}
                            >
                                <span>+</span> Внести расход
                            </NavLink>
                            <NavLink
                                className={styles.NavLink}
                                to={{
                                    pathname:`/mb-projects/project/add-income`,
                                    state: {project: project}
                                }}
                            >
                                <span>+</span> Внести доход
                            </NavLink>
                        </div>
                }
                <div className={styles.Filter}>
                    <div className="row">
                        <div className="col-2 pr-0">
                            <label className={styles.Label}>Период</label>
                            <DatePicker
                                className={`${styles.DatePicker} inputField`}
                                selectsRange
                                locale={ru}
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                                startDate={searchParams.startDate}
                                endDate={searchParams.endDate}
                                onChange={onDataPickerChange}
                            />
                        </div>
                        <div className="col-2 pr-0">
                            <label className={styles.Label}>Статья расходов</label>
                            <MultiSelect
                                placeholder="Все"
                                allOptions={expensesCategoryOptions}
                                onChange={val => setSearchParams({
                                    ...searchParams, expenseCategories: val
                                })}
                            />
                        </div>
                        <div className="col-2 pr-0">
                            <label className={styles.Label}>Статья доходов</label>
                            <MultiSelect
                                placeholder="Все"
                                allOptions={incomeCategoryOptions}
                                onChange={val => setSearchParams({
                                    ...searchParams, incomeCategories: val
                                })}
                            />
                        </div>
                        <div className="col-1 d-flex align-items-end">
                            <Button
                                className={styles.GetReportBtn}
                                type={'primary'}
                                onClick={() => getExpensesIncome(searchParams)}
                            >
                                Показать
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.ContentBlock}>
                    <div className={styles.ContentHeader}>
                        <div className="col-2">Статья расхода</div>
                        <div className="col-2">Статья дохода</div>
                        <div className="col-1-6 text-center">Сумма расхода</div>
                        <div className="col-1-6 text-center">Сумма дохода</div>
                        <div className="col-1-5-p-left ">Дата расхода</div>
                        <div className="col-1-5-p-left ">Дата дохода</div>
                    </div>
                    {expensesIncome.length
                        ? 
                            expensesIncome.map((item, i) => {
                                return <ProjectRow 
                                    project={project} 
                                    data={item} 
                                    getExpenses={getExpenses} 
                                    getIncome={getIncome} 
                                    key={item.id} 
                                    getExpensesIncome={getExpensesIncome}
                                />
                            }
                                
                            )
                        : <div className="mt-5 text-center">Расходы и доходы отсутствуют</div>
                    }
                </div>
            </div>
        </AppContent>
    );
};

export default SingleProject;
