import React from 'react';
import './Icons.css';
const SearchIcon = () => {
    return (
        <svg className="searchIcon" width="24" fill="none" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0031 16.0062C13.7662 16.0062 16.0062 13.7662 16.0062 11.0031C16.0062 8.23995 13.7662 6 11.0031 6C8.23995 6 6 8.23995 6 11.0031C6 13.7662 8.23995 16.0062 11.0031 16.0062Z"  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.0001 17.9999L14.5386 14.5383"  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default SearchIcon;