const SET_PAGES_OFFERS =  "SET_PAGES_OFFER";
const SET_OFFER =  "SET_OFFER";
const SET_OFFER_SINGLE =  "SET_OFFER_SINGLE";
const SET_CURRENT_OFFER = "SET_CURRENT_OFFER";
const SET_OFFER_ERRORS = "SET_OFFER_ERRORS";
const OFFER_RESET_PAGINATION = "OFFER_RESET_PAGINATION";


const defaultState = {
    items: [],
    item: {},    //TODO this property is left for compatibility. Must be remove in future
    current: null,
    errors: false,
    pagination:'',
};
export default function offerReducer(state = defaultState, action){
    switch (action.type) {
        case SET_PAGES_OFFERS:
            const item = action.payload.docs;
            delete action.payload.docs;
            return {
                ...state,
                items:  item,
                pagination: action.payload
            };
        case SET_OFFER:
            return {
                ...state,
                items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
            };

        case SET_OFFER_SINGLE:
            return {
                ...state,
                item: action.payload,
            };
        case SET_CURRENT_OFFER:
            return {
                ...state,
                current: action.payload,
            };
        case SET_OFFER_ERRORS:
            return {
                ...state,
                errors:  action.payload
            };
        case OFFER_RESET_PAGINATION:
            return {
                ...state,
                pagination: {
                  ...state.pagination,
                  page: 1
                }
            };
        default:
            return state
    }
}
export const setOfferSingle = (item) => ({type: SET_OFFER_SINGLE, payload: item});
export const setCurrentOffer = (item) => ({type: SET_CURRENT_OFFER, payload: item});
export const setOfferItem = (item) => ({type: SET_OFFER, payload: item});
export const setOfferPageItems = (items) => ({type: SET_PAGES_OFFERS, payload: items});
export const setOfferErrors = (items) => ({type: SET_OFFER_ERRORS, payload: items})
export const setOfferPaginationReset = () => ({type: OFFER_RESET_PAGINATION});
