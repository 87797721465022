import React from 'react';
import styles from './AdvertisersInvoiceList.module.css';
import AdvertisersInvoiceLi from "./advertisersInvoiceLi/AdvertisersInvoiceLi";

const AdvertisersinvoiceList = ({invoices,data}) => {
    return (
        <div className={styles.ListBlock}>
            <div className={styles.BillingPartLabel}>
                <div className={`${styles.Number}`}>Номер акта</div>
                <div className={`${styles.Date}`}>Дата акта</div>
                <div className={`${styles.Btn}`}></div>
            </div>
            <ul className={styles.HistoryList}>
                {invoices.length > 0
                    ? invoices.map((invoice, index) =>
                        <AdvertisersInvoiceLi invoice={invoice} data={data} key={index}/> )
                    : <li className="mt-3"><i>Акты отсутствуют</i></li>
                }
            </ul>
        </div>
    );
};

export default AdvertisersinvoiceList;
