import React from 'react';
import styles from './ReportCurrencyRow.module.css';
import parentStyles from "../../../Reports.module.css";
import ReportCurrencyColumn from "./reportCurrencyColumn/ReportCurrencyColumn";

export const ReportCurrencyRow = ({currency, projectIncome, currencyRowData, monthsArr}) => {
    let columnData = '';
    const columns = monthsArr?.map((date, index) => {
        // Remove duplication of income items in offers section
        if (!projectIncome[currency]?.[date.year]?.[date.month]?.[0]?.income) {
            // columnData = currencyRowData ? currencyRowData[date.year]?.[date.month] : '';
            columnData = currencyRowData?.[date.year]?.[date.month] || '';
        }
        return <ReportCurrencyColumn 
                    month={`${date.year.slice(2)}.${date.month}`} 
                    data={columnData} 
                    key={index} 
                />
    });

    return (
        <div className={styles.Names}>
            <div className={styles.CurrencyRow}>
                <div className={parentStyles.firstColumn}>
                    <div className={styles.SwitchBlock}>
                        <div className={styles.TableCell}></div>
                        <div>
                            {Array.isArray(columnData) && columnData?.length ? columnData.map((el, index) => {
                            return <div key={index} className={parentStyles.totalCell}>{el.currency}</div>
                        }): <div className={parentStyles.totalCell}>{currency ? currency : ''}</div>}
                        </div>
                    </div>
                </div>
                    {columns.length > 0 && columns}
            </div>
        </div>
        
    );
};

export const ReportCurrencyRowExpenses = ({currency, currencyRowData, monthsArr, arr}) => {
    let columnData;
    const columns = monthsArr?.map((date, index) => {
        columnData = currencyRowData ? currencyRowData[date.year][date.month] : '';
        return <ReportCurrencyColumn month={`${date.year.slice(2)}.${date.month}`} data={columnData} key={index} />
    });

    return (
        <div className={styles.Names}>
            <div className={styles.CurrencyRow}>
                <div className={parentStyles.firstColumn}>
                    <div className={styles.SwitchBlock}>
                        <div className={styles.TableCell}></div>
                        <div>
                            {arr?.length ? arr.map((el, index) => {
                            return <div key={index} className={parentStyles.totalCell}>{el.split('_')[1]}</div>
                        }): <div className={parentStyles.totalCell}>{currency ? currency : ''}</div>}
                        </div>
                    </div>
                </div>
                    {columns.length > 0 && columns}
            </div>
        </div>
        
    );
};

export const ReportCurrencyRowIncome = ({currency, currencyRowData, monthsArr, arr}) => {
    let columnData;
    const columns = monthsArr?.map((date, index) => {
        columnData = currencyRowData ? currencyRowData[date.year][date.month] : '';
        return <ReportCurrencyColumn month={`${date.year.slice(2)}.${date.month}`} data={columnData} key={index} />
    });

    return (
        <div className={styles.Names}>
            <div className={styles.CurrencyRow}>
                <div className={parentStyles.firstColumn}>
                    <div className={styles.SwitchBlock}>
                        <div className={styles.TableCell}></div>
                        <div>
                            {arr?.length ? arr.map((el, index) => {
                            return <div key={index} className={parentStyles.totalCell}>{el.split('_')[1]}</div>
                        }): <div className={parentStyles.totalCell}>{currency ? currency : ''}</div>}
                        </div>
                    </div>
                </div>
                    {columns.length > 0 && columns}
            </div>
        </div>
        
    );
};
