import React from 'react';
import './Icons.css';
const ArrowIcon = () => {
    return (
			<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
				<path d="M4.22894 6.17157L3.28613 7.11438L8.00017 11.8284L12.7142 7.1144L11.7714 6.17159L8.00018 9.94281L4.22894 6.17157Z" />
				<path d="M4.22894 6.17157L3.28613 7.11438L8.00017 11.8284L12.7142 7.1144L11.7714 6.17159L8.00018 9.94281L4.22894 6.17157Z" />
			</svg>

    );
};

export default ArrowIcon;