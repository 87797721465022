import React from 'react';
import './Input.css';

const Input = ({ value, filterData, requestFilterData, type, placeholder, setValue, required, addBillingComment, isSearch, setIsFocuses, disabled, style = {}, fontSize, onRedirect }) => {
    if (required) {
        required = 'required';
    }
    const handleKeyPress = (event) => {
        if (event?.charCode === 13 && type === 'search') {
            if (filterData && requestFilterData) {
                requestFilterData(filterData);
            } else if (requestFilterData) {
                requestFilterData(value);
                setValue(value);
            }
        }
        // if (event.charCode === 13 && type === 'search') {
        //         setValue({
        //             value: event.target.value,
        //             key: 'enter'
        //         })
        // }
        if (event.charCode === 13 && type === 'comment') {
            addBillingComment(event.target.value)
        }

        if (event.charCode === 13 && type === 'knowledge') {
            onRedirect?.(event.target.value)
        }
    }
    return (

        <input
            data-lpignore="true"
            className={`inputField ${isSearch ? 'pd-left' : ''}`}
            onChange={(event) => setValue(event.target.value)}
            value={value}
            style={{ ...style, fontSize: type === 'knowledge' ? '14px' : '12px' }}
            type={type}
            placeholder={placeholder}
            onFocus={() => setIsFocuses?.(true)}
            onBlur={() => value.length === 0 && setIsFocuses?.(false)}
            required={required}
            disabled={disabled}
            onKeyPress={(event) => handleKeyPress(event)}
        />

    );
};

export default Input;
