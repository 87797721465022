import React from 'react';
import AdvertisersContentLi from './advertisersContentLi/AdvertisersContentLi';
import styles from './AdvertisersContent.module.css';

const AdvertisersContentHeader = () => {
    return (
        <div className={`${styles.ListHeader} row`}>
            <div className={styles.Name}>Email</div>
            <div className={styles.Id}>Название</div>
            <div className={styles.Manager}>Менеджер</div>
            <div className={styles.Site}>Юр лицо</div>
            <div className={styles.Offers}>Офферы</div>
            <div className={styles.Country}>Страна</div>
            <div className={styles.Status}>Статус</div>
            <div className={styles.Btn}></div>
        </div>
    );
};

const AdvertisersContent = ({ data }) => {
    return (
        <div className={styles.ListContainer}>
            {data.length > 0 ? (
                <div>
                    <AdvertisersContentHeader />
                    <ul className={styles.ListBody}>
                        {data.map(item => (
                            <AdvertisersContentLi key={item.id} item={item} />
                        ))}
                    </ul>
                </div>
            ) : (
                <div className={styles.NoData}>
                    <h1>Ничего не найдено</h1>
                </div>
            )}
        </div>
    );
};

export default AdvertisersContent;
