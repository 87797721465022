import $api from "../core/api";
import {processError} from "../core/helpers";
import {createNotification} from "./app";

export const getMbProjectGroupList = () => {
    return async ( dispatch ) => {
        try {
            const response = await $api.get(`/api/mb-projects/project-group-list`);
            if ( response && response.data && response.data.success ) {
                return response.data.groups;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const getProjectById = ( id ) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.get(`/api/mb-projects/project/${id}`);
            if ( response && response.data && response.data.success ) {
                return response.data.project;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getMbProjectsList = (ids) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.get(`/api/mb-projects/projects-list?ids=${ids}`);
            if ( response && response.data && response.data.success ) {
                return response.data.projects;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const getMbProjectsByGroups = (params) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.get(`/api/mb-projects/projects-by-groups`, {
                params: params
            });
            if ( response && response.data && response.data.success ) {
                return response.data.projects;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const createMbProjectGroup = (data) => {
    return async ( dispatch ) => {
        try {
            if (!data.groupName || data.groupName.length < 3 || data.groupName.length > 32) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Название группы должно быть не меньше 3 и не больше 32 символов'
                }));
                return;
            }
            const response = await $api.post(`/api/mb-projects/create-group`, data);
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Группа проектов успешно ${data.update ? 'обновлена' : 'создана'}`
                }));
                return response.data.projects;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const deleteMbProjectGroup = (groupName) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.post(`/api/mb-projects/delete-group`, {groupName});
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Группа проектов успешно удалена'
                }));
                return response.data.projects;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const createMbProject = ( data ) => {
    return async ( dispatch ) => {
        try {
            let error = false, message = '';
            if ( !data.name || data.name < 3 || data.name > 32 ) {
                error = true;
                message += `Название проекта должно быть не меньше 3 и не больше 32 символов\n`;
            }
            if ( !data.projectGroup ) {
                error = true;
                message += `Выберите группу проекта\n`;
            }
            if ( !data.partner ) {
                error = true;
                message += `Выберите вебмастера\n`;
            }
            if ( !data.offers || data.offers.length === 0  ) {
               error = true;
               message += 'Выберите офферы';
            }

            if (error) {
                dispatch(createNotification({
                    type: 'error',
                    children: message
                }));
                return;
            }

            const response = await $api.post(`/api/mb-projects/create-project`, data);
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Проект успешно ${data.update ? 'обновлен' :'создан'}`
                }));
                return true;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const deleteMbProject = (id) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.post(`/api/mb-projects/delete-project`, {id: id});
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Проект удален'
                }));
                return true;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}
