import React from 'react';

const BillingStatusPending = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 10.9091C3.28878 10.9091 1.09091 8.71122 1.09091 6C1.09091 3.28878 3.28878 1.09091 6 1.09091C8.71122 1.09091 10.9091 3.28878 10.9091 6C10.9091 8.71122 8.71122 10.9091 6 10.9091Z" fill="#0ECD21"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 10.9091C3.28878 10.9091 1.09091 8.71122 1.09091 6C1.09091 3.28878 3.28878 1.09091 6 1.09091C8.71122 1.09091 10.9091 3.28878 10.9091 6C10.9091 8.71122 8.71122 10.9091 6 10.9091ZM2.72727 6.03875L5.04144 8.35292L8.89839 4.49597L8.127 3.72458L5.04144 6.81014L3.49866 5.26736L2.72727 6.03875Z" fill="#0ECD21"/>
        </svg>
    );
};

export default BillingStatusPending;