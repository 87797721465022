import { v4 as uuidv4 } from 'uuid';
import {addNotificationAction} from "../reducers/appReducer";

export const createNotification = ({type, children, className=false, link=false, action=false}) => {
    return async dispatch => {
        try {
            const id = uuidv4();
            dispatch(addNotificationAction({
                id: id,
                children: children,
                type: type,
                className: className,
                link: link,
                action: action
            }));
        } catch (e) {
            console.log(e.message);
            console.log('Can not create notification');
        }
    }
};


