import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import parentStyles from "../../../Reports.module.css";
import styles from "./ReportTotalsRow.module.css";
import TotalAmountsCols from "../totalAmountsCols/TotalAmountsCols";

const ReportTotalsRow = ({monthsArr}) => {
    const reportTotals = useSelector(state => state.reports.reportTotals);
    const [reportTotalsColumns, setReportTotalsColumns] = useState([]);

    useEffect(() => {
        if (Object.keys(reportTotals).length > 0) {
            const totalsColumnsArr = monthsArr.map((date, index) => {
                const amountData = reportTotals[`${date.year}.${date.month}`];
                return <TotalAmountsCols date={date} amountData={amountData} key={index}/>;
            });
            setReportTotalsColumns(totalsColumnsArr);
        }
        // eslint-disable-next-line
    }, [reportTotals]);

    return (
        <div className={styles.ReportTableRow}>
            <div className="d-flex">
                <div className={`${parentStyles.firstColumn} ${styles.FirstColumn}`}>
                    Totals:
                </div>
                {reportTotalsColumns.length > 0 && reportTotalsColumns}
            </div>
        </div>
    );
};

export default ReportTotalsRow;
