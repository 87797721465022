export const fetchImageAsFile = (url) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const filename = getFilenameFromUrl(url);
      const file = new File([blob], filename, { type: blob.type });
      return file;
    });
};

function getFilenameFromUrl(url) {
  const urlParts = url?.split("/");
  return urlParts[urlParts.length - 1];
}

export const extractFilenameFromPath = (filePath) => {
  // Split the path using the directory separator
  const pathParts = filePath.split('/');

  // Get the last part of the path, which is the filename
  const filename = pathParts[pathParts.length - 1];

  return filename;
}