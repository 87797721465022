/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import styles from './SearchBg.module.css';
import { useDispatch } from 'react-redux';
import { searchPost } from '../../../actions/knowledge';

const SearchBg = ({ value, setSearchData, setLoading, onSearchBgHandler }) => {
    const dispatch = useDispatch();
    const searchBgRef = useRef(null);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        if (value) {
            dispatch(searchPost(value, setSearchData, setLoading));
            searchBgRef?.current?.classList?.add(styles.searchBgActive);
            searchBgRef?.current?.classList?.remove(styles.searchBgPassive);
        } else {
            if (flag) {
                searchBgRef?.current?.classList?.remove(styles.searchBgActive);
                searchBgRef?.current?.classList?.add(styles.searchBgPassive);
            }
            setFlag(true);
        }
    }, [value]);

    return (
        <div ref={searchBgRef} className={`${styles.searchBg} `} onClick={onSearchBgHandler}></div>
    )
};

export default SearchBg;