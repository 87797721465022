import React from 'react';
import styles from './PayRequisitesItem.module.css';

const PayRequisitesList = ({ requisites }) => {

    return (
        <>
            { Object.keys( requisites ).map( (item, index) =>
                <div className={styles.RequisitesItem} key={index}>
                    <span className={styles.ItemLabel}>{item}: </span>
                    <span className={styles.ItemValue}>{requisites[item]}</span>
                </div>
            )}
        </>
    );
};

export default PayRequisitesList;
