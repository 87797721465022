import React, { useEffect, useState } from 'react';
import styles from './DashboardBillingList.module.css';
import DashboardBillingLi from '../dashboardBillingLi/DashboardBillingLi';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBillings } from '../../../../actions/billing';
import ContentLoader from '../../../ui/contentLoader/ContentLoader';

const DashboardBillingList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Set initial state to true for loading indicator
  const params = useSelector(state => state.billing.items);

  useEffect(() => {
    dispatch(
      fetchBillings({
        sort: { name: 'postedDate', value: 2 },
        filters: [{ name: 'period', value: 'day30' }, { name: 'status', value: -2 }],
        dashboard: true
      },setLoading)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Early return if loading
  if (loading) {
    return <ContentLoader />;
  }

  // Early return if there are no billing items
  if (params.length === 0) {
    return <div className={styles.NoData}>Биллинги отсутствуют</div>;
  }

  return (
    <ul className={styles.List}>
      {params.map((billing, index) => (
        <DashboardBillingLi key={billing.id} index={index} billing={billing} />
      ))}
    </ul>
  );
};

export default DashboardBillingList;
