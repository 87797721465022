import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools } from 'redux-devtools-extension'
import thunk from "redux-thunk";
import appReducer from "./appReducer";
import userReducer from "./userReducer";
import billingReducer from "./billingReducer";
import conversionsReducer from "./conversionsReducer";
import offerReducer from "./offerReduser";
import partnersReducer from "./partnersReducer";
import advertisersReducer from "./advertisersReduser";
import billsReducer from "./billsReduser";
import knowledgeReducer from "./knowledgeReducer";
import reportsReducer from "./reportsReducer";

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    billing:billingReducer,
    conversions:conversionsReducer,
    offers:offerReducer,
    partners:partnersReducer,
    advertisers:advertisersReducer,
    bills: billsReducer,
    knowledge: knowledgeReducer,
    reports: reportsReducer
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
