import React from 'react';
import './Icons.css';
const ClearIcon = () => {
    return (
        <svg className='clearIcon' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.15028 3.20735C3.88993 2.947 3.46782 2.947 3.20747 3.20735C2.94712 3.4677 2.94712 3.88981 3.20747 4.15016L7.05729 7.99998L3.20751 11.8498C2.94716 12.1101 2.94716 12.5322 3.20751 12.7926C3.46786 13.0529 3.88997 13.0529 4.15032 12.7926L8.0001 8.94279L11.8499 12.7926C12.1102 13.0529 12.5323 13.0529 12.7927 12.7926C13.053 12.5322 13.053 12.1101 12.7927 11.8498L8.94291 7.99998L12.7927 4.15016C13.0531 3.88981 13.0531 3.4677 12.7927 3.20735C12.5324 2.947 12.1103 2.947 11.8499 3.20735L8.0001 7.05717L4.15028 3.20735Z" />
        </svg>

    );
};

export default ClearIcon;