import React, {useState} from 'react';
import styles from "../../SingleBilling.module.css";
import ChildrenHistoryItem from "./ChildrenHistoryItem";
import {format} from "date-fns";
import {FilterStatusOptions} from "../../../../../../core/config";
import ArrowIcon from "../../../../../ui/icons/ArrowIcon";

const ChildrenHistory = ({billing, children}) => {
    const [dropDown,setDropDown] = useState(false);
    const billingStatusObj = FilterStatusOptions.find( status => status.value === billing.status );

    const clickDropdown = () => {
        if ( dropDown ) {
            setDropDown( false );
        } else {
            setDropDown( true );
        }
    }
    return (
        <div className={styles.InfoBlock}>
            <div className="row">
                <div className="col-2 d-flex align-items-center">
                    {billingStatusObj && billingStatusObj.label }
                </div>
                <div className={`col-7 ${styles.DropDownBlock}`} onClick={clickDropdown}>
                    Платеж выполнен частями <ArrowIcon />
                </div>
                <div className="col-3">{format(new Date(billing.postedDate), "dd.MM.yyyy HH:mm:ss")}</div>
            </div>
            {dropDown &&
                <div className="p-3">
                    {children.length > 0 && children.map((item, index) =>
                        <ChildrenHistoryItem billing={item} key={index}/>
                    )}
                </div>
            }
        </div>
    );
};

export default ChildrenHistory;
