import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import styles from '../AdvertiserCard.module.css';
import InputFile from "../../../../ui/input/inputFile/InputFile";
import {uploadLegalDocs, deleteLegalDoc} from "../../../../../actions/advertisers";
import PdfIcon from "../../../../ui/icons/PdfIcon";
import Button from "../../../../ui/button_v2/ButtonV2";

const DocumentsTab = ({advertiser}) => {
    const dispatch = useDispatch();
    const legalDocs = advertiser.legalDocs ?? {};
    const [fields, setFields] = useState({
        charter: {value: null, error: false},
        taxStatement: {value: null, error: false},
        legalEntityCard: {value: null, error: false},
    });


    const uploadFile = () => {
        const docs = {
            charter: fields.charter.value,
            taxStatement: fields.taxStatement.value,
            legalEntityCard: fields.legalEntityCard.value
        };
        dispatch( uploadLegalDocs( advertiser.id, docs ) )
            .then( result => {
                if (result.errors) {
                    const newFields = {...fields};
                    result.errors.forEach( err => {
                        if(err.param in fields){
                            newFields[err.param] = {
                                value: fields[err.param].value,
                                error: err.msg
                            };
                        }
                    });
                    setFields({...newFields});
                } else {
                    setFields({
                        charter: {value: null, error: false},
                        taxStatement: {value: null, error: false},
                        legalEntityCard: {value: null, error: false},
                    });
                }
            });
    };

    const deleteDoc =  ( doc ) => {
         dispatch( deleteLegalDoc( advertiser.id, doc ) );
    }

    return (
        <div className={styles.AdvTabContent}>

            {(fields.taxStatement.value || fields.charter.value || fields.legalEntityCard.value) &&
                <Button type={'primary'} className={styles.Save} onClick={uploadFile}>Сохранить</Button>
            }

            <div className="row">
                <div className="col-12">
                    <div className={styles.uploadBlock}>
                        <label className={styles.FieldLabel}>Справка о налоговом резиденстве</label>
                        {legalDocs.taxStatement
                            ? <div className="d-flex align-items-center">
                                <a href={legalDocs.taxStatement} target='_blank' rel="noreferrer" ><PdfIcon/></a>
                                <Button type='danger' onClick={() => deleteDoc('taxStatement')} >Удалить</Button>
                              </div>
                            : <>
                                <InputFile
                                    type="file"
                                    label={fields.taxStatement.value ? fields.taxStatement.value.name : 'Загрузить'}
                                    value={fields.taxStatement.value}
                                    setValue={ val => setFields({...fields,
                                        taxStatement: {value: val, error: false} })
                                    }
                                />
                                {fields.taxStatement.error && <div className={styles.FieldError}>{fields.taxStatement.error}</div>}
                            </>
                        }
                    </div>
                    <div className={styles.SectionDivider}></div>
                </div>
                <div className="col-12">
                    <div className={styles.uploadBlock}>
                        <label className={styles.FieldLabel}>Устав</label>
                        {legalDocs.charter
                            ? <div className="d-flex">
                                <a href={legalDocs.charter} target='_blank' rel="noreferrer" ><PdfIcon/></a>
                                <Button type='deleteRed' onClick={() => deleteDoc('charter')} >Удалить</Button>
                              </div>
                            : <>
                                <InputFile
                                    type="file"
                                    label={fields.charter.value ? fields.charter.value.name : 'Загрузить'}
                                    value={fields.charter.value}
                                    setValue={ val => setFields({...fields,
                                        charter: {value: val, error: false} })
                                    }
                                />
                                {fields.charter.error && <div className={styles.FieldError}>{fields.charter.error}</div>}
                            </>
                        }
                    </div>
                    <div className={styles.SectionDivider}></div>
                </div>
                <div className="col-12">
                    <div className={styles.uploadBlock}>
                        <label className={styles.FieldLabel}>Карточка юридического лица</label>
                        {legalDocs.legalEntityCard
                            ? <div  className="d-flex">
                                <a href={legalDocs.legalEntityCard} target='_blank' rel="noreferrer" ><PdfIcon/></a>
                                <Button type='deleteRed' onClick={() => deleteDoc('legalEntityCard')} >Удалить</Button>
                              </div>
                            : <>
                                <InputFile
                                    type="file"
                                    label={fields.legalEntityCard.value ? fields.legalEntityCard.value.name : 'Загрузить'}
                                    value={fields.legalEntityCard.value}
                                    setValue={ val => setFields({...fields,
                                        legalEntityCard: {value: val, error: false} })
                                    }
                                />
                                {fields.legalEntityCard.error && <div className={styles.FieldError}>{fields.legalEntityCard.error}</div>}
                            </>
                        }
                    </div>
                    <div className={styles.SectionDivider}></div>
                </div>
                <p className="col-12" style={{color: 'red'}}>Только файлы в формате pdf и размером не больше 5mb</p>
            </div>
        </div>
    );
};

export default DocumentsTab;
