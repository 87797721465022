/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./KnowledgeSearchPage.module.css";
import AppContent from "../../appContent/AppContent";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import KnowledgeBaseSearch from "../../knowledgeBase/knowledgeBaseSearch/KnowledgeBaseSearch";
import { useDispatch } from "react-redux";
import { searchPost } from "../../../actions/knowledge";
import { setSearchText } from "../../../reducers/knowledgeReducer";

const KnowledgeSearchPage = ({ match }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(match.params.searchData);
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [contentLoader, setContentLoader] = useState(true);

    useEffect(() => {
        dispatch(searchPost(value, setSearchData, setContentLoader));

        return () => {
            dispatch(setSearchText(''));
        }
    }, [])

    useEffect(() => {
        if (value) {
            dispatch(searchPost(value, setSearchData, setLoading));
        } else {
            setSearchData([]);
        }
    }, [value]);

    return (
        <AppContent>
            <div className={`partnersContainer container`}>
                {contentLoader ? (
                    <ContentLoader />
                ) : (
                    <div className={styles.wrapperContainer}>
                        <KnowledgeBaseSearch
                            isPos={false}
                            value={value}
                            loading={loading}
                            setValue={setValue}
                            placeholder="Поиск"
                            searchData={searchData}
                            setSearchData={setSearchData}
                            style={{ width: "100%", borderRadius: "16px", border: "none", marginBottom: '35px' }}
                        />
                    </div>
                )}
            </div>
        </AppContent>
    );
};

export default KnowledgeSearchPage;
