import React from 'react';
import styles from "../../SingleBilling.module.css";
import HistoryItem from "./HistoryItem";
import {bySortFieldDESC} from "../../../../../../core/helpers";
import ChildrenHistory from "./ChildrenHistory";

const HistoryTab = ({billing}) => {
    const historyData = [...billing.history];

    if ( billing.children && billing.children.length > 0 ) {
        historyData.push({ children: billing.children, date: billing.children[0].postedDate });
    }

    historyData.sort(bySortFieldDESC('date'));

    return (
        <div className={styles.TabContainer}>
            {billing.history.length > 0
                ?
                historyData.map( (item, index) =>
                    (item.children && item.children.length > 0 )
                        ? <ChildrenHistory billing={billing} children={item.children} key={index}/>
                        : <HistoryItem history={item} key={index}/>
                )
                : <div>История отсутствует</div>
            }
        </div>
    );
};

export default HistoryTab;
