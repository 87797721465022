import React from 'react';

const BillingStatusArchive = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 10.9091C3.28878 10.9091 1.09091 8.71122 1.09091 6C1.09091 3.28878 3.28878 1.09091 6 1.09091C8.71122 1.09091 10.9091 3.28878 10.9091 6C10.9091 8.71122 8.71122 10.9091 6 10.9091Z" fill="#808080"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 10.9091C3.28878 10.9091 1.09091 8.71122 1.09091 6C1.09091 3.28878 3.28878 1.09091 6 1.09091C8.71122 1.09091 10.9091 3.28878 10.9091 6C10.9091 8.71122 8.71122 10.9091 6 10.9091ZM8.39408 3.7686C8.59947 3.98898 8.58733 4.33412 8.36695 4.53952L6.77088 6.0271L8.25846 7.62317C8.46386 7.84354 8.45171 8.18869 8.23134 8.39408C8.01097 8.59947 7.66582 8.58733 7.46043 8.36695L5.97285 6.77088L4.37678 8.25846C4.15641 8.46386 3.81126 8.45171 3.60587 8.23134C3.40048 8.01097 3.41262 7.66582 3.63299 7.46043L5.22906 5.97285L3.74148 4.37678C3.53609 4.15641 3.54823 3.81126 3.7686 3.60587C3.98898 3.40048 4.33412 3.41262 4.53952 3.63299L6.0271 5.22906L7.62317 3.74148C7.84354 3.53609 8.18869 3.54823 8.39408 3.7686Z" fill="#808080"/>
            <path d="M8.36695 4.53952C8.58733 4.33412 8.59947 3.98898 8.39408 3.7686C8.18869 3.54823 7.84354 3.53609 7.62317 3.74148L6.0271 5.22906L4.53952 3.63299C4.33412 3.41262 3.98898 3.40048 3.7686 3.60587C3.54823 3.81126 3.53609 4.15641 3.74148 4.37678L5.22906 5.97285L3.63299 7.46043C3.41262 7.66582 3.40048 8.01097 3.60587 8.23134C3.81126 8.45171 4.15641 8.46386 4.37678 8.25846L5.97285 6.77088L7.46043 8.36695C7.66582 8.58733 8.01097 8.59947 8.23134 8.39408C8.45171 8.18869 8.46386 7.84354 8.25846 7.62317L6.77088 6.0271L8.36695 4.53952Z" fill="#808080"/>
            <g clipPath="url(#clip0_1358_18335)">
                <path d="M5.3335 5.99996C5.14939 5.99996 5.00016 6.14919 5.00016 6.33329C5.00016 6.51739 5.14939 6.66663 5.3335 6.66663H6.66683C6.85091 6.66663 7.00016 6.51739 7.00016 6.33329C7.00016 6.14919 6.85091 5.99996 6.66683 5.99996H5.3335Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M3.3335 2.66663C2.78121 2.66663 2.3335 3.11434 2.3335 3.66663V8.33329C2.3335 8.88558 2.78121 9.33329 3.3335 9.33329H8.66683C9.21912 9.33329 9.66683 8.88558 9.66683 8.33329V3.66663C9.66683 3.11434 9.21912 2.66663 8.66683 2.66663H3.3335ZM8.66683 3.33329H3.3335C3.14939 3.33329 3.00016 3.48252 3.00016 3.66663V4.66663H9.00016V3.66663C9.00016 3.48252 8.85091 3.33329 8.66683 3.33329ZM3.00016 8.33329V5.33329H9.00016V8.33329C9.00016 8.51739 8.85091 8.66663 8.66683 8.66663H3.3335C3.14939 8.66663 3.00016 8.51739 3.00016 8.33329Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1358_18335">
                    <rect width="8" height="8" fill="white" transform="translate(2 2)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default BillingStatusArchive;