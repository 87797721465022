import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import TimerNumber from "./timerNumber/TimerNumber";
import classes from './Timer.Module.css';

const Timer = ({ expiryTimestamp, timer, setTimer }) => {
    const { seconds, minutes, restart } = useTimer({ expiryTimestamp, onExpire: () => setTimer(false) });

    useEffect(() => {
        restart(expiryTimestamp);
        setTimer(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiryTimestamp]);

    return (
        <div className={classes.Timer}>
            {timer &&
                <div className={classes.Container}>
                    <TimerNumber value={minutes} /><TimerNumber value={seconds}/>
                </div>
            }
        </div>
    );
};

export default Timer;
