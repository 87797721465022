/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./FindSection.module.css";
import FindSectionItem from "./findSectionItem/FindSectionItem";
import { useDispatch, useSelector } from "react-redux";
import { MODULE_TYPE, getModuleRule, isEdit } from "../../../core/helpers";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import AddNewSection from "../addNewSection/AddNewSection";
import "./style.css";
import {
    changeSectionPosition,
    fetchKnowledge,
} from "../../../actions/knowledge";

const FindSection = ({ data, setLoading }) => {
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.user.currentUser);
    const rule = getModuleRule(currentUser.rules, MODULE_TYPE.KNOWLEDGE);
    const [contentLoading, setContentLoading] = useState(false);

    useEffect(() => {
        if (data.length > 0) {
            setTimeout(() => {
                function DragNSort (config) {
                    this.$activeItem = null;
                    this.$container = config.container;
                    this.$items = this.$container.querySelectorAll(
                        "." + config?.itemClass
                    );
                    this.dragStartClass = config.dragStartClass;
                    this.dragEnterClass = config.dragEnterClass;
                }

                DragNSort.prototype.removeClasses = function () {
                    [].forEach.call(
                        this.$items,
                        function ($item) {
                            $item.classList.remove(this.dragStartClass, this.dragEnterClass);
                        }.bind(this)
                    );
                };

                DragNSort.prototype.on = function (elements, eventType, handler) {
                    [].forEach.call(
                        elements,
                        function (element) {
                            element.addEventListener(
                                eventType,
                                handler.bind(element, this),
                                false
                            );
                        }.bind(this)
                    );
                };

                DragNSort.prototype.onDragStart = function (_this, event) {
                    _this.$activeItem = this;

                    this.classList.add(_this.dragStartClass);
                    event.dataTransfer.effectAllowed = "move";
                    event.dataTransfer.setData("text/html", this.innerHTML);
                };

                DragNSort.prototype.onDragEnd = function (_this) {
                    this.classList.remove(_this.dragStartClass);
                };

                DragNSort.prototype.onDragEnter = function (_this) {
                    this.classList.add(_this.dragEnterClass);
                };

                DragNSort.prototype.onDragLeave = function (_this) {
                    this.classList.remove(_this.dragEnterClass);
                };

                DragNSort.prototype.onDragOver = function (_this, event) {
                    if (event.preventDefault) {
                        event.preventDefault();
                    }

                    event.dataTransfer.dropEffect = "move";

                    return false;
                };

                DragNSort.prototype.onDrop = async function (_this, event) {
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }

                    if (_this.$activeItem && _this.$activeItem !== this) {
                        await onChangePositionHandler(_this.$activeItem.id, this.id);
                        _this.$activeItem.innerHTML = this.innerHTML;
                        this.innerHTML = event.dataTransfer.getData("text/html");
                    }

                    _this.removeClasses();

                    return false;
                };

                DragNSort.prototype.bind = function () {
                    this.on(this.$items, "dragstart", this.onDragStart);
                    this.on(this.$items, "dragend", this.onDragEnd);
                    this.on(this.$items, "dragover", this.onDragOver);
                    this.on(this.$items, "dragenter", this.onDragEnter);
                    this.on(this.$items, "dragleave", this.onDragLeave);
                    this.on(this.$items, "drop", this.onDrop);
                };

                DragNSort.prototype.init = function () {
                    this.bind();
                };

                setTimeout(() => {
                    var draggable = new DragNSort({
                        container: document.querySelector(".drag-list"),
                        itemClass: "drag-item",
                        dragStartClass: "drag-start",
                        dragEnterClass: "drag-enter",
                    });
                    isEdit(rule) && draggable.init();
                }, 200);
            }, 100);
        }
    }, [data]);

    const onChangePositionHandler = async (start, end) => {
        dispatch(changeSectionPosition(start, end, onSuccess));
    };

    const onSuccess = () => {
        setContentLoading(true);
        dispatch(fetchKnowledge(setContentLoading));
    };

    return (
        <div className={styles.container}>
            {contentLoading ? (
                <ContentLoader zIndex={1} />
            ) : (
                <div className={`${styles.items} row`}>
                    <div className="drag-list row">
                        {data.map((item) => {
                            return (
                                <FindSectionItem
                                    key={item._id}
                                    data={item}
                                    isAccess={isEdit(rule)}
                                />
                            );
                        })}

                        {/* {isEdit(rule) && <AddSection setLoading={setContentLoading} />} */}
                        {isEdit(rule) && <AddNewSection setLoading={setContentLoading} />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FindSection;
