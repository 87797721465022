import React from 'react';
import styles from './PartnersPaymentsLi.module.css';
import {getCurrencySymbol} from "../../../../../../core/helpers";
import {format} from "date-fns";
import BillingCreditStatus from "../../../../../pages/billing/billingCreditStatus/BillingCreditStatus";

const PartnersPaymentLi = ({ item, index, data }) => {
    const currencySymbol = getCurrencySymbol(item.currency) ? getCurrencySymbol(item.currency) : '';

    return (
        <li className={styles.Item}>
            <div className={styles.Num}>
                {item.affiseId === '-99' || item.billingType === 'credit' || item.billingType === 'adjustment'
                    ? <BillingCreditStatus billing={item}  />
                    : item.affiseId }
            </div>
            <div className={styles.Sum}>{item.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')} {currencySymbol}</div>
            <div className={item.credit && item.credit < 0 ? styles.Debit : `${styles.Debit} ${styles.Active}`}>{item.credit ? `${item.credit.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ') } ${getCurrencySymbol( item.convertCurrency ? item.convertCurrency : item.currency)}` : '-'}</div>
            <div className={styles.Date}>{format(new Date(item.postedDate), 'dd.MM.yyyy')}</div>
        </li>
    );
};

export default PartnersPaymentLi;
