const SET_SECTIONS_DATA = "SET_SECTIONS_DATA";
const SET_ARTICLES_DATA = "SET_ARTICLES_DATA";
const SET_ARTICLES_PARAMS_DATA = "SET_ARTICLES_PARAMS_DATA";
const SET_CURRENT_SECTIONS_DATA = "SET_CURRENT_SECTIONS_DATA";
const SET_CURRENT_ARTICLE_DATA = "SET_CURRENT_ARTICLE_DATA";
const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";

const defaultState = {
    data: [],
    articlesData: [],
    articlesSortParams: {
        name: true,
        date: false,
    },
    currentSectionData: {},
    currentArticleData: {},
    searchText: ''
};


export default function knowledgeReducer (state = defaultState, action) {
    switch (action.type) {
        case SET_SECTIONS_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case SET_ARTICLES_DATA:
            return {
                ...state,
                articlesData: action.payload,
            };
        case SET_ARTICLES_PARAMS_DATA:
            return {
                ...state,
                articlesSortParams: { ...state.articlesSortParams, ...action.payload },
            };
        case SET_CURRENT_SECTIONS_DATA:
            return {
                ...state,
                currentSectionData: action.payload,
            };
        case SET_CURRENT_ARTICLE_DATA:
            return {
                ...state,
                currentArticleData: action.payload,
            };
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload,
            };
        default:
            return state
    }
}


export const setSectionsData = (data) => ({ type: SET_SECTIONS_DATA, payload: data });
export const setArticlesData = (data) => ({ type: SET_ARTICLES_DATA, payload: data });
export const setArticlesParamsData = (data) => ({ type: SET_ARTICLES_PARAMS_DATA, payload: data });
export const setCurrentSectionsData = (data) => ({ type: SET_CURRENT_SECTIONS_DATA, payload: data });
export const setCurrentArticleData = (data) => ({ type: SET_CURRENT_ARTICLE_DATA, payload: data });
export const setSearchText = (value) => ({ type: SET_SEARCH_TEXT, payload: value });
