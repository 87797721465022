import React, { useEffect, useRef, useState } from 'react';
import Search from "../../../ui/seacrh/Search";
import UserList from "../userList/UserList";
import { NavLink } from "react-router-dom";
import AddIcon from "../../../ui/icons/AddIcon";
import { isEdit } from "../../../../core/helpers";
import styles from "./UsersContent.module.css"

const UsersContent = ({ users, current, rule }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [hasScrollbar, setHasScrollbar] = useState(false);
    const [searchUsers, setSearchUsers] = useState([]);
    const userContentRef = useRef(null);

    const checkScrollbar = () => {
        if (userContentRef.current.scrollHeight > userContentRef.current.clientHeight) {
            setHasScrollbar(true);
        } else {
            setHasScrollbar(false);
        }
    };

    useEffect(() => {
        checkScrollbar();
    }, []);

    const onSetSearchQuery = (data) => {
        if (typeof data === 'object') {
            setSearchQuery(data.value);
        } else {
            setSearchQuery(data);
        }
    }

    useEffect(() => {
        const query = searchQuery.toLocaleLowerCase();
        if (searchQuery) {
            const res = users.filter(user => {
                return user.firstName.toLocaleLowerCase().includes(query) || user.lastName.toLocaleLowerCase().includes(query) || (user.email.toLocaleLowerCase().includes(query))
            });

            setSearchUsers(res)
        }
    }, [searchQuery, users]);


    return (
        <div className={`UserContent col-12`}>
            <div className={styles.controlSection}>
                <Search 
                    styles={{ width: '100%' }} 
                    value={searchQuery} 
                    setValue={onSetSearchQuery} 
                    placeholder="id, e-mail, login" 
                />
                {!current && isEdit(rule) && <NavLink to="/users/create" className="add"><AddIcon /></NavLink>}
            </div>
            <div 
                ref={userContentRef} 
                style={{ paddingRight: hasScrollbar ? '15px' : '0px' }} 
                className={`${styles.users} ${current ? styles.leftBlockOpen : styles.leftBlockClose} `} 
            >
                {
                    searchQuery ? <UserList users={searchUsers} current={current} /> : <UserList users={users} current={current} />
                }
            </div>
        </div>
    );
};

export default UsersContent;