import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const ReportChartsComponent = () => {
const reportTotals = useSelector(state => state.reports.reportTotals);
const [chartsData, setChartsData] = useState([]);

useEffect(() => {
  getChartsData();
    // eslint-disable-next-line
}, [reportTotals]);

const getChartsData = async () => {
  const keysTotalsArr = Object.keys(reportTotals);
  const chartsDataArr = [];

    if (keysTotalsArr.length > 0) {
        for (let i = 0; i < keysTotalsArr.length; i++) {
            let chartsPoint = {};
            let year = keysTotalsArr[i].split('.')[0];
            let month = keysTotalsArr[i].split('.')[1];
            let chartDate = `${month}/${year[2]}${year[3]}`;
            let pointValue = reportTotals[keysTotalsArr[i]];

            chartsPoint = {
                date: chartDate,
                доход: pointValue.income ? Number(pointValue.income.toFixed(0)) : 0,
                расход: pointValue.expenses ? Number(pointValue.expenses.toFixed(0)) : 0,
                маржа: pointValue.marginIncome ? Number(pointValue.marginIncome.toFixed(0)) : 0
            };
            chartsDataArr.push(chartsPoint);
        }
    }
    setChartsData(chartsDataArr);
}

  return (
    <LineChart
      width={1430}
      height={300}
      data={chartsData}
      margin={{
        top: 50,
        right: 30,
        left: 70,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="доход"
        stroke="#0e8229"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="расход" stroke="#de9d04" />
      <Line type="monotone" dataKey="маржа" stroke="#0437de" />
    </LineChart>
  );
  
}

export default ReportChartsComponent;