import React, { useMemo } from "react";
import PartnersContentLi from "./partnersContentLi/PartnersContentLi";
import styles from "./PartnersContent.module.css";
import { getCurrencySymbol } from "../../../../core/helpers";

const PartnersContent = ({ data, setSortData, sortData, sumRevenue }) => {
    const isEmpty = data.length === 0;

    const sortField = (param) => {
        if (param === sortData.name) {
            if (sortData.value < 2) {
                return { name: param, value: sortData.value + 1 };
            } else {
                return { name: param, value: 1 };
            }
        } else {
            return { name: param, value: 1 };
        }
    };

    const partnersSumRevenue = useMemo(() => {
        if (sumRevenue?.length === 0) return [];
        return sumRevenue
            ?.sort((a, b) => (a._id > b._id ? 1 : -1))
            .map((item, index) => (
                <span key={index} style={{ display: 'flex', flexDirection: "column" }}>
                    {item.totalQuantity
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ")}{" "}
                    {getCurrencySymbol(item._id)}
                </span>
            ));
    }, [sumRevenue]);

    return (
        <div className={styles.ListContainer}>
            {isEmpty ? (
                <div className={styles.NoData}>
                    <h1>Ничего не найдено</h1>
                </div>
            ) : (
                <>
                    <div className={`${styles.ListHeader} row`}>
                        <div className={`${styles.Id}`}>ID</div>
                        <div className={styles.Name}>Почта, логин</div>
                        <div className={styles.Manager}>Менеджер</div>
                        <div className={styles.Payout}>Последняя выплата</div>
                        <div
                            className={sortData.name === "postedDate"
                                ? `${styles.Debt} ${styles.Active}`
                                : `${styles.Debt}`
                            }

                        >
                            <span className={styles.debt_text}>Долг вебмастера</span>
                            <div
                                className={
                                    sortData.value === 1
                                        ? `${styles.Icon} ${styles.Down}`
                                        : `${styles.Icon} ${styles.Up}`
                                }
                                onClick={() => setSortData(sortField("amount"))}
                            ></div>
                        </div>
                        <div className={styles.Requisites}>Реквизиты</div>
                        <div className={styles.Status}>Статус</div>
                        <div className={styles.Additionally}>Дополнительно</div>
                        <div className={styles.Btn}></div>
                    </div>
                    <div className={`${styles.result_header} row`}>
                        <div className={styles.Id}><span className={styles.result_header_text}>Всего :</span></div>
                        <div className={styles.Name}></div>
                        <div className={styles.Manager}></div>
                        <div className={styles.Payout}></div>
                        <div className={styles.Debt}>
                            <div className={styles.Sum}> {partnersSumRevenue}</div>
                        </div>
                        <div className={styles.Requisites}></div>
                        <div className={styles.Status}></div>
                        <div className={styles.Btn}></div>
                    </div>
                    <ul className={styles.ListBody}>
                        {data.map((item) => (
                            <PartnersContentLi key={item.id} item={item} />
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default PartnersContent;