import React, {useEffect} from 'react';
import AppContent from "../../appContent/AppContent";
import {useDispatch} from "react-redux";
import {setAppError} from "../../../reducers/appReducer";
import styles from "./NotFound.module.css";



const NotFound = () => {

    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setAppError(false));
    });

    return (
        <AppContent className='discover'>
                <div className={styles.main404}>
                    <h1 className={styles.pageHeader}>Ошибка 404</h1>
                    <p>Ой! Похоже, такой страницы не существует или она потерялась</p>
                </div>
        </AppContent>
    );
};

export default NotFound;
