import $api from "../core/api";
import {processError} from "../core/helpers";
import {setBillErrors, setBillsList} from "../reducers/billsReduser";
import {removeModal} from "../reducers/appReducer";

export const getBillsByAdvId = ( advId ) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/bills/get-bills-by-advid`, {
                params: {
                    advId: advId
                },
                withCredentials: true
            });

            if (response.data && response.data.success) {
                dispatch( setBillsList(response.data.bills) );
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const processBill = ( data, type ) => {
    return async dispatch => {
        try {
            dispatch( setBillErrors(false) );
            const formData = new FormData();
            for ( const param in data ) {
                formData.append(param, data[param]);
            }

            const response = await $api.post(`/api/bills/${type}`, formData, {withCredentials: true});

            if (response.data) {
                if (response.data.success) {
                    dispatch(setBillsList(response.data.bills));
                    dispatch(removeModal('createBill'));
                } else {
                    if ( response.data.errors.length > 0 ) {
                        dispatch( setBillErrors(response.data.errors) );
                    }
                }
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

