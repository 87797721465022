import $api from "../core/api";
import {getDateString, getFirstDayOfMonth, getLastDayOfMonth, processError, shiftDateToUTC} from "../core/helpers";

export const getRecentReportsList = () => {
    return async ( dispatch ) => {
        try {
            const response = await $api.get(`/api/reports/recent-reports-list`);
            if ( response && response.data && response.data.success ) {
                return response.data.reports;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getRecentReport = ( reportName ) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.get(`/api/reports/ger-recent-report/${reportName}`);
            if ( response && response.data && response.data.success ) {
                return response.data.report;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getStats = ( searchParams ) => {
    return async ( dispatch ) => {
        try {
            const startDate = getDateString( shiftDateToUTC(getFirstDayOfMonth(searchParams.startDate)) );
            const endDate = getDateString( shiftDateToUTC(getLastDayOfMonth(searchParams.endDate)) );
            const params = {
                startDate: startDate,
                endDate: endDate,
                partners: searchParams.partners,
                projects: searchParams.projects,
                projectsGroups: searchParams.projectsGroups,
                partnersList: searchParams.partnersList
            };

            const response = await $api.post(`/api/reports/get-stats`, params);
            if ( response && response.data && response.data.success ) {
                return response.data.stats;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}
