/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styles from "./BillingPDFConfirmModal.module.css";
import Button from "../../ui/button/Button";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../reducers/appReducer";
import './BillingPDFConfirmModal.css'
import { API_URL } from "../../../core/config";
import { removeInvoicePDF } from "../../../actions/billing";
import { setBillingInvoicePDFPath } from "../../../reducers/billingReducer";
import { useSelector } from "react-redux";

const BillingPDFConfirmModal = ({ data }) => {
    const dispatch = useDispatch();
    const billing = useSelector(state => state.billing.current);

    useEffect(() => {
        document.querySelector("body").style.overflow = "hidden";
        return () => {
            document.querySelector("body").style.overflow = "unset";
            dispatch(setBillingInvoicePDFPath(''));
            // dispatch(removeInvoicePDF(data.billingInvoicePDFPath));
        };
    }, []);

    const onCancelHandler = () => {
        dispatch(removeInvoicePDF(data.billingInvoicePDFPath));
        dispatch(closeModal("BillingPDFConfirmModal"));
    };

    const onCloseHandler = () => {
        dispatch(closeModal("BillingPDFConfirmModal"));
    };

    const onSendInvoice = () => {
        data.send(data.billingInvoicePDFPath)
    };

    return (
        <div className={styles.ModalModule} id="ModalModule">
            {/* <div className={styles.Header}>
                <h2 className={styles.title}>
                    {type === "article" ? "Удаление статьи" : "Удаление раздела "}
                </h2>
                <h2 className={styles.titleName}>{data?.title}</h2>
            </div> */}
            <iframe id="pdfViewer" title="PDF Document Viewer" width="100%" height="600px" src={`${API_URL}/${data.billingInvoicePDFPath}`} frameBorder="0" allowFullScreen></iframe>
            {(billing?.invoice && billing?.status === 2) ? <div className={styles.btns}>
                <Button className={styles.createArticle} onClick={onCloseHandler}>
                    закрыть
                </Button>
            </div>:
            <div className={styles.btns}>
                <Button className={styles.viewBtn} onClick={onCancelHandler}>
                    отменить
                </Button>
                <Button className={styles.createArticle} onClick={onSendInvoice}>
                    оплатить
                </Button>
            </div>}
        </div>
    );
};

export default BillingPDFConfirmModal;
