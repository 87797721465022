import React, {useEffect, useState} from 'react';
import styles from '../../OfferCard.module.css';
import {useDispatch} from "react-redux";
import {fetchOfferHistory} from "../../../../../../actions/offer";
import PaymentHistoryItem from "./PaymentHistoryItem";

const PaymentsHistoryTab = ({offer}) => {
    const dispatch = useDispatch();
    const [history, setHistory] = useState([]);

    useEffect(() => {
        dispatch( fetchOfferHistory(offer.id) )
            .then( data => {
                if ( data ) {
                    setHistory( data );
                }
            });
    }, [dispatch, offer]);

    return (
        <div className={styles.offerTabContent}>
            <h3 className="mt-3 mb-4">История изменения ставок по офферу {offer.affiseId}</h3>
            {history.length > 0
                ? history.map((item, index )=>
                        <PaymentHistoryItem item={item} key={index} />
                      )
                : <div>История операций отсутствует</div>
            }
        </div>
    );
};

export default PaymentsHistoryTab;
