import React, {lazy, Suspense} from 'react';
import styles from "../../SingleBilling.module.css";
import {API_URL} from "../../../../../../core/config"
import iconTrash from "../../../../../../assets/img/icon-trash.svg";
import {format} from "date-fns";
import {deleteDocument} from "../../../../../../actions/billing";
import {addModal, removeModal} from "../../../../../../reducers/appReducer";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";
import {useDispatch} from "react-redux";

const BillingModalVerification = lazy( () => import("../../../../../modals/billingModalVerification/BillingModalVerification"));

const BillingDocument = ({billingId, doc, setLoading}) => {
    const dispatch = useDispatch();

    const deleteDoc = async () => {
        setLoading(true);
        const result = await dispatch(deleteDocument({id: billingId, fileId: doc._id}));
        if (result === 'verify') {
            dispatch(addModal({
                id: 'billingModalVerification',
                size: 'big',
                children:
                    <Suspense fallback={<ContentLoader/>}>
                        {BillingModalVerification &&
                            <BillingModalVerification
                                params={{id: billingId, fileId: doc._id}}
                                type='billingDocumentDelete'
                                onError={() => {
                                    dispatch(removeModal('billingModalVerification'))
                                }}
                            />}
                    </Suspense>
            }));
        }
        setLoading(false);
    };

    return (
        <div className={`${styles.InfoBlock} ${styles.PaySystemBlock}`}>
            <div className="row">
                <div className="col-5 d-flex align-items-center">
                    <div className={styles.DocLabel}>{doc.name}</div>
                </div>
                <div className="col-4 d-flex align-items-center">
                    <div className={styles.DocLabel}>
                        <small>
                            {format(new Date(doc.date), 'LLLL dd yyyy HH:mm:ss')}
                        </small>
                    </div>
                </div>
                <div className="col-3 d-flex justify-content-end">
                    <button className={styles.BtnDelete} onClick={deleteDoc} >
                        <img src={iconTrash} alt="delete"/>
                    </button>
                    <a className={styles.DocLink} href={`${API_URL}/${doc.path}`} target="_blank" rel="noreferrer">Посмотреть</a>
                </div>
            </div>
        </div>
    );
};

export default BillingDocument;
