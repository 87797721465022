/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './Offers.module.css';
import AppContent from "../../appContent/AppContent";
import OffersFilters from "./filters/OffersFilters";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import Pagination from "../../ui/pagination/Pagination";
import OffersContent from "./content/OffersContent";
import { useDispatch, useSelector } from "react-redux";
import { MODULE_TYPE, getModuleRule, isRead } from "../../../core/helpers";
import { Redirect } from "react-router-dom";
import { fetchOffers } from "../../../actions/offer";
import { delayByLoader } from '../../../utils/deleyByLoader';
import { setOfferPaginationReset } from '../../../reducers/offerReduser';

const Offers = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const pagination = useSelector(state => state.offers.pagination);
    const data = useSelector(state => state.offers.items);
    const rule = getModuleRule(useSelector(state => state.user.currentUser.rules), MODULE_TYPE.OFFERS);
    let paramsDefault = {
        search: '',
        page: pagination.page,
    };

    const [params, setParams] = useState(paramsDefault);

    useEffect(() => {
        return () => {
            dispatch(setOfferPaginationReset());
        }
    }, []);

    useEffect(() => {
        delayByLoader(true, setLoading, 200)
        dispatch(fetchOffers(params));
    }, [dispatch, params]);

    if (!isRead(rule)) {
        return (
            <Redirect to='/' />
        );
    }

    return (
        <AppContent>
            <div className={`offersContainer container wrapper`}>
                <div className={`${styles.TitleBlock}`}>
                    <h2>Офферы</h2>
                </div>
                <div className={`${styles.FiltersBlock}`}>
                    <OffersFilters params={params} setParams={setParams} />
                </div>
                {loading ? <ContentLoader /> : (
                    <div className={styles.ContentBlock}>
                        {params.search && <div className={styles.DescSearch}>Результаты поиска</div>}
                        <OffersContent data={data} loading={loading} />
                    </div>
                )}

                <div className={styles.PaginationBlock}>
                    <Pagination paginationData={pagination} paramData={params}
                        setParamData={(val) => setParams({ ...params, page: val })} />
                </div>
            </div>
        </AppContent>
    );
};

export default Offers;