import React, {useEffect, useState} from 'react';
import styles from './MbProjects.module.css';
import AppContent from "../../appContent/AppContent";
import MultiSelect from "../../ui/multiSelect/MultiSelect";
import Button from "../../ui/button_v2/ButtonV2";
import {useDispatch, useSelector} from "react-redux";
import {createMbProjectGroup, getMbProjectsByGroups, getMbProjectsList} from "../../../actions/mbprojects";
import {getProjectPartnersList} from "../../../actions/partners";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import MbProjectsList from "./mbProjectsList/MbProjectsList";
import {NavLink} from "react-router-dom";
import { getPartnerByUserId } from '../../../actions/partners';

const MbProjects = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [partnersSelectOptions, setPartnersSelectOptions] = useState([]);
    const [projectsSelectOptions, setProjectsSelectOptions] = useState([]);
    const [searchParams, setSearchParams] = useState({});
    const [creatGroup, setCreateGroup] = useState(false);
    const [groupName, setGroupName] = useState('');
    const currentUser = useSelector(state => state.user.currentUser);

    useEffect(() => {
        setLoading(true);
        const getSelectProjectsList = async () => {
            const projectsList = await dispatch(getMbProjectsList());
            if (projectsList) {
                setProjectsSelectOptions(projectsList.map(project => { return {
                    value: project.id,
                    label: project.name
                }}));
            }
        };
        getSelectProjectsList().then(() => console.log('Project list loaded'));

        const getSelectPartners = async () => {
            const partnersList = await dispatch(getProjectPartnersList( true ));
            if (partnersList) {
                setPartnersSelectOptions(partnersList.map(partner => { return {
                    value: partner.id,
                    label: `${partner.email} (${partner.affiseId})`
                }}));
            }
        };

        const getSelectPartner = async () => {
            const partners = await dispatch(getPartnerByUserId(currentUser.email, setLoading));
            if (partners && partners.length) {
                if (partners[0].partnersIds && partners[0].partnersIds.length) {
                        const partnersIds = partners[0].partnersIds;
                        setPartnersSelectOptions(partnersIds.map(id => { return {
                            value: Number(id),
                            label: `${currentUser.email} (${Number(id)})`
                        }}));
                    } else {
                        setPartnersSelectOptions(partners.map(partner => { return {
                            value: partner.affiseId,
                            label: `${partner.email} (${partner.affiseId})`
                        }}));
                    }
            }
        };
        if (currentUser.capability >= 2) {
            getSelectPartner().then(() => {
                console.log('Partner loaded');
            });
        } else {
            getSelectPartners().then(() => {
                console.log('Partners list loaded');
            });
        }

        getProjectsByGroup().then(() => console.log('Projects loaded'));
        setLoading(false);
        // eslint-disable-next-line
    }, [dispatch]);

    const getProjectsByGroup = async () => {
            const prjList = await dispatch( getMbProjectsByGroups(searchParams) );
            if (prjList) {
                setProjects( prjList );
            }
    };

    const createProjectGroup = async () => {
        let partnerProjects
        if (currentUser.capability >= 2) {
            const partners = await dispatch(getPartnerByUserId(currentUser.email));
            
            if (partners && partners[0] && partners[0]._id) {
                const partnerId = partners[0]._id;
                partnerProjects = await dispatch(createMbProjectGroup({
                    groupName, 
                    partnerId, 
                    email: currentUser?.email, 
                    capability: currentUser?.capability
                }));
            } else {
                partnerProjects = await dispatch(createMbProjectGroup({
                    groupName,
                    email: currentUser?.email, 
                    capability: currentUser?.capability
                }));
            }
        } else {
            partnerProjects = await dispatch(createMbProjectGroup({groupName}));
        }
        
        if ( partnerProjects ) {
            setSearchParams({});
            setProjects( partnerProjects );
            setGroupName('');
            setCreateGroup(false);
        }
    };

    return (
        <AppContent>
            <div className="container wrapper">
                <div className={styles.TitleBlock}>
                    <h2>Каталог проектов</h2>
                    <NavLink className={styles.NavLink} to={'/mb-projects/create'} >
                        <span>+</span> Создать проект
                    </NavLink>
                </div>
                <div className="row">
                    <div className="col">
                        <div className={styles.SearchParamsBlock}>
                            <div className={styles.FilterItem}>
                                <label className={styles.Label}>Вебмастера</label>
                                <MultiSelect
                                    placeholder="Все"
                                    allOptions={partnersSelectOptions}
                                    onChange={val => setSearchParams({
                                        ...searchParams, partners: val
                                    })}
                                />
                            </div>
                            <div className={styles.FilterItem}>
                                <label className={styles.Label}>Проекты</label>
                                <MultiSelect
                                    placeholder="Все"
                                    allOptions={projectsSelectOptions}
                                    onChange={val => setSearchParams({
                                        ...searchParams, projects: val
                                    })}
                                />
                            </div>
                            <Button
                                className={styles.GetReportBtn}
                                type={'primary'}
                                onClick={getProjectsByGroup}
                            >
                                Показать
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.ProjectsContent}>
                    <Button type={'approve'} className="my-3" onClick={() => setCreateGroup(!creatGroup)} >
                        {creatGroup ? 'Отмена' : 'Создать группу проектов'}
                    </Button>
                    {creatGroup &&
                        <div className="row">
                            <div className="col-3 pr-0">
                                <label className={styles.Label}>Введите название группы*</label>
                                <input
                                    type="text"
                                    className={styles.Input}
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                    required={true}
                                />
                            </div>
                            <div className="col-2 d-flex align-items-end">
                                <Button
                                    type={'primary'}
                                    onClick={createProjectGroup}
                                >Создать</Button>
                            </div>
                        </div>
                    }
                    {loading
                        ? <ContentLoader />
                        : <MbProjectsList
                            projects={projects}
                            setLoading={setLoading}
                            getProjectsByGroup={getProjectsByGroup}
                        />
                    }
                </div>
            </div>
        </AppContent>
    );
};

export default MbProjects;
