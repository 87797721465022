import React from 'react';
import styles from "../AdvertiserCard.module.css";
import AdvertisersOffersList from '../advertisersOffersList copy/AdvertisersOffersList';

const OffersTab = ({ offers }) => {
    return (
        <div className={styles.AdvTabContent}>
            <div className={styles.DocList}>
                <AdvertisersOffersList offers={offers} />
            </div>
        </div>
    );
};

export default OffersTab;
