import React, {useEffect, useState} from 'react';
import styles from './DashboardCurrencyRatesList.module.css';
import DashboardCurrencyRatesItem from "../dashboardCurrencyRatesItem/DashboardCurrencyRatesItem";
import {useDispatch, useSelector} from "react-redux";
import {getCurrencyRates} from "../../../../actions/billing";
import {getUserCurrencyRates} from "../../../../actions/currency";
import ContentLoader from "../../../ui/contentLoader/ContentLoader";
import { delayByLoader } from '../../../../utils/deleyByLoader';
import {format} from "date-fns";
import {ru} from "date-fns/locale";

const DashboardCurrencyRatesList = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const currencyRatesData = useSelector(state => state.billing.currencyRatesData);
    const currentUser = useSelector(state => state.user.currentUser);
    const [objectRates, setObjectRates] = useState({})

    useEffect(() => {
        delayByLoader(true,setLoading,300);
        if (currentUser.capability === 1) {
            dispatch(getCurrencyRates());
        } else {
            dispatch(getUserCurrencyRates());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(()=>{
        if (currencyRatesData?.length > 0){
            let data = {}
            currencyRatesData.forEach(item => {
                data[item.title] = item;

            });
            setObjectRates(data);
        }
    }, [currencyRatesData]);

    return (
        loading ? <ContentLoader/> : (
            currencyRatesData?.length !== 0 ?
                <div className={styles.Body}>
                    <div className={styles.CurrencyBlock}>
                        <div className={styles.Title}>ЦБ РФ на { format(new Date(),'dd MMMM yyyy',{locale:ru}) }</div>
                        <div className={`${styles.Content} row `}>
                            {objectRates.USDRUR &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.USDRUR}/>
                            }
                            {objectRates.RURUSD &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.RURUSD}/>
                            }
                            {objectRates.EURRUR &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.EURRUR}/>
                            }
                            {objectRates.RUREUR &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.RUREUR}/>
                            }
                            {objectRates.KZTRUR &&
                                <DashboardCurrencyRatesItem className={`col-8 pl-0`} data={objectRates.KZTRUR}/>
                            }
                            {objectRates.USDTtRUR &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.USDTtRUR}/>
                            }
                            {objectRates.RURUSDTt &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.RURUSDTt}/>
                            }
                        </div>
                    </div>
                    <div className={styles.CurrencyBlock}>
                        <div className={styles.Title}>Кросс-курсы ЦБ РФ на { format(new Date(),'dd MMMM yyyy',{locale:ru}) }</div>
                        <div className={`${styles.Content} row `}>
                            {objectRates.USDEUR &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.USDEUR}/>
                            }
                            {objectRates.EURUSD &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.EURUSD}/>
                            }
                            {objectRates.KZTUSD &&
                                <DashboardCurrencyRatesItem className={`col-8 pl-0`} data={objectRates.KZTUSD}/>
                            }
                            {objectRates.KZTEUR &&
                                <DashboardCurrencyRatesItem className={`col-8 pl-0`} data={objectRates.KZTEUR}/>
                            }
                            {objectRates.USDUSDTt &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.USDUSDTt}/>
                            }
                            {objectRates.USDTtUSD &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.USDTtUSD}/>
                            }
                            {objectRates.EURUSDTt &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.EURUSDTt}/>
                            }
                            {objectRates.USDTtEUR &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.USDTtEUR}/>
                            }
                            {objectRates.KZTUSDTt &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.KZTUSDTt}/>
                            }
                            {objectRates.USDTtKZT &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.USDTtKZT}/>
                            }
                        </div>
                    </div>
                    <div className={styles.CurrencyBlock}>
                        <div className={styles.Title}>CB UAE на { format(new Date(),'dd MMMM yyyy',{locale:ru}) }</div>
                        <div className={`${styles.Content} row `}>
                            {objectRates.USDAED &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.USDAED}/>
                            }
                            {objectRates.EURAED &&
                                <DashboardCurrencyRatesItem className={`col-5 pl-0`} data={objectRates.EURAED}/>
                            }
                            {objectRates.KZTAED &&
                                <DashboardCurrencyRatesItem className={`col-8 pl-0`} data={objectRates.KZTAED}/>
                            }
                            {objectRates.RURAED &&
                                <DashboardCurrencyRatesItem className={`col-8 pl-0`} data={objectRates.RURAED}/>
                            }
                            {objectRates.USDTtAED &&
                                <DashboardCurrencyRatesItem className={`col-8 pl-0`} data={objectRates.USDTtAED}/>
                            }
                        </div>
                    </div>
                    {/* Все курсы сейчас берем с ЦБ РФ. Этот блок оставил пока, если вдруг руководство передумает */}
                    {/*<div className={styles.CurrencyBlock}>*/}
                    {/*    <div className={styles.Title}>Capitalist</div>*/}
                    {/*    <div className={`${styles.Content} row `}>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={`${styles.CurrencyBlock} ${ styles.KZTBank}`}>*/}
                    {/*    <div className={styles.Title}>Национальный банк Казахстана</div>*/}
                    {/*    <div className={`${styles.Content} row `}>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                : <div className={styles.NoData}>Биллинги отсутствуют</div>
        )
    );
};

export default DashboardCurrencyRatesList;
