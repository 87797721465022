import React from 'react';
import styles from '../../OfferCard.module.css';
import defaultLogoOffer from "../../../../../../assets/img/deffaultLogoOffer.png";
import { getOptions } from "../../../../../../core/helpers";
import { OfferStatus } from "../../../../../../core/config";
import PaymentItem from "./PaymentItem";

const PaymentsTab = ({ offer }) => {
    const logo = offer.logo ? offer.logo : defaultLogoOffer;

    return (
        <div className={styles.offerTabContent}>
            <h4 className="d-flex align-items-center">
                <img className="mr-3" width="80" height="auto" src={logo} alt="" />
                {offer.title}
            </h4>

            <div className={`${styles.CommonData} row mt-4 mb-5`}>
                <div className="col-2">
                    <div className={styles.InfoBlock}>
                        <div className={styles.Label}>Менеджер</div>
                        <div className={styles.Data}>{offer.advertiser.manager.firstName} {offer.advertiser.manager.lastName}</div>
                    </div>
                </div>
                <div className="col-2">
                    <div className={styles.InfoBlock}>
                        <div className={styles.Label}>ID Оффера</div>
                        <div className={styles.Data}>{offer.affiseId}</div>
                    </div>
                </div>
                <div className="col-3">
                    <div className={styles.InfoBlock}>
                        <div className={styles.Label}>Рекламодатель</div>
                        <div className={styles.Data}>{offer.advertiser.title}</div>
                    </div>
                </div>
                <div className="col-2">
                    <div className={styles.InfoBlock}>
                        <div className={styles.Label}>Статус</div>
                        <div className={styles.Data}>{getOptions(OfferStatus, offer.status)}</div>
                    </div>
                </div>
            </div>

            <div className={styles.Payments}>
                <div className={`${styles.Header} row my-3`}>
                    <div className="col-2">Цель</div>
                    <div className="col-2">Имя цели</div>
                    <div className="col-2">Страна</div>
                    <div className="col-2">Тип оплаты</div>
                    <div className="col-1">Доход</div>
                    <div className="col-1">Вебмастеру</div>
                    <div className="col-1"></div>
                </div>
                {offer.payments.length > 0
                    ? offer.payments.map(item => <PaymentItem payment={item} key={item.hash} />)
                    : <div>Выплаты не найдены</div>
                }

                <h3 className={styles.SectionHeader}>Персональные выплаты</h3>
                <div className={`${styles.Header} row my-3`}>
                    <div className="col-3">Вебмастер</div>
                    <div className="col-3">Страна</div>
                    <div className="col-2">Тип оплаты</div>
                    <div className="col-1">Доход</div>
                    <div className="col-1">Вебмастеру</div>
                    <div className="col-1"></div>
                </div>
                {offer.partnerPayments.length > 0
                    ? offer.partnerPayments.map(item => <PaymentItem payment={item} personal={true} key={item.hash} />)
                    : <div>Персональные выплаты не найдены</div>
                }
            </div>
        </div>
    );
};

export default PaymentsTab;
