import React from 'react';

import InputLabel from "../../../ui/input/inputLabel/InputLabel";
import InputRadio from "../../../ui/input/InputRadio/InputRadio";
import { isEdit } from "../../../../core/helpers";

const EditForm = ({ user, onChange, rule, formClicked, setFormClicked }) => {
    const disabled = (!isEdit(rule));

    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <InputLabel
                        type='text'
                        value={user.firstName.value}
                        label='Имя'
                        required={true}
                        disabled={disabled}
                        setValue={(val) => {
                            onChange({ ...user, firstName: { value: val, error: false } })
                        }}
                        error={user.firstName.error}
                    />
                </div>
                <div className="col-4">
                    <InputLabel
                        type='text'
                        value={user.lastName.value}
                        label='Фамилия'
                        required={true}
                        name="lastName"
                        disabled={disabled}
                        setValue={(val) => {
                            onChange({ ...user, lastName: { value: val, error: false } })
                        }}
                        error={user.lastName.error}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <InputLabel
                        type='email'
                        value={user.email.value}
                        label='Email'
                        name="email"
                        required={true}
                        disabled={disabled}
                        setValue={(val) => {
                            onChange({ ...user, email: { value: val, error: false } })
                        }}
                        error={user.email.error}
                    />
                </div>
                <div className="col-4">
                    <InputLabel
                        type='tel'
                        value={user.phone.value}
                        label='Телефон'
                        required={true}
                        name="phone"
                        disabled={disabled}
                        setValue={(val) => {
                            onChange({ ...user, phone: { value: val, error: false } })
                        }}
                        error={user.phone.error}
                    />
                </div>

            </div>
            <div className="row">
                <div className="col-4">
                    <InputLabel
                        type='password'
                        label='Пароль'
                        name="password"
                        value={user.password.value}
                        required={true}
                        disabled={disabled}
                        setValue={(val) => {
                            onChange({ ...user, password: { value: val, error: false } })
                        }}
                        error={user.password.error}
                    />
                </div>
                <div className="col-4">
                    <InputLabel
                        type='text'
                        label='Партнеры (ID)'
                        name="partnersIds"
                        value={user?.partnersIds?.value}
                        required={false}
                        disabled={disabled}
                        setValue={(val) => {
                            onChange({ ...user, partnersIds: { value: val, error: false } })
                        }}
                        error={user?.partnersIds?.error}
                    />
                </div>

            </div>
            <div className="row radioBlock">
                <div className="title radiosTitle col-12">
                    Тип пользователя
                </div>
                <div className="col-6 row">
                    <InputRadio
                        value={2}
                        check={user.capability === 2}
                        label='Аккаунт-менеджер'
                        name='capability'
                        required={true}
                        disabled={disabled}
                        id='capability2'
                        setValue={(val) => {
                            setFormClicked(!formClicked);
                            onChange({ ...user, capability: val });
                        }}

                    />
                    <InputRadio
                        value={1}
                        check={user.capability === 1}
                        label='Администратор'
                        required={true}
                        name='capability'
                        disabled={disabled}
                        id='capability1'
                        setValue={(val) => {
                            setFormClicked(!formClicked);
                            onChange({ ...user, capability: val });
                        }}
                    />
                    <InputRadio
                        value={3}
                        check={user.capability === 3}
                        label='Гость '
                        required={true}
                        name='capability'
                        disabled={disabled}
                        id='capability3'
                        setValue={(val) => {
                            setFormClicked(!formClicked);
                            onChange({ ...user, capability: val });
                        }}
                    />
                </div>

            </div>
        </div>
    );
};

export default EditForm;
