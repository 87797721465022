import React from 'react';
import styles from './ItemsList.module.css';
import Item from "./item/Item";

const ItemsList = ({items}) => {
    return (
        <div className={styles.ListBlock}>
            <div className={styles.BillingPartLabel}>
                <div className={`${styles.Number}`}>Номер акта</div>
                <div className={`${styles.Date}`}>Дата акта</div>
                <div className={`${styles.Btn}`}></div>
            </div>
            <ul className={styles.HistoryList}>
                {items.length > 0
                    ? items.map( (item, index) => <Item item={item} key={index} /> )
                    : <li className="mt-3"><i>Счета отсутствуют</i></li>
                }
            </ul>
        </div>
    );
};

export default ItemsList;
