import React from 'react';
import styles from './AdvertisersOffersList.module.css';
import AdvertisersOffersLi from "./advertisersOffersLi/AdvertisersOffersLi";


const AdvertisersOffersList = ({offers}) => {
    return (
        <div className={styles.ListBlock}>
            <div className={styles.BillingPartLabel}>
                <div className={`${styles.Number}`}>ID</div>
                <div className={`${styles.Title}`}>Название</div>
                <div className={`${styles.Status}`}>Статус</div>
            </div>
            <ul className={styles.HistoryList}>
                {offers.length > 0
                    ? offers.map((offer, index) =>
                        <AdvertisersOffersLi offer={offer} key={index}/> )
                    : <li className="mt-3"><i>Офферы отсутствуют</i></li>
                }
            </ul>
        </div>
    );
};

export default AdvertisersOffersList;
