import React from 'react';
import styles from "./MbProjectList.module.css";
import MbProjectsGroupRow from "./mbProjectsGroupRow/MbProjectsGroupRow";

const MbProjectsList = ({projects, setLoading, getProjectsByGroup}) => {
    const prjGroups = Object.keys(projects).map((group, index) =>
        <MbProjectsGroupRow
            name={group}
            projects={projects[group]}
            setLoading={setLoading}
            getProjectsByGroup={getProjectsByGroup}
            key={index}
        />
    );

    return (
        <div className={styles.ProjectsList}>
            {prjGroups.length > 0
                ? prjGroups
                : <div>Проекты не найдены</div>
            }
        </div>
    );
};

export default MbProjectsList;
