import React from 'react';

const CheckboxIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 13L2 8L3.5 6.33333L6.5 9.66667L12.5 3L14 4.66667L6.5 13Z" />
        </svg>
    );
};

export default CheckboxIcon;