import React from 'react';

const TimerNumber = ({value}) => {
    const leftDigit = value >= 10 ? value.toString()[0] : '0';
    const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
    return (
        <div>
            {leftDigit}{rightDigit}
        </div>
    );
};

export default TimerNumber;