import React from "react";
import SearchLoader from "../../../assets/img/findSectionIcon/search-loader.png";
import styles from "./SearchSpinner.module.css";

const SearchSpinner = () => {
  return (
    <div className={styles.searchSpinner}>
      <img
        src={SearchLoader}
        alt=""
      />
    </div>
  );
};

export default SearchSpinner;
