import React, {useEffect, useState} from 'react';
import styles from './CreateExpense.module.css';
import AppContent from "../../../appContent/AppContent";
import {NavLink, useHistory} from "react-router-dom";
import SelectUi from "../../../ui/select/SelectUiv2";
import Button from "../../../ui/button_v2/ButtonV2";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import {useDispatch} from "react-redux";
import {createProjectExpense, getExpenseCategoryList} from "../../../../actions/partners-expenses";
import {CurrencyByFilter} from "../../../../core/config";
import {setOneMonthAgoDate} from "../../../../core/helpers";

const CreateExpense = ({ location }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const project = location.state.project;
    const expense = location.state.data;
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [expenseData, setExpenseData] = useState({
        mbProject: project.id,
        expensesCategory: expense ? expense.expensesCategory.id : '',
        date: expense ? setCurrentExpenseDate(expense) : setOneMonthAgoDate(),
        offers: project.offers,
        amount: expense ? expense.amount : '',
        currency: expense ? expense.currency : 0
    });

    useEffect(() => {
        const getExpenseCategories = async () => {
            const categoryList = await dispatch(getExpenseCategoryList());
            if (categoryList) {
                setCategoryOptions(categoryList.map(category => { return {
                    value: category.id,
                    label: category.name
                }}));
            }
        };
        getExpenseCategories().then(() => console.log('Categories list loaded'));
    }, [dispatch, project]);

    const onDataPickerChange = (date) => {
        setExpenseData({ ...expenseData, date: date});
    };

    const createExpense = async () => {
        if (expense) {
            expenseData.id = expense.id;
            expenseData.update = true;
        }
        const result = await dispatch(createProjectExpense(expenseData));
        if ( result ) {
            history.push({
                pathname: `/mb-projects/project`,
                state: {project: project}
            });
        }
    };

    function setCurrentExpenseDate(expense) {
        const now = new Date();
        now.setFullYear(expense.year);
        return now.setMonth(expense.month - 1);
    }

    return (
        <AppContent>
            <div className="container wrapper">
                <div className="row">
                    <div className="col-8 offset-2">
                        <div className='m-3'>
                            <NavLink to={{
                                pathname: `/mb-projects/project`,
                                state: {project: project}
                            }}>Назад</NavLink>
                        </div>
                        <div className={styles.CreateExpenseContent}>
                            <div className={`${styles.TitleBlock} d-flex justify-content-between`}>
                                <h2>{expense ? 'Редактирование' :'Внесение'} расхода</h2>
                                <Button
                                    className={styles.Button}
                                    type={'primary'}
                                    onClick={createExpense}
                                >{expense ? 'Обновить' : 'Внести'}</Button>
                            </div>
                            <div className={styles.TitleBlock}>
                                <h3>{project.name}</h3>
                                <div className="mt-1">{project.partner.email}</div>
                                <div className={styles.ID}>ID: {project.partner.affiseId}</div>
                            </div>
                            <div className={styles.FormBlock}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 pr-0">
                                                <SelectUi
                                                    label={'Статья расхода*'}
                                                    placeholder={'Выбрать...'}
                                                    options={categoryOptions}
                                                    required={true}
                                                    value={categoryOptions.find(item =>
                                                        item.value === expenseData.expensesCategory
                                                    )}
                                                    onChange={val => setExpenseData({
                                                        ...expenseData, expensesCategory: val
                                                    })}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className={styles.Label}>Месяц расхода*</label>
                                                <DatePicker
                                                    className={`${styles.DatePicker} inputField`}
                                                    locale={ru}
                                                    selected={expenseData.date}
                                                    showMonthYearPicker
                                                    dateFormat="MM.yy"
                                                    onChange={onDataPickerChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6 pr-0">
                                                <label className={styles.Label}>Сумма по расходу*</label>
                                                <input
                                                    type="number"
                                                    className={styles.Input}
                                                    value={expenseData.amount}
                                                    onChange={(e) => setExpenseData({
                                                        ...expenseData, amount: e.target.value
                                                    })}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <SelectUi
                                                    label={'Валюта*'}
                                                    placeholder={'Выбрать...'}
                                                    options={CurrencyByFilter}
                                                    required={true}
                                                    value={CurrencyByFilter.find(item =>
                                                        item.value === expenseData.currency
                                                    )}
                                                    onChange={val => setExpenseData({
                                                        ...expenseData, currency: val
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppContent>
    );
};

export default CreateExpense;
