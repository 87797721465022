import React from 'react';
import styles from './KnowledgeModerationContent.module.css';
import SectionShowAndEdit from '../sectionShowAndEdit/SectionShowAndEdit';

const KnowledgeModerationContent = ({ loading, setLoading }) => {
    return (
        <div className={styles.container}>
            <SectionShowAndEdit loading={loading} setLoading={setLoading} />
        </div>
    )
}

export default KnowledgeModerationContent;