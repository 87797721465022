import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setBillingCommentOpenState, setDatePickerOpen } from '../../reducers/billingReducer';
import Header from "../header/Header";
import styles from './AppContent.module.css';

const AppContent = ({ children, paddingReset, props }) => {
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.user.isAuth);
    const billingComment = useSelector(state => state.billing.billingComment);
    const searchText = useSelector(state => state.knowledge.searchText);
    const containerRef = useRef(null);

    useEffect(() => {
        searchText ? containerRef.current.classList.add(styles.searchTextExist) : containerRef.current.classList.remove(styles.searchTextExist);
    }, [searchText]);

    return (
        <div ref={containerRef} className={styles.App} {...props} >
            {isAuth && <Header />}
            <div className={`${paddingReset ? styles.LoginContent : styles.Content}`} onClick={(e) => {
                if (!e.target.closest('.inputBillingRange') && !e.target.closest('.date-picker-select')) {
                    dispatch(setDatePickerOpen(false));
                }
                if (!e.target.closest('.add_comment')) {
                    if (!billingComment?.trim()) {
                        dispatch(setBillingCommentOpenState(false));
                    }
                }
            }}>
                {children}
            </div>
        </div>
    );
};

export default AppContent;