import React, { useEffect, useState} from 'react';
import styles from './ConversionsLi.module.css';
import InputCheckbox from "../../../../ui/input/InputCheckbox/InputCheckbox";
import ConversionsStatus from "../../conversionsStatus/ConversionsStatus";
import ArrowIcon from "../../../../ui/icons/ArrowIcon";
import {getCurrencyNameSymbol} from "../../../../../core/helpers";

const ConversionsLi = ({conversions, active, index, massCheck, setMassCheck,setDropDown, setEditList, editList}) => {
    const [checkbox, setCheckbox] = useState({name: '', value: '', checked: false});
    const [flag,setFlag] = useState(false);
    if(!flag && !massCheck){

       checkbox.checked = parseFloat(conversions.payment).toFixed(4) === parseFloat(conversions.sum).toFixed(4) ? false : true;
    }
    useEffect(() => {
        const edt = editList.find(list => list.value === conversions.id);
        if (edt) {
            setCheckbox(edt);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editList, checkbox]);
    function addElement(value) {
        const check = editList.map(list => {
                if (value.value === list.value) {
                    list = value;
                }
                return list;
            }, checkbox
        );
        setFlag(true);
        setEditList(check);
    }

    return (
        <div className={checkbox.checked=== true ? `${styles.ItemContainer} ${styles.Check}` : styles.ItemContainer}>
            <div className={(active === true) ? `${styles.Item} ${styles.Active}` : styles.Item}>
                <div className={`${styles.Td} ${styles.Checkbox}`}><InputCheckbox
                    name={checkbox.name} value={checkbox.value}
                    setValue={(val) => addElement(val)} check={checkbox.checked}/></div>

                <div className={`${styles.Td} ${styles.OrderId}`}>{conversions.actionId}</div>

                <div
                    className={`${styles.Td} ${styles.Revenue}`}>{conversions.revenue} {getCurrencyNameSymbol(conversions.currency)}</div>
                <div
                    className={`${styles.Td} ${styles.Payouts}`}>{conversions.payouts} {getCurrencyNameSymbol(conversions.currency)}</div>

                <div
                    className={`${styles.Td} ${styles.Sum}`}>{conversions.sum} {getCurrencyNameSymbol(conversions.currency)}</div>
                <div
                    className={`${styles.Td} ${styles.Payment}`}>{conversions.payment} {getCurrencyNameSymbol(conversions.currency)}</div>
                <div className={`${styles.Td} ${styles.PayType}`}>
                    <div className={styles.Email}>Тип платежа: {conversions.paymentType}</div>
                    <div className={styles.Email}>Цель: {conversions.goalValue}</div>
                </div>
                <div className={`${styles.Td} ${styles.Status}`}><ConversionsStatus status={conversions.status}/>
                </div>
                <div className={`${styles.Td} ${styles.Date}`}>{conversions.createAt}</div>


                <div className={`${styles.Td} ${styles.Btn}`}>
                    <div
                        className={(active === true) ? `${styles.ActiveHidden} ${styles.Active}` : styles.ActiveHidden}
                        onClick={() => setDropDown(index)}><ArrowIcon/></div>
                </div>
                <div
                    className={(active === true) ? `${styles.HiddenContainer} ${styles.Col} ${styles.Active}` : `${styles.HiddenContainer} ${styles.Col}`}>
                    <div className={`${styles.PartnerContainer}`}>
                        <div className={styles.Label}>Партнер</div>
                        <div className={`${styles.Td} ${styles.Partner}`}>
                            <div className={styles.Email}>{conversions.partner.email}</div>
                            <div
                                className={styles.Login}>{conversions.partner.login} ID: {conversions.partner.id}</div>
                            <div className={styles.Offer}> Оффер: {conversions.offer.title}({conversions.offer.id})
                            </div>

                        </div>
                    </div>
                    <div className={styles.ClickId}>
                        <div className={styles.Label}>Click ID</div>
                        <div className={`${styles.Td} ${styles.ClickId}`}>{conversions.clickId}</div>
                    </div>
                    <div>
                        <div className={styles.Label}>Комментарий</div>
                        <div className={styles.Comment}>{conversions.comment}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConversionsLi;