import React from 'react';

const ReloadIcon = () => {
    return (
        <svg  className='reloadIcon' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.53125 6.81906L3.09046 1L4.74265 2.65219C7.76616 0.747048 11.8097 1.11124 14.4432 3.74478L13.2135 4.97449C11.2638 3.02477 8.32043 2.67428 6.01347 3.92301L7.35031 5.25984L1.53125 6.81906Z" />
            <path d="M14.4685 9.18094L12.9093 15L11.2571 13.3478C8.23363 15.2529 4.19011 14.8888 1.55657 12.2552L2.78628 11.0255C4.736 12.9752 7.67936 13.3257 9.98632 12.077L8.64949 10.7402L14.4685 9.18094Z" />
        </svg>
    );
};

export default ReloadIcon;