import React from 'react';
import styles from './ContentLoader.module.css';
import imgContentLoader from '../../../assets/img/contentLoaderLine.svg';


const ContentLoader = ({ bg, zIndex }) => {
    return (
        <div
            className={styles.LoaderWrapper} style={{ zIndex: zIndex ? zIndex : '1000', background: bg || "#FAFAFA" }}
        >
            <div className={styles.AppSpinner}>
                <div className={styles.Spinner}>
                    <img src={imgContentLoader} alt="loader line" />
                </div>
            </div>
        </div>
    );

};

export default ContentLoader;
