import {
    setUser,
    setUserItems,
    getUser,
    updateUserList,
    setUserErrors,
    logoutUser,
} from "../reducers/userReducer";
import {closeModal, setAppLoader} from "../reducers/appReducer";
import {createNotification} from "./app";
import $api from '../core/api';
import axios from "axios";
import {API_URL} from '../core/config';
import {processError} from "../core/helpers";
import { delayByLoader } from "../utils/deleyByLoader";

export const login = (email, password, code = false) => {
    return async dispatch => {
        dispatch(setUserErrors(false));
        try {
            const response = await axios.post(`${API_URL}/api/user/login`, {email, password, code}, {withCredentials: true});
            dispatch(closeModal('ModalVerification'));
            if(response.data === 'verify'){
                return response.data;
            }else{
                localStorage.setItem('token', response.data.accessToken);
                dispatch(setUser(response.data.user));
            }

        } catch (e) {
            if ( e.response && e.response.data && e.response.data.message !== 'Неверный код верификации') {
                dispatch(closeModal('ModalVerification'));
            }
            dispatch(setAppLoader(false));
            if (e.response) {
                const response = e.response;
                const errors = response.data.errors;
                if (errors && Array.isArray(errors) && errors.length > 0) {
                    dispatch(setUserErrors(errors));
                } else {
                    const msgText = e.response.data.message ? e.response.data.message : 'Что-то пошло не так!';
                    dispatch(createNotification({
                        type: 'error',
                        children: msgText
                    }));
                }
            } else {
                dispatch(closeModal('ModalVerification'));
                dispatch(createNotification({
                    type: 'error',
                    children: e.request ? 'Ошибка подключения' : 'Непредвиденная ошибка'
                }));
            }

        }
    }
};

export const logout = () => {
    return async dispatch => {
        dispatch(setAppLoader(true));
        try {
            const response = await $api.post(`/api/user/logout`);

            if (response.data.ok === 1) {
                dispatch(logoutUser());
            }
        } catch (e) {
            //errors

        } finally {
            //reload app to set all states to default
            window.location.reload();
        }
    }
};
export const checkAuth = () => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/user/refresh`);
            localStorage.setItem('token', response.data.accessToken);
            dispatch(setUser(response.data.user));
            dispatch(setAppLoader(false));
        } catch (e) {
            localStorage.removeItem('token');
            dispatch(createNotification({
                type: 'error',
                children: 'Ошибка авторизации.',
                className: 'Important'
            }));
            dispatch(setAppLoader(false));
            window.location.href = '/login';
        }
    }
};
export const registerUser = (data,onSuccess) => {
    return async dispatch => {
        try {
            const response = await $api.post(`${API_URL}/api/user/registration`, {...data}, {withCredentials: true});
            const user = response.data;

            // dispatch(setUser(user));
            onSuccess(`/users/${user.id}`)
            // window.location.replace(`/user/${user.id}`);
            // window.location.href = `/user/${user.id}`

        } catch (e) {

            console.log(e.message);

            if (e.response) {
                const response = e.response;
                const errors = response.data.errors;
                if (errors && Array.isArray(errors) && errors.length > 0) {
                    dispatch(setUserErrors(errors));
                } else {
                    const msgText = e.response.data.message ? e.response.data.message : 'Что-то пошло не так!';
                    dispatch(createNotification({
                        type: 'error',
                        children: msgText
                    }));
                }
            } else {
                dispatch(createNotification({
                    type: 'error',
                    children: e.request ? 'Ошибка подключения' : 'Непредвиденная ошибка'
                }));
            }
        }
    }
};

export const fetchUsers = (setLoading) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/user`);
            dispatch(setUserItems(response.data));
            return {success: true};
        } catch (e) {
            processError(dispatch, e);
        }finally{
          delayByLoader(false,setLoading,300)
        }
    }
};
export const fetchUser = (id) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/user/${id}`);
            dispatch(getUser(response.data));
            return {success: true};
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const updateUser = (data,setLoading) => {
  setLoading?.(true)
    return async dispatch => {
        try {
            const response = await $api.post(`/api/user/${data.id}`, {...data});
            dispatch(updateUserList(response.data));
            dispatch(createNotification({
                type: 'primary',
                children: 'Данные успешно обновлены.'
            }));
        } catch (e) {
            if (e.response) {
                const response = e.response;
                const errors = response.data.errors;
                if (errors && Array.isArray(errors) && errors.length > 0) {
                    dispatch(setUserErrors(errors));
                } else {
                    const msgText = e.response.data.message ? e.response.data.message : 'Что-то пошло не так!';
                    dispatch(createNotification({
                        type: 'error',
                        children: msgText
                    }));
                }
            } else {
                dispatch(createNotification({
                    type: 'error',
                    children: e.request ? 'Ошибка подключения' : 'Непредвиденная ошибка'
                }));
            }
        }finally{
          setLoading?.(false)
        }
    };
}

export const deleteUserById = ( userId ) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/user/delete/${userId}`);
            if ( response.data && response.data.success ) {
                return true;
            } else {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Ошибка удаления пользователя'
                }));
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const recoverPassword = (email,setLoading,setIsSend) => {
  setLoading(true)
  return async (dispatch) => {
    try {
      await axios.post(`${API_URL}/api/user/recover`, { email });
      setIsSend(true)
    } catch (e) {
      processError(dispatch, e);
    }finally{
    setLoading(false)

    }
  };
};

export const updatePassword = (
  id,
  recoverId,
  password,
  setLoading,
  setIsSend,
  onSuccess
) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      await $api.post(`/api/user/updatePassword`, {
        id,
        recoverId,
        password,
      });

      setIsSend(true)
      onSuccess()
    } catch (e) {
       dispatch(createNotification({
          type: 'error',
          children: e?.response?.data?.errors[0].msg
       }));
    }finally{
      delayByLoader(false, setLoading, 300);
    }
  };
};
