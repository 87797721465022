import styles from './CurrencyRateRow.module.css';

const CurrencyRateRow = (props) => {
    return (
        <div className={styles.CurrencyRateRow}>
            <label className="col-4">{props.currency}</label>
            <label className="col-4">{props.rate ? props.rate : '-'}</label>
            <label className="col-4">{`${props.month}/${props.year}`}</label>
        </div>
    )
};

export default CurrencyRateRow;