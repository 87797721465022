import React, {lazy, Suspense} from 'react';
import styles from "../../SingleBilling.module.css";
import {format} from "date-fns";
import {FilterStatusOptions} from "../../../../../../core/config";
import {formatCurrencyAmount} from "../../../../../../core/helpers";
import Button from "../../../../../ui/button_v2/ButtonV2";
import {useDispatch} from "react-redux";
import {payChildBilling} from "../../../../../../actions/billing";
import {addModal, removeModal} from "../../../../../../reducers/appReducer";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";

const BillingModalVerification = lazy( () => import("../../../../../modals/billingModalVerification/BillingModalVerification"));

const ChildrenHistoryItem = ({billing}) => {
    const dispatch = useDispatch();
    const history = billing.history[0] ?? '';
    const billingStatusObj = FilterStatusOptions.find( status => status.value === history.status );

    const performChildPayment = async () => {
        const result = await dispatch(payChildBilling({billingId: billing._id}));
        if ( result === 'verify' ) {
            dispatch(addModal({
                id: 'billingModalVerification',
                size: 'big',
                children:
                    <Suspense fallback={<ContentLoader/>}>
                        {BillingModalVerification &&
                            <BillingModalVerification
                                billing={billing}
                                type={'payChild'}
                                onError={() => {
                                    dispatch(removeModal('billingModalVerification'))
                                }}
                            />}
                    </Suspense>
            }));
        }
    };

    return (
        <div className={styles.InfoBlock}>
            <div className="row">
                <div className="col-1 d-flex align-items-center">
                    {billing.affiseId}
                </div>
                <div className="col-2 d-flex align-items-center">
                    {formatCurrencyAmount(billing.amount, billing.currency)}
                </div>
                <div className="col-1 d-flex align-items-center px-0">
                    {billingStatusObj
                        ? billingStatusObj.label
                        : <div className={styles.ErrorLabel}>ошибка</div>
                    }
                </div>
                <div className={`col-5 d-flex align-items-center justify-content-between ${history.error ? styles.Red : ''}`}>
                    {history.message}
                    {billing.status === -1 &&
                        <Button type={'danger'} onClick={performChildPayment}>Оплатить</Button>
                    }
                </div>
                <div className="col-3">{format(new Date(history.date), "dd.MM.yyyy HH:mm:ss")}</div>
            </div>
        </div>
    );
};

export default ChildrenHistoryItem;
