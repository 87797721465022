import React from 'react';
import parentStyles from "../../../Reports.module.css";
import {formatAmountByTriplets} from "../../../../../../core/helpers";
import {useSelector} from "react-redux";

const TotalAmountsCols = ({date, amountData}) => {
    const collapsedIncome = useSelector(state => state.reports.collapsedIncomeColumns)
        .includes(`${date.year.slice(2)}.${date.month}`);
    const collapsedExpenses = useSelector(state => state.reports.collapsedExpensesColumns)
        .includes(`${date.year.slice(2)}.${date.month}`);
    const collapsedMonth = useSelector(state => state.reports.collapsedMonths)
        .includes(`${date.year.slice(2)}.${date.month}`);

    return (
        <div
            className={`${parentStyles.reportColumn} ${collapsedMonth 
                ? parentStyles.hiddenColumn: ''} ${collapsedIncome
                ? parentStyles.hiddenIncome : ''} ${collapsedExpenses
                ? parentStyles.hiddenExpenses : ''}`}
        >
            <div className={`${parentStyles.h100} d-flex`}>
                <div className={`${parentStyles.currencyAmount} ${parentStyles.brNone} ${collapsedIncome ? 'd-none' : ''}`}></div>
                <div className={parentStyles.convertedAmount}>
                    {formatAmountByTriplets(amountData.income)}
                </div>

                <div className={`${parentStyles.currencyAmount} ${parentStyles.brNone} ${collapsedExpenses ? 'd-none' : ''}`}></div>
                <div className={parentStyles.convertedAmount}>
                    {formatAmountByTriplets(amountData.expenses)}
                </div>

                <div className={parentStyles.marginIncomeAmount}>
                    {formatAmountByTriplets(amountData.marginIncome)}
                </div>
            </div>
        </div>
    );
};

export default TotalAmountsCols;
