import React from 'react';

const Textarea = ({name,value, placeholder, setValue,required}) => {

    return (
        <textarea
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(event => setValue(event.target.value))}
            required={required? true:false}
        >{value}</textarea>
    );
};

export default Textarea;