import React, { useEffect, useState } from 'react';
import styles from './PartnersGeneral.module.css';
import InputFile from "../../../../ui/input/inputFile/InputFile";
// import SelectUi from "../../../../ui/select/SelectUi";
// import Button from "../../../../ui/button/Button";
import { useDispatch } from "react-redux";
import { deletePartnerLogo, uploadPartnerLogo,
    // updatePartnerAccountType,
    // updatePartnerMainAccount
} from "../../../../../actions/partners";
import { API_URL } from "../../../../../core/config";
// import InputRadio from "../../../../ui/input/InputRadio/InputRadio";

const PartnersGeneral = ({ data }) => {
    const dispatch = useDispatch();
    const [logo, setLogo] = useState();
    const [logoUrl, setLogoUrl] = useState(data.logo);
    const [preview, setPreview] = useState();

    //Пока отключено из-за плохой (неполной) реализации
    // const [mainAccount, setMainAccount] = useState('');

    const requisites = data?.paymentSystems?.length !== 0 ?
        data?.paymentSystems?.map((paySystem, index) => {
            const value = paySystem.fields.length !== 0 ? Object.values(paySystem.fields).join(" ") : '';
            return <li className={styles.ReqItem} key={index}>
                <div className={styles.Name}>{paySystem.system}</div>
                <span className={styles.ValueReq}>{value}</span>
            </li>;
        })
        : '';

    const uploadLogo = () => {
        dispatch(uploadPartnerLogo({
            partnerId: data.id,
            logo: logo,
            setLogoUrl: setLogoUrl
        }));
        setLogo(null);
    };

    const deleteLogo = () => {
        dispatch(deletePartnerLogo({
            partnerId: data.id,
            setLogoUrl: setLogoUrl
        }));
        setLogo(null);
    };

    useEffect(() => {
        if (logo) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(logo);
            fileReader.onload = function () {
                setPreview(fileReader.result);
            }
        } else {
            setPreview(null);
        }
    }, [logo]);

    //Пока отключено из-за плохой (неполной) реализации

    // const updateAccountType = (id, linked) => {
    //     dispatch(updatePartnerAccountType(id, linked));
    // }
    //
    // const optionsAdvertiserEntityType = [
    //     {value: 1, label: 'dev@x-partners.com'},
    //     {value: 2, label: 'anna@goldlead.com'},
    //     {value: 3, label: 'demo@demo.com'},
    // ];
    //
    // const handleUpdateMainAccount = () => {
    //     dispatch(updatePartnerMainAccount(data.id, mainAccount));
    // }
    //
    // const handleSelectMainAccount = (value) => {
    //     const selected = optionsAdvertiserEntityType.find(el => el.value === value);
    //     setMainAccount(selected.label);
    // }

    return (
        <div className={styles.General}>
            <div className={`${styles.PersonBlock} row`}>
                <div className={`${styles.Partner} col-4`}>
                    <div>
                        <div className={styles.Label}>Вебмастер</div>
                        <div className={styles.Value}>{data?.login}</div>
                        <div className={styles.Value}>ID: {data?.affiseId}</div>
                        <div className={styles.Value}>{data?.email}</div>
                    </div>
                </div>
                <div className={`${styles.Manager} col-4`}>
                    <div className={styles.Label}>Менеджер</div>
                    <div className={styles.Value}>{data?.manager.firstName} {data?.manager.lastName}</div>
                </div>
                <div className="col-4">
                    {logoUrl
                        ? <> <img className={styles.PartnerLogo} src={`${API_URL}/${logoUrl}`} alt="logo" />
                            <div className={styles.DeleteLogo} onClick={deleteLogo}>Удалить лого</div>
                        </>
                        :
                        <>
                            {preview && <img className={styles.PartnerLogo} src={preview} alt="" />}
                            <InputFile
                                type="file"
                                label={logo ? 'Выбрать другое' : 'Загрузить лого'}
                                value={logo}
                                setValue={val => setLogo(val)}
                                className={`${styles.InputUpload} ${logo ? styles.Loaded : ''} ${styles.hoverStyle}`}
                            />
                            {logo &&
                                <>
                                    <div className={styles.SaveLogo} onClick={uploadLogo}>Сохранить лого</div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>

            <div className={`${styles.Requisites} row`}>
                <div className={`${styles.Partner} col-10`}>
                    <div className={styles.Label}>Реквизиты</div>
                    <ul className={styles.RequisitesContent}>
                        {requisites}
                    </ul>
                </div>
            </div>

            {/*Пока отключено из-за плохой (неполной) реализации*/}
            {/*{data?.isLinked && <div className={`${styles.Requisites} row`}>*/}
            {/*    <div className={`${styles.Partner} col-10`}>*/}
            {/*        <div className={styles.Label}>Главный аккаунт</div>*/}
            {/*                <div className={styles.AccountBtn}>*/}
            {/*                    <div className={styles.MainAccount}>{data?.mainAccount ? data?.mainAccount : ''}</div>*/}
            {/*                    <div className={styles.InputBlock}>*/}
            {/*                        <SelectUi*/}
            {/*                            placeholder='Выбрать'*/}
            {/*                            classes={'InputBlock'}*/}
            {/*                            options={ optionsAdvertiserEntityType }*/}
            {/*                            defaultValue={ optionsAdvertiserEntityType[0] }*/}
            {/*                            onChange={value => handleSelectMainAccount(value)}*/}
            {/*                        />*/}
            {/*                    </div>*/}
            {/*                    <Button*/}
            {/*                        className={styles.UpdateSectionBtn}*/}
            {/*                        onClick={handleUpdateMainAccount}*/}
            {/*                        >*/}
            {/*                        Сохранить*/}
            {/*                    </Button>*/}
            {/*                </div>*/}
            {/*    </div>*/}
            {/*</div>}*/}

            {/*<div className={`${styles.Requisites} row`}>*/}
            {/*    <div className={`${styles.Partner} col-10`}>*/}
            {/*        <div className={styles.Label}>Тип аккаунта</div>*/}
            {/*        <div className={styles.RadioBlockFlex}>*/}
            {/*                <InputRadio*/}
            {/*                    value={false}*/}
            {/*                    check={ data.isLinked === false }*/}
            {/*                    label='Главный'*/}
            {/*                    name='Main'*/}
            {/*                    required={true}*/}
            {/*                    id='Main'*/}
            {/*                    setValue={ () => updateAccountType(data.id, false) }*/}

            {/*                />*/}
            {/*                <InputRadio*/}
            {/*                    value={true}*/}
            {/*                    check={ data.isLinked === true }*/}
            {/*                    label='Связанный'*/}
            {/*                    required={true}*/}
            {/*                    name='Linked'*/}
            {/*                    id='Linked'*/}
            {/*                    setValue={ () => updateAccountType(data.id, true) }*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default PartnersGeneral;
