import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../../actions/user";
import AppContent from "../../appContent/AppContent";
import UsersContent from "./usersContent/UsersContent";
import { MODULE_TYPE, getModuleRule, isRead } from "../../../core/helpers";
import { Redirect } from 'react-router-dom';
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import styles from "./Users.module.css";

const Users = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const users = useSelector(state => state.user.items);
    const currentUserRules = useSelector(state => state.user.currentUser.rules);
    const rule = getModuleRule(currentUserRules, MODULE_TYPE.USERS);

    useEffect(() => {
        dispatch(fetchUsers(setLoading));
    }, [dispatch]);

    if (!isRead(rule)) {
        return (
            <Redirect to='/' />
        );
    }

    return (
        <AppContent>
            {loading ? <ContentLoader /> : (
                <div className="container wrapper">
                    <div className={`${styles.TitleBlock}`}>
                        <h2>Управление пользователями</h2>
                    </div>
                    <div className="row">
                        <UsersContent users={users} current={false} rule={rule} />
                    </div>
                </div>
            )}
        </AppContent>
    );
};
export default Users;