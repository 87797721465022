import React from 'react';
import styles from './ConversionsContent.module.css';
import ConversionsList from "./conversionsList/ConversionsList";
const ConversionsContent = ({conversionsData,loading}) => {

    return (
        <div className={styles.ConversionContent}>
            <ConversionsList conversionsData={conversionsData} loading={loading} />
        </div>
    );
};

export default ConversionsContent;