/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppContent from "../../appContent/AppContent";
import KnowledgeBase from "../../knowledgeBase/KnowledgeBase";
import FindSection from "../../knowledgeBase/findSection/FindSection";
import PopularSection from "../../knowledgeBase/popularSection/PopularSection";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import { useDispatch, useSelector } from "react-redux";
import { fetchKnowledge } from "../../../actions/knowledge";

const KnowledgeBasePage = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [popularDocShow, setPopularDocShow] = useState(false);

    const sectionsData = useSelector((state) => state.knowledge.data);

    useEffect(() => {
        if (sectionsData.length > 0) {
            const index = sectionsData?.findIndex(
                (item) => item?.articles?.length > 0
            );
            setPopularDocShow(index !== -1);
        } else {
            setPopularDocShow(false);
        }
    }, [sectionsData]);

    useEffect(() => {
        dispatch(fetchKnowledge(setLoading));
    }, []);

    return (
        <AppContent>
            <div className={`partnersContainer container`}>
                {loading ? (
                    <ContentLoader />
                ) : (
                    <>
                        <KnowledgeBase />
                        <FindSection data={sectionsData} setLoading={setLoading} />
                        {popularDocShow && <PopularSection data={sectionsData} />}
                    </>
                )}
            </div>
        </AppContent>
    );
};

export default KnowledgeBasePage;
