import $api from "../core/api";
import {setPartnersItems,setPartnersItem, setCurrentPartner} from "../reducers/partnersReducer";
import {closeModal} from "../reducers/appReducer";
import {processError} from "../core/helpers";
import { delayByLoader } from "../utils/deleyByLoader";

export const fetchPartners = (params, setLoading) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/partners`, params);
            dispatch(setPartnersItems(response.data));
            return {success: true};

        } catch (e) {
            processError(dispatch, e);
        }finally{
           delayByLoader(false, setLoading, 200);
        }
    }
}
export const fetchPartner = (params) => {
    return async dispatch => {
        try {
            dispatch(setPartnersItem(params));
            return {success: true};

        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const createCredit = ( params ) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/partners-credit/create`, params );
            if( response.data === 'verify' ) {
                return response.data;
            }
            await dispatch( setPartnersItem( response.data ) );
            dispatch( closeModal('createCreditModalVerification') );
            dispatch( closeModal('createCredit') );
        } catch (e) {
            dispatch( closeModal('createCreditModalVerification') );
            processError(dispatch, e);
        }
    }
}
export const deleteCredit = (params, code = '') => {

    return async dispatch => {
        try {
            const response = await $api.post(`/api/partners-credit/delete`, {id: params, code: code});
            if(response.data === 'verify'){
                return response.data;
            }
            await dispatch(setPartnersItem(response.data));
            dispatch(closeModal('partnerModalVerification'));
        } catch (e) {
            dispatch(closeModal('partnerModalVerification'));
            processError(dispatch, e);
        }
    }
}

export const uploadPartnerLogo = ({partnerId, logo, setLogoUrl}) => {
    return async dispatch => {
        try {
            const formData = new FormData();

            formData.append('partnerId', partnerId );
            formData.append('logo', logo, logo.name );

            const response = await $api.post(`/api/partners/upload-logo`, formData,{withCredentials: true});

            if ( response.data.success ) {
                const partner = response.data.partner;
                await dispatch( setPartnersItem( partner ) );
                setLogoUrl( partner.logo );
            }
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const deletePartnerLogo = ({partnerId, setLogoUrl}) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/partners/delete-logo`,
                {partnerId: partnerId},
                {withCredentials: true}
            );

            if ( response.data.success ) {
                const partner = response.data.partner;
                await dispatch( setPartnersItem( partner ) );
                setLogoUrl( null );
            }
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getProjectPartnersList = ( filtered ) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/partners/partners-list`, {
                params: {
                    filtered
                }
            });
            if ( response && response.data && response.data.success ) {
                return response.data.partners;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const updatePartnersStopped = (partnerId, status,onSuccess) => {
    return async dispatch => {
        try {
            await $api.post(`/api/partners/partners-stopped`,
                { status, partnerId },
            );

          onSuccess()
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const updatePartnerAccountType = (partnerId, accountType) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/partners/partner-accountType`,
                { accountType, partnerId },
            );
            dispatch(setCurrentPartner(response?.data?.partner));
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const updatePartnerMainAccount = (partnerId, mainAccount) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/partners/partner-mainAccount`,
                { mainAccount, partnerId },
            );
            dispatch(setCurrentPartner(response?.data?.partner));
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getPartnerById = (partnerId, setLoading) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/partners/partner`,
              { partnerId },
            );
            dispatch(setCurrentPartner(response.data));
        } catch (e) {
            processError(dispatch, e);
        } finally {
            delayByLoader(false, setLoading, 200);
        }
    }
}

export const getPartnerByUserId = (userEmail, setLoading) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/partners/user-partner`,
              { userEmail },
            );
            return response.data;
        } catch (e) {
            processError(dispatch, e);
        } finally {
            // delayByLoader(false, setLoading, 200);
        }
    }
}

export const getPartnersByGroupsList = (groups) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/partners/partners-groups?groups=${groups}`);
            return response.data;
        } catch (e) {
            processError(dispatch, e);
        } finally {
            // delayByLoader(false, setLoading, 200);
        }
    }
}