import React, { useEffect, useState } from "react";
import styles from "./FindSectionItem.module.css";
import { NavLink } from "react-router-dom";
import SectionDefault from '../../../../assets/img/section-default.png'

const FindSectionItem = ({ data, isAccess }) => {
    const [articlesData, setArticlesData] = useState([]);

    const flattenArticles = (data) => {
        const flatArticles = [];

        const traverse = (node) => {
            if (node?.articles) {
                node.articles.forEach((article) => {
                    traverse(article);
                });
            }
            if (node && node.sectionId) {
                flatArticles.push(node);
            }
        };

        traverse(data);

        return flatArticles;
    };

    useEffect(() => {
        setArticlesData(flattenArticles(data));
    }, [data]);

    return (
        <div
            className={`${styles.wrapperContainer} col-3`}

        >
            <div draggable={isAccess} id={`${data._id}`} className="drag-item">
                <NavLink
                    to={`/knowledge/section/${data._id}`}
                    className={`${styles.container}`}
                    draggable={false}
                >
                    <img className={styles.image} src={data.logo || SectionDefault} alt="" />
                    <p className={styles.title}>{data.title}</p>
                    <div
                        dangerouslySetInnerHTML={{ __html: data?.description }}
                        className={styles.description}
                    ></div>
                    <div className={styles.links}>
                        {articlesData.map((item, index) => {
                            if (index < 2) {
                                return (
                                    <NavLink
                                        to={`/knowledge/article/${item._id}`}
                                        key={index}
                                        className={styles.link}
                                    >
                                        {item.title}
                                    </NavLink>
                                );
                            }
                            if (index === 2) {
                                return (
                                    <NavLink
                                        to={`/knowledge/section/${data._id}`}
                                        key={index}
                                        className={styles.link}
                                    >
                                        + ещё
                                    </NavLink>
                                );
                            }
                            return null;
                        })}
                    </div>
                </NavLink>
            </div>

        </div>
    );
};

export default FindSectionItem;
