import React from 'react';
import styles from './ReportTableRow.module.css';
import ReportProjectRow from "./reportProjectRow/ReportProjectRow";
import ReportPartnerRow from "./reportPartnerRow/ReportPartnerRow";

const ReportTableRow = ({rowData, partnerTotalAmounts, partnerGroupsTotalAmounts, rowType, monthsArr, partnerIncomeData, partnerExpensesData}) => {
    let row;
    //TODO сейчас сделано для вебмастеров: rowType="partner".
    // Нужно еще добавить варианты для rowType="project" и rowType="projectsGroup"


    switch (rowType) {
        case 'partner':
            row = <ReportPartnerRow 
                    partnerData={rowData} 
                    partnerTotalAmounts={partnerTotalAmounts} 
                    partnerGroupsTotalAmounts={partnerGroupsTotalAmounts} 
                    partnerIncomeData={partnerIncomeData} 
                    partnerExpensesData={partnerExpensesData} 
                    monthsArr={monthsArr} 
                />;
            break;
        case 'project':
            row = <ReportProjectRow 
                    projectData={rowData} 
                    partnerTotalAmounts={partnerTotalAmounts}
                    partnerGroupsTotalAmounts={partnerGroupsTotalAmounts}
                    partnerIncomeData={partnerIncomeData} 
                    partnerExpensesData={partnerExpensesData}  
                    monthsArr={monthsArr} 
                />;
            break;
        case 'projectsGroup':
            row = <div>Under construction</div>
            break;
        default:
            row = <div>Неизвестный параметр группировки</div>
    }

    return (
        <div className={styles.ReportTableRow}>
            {row}
        </div>
    );
};

export default ReportTableRow;
