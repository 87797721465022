import React, { useEffect, useMemo, useState } from "react";
import Input from "../../ui/input/Input";
import styles from "./KnowledgeBaseSearch.module.css";
import SearchResultItem from "../searchResultItem/SearchResultItem";
import SearchIcon from "../../../assets/img/findSectionIcon/search-icon.svg";
import SearchSpinner from "../../ui/searchSpinner/SearchSpinner";
import { useHistory } from "react-router-dom/";

const Label = ({ label }) => {
    return label ? <label>{label}</label> : null;
};

const SearchBlock = ({ style, isFocuses, children }) => (
    <div
        style={{ ...style, ...(isFocuses ? style : {}) }}
        className={styles.searchBlock}
    >
        {children}
    </div>
);

const Results = ({
    searchData,
    value,
    stylesMemo,
    isSection,
    isPos,
    onSearchBgHandler,
}) => {
    const hasData = searchData && Object.keys(searchData).length > 0 && value;

    const dataKey = isSection ? "sections" : "articles";

    const hasDataItems =
        searchData &&
        searchData[dataKey] &&
        Object.keys(searchData[dataKey]).length > 0;

    if (hasData && hasDataItems) {
        return (
            <>
                {Object.keys(searchData[dataKey]).map((item) => (
                    <SearchResultItem
                        key={item}
                        data={searchData[dataKey][item]}
                        isSection={isSection}
                        isPos={isPos}
                        onSearchBgHandler={onSearchBgHandler}
                    />
                ))}
            </>
        );
    }

    return null;
};

const KnowledgeBaseSearch = ({
    value,
    setValue,
    placeholder,
    label,
    style = {},
    searchData,
    focusStyle = {},
    loading,
    isPos,
    searchId = null,
    onSearchBgHandler,
}) => {
    const history = useHistory();

    const onRedirect = () => {
        history.push(`/knowledge/search/${value}`);
    };

    const [isFocuses, setIsFocuses] = useState(false);

    useEffect(() => {
        if (!value) {
            setIsFocuses(false);
        } else {
            setIsFocuses(true);
        }
    }, [value]);

    const stylesMemo = useMemo(() => {
        return { ...style, ...(isFocuses ? focusStyle : {}) };
    }, [focusStyle, style, isFocuses]);

    return (
        <div className={styles.searchContainer}>
            <Label label={label} />

            <SearchBlock style={stylesMemo} isFocuses={isFocuses}>
                <img src={SearchIcon} className={styles.searchIcon} alt="" />
                <Input
                    value={value}
                    setValue={setValue}
                    type="knowledge"
                    placeholder={placeholder}
                    setIsFocuses={setIsFocuses}
                    isSearch
                    isFocuses={isFocuses}
                    onRedirect={onRedirect}
                    style={{ padding: "36px 25px 36px 45px" }}
                />
                {loading && <SearchSpinner />}
            </SearchBlock>
            {value && (
                <div
                    style={{
                        ...stylesMemo,
                        position: isPos || searchId ? "absolute" : "relative",
                        top: isPos && !searchId ? "330px" : searchId ? "140px" : "",
                    }}
                    className={`${styles.searchData} ${value ? styles.searchBgActive : styles.searchBgPassive}`}
                >
                    <div
                        style={{
                            maxHeight: isPos && !searchId ? "420px" : searchId ? "480px" : "",
                            width: "100%",
                            overflowY: isPos ? "auto" : "",
                            paddingRight: "24px",
                        }}
                    >
                        <Results
                            isSection={true}
                            searchData={searchData}
                            value={value}
                            stylesMemo={stylesMemo}
                            isPos={isPos}
                            onSearchBgHandler={onSearchBgHandler}
                        />
                        <Results
                            isSection={false}
                            searchData={searchData}
                            value={value}
                            stylesMemo={stylesMemo}
                            isPos={isPos}
                            onSearchBgHandler={onSearchBgHandler}
                        />
                        {Object.keys(searchData).length === 0 ||
                            (Object.keys(searchData["sections"]).length === 0 &&
                                Object.keys(searchData["articles"]).length === 0 && (
                                    <>Ничего не найдено</>
                                ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default KnowledgeBaseSearch;
