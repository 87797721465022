/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from "react";
import styles from "./KnowledgeBaseContent.module.css";
import DocumentByTheme from "./documentByTheme/DocumentByTheme";
import EditIcon from "../../../../assets/img/knowledge-edit.svg";
import RemoveIcon from "../../../../assets/img/comment-remove-icon.png";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { articleRemove, changeArticlePosition, fetchKnowledge } from "../../../../actions/knowledge";
import { addModal, closeModal, removeModal } from "../../../../reducers/appReducer";
import ContentLoader from "../../../ui/contentLoader/ContentLoader";
import KnowledgeRemoveModal from '../../../modals/knowledgeRemoveModal/KnowledgeRemoveModal.jsx'
import { useHistory, useLocation } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { delayByLoader } from "../../../../utils/deleyByLoader";
import SelectUiWiki from "../../../ui/select/SelectUiWiki";


const KnowledgeBaseContent = ({ setSidebarLoading, data, isEdit }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [articlePos, setArticlePos] = useState(null);
    const [articlePosData, setArticlePosData] = useState([]);

    const sectionData = useSelector((state) => state.knowledge.data);
    const currentSectionData = useSelector(
        (state) => state.knowledge.currentSectionData
    );

    const currentArticleData = useSelector(
        (state) => state.knowledge.currentArticleData
    );


    useEffect(() => {
        window.scrollTo(0, 0);
        delayByLoader(true, setLoading, 700);
    }, [location]);

    useEffect(() => {
        if (sectionData?.length > 0) {
            if (data.isSub) {
                const findOne = sectionData.find((item) => item._id === currentSectionData._id);

                const newData = findOne?.articles.find((item) => item._id === currentArticleData?.subSectionId)?.articles.map((item, index) => ({ label: index + 1, value: index + 1, id: item._id, title: item?.title }))
                setArticlePosData(newData);
            } else {
                const findOne = sectionData.find((item) => item._id === currentSectionData?._id);

                const newData = findOne?.articles?.map((item, index) => ({ label: index + 1, value: index + 1, id: item._id, title: item?.title }));

                setArticlePosData(() => newData);
            }
        }
    }, [sectionData, currentSectionData, currentArticleData]);

    const onArticleRemoveHandler = () => {
        dispatch(
            addModal({
                id: "knowledgeRemoveModal",
                size: "bigger",
                children: (
                    <Suspense fallback={<ContentLoader />}>
                        {KnowledgeRemoveModal && (
                            <KnowledgeRemoveModal
                                type="article"
                                data={data}
                                onRemove={onRemove}
                                onError={() => {
                                    dispatch(removeModal("knowledgeRemoveModal"));
                                }}
                            />
                        )}
                    </Suspense>
                ),
            })
        );
    };


    const onRemove = () => {
        dispatch(articleRemove(data._id, onSuccess));
    }

    const onSuccess = () => {
        dispatch(closeModal('knowledgeRemoveModal'));
        history.push(`/knowledge/section/${data.sectionId}`);
    }

    const onPositionChangeHandler = (val) => {
        if (val !== articlePos) {
            const findOne = sectionData.find((item) => item._id === currentSectionData._id);

            if (data.isSub) {
                dispatch(changeArticlePosition(data._id, findOne?.articles.find((item) => item._id === currentArticleData.subSectionId).articles.findIndex((item) => item._id === data._id) + 1, val, onRedirect, setSidebarLoading));
            } else {
                dispatch(changeArticlePosition(data._id, findOne?.articles.findIndex((item) => item._id === data._id) + 1, val, onRedirect, setSidebarLoading));
            }
        }

        setArticlePos(val);
    };

    const onRedirect = () => {
        dispatch(fetchKnowledge());
    };

    return (
        <div className={styles.container}>
            {
                loading ? <ContentLoader /> : <>
                    <div className={styles.titleAndIcon}>
                        <h2 className={styles.title}>{data?.title}</h2>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isEdit && <div style={{ marginRight: '15px' }}>
                                {articlePosData && articlePosData?.length > 0 && <SelectUiWiki
                                    customKey={'position'}
                                    placeholder="Позиция раздела"
                                    label={false}
                                    options={[...articlePosData]}
                                    onChange={(val) => {
                                        onPositionChangeHandler(val)
                                    }}
                                    isSearchable
                                    classes="sections_filter"
                                    value={articlePosData.find(
                                        (item) => (item.id === currentArticleData._id)
                                    )}
                                />}
                            </div>}
                            {isEdit && (
                                <NavLink className={styles.editLink} to={`/knowledge/article/edit/${data._id}`}>
                                    <img className={styles.editIcon} src={EditIcon} alt="" />
                                </NavLink>
                            )}
                            {isEdit && (
                                <img
                                    className={styles.removeIcon}
                                    onClick={onArticleRemoveHandler}
                                    src={RemoveIcon}
                                    alt=""
                                />
                            )}
                        </div>
                    </div>
                    {/* {data?.image && <div
                        className={styles.imageZone}
                        style={{
                            backgroundImage: `url(${data?.image})`,
                        }}
                    ></div>} */}
                    {
                        data?.image && <img src={data?.image} className={styles.img} alt="" />
                    }
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                    <DocumentByTheme articleData={data} setLoading={setLoading} />
                </>
            }


        </div>
    );
};

export default KnowledgeBaseContent;
