import React from 'react';
import styles from './CurrencyRatesDashboard.module.css';
import DashboardCurrencyRatesList from "../../../dashboards/dashboardCurrencyRates/dashboardCurrencyRatesList/DashboardCurrencyRatesList";

const CurrencyRatesDashboard = () => {
    return (
        <div className={styles.DashboardContainer}>
            <div className={styles.TitleBlock}>
                <p>Курс валют</p>
            </div>
            <DashboardCurrencyRatesList />
        </div>

    );
};

export default CurrencyRatesDashboard;
