const SET_APP_LOADER = 'SET_APP_LOADER';
const ADD_MODAL = 'ADD_MODAL';
const REMOVE_MODAL = 'REMOVE_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
const SET_APP_ERROR = 'SET_APP_ERROR';

const defaultState = {
    loader: true,
    modals: [],
    error: false,
    notifications: [],
};

export default function appReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_APP_LOADER:
            return {...state, loader: action.payload};
        case ADD_MODAL:
            return {...state, modals: [...state.modals, action.payload]};
        case REMOVE_MODAL:
            return {...state, modals: [...state.modals.filter(modal=>modal.id !== action.payload)]};
        case CLOSE_MODAL:
            return {...state, modals: [...state.modals.map(modal=>modal.id === action.payload ? {...modal, close: true} : modal)]};
        case ADD_NOTIFICATIONS:
            return {...state, notifications: [...state.notifications, action.payload]};
        case DELETE_NOTIFICATIONS:
            return {...state, notifications: [...state.notifications.filter(notification=>notification.id !== action.payload)]};
        case SET_APP_ERROR:
            return {...state, error: action.payload};
        default:
            return state
    }
}

export const setAppLoader = (set) => ({type: SET_APP_LOADER, payload: set});
export const setAppError = (code) => ({type: SET_APP_ERROR, payload: code});
export const addNotificationAction  = (notification) => ({type: ADD_NOTIFICATIONS, payload: notification});
export const deleteNotificationAction  = (notificationId) => ({type: DELETE_NOTIFICATIONS, payload: notificationId});
export const addModal = (data) => ({type: ADD_MODAL, payload: data});
export const removeModal = (id) => ({type: REMOVE_MODAL, payload: id});
export const closeModal = (id) => ({type: CLOSE_MODAL, payload: id});
