/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import styles from './Pagination.Module.css';
import PaginationLi from './paginationLi/PaginationLi';

const generatePagination = (totalPages, currentPage, onClick) => {
    const minPage = Math.max(currentPage - 2, 1);
    const maxPage = Math.min(currentPage + 2, totalPages);

    return Array.from({ length: maxPage - minPage + 1 }, (_, index) => {
        const pageNumber = minPage + index;
        return (
            <PaginationLi
                name={pageNumber}
                value={pageNumber}
                key={pageNumber}
                type={pageNumber === currentPage ? 'active' : ''}
                onClick={onClick}
            />
        );
    });
};

const Pagination = ({ paginationData, setParamData }) => {
    const { totalPages, page, prevPage, nextPage } = paginationData;

    const handlePaginationClick = (value) => {
        setParamData(value);
    };

    const pagination = useMemo(
        () => generatePagination(totalPages, page, handlePaginationClick),
        [totalPages, page]
    );

    return (
        <div className={styles.Pagination}>
            {totalPages > 1 && (
                <ul>
                    {page !== 1 && (
                        <PaginationLi name="&laquo;" value={1} onClick={handlePaginationClick} type="btn" />
                    )}
                    {prevPage && (
                        <PaginationLi name="&lsaquo;" value={prevPage} onClick={handlePaginationClick} type="btn" />
                    )}
                    {pagination}
                    {nextPage && (
                        <PaginationLi name="&rsaquo;" value={nextPage} onClick={handlePaginationClick} type="btn" />
                    )}
                    {page !== totalPages && (
                        <PaginationLi name="&raquo;" value={totalPages} onClick={handlePaginationClick} type="btn" />
                    )}
                </ul>
            )}
        </div>
    );
};

export default Pagination;