import React from 'react';
import styles from './UserList.module.css';
import UserLi from "./userListItem/UserListItem";

const UserList = ({ users, current }) => {
    if (users.length === 0) {
        return (
            <div>
                <div>Не найдено</div>
            </div>
        );
    }

    return (
        <ul className={styles.List}>
            {users.map(user => <UserLi key={user.id} user={user} current={current} />)}
        </ul>
    );
};

export default UserList;