import React from 'react';
import { NavLink } from "react-router-dom";
import DashboardBillingList from "../../../dashboards/dashboardBilling/dashboardBillingList/DashboardBillingList";
import LinkArrow from "../../../ui/icons/LinkArrow";
import styles from './BillingDashboard.module.css';

const BillingDashboard = ({link}) => {
    return (
        <div className={styles.DashboardContainer}>
            <div className={styles.TitleBlock}>
                <p>Оплаты вебмастерам</p>
                <h3>Последние транзакции</h3>
            </div>
            <DashboardBillingList />
            <NavLink className={styles.Link} to="/billing">Оплаты вебмастерам<LinkArrow/></NavLink>
        </div>

    );
};

export default BillingDashboard;