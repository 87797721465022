import React from 'react';
import styles from './ConversionsStatus.module.css';
import BillingStatusDeclined from "../../../ui/icons/BillingStatusDeclined";
import BillingStatusFinished from "../../../ui/icons/BillingStatusFinished";
import BillingStatusPending from "../../../ui/icons/BillingStatusPending";
import BillingStatusWaiting from "../../../ui/icons/BillingStatusWaiting";
const ConversionsStatus = ({status, className}) => {
    let statusClass = className ? `${styles.Status} ${className} ` : `${styles.Status} `;
    let name = '';
    let icon = '';
    switch (status) {

        case 'confirmed': {
            name = 'Принято';
            statusClass += `${styles.Pending}`;
            icon = <BillingStatusFinished/>;
            break;

        }
        case 'pending': {
            name = 'В обработке';
            statusClass += `${styles.Waiting}`;
            icon = <BillingStatusWaiting/>;
            break;

        }
        case 'declined': {
            name = 'Отклонено';
            statusClass += `${styles.Declined}`;
            icon = <BillingStatusDeclined/>;
            break;

        }

        case 'hold': {
            name = 'Холд';
            statusClass += `${styles.Finished}`;
            icon = <BillingStatusPending/>;
            break;

        }
        default:{

        }
    }

    return (
        <span className={statusClass}>
            {icon}  {name}
        </span>
    );
};

export default ConversionsStatus;