import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import styles from "../../SingleBilling.module.css";
import {format} from "date-fns";
// eslint-disable-next-line no-unused-vars
import {API_URL} from "../../../../../../core/config";
import { setBillingInvoicePDFPath } from "../../../../../../reducers/billingReducer";

const BillingInvoice = ({ invoice }) => {
    const dispatch = useDispatch();
    const billing = useSelector(state => state.billing.current);

    const handleBillingInvoice = () => {
        dispatch(setBillingInvoicePDFPath(billing?.invoice?.path));
    }

    return (
        <div className={`${styles.InfoBlock} ${styles.InvoiceBlock}`}>
            <div className="row">
                <div className="col-1 d-flex align-items-center">
                    <h3>Инвойс: </h3>
                </div>
                <div className="col-4 d-flex align-items-center">
                    <div className={styles.DocLabel}>{invoice.name}</div>
                </div>
                <div className="col-4 d-flex align-items-center">
                    <div className={styles.DocLabel}>
                        <small>
                            {format(new Date(invoice.date), 'LLLL dd yyyy HH:mm:ss')}
                        </small>
                    </div>
                </div>
                <div className="col-3 d-flex justify-content-end">
                    {/* <a className={styles.DocLink} href={`${API_URL}/${invoice.path}`} target="_blank" rel="noreferrer">Посмотреть</a> */}
                    <button className={styles.DocBtn} onClick={handleBillingInvoice}>Посмотреть</button>
                </div>
            </div>
        </div>
    );
};

export default BillingInvoice;
