import $api from '../core/api';
import {processError} from "../core/helpers";

export const fetchManagers = (setManagersData) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/managers`);

            const data = response.data.reduce((acc,cur)=>{
              acc.push({label: `${cur.lastName} ${cur.firstName} - ${cur.email}`, value: cur.email})
              
              return acc;
            },[]);
            
            const sortedData = data?.sort((a, b) => a?.label?.localeCompare(b?.label))
            setManagersData(sortedData);

        } catch (e) {
            processError(dispatch, e);
        }
    }
};