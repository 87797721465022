/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styles from "./KnowledgeDetail.module.css";
import AppContent from "../../appContent/AppContent";
import KnowledgeBaseSideBar from "./knowledgeBaseSideBar/KnowledgeBaseSideBar";
import KnowledgeBaseContent from "./knowledgeBaseContent/KnowledgeBaseContent";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticlesById, fetchKnowledge } from "../../../actions/knowledge";
import { MODULE_TYPE, getModuleRule, isEdit } from "../../../core/helpers";
import KnowledgeNavigation from "../knowledgeBasePage/moderation/knowledgeNavigation/KnowledgeNavigation";
import KnowledgeBaseSearch from "../../knowledgeBase/knowledgeBaseSearch/KnowledgeBaseSearch";
import { setSearchText } from "../../../reducers/knowledgeReducer";
import SearchBg from "../../knowledgeBase/searchBg/SearchBg";


const KnowledgeArticleDetail = ({ match }) => {
    const dispatch = useDispatch();
    const id = match.params.id;

    const [contentLoading, setContentLoading] = useState(true);
    const [sidebarLoading, setSidebarLoading] = useState(false);

    const currentArticleData = useSelector(state => state.knowledge.currentArticleData);
    const currentUser = useSelector(state => state.user.currentUser);
    const rule = getModuleRule(currentUser.rules, MODULE_TYPE.KNOWLEDGE);

    const [value, setValue] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        dispatch(fetchKnowledge(setContentLoading));
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(fetchArticlesById(id));
        }
    }, [id]);

    const onSearchBgHandler = () => {
        setValue('');
        dispatch(setSearchText(''));
        setSearchData([]);
    };

    const onSetSearchText = (value) => {
        setValue(value);
        dispatch(setSearchText(value));
    };

    return (
        <AppContent>
            <SearchBg value={value} setSearchData={setSearchData} setLoading={setLoading} onSearchBgHandler={onSearchBgHandler} />
            <div className={`container ${styles.container}`} ref={containerRef}>
                {contentLoading ? (
                    <ContentLoader />
                ) : (
                    <>
                        <KnowledgeNavigation isArticle data={currentArticleData} />
                        <KnowledgeBaseSearch
                            searchId={id}
                            isPos={true}
                            value={value}
                            loading={loading}
                            setValue={onSetSearchText}
                            placeholder="Поиск"
                            searchData={searchData}
                            setSearchData={setSearchData}
                            style={{ zIndex: 3, width: "100%", borderRadius: "16px", border: "none", marginBottom: '35px' }}
                            onSearchBgHandler={onSearchBgHandler}
                        />
                        <div className={styles.wrapperContainer}>
                            <KnowledgeBaseSideBar sidebarLoading={sidebarLoading} />
                            <KnowledgeBaseContent setSidebarLoading={setSidebarLoading} data={currentArticleData} isEdit={isEdit(rule)} />
                        </div>
                    </>

                )}
            </div>
        </AppContent>
    );
};

export default KnowledgeArticleDetail;
