import React, { useEffect, useState } from "react";
import styles from "./UpdatePasswordPage.module.css";
import AppContent from "../../appContent/AppContent";
import { useDispatch, useSelector } from "react-redux";
import { delayByLoader } from "../../../utils/deleyByLoader";
import { Redirect } from "react-router-dom";
import Button from "../../ui/button/Button";
import EyeIcon from "../../../assets/img/eye-icon.png";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import Logo from "../../../assets/img/header-logo.png";
import InputLabel from "../../ui/input/inputLabel/InputLabel";
import { useParams ,useHistory} from "react-router-dom";
import { updatePassword } from "../../../actions/user";

const UpdatePasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, recoverId } = useParams();

  const isAuth = useSelector((state) => state.user.isAuth);
  const userErrors = useSelector((state) => state.user.errors);
  const [loading, setLoading] = useState(true);
  const [eye, setEye] = useState(false);
  const [isSend, setIsSend] = useState(false);


  const [authData, setAuthData] = useState({
    password: { value: "", errors: false },
  });

  useEffect(() => {
    delayByLoader(true, setLoading, 300);
  }, []);

  useEffect(() => {
    if (userErrors) {
      let theFields = { ...authData };
      userErrors.map((error) => {
        if (error.param in authData) {
          theFields[error.param] = {
            value: authData[error.param].value,
            error: error.msg,
          };
        }
        return error;
      });
      setAuthData({ ...theFields });
    }
    // eslint-disable-next-line
  }, [userErrors]);

  const onUpdateHandler = () => {
    dispatch(
      updatePassword(id, recoverId, authData.password.value, setLoading,setIsSend,onSuccess)
    );
  };

  const onSuccess = ()=>{
   setTimeout(() => {
    history.push('/login');
   }, 1500);
  }

  if (isAuth) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const onEyeHandler = () => {
    setEye(!eye);
  };

  return (
    <AppContent paddingReset>
      <div className={styles.container}>
        <div className={styles.content}>
          {loading && <ContentLoader />}
          <div className={styles.logo}>
            <img src={Logo} alt="" />
          </div>
          {
            !isSend ? (
          <>
           <div className={styles.inputs}>
            <InputLabel
              type={eye ? "text" : "password"}
              value={authData.password.value}
              placeholder="New Password"
              setValue={(val) => {
                setAuthData({
                  ...authData,
                  password: { value: val, error: false },
                });
              }}
              className={styles.password_input}
              icon={
                <img
                  src={EyeIcon}
                  alt="eye"
                  className={styles.eye_icon}
                  onClick={onEyeHandler}
                />
              }
            />
            {authData.password.error && (
              <span className={styles.error_text}>
                {authData.password.error}
              </span>
            )}

            <Button className={styles.login_btn} onClick={onUpdateHandler}>
              Обновить
            </Button>
          </div>
          </>
            ) : (
            <p className={styles.is_send_text}>
             Пароль успешно обновлён
            </p>
          )
          }
        
         
        </div>
      </div>
    </AppContent>
  );
};

export default UpdatePasswordPage;
