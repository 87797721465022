import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../reducers/appReducer';
import styles from './ArticleImageRemoveModal.module.css';
import './ArticleImageRemoveModal.css'
import Button from "../../ui/button/Button";

const ArticleImageRemoveModal = ({ onRemove }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.querySelector("body").style.overflow = "hidden";
        return () => {
            document.querySelector("body").style.overflow = "unset";
        };
    }, []);

    const onCancelHandler = () => {
        dispatch(closeModal("articleImageRemoveModal"));
    };

    return (
        <div className={styles.ModalModule} id="ModalModule">
            <div className={styles.Header}>
                <h2 className={styles.title}>
                    Удаление изображения
                </h2>
            </div>
            <p className={styles.infoTextSecond}>
                Вы уверены, что хотите удалить изображение?
            </p>
            <div className={styles.btns}>
                <Button className={styles.viewBtn} onClick={onCancelHandler}>
                    отменить
                </Button>
                <Button className={styles.createArticle} onClick={onRemove}>
                    Удалить
                </Button>
            </div>
        </div>
    );
}

export default ArticleImageRemoveModal;