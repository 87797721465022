import React from 'react';
import styles from "../../OfferCard.module.css";

const PaymentHistoryItem = ({item}) => {
    const goal = item.personal ? `[ ${item.goal} ]` : `${item.goal}`;

    return (
        <div className={`${styles.HistoryItem} row mt-3`}>
            <div className={`${styles.historyDate} col-2`}>
                [ { new Date(item.createdAt).toLocaleString("ru",{dateStyle: "short", timeStyle: "short"}) } ]
            </div>
            <div className={`${styles.historyDescription} col-10 pl-0`}>
                <p className="mb-1">Пользоватетелем <span className={styles.UserName}>{item.user}</span> {item.description}</p>
                <p>
                    {item.personal
                        ? <><span className={styles.HistoryLabel}>Вебмастера: </span>{goal},&nbsp;</>
                        : <><span className={styles.HistoryLabel}>Цель: </span>{goal},&nbsp;</>
                    }
                    <span className={styles.HistoryLabel}>Имя цели:</span> {item.title},&nbsp;
                    <span className={styles.HistoryLabel}>Доход:</span> старый {item.totalOld}  / новый {item.total},&nbsp;
                    <span className={styles.HistoryLabel}>Вебмастеру:</span> старая {item.revenueOld} / новая {item.revenue}.
                </p>
            </div>
        </div>
    );
};

export default PaymentHistoryItem;
