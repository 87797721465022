const SET_USER = "SET_USER";
const LOGOUT = "LOGOUT";
const SET_PAGE_USERS = "SET_PAGE_USERS";
const SET_PAGE_USER = "SET_PAGE_USER";
const UPDATE_USER = "UPDATE_USER";
const SET_USER_ERRORS = "SET_USER_ERRORS";
const DELETE_USER = "DELETE_USER";

const defaultState = {
    currentUser: {},
    isAuth: false,
    errors: false,
    items:[],
    item: {}
};

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                currentUser: action.payload,
                isAuth: true
            };
        case LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                currentUser: {},
                isAuth: false
            };
        case SET_PAGE_USERS:
            return {
                ...state,
                 items: action.payload,
            };
        case UPDATE_USER:
            return {
                ...state,
                 items: state.items.map(user=>user.id === action.payload.id ? action.payload : user),
                 item: action.payload
            };

       case SET_PAGE_USER:
            return {
                ...state,
                 item: action.payload,
            };
        case SET_USER_ERRORS:
            return {
                ...state,
                errors: action.payload
            };
        case DELETE_USER:
            return {
                ...state,
                items: state.items.filter( user => user.id !== action.payload )
            };
        default:
            return state
    }

}


export const setUser = user => ({type: SET_USER, payload: user});
export const logoutUser = () => ({type: LOGOUT});
export const setUserItems = items=>({type: SET_PAGE_USERS, payload:items});
export const getUser = item=>({type: SET_PAGE_USER,payload:item});
export const updateUserList = item => ({type: UPDATE_USER, payload:item});
export const setUserErrors = errors => ({type: SET_USER_ERRORS, payload: errors});
export const deleteUser = id => ({type: DELETE_USER, payload: id})
