import React from 'react';
import { components } from "react-select";

const CustomValueContainer = ({children, index, getValue, ...props }) => {
    const maxToShow = 1;

    return (
        index < maxToShow
            ? <components.MultiValue {...props}  children={children}/>
            : index === maxToShow
                ? <div className="cl.MultiAddQty">{`+  ${getValue().length - 1}`}</div>
                : null
        );
};

export default CustomValueContainer;
