import React from 'react';
import "./SelectUi.css";
import Select from "react-select";
const SelectUiv2 = ({ options, defaultValue, onChange, placeholder, value, label }) => {
    return (
        <div>
            <div className='label'>{label}</div>
            <Select
                value={value}
                options={options}
                defaultValue={defaultValue}
                onChange={(val) => {
                    onChange(val.value)
                }}
                placeholder={placeholder}
                isSearchable={false}
                className={`selectModal`}
                classNamePrefix='selPref'
            />
        </div>
    );
};

export default SelectUiv2;
