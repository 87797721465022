import styles from './Header.module.css';
import Navigation from "./navigation/Navigation";
const Header = () => {
    return (

        <header className={styles.appHeader}>
            <Navigation/>
        </header>
    );
};

export default Header;
