import React, {useEffect, useState} from 'react';
import styles from './CurrencyRatesPicker.module.css'
import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";
import {setThreeMonthsAgoDate, setOneMonthAgoDate, generateYearMonthMatrix, getMonthsArr} from "../../../../../core/helpers";
import {getMonthsCurrencyRates} from "../../../../../actions/currency";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../../ui/button_v2/ButtonV2";
import CurrencyRateRow from '../currencyRow/CurrencyRateRow';
import SelectUi from "../../../../ui/select/SelectUiv2";
import {createNotification} from "../../../../../actions/app";

const CurrencyRatesPickerComponent = () => {
    const dispatch = useDispatch();
    const monthsCurrencyRates = useSelector(state => state.reports.monthsCurrencyRates);
    const currencyDropDownList = [
        {value: 'USD', label: 'USD'},
        {value: 'EUR', label: 'EUR'},
        {value: 'KZT', label: 'KZT'},
    ];

    useEffect(() => {
        dispatch(getMonthsCurrencyRates());
    }, [dispatch]);

    const [currencyRatesList, setCurrencyRatesList] = useState([]);
    const [searchParams, setSearchParams] = useState({
        startDate: setThreeMonthsAgoDate(),
        endDate: setOneMonthAgoDate(),
        currency: ''
      });

    registerLocale('ru', ru);
    const onDataPickerChange = (dates) => {
        const [start, end] = dates;
        setSearchParams({
            ...searchParams,
            startDate: start,
            endDate: end,
        });
    };

    const getCurrencyRates = () => {
        try {
            if (searchParams.currency) {
                const currencyRatesArr = [];
                let currencyRate = {};
                const monthMatrix = generateYearMonthMatrix(searchParams.startDate, searchParams.endDate);
                const monthsArr = getMonthsArr(monthMatrix);
                for (const date of monthsArr) {
                    currencyRate = {
                        currency: searchParams.currency,
                        year: date.year,
                        month: date.month,
                        rate: monthsCurrencyRates[date.year][date.month][searchParams.currency]
                    };
                    currencyRatesArr.push(currencyRate);
                }
                setCurrencyRatesList(currencyRatesArr);
            } else {
                dispatch(createNotification({
                    type: 'secondary',
                    children: 'Валюта не выбрана'
                }));
            }
        } catch(e) {
            dispatch(createNotification({
                type: 'secondary',
                children: 'Ошибка при получении курсов валют'
            }));
        }
    };

    return (
        <>
            <div className={styles.SearchParamsBlock}>
                <div className={styles.FilterItem}>
                    <label className={styles.Label}>Период</label>
                    <DatePicker
                        className={`${styles.DatePicker} inputField`}
                        selectsRange
                        locale={ru}
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        startDate={searchParams.startDate}
                        endDate={searchParams.endDate}
                        onChange={onDataPickerChange}
                    />
                </div>
                <div className={styles.FilterItem}>
                <label className={styles.Label}>Валюта</label>
                    <div className={styles.CurrencyDropDown}>
                        <div className={styles.CurrencySelect}>
                            <SelectUi
                                options={currencyDropDownList}
                                onChange={val => setSearchParams({
                                    ...searchParams, currency: val
                                    })}
                                placeholder='Выбирете валюту'
                            />
                        </div>
                        <Button
                            className={"py-0"}
                            type={'primary'}
                            onClick={getCurrencyRates}
                        >
                            Показать
                        </Button> 
                    </div>
                </div>
            </div>
            {currencyRatesList.length ? 
            <div className={styles.CurrencyRateRow}>
                <label className="col-4">Валюта</label>
                <label className="col-4">Курс</label>
                <label className="col-4">Месяц</label>
            </div>: ''
            }
            
            {currencyRatesList.length ? currencyRatesList.map((currencyRate, index) => 
                    <CurrencyRateRow 
                        key={index} 
                        currency={currencyRate.currency} 
                        rate={currencyRate.rate} 
                        month={currencyRate.month} 
                        year={currencyRate.year}
                    />
            ): ''
            }
        </>
        
    )
}

export default CurrencyRatesPickerComponent;