/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppContent from '../../../appContent/AppContent';
import { getPartnerById } from '../../../../actions/partners';
import { setCurrentPartner } from '../../../../reducers/partnersReducer';
import ContentLoader from '../../../ui/contentLoader/ContentLoader';
import styles from './PartnerCard.module.css'
import TabsModal from '../../../ui/tabsModal/TabsModal';
import { NavLink } from "react-router-dom";
import PartnersGeneral from '../partnersTabElements/parnersGeneral/PartnersGeneral';
import PartnersPayment from '../partnersTabElements/partnersPayment/PartnersPayment';
import PartnersCredit from '../partnersTabElements/partnersCredit/PartnersCredit';

const PartnerCard = ({ match }) => {
    const dispatch = useDispatch();
    const partnerId = match.params.partnerId;
    const currentPartner = useSelector(state => state.partners.currentPartner);

    const [loading, setLoading] = useState(true);
    const controls = ['Основное', 'Выплаты', 'Аванс'];
    const [verify, setVerify] = useState(false);

    const [general, setGeneral] = useState('')
    const [payments, setPayments] = useState('')
    const [credit, setCredit] = useState('')


    useEffect(() => {
        if (Object.keys(currentPartner)?.length > 0) {
            setGeneral(<PartnersGeneral data={currentPartner} />);
            setPayments(<PartnersPayment data={currentPartner} />)
            setCredit(<PartnersCredit data={currentPartner} verify={verify} setVerify={(val) => { setVerify(val) }} loading={loading} setLoading={setLoading} />)

        }
    }, [currentPartner])

    useEffect(() => {
        dispatch(getPartnerById(partnerId, setLoading));
        return () => {
            dispatch(setCurrentPartner(''));
        }
    }, [dispatch, partnerId]);

    return (
        <AppContent>
            <div className={`container wrapper`}>
                {loading && <ContentLoader />}
                <div className='row'>
                    <div className='col-12'>
                        <div className='m-3'><NavLink className={styles.BackLink} to="/partners">Назад</NavLink></div>
                        <div className={styles.OfferCardContent}>
                            <div className={styles.Header}>
                                <div className={styles.Title}>
                                    Вебмастер {currentPartner.login}
                                    <span className={styles.PartnerContacts}>&nbsp;&nbsp;({currentPartner.email}, ID: {currentPartner.affiseId})</span>
                                </div>
                            </div>
                            <div className={styles.Body}>
                                <TabsModal updateData data={currentPartner} controls={controls} contents={[general, payments, credit]} />
                            </div>
                        </div>
                    </div>

                </div>

                {/* {loading ? <ContentLoader /> : (
                    <div>
                        <div className={styles.Header}>
                            <div className={styles.Title}>
                                Вебмастер {currentPartner.login}
                                <span className={styles.PartnerContacts}>&nbsp;&nbsp;({currentPartner.email}, ID: {currentPartner.affiseId})</span>
                            </div>
                        </div>
                        <div className={styles.Body}>
                            <TabsModal updateData data={currentPartner} controls={controls} contents={[general, payments, credit]} />
                        </div>
                    </div>
                )} */}
            </div>
        </AppContent>
    )
}

export default PartnerCard;