import React from 'react';
import styles from './OffersContenLi.module.css';
import { OfferStatus, OfferPrivacy } from "../../../../../core/config";
import { getOptions } from "../../../../../core/helpers";
import defaultLogoOffer from '../../../../../assets/img/deffaultLogoOffer.png';
import { NavLink } from "react-router-dom";
import DetailsIcon from '../../../../../assets/img/details-icon.png'

const OffersContentLi = ({ item }) => {
    return (
        <li className={`${styles.Item} `}>
            <div className={`${styles.Col} ${styles.Id}`}>{item.affiseId}</div>
            <div className={`${styles.Col} ${styles.Name}`}>
                <div className={styles.Image}><img src={item.logo ? item.logo : defaultLogoOffer} alt="" /></div>
                <div className={styles.Title}>{item.title}</div>
            </div>
            <div className={`${styles.Col} ${styles.Status} `}>
                {item?.archive ? <span className={styles.archive}>В архиве</span> : getOptions(OfferStatus, item.status)}
            </div>
            <div className={`${styles.Col} ${styles.Privacy} `}>
                {getOptions(OfferPrivacy, item.privacy) ? getOptions(OfferPrivacy, item.privacy):
                <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Col} ${styles.Advertiser} `}>
                {item?.advertiser?.title ? item?.advertiser?.title:
                <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Col} ${styles.Manager} `}>
                {item?.advertiser?.manager && Object.keys(item?.advertiser?.manager)?.length ? `${item.advertiser.manager.firstName} ${item.advertiser.manager.lastName}` : 
                <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Td} ${styles.Btn}`}>
                <NavLink to={`/offers/${item.id}`}><img src={DetailsIcon} alt="" /></NavLink>
            </div>
        </li>
    );
};

export default OffersContentLi;
