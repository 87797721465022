import React from 'react';
import { Draggable } from "react-beautiful-dnd";

const Rule = ({ rule, index }) => {
    return (
        <Draggable key={rule.id} draggableId={rule.id} index={index}>
            {(provided) => (
                <div className='ruleItem' ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    {rule.content}
                </div>

            )}
        </Draggable>
    );
};

export default Rule;