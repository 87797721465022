import React from 'react';

const EditIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 10.9999C18.7348 10.9999 18.4804 11.1053 18.2929 11.2928C18.1054 11.4804 18 11.7347 18 11.9999V17.9999C18 18.2652 17.8946 18.5195 17.7071 18.707C17.5196 18.8946 17.2652 18.9999 17 18.9999H3C2.73478 18.9999 2.48043 18.8946 2.29289 18.707C2.10536 18.5195 2 18.2652 2 17.9999V3.99994C2 3.73472 2.10536 3.48037 2.29289 3.29283C2.48043 3.1053 2.73478 2.99994 3 2.99994H9C9.26522 2.99994 9.51957 2.89458 9.70711 2.70705C9.89464 2.51951 10 2.26516 10 1.99994C10 1.73472 9.89464 1.48037 9.70711 1.29283C9.51957 1.1053 9.26522 0.999939 9 0.999939H3C2.20435 0.999939 1.44129 1.31601 0.87868 1.87862C0.316071 2.44123 0 3.20429 0 3.99994V17.9999C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 20.9999 3 20.9999H17C17.7956 20.9999 18.5587 20.6839 19.1213 20.1213C19.6839 19.5587 20 18.7956 20 17.9999V11.9999C20 11.7347 19.8946 11.4804 19.7071 11.2928C19.5196 11.1053 19.2652 10.9999 19 10.9999ZM4 11.7599V15.9999C4 16.2652 4.10536 16.5195 4.29289 16.707C4.48043 16.8946 4.73478 16.9999 5 16.9999H9.24C9.37161 17.0007 9.50207 16.9755 9.62391 16.9257C9.74574 16.8759 9.85656 16.8026 9.95 16.7099L16.87 9.77994L19.71 6.99994C19.8037 6.90698 19.8781 6.79637 19.9289 6.67452C19.9797 6.55266 20.0058 6.42195 20.0058 6.28994C20.0058 6.15793 19.9797 6.02722 19.9289 5.90536C19.8781 5.7835 19.8037 5.6729 19.71 5.57994L15.47 1.28994C15.377 1.19621 15.2664 1.12182 15.1446 1.07105C15.0227 1.02028 14.892 0.994141 14.76 0.994141C14.628 0.994141 14.4973 1.02028 14.3754 1.07105C14.2536 1.12182 14.143 1.19621 14.05 1.28994L11.23 4.11994L4.29 11.0499C4.19732 11.1434 4.12399 11.2542 4.07423 11.376C4.02446 11.4979 3.99924 11.6283 4 11.7599ZM14.76 3.40994L17.59 6.23994L16.17 7.65994L13.34 4.82994L14.76 3.40994ZM6 12.1699L11.93 6.23994L14.76 9.06994L8.83 14.9999H6V12.1699Z" fill="#A7A7A7"/>
        </svg>
    );
};

export default EditIcon;
