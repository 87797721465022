import React, {lazy, Suspense, useEffect, useMemo, useState} from 'react';
import styles from './ConversionsList.module.css';
import ConversionsLi from "../conversionsLi/ConversionsLi";
import Button from "../../../../ui/button_v2/ButtonV2";
import AddIcon from "../../../../ui/icons/AddIcon";
import InputCheckbox from "../../../../ui/input/InputCheckbox/InputCheckbox";
import DesertClockIcon from "../../../../ui/icons/DesertClockIcon";
import CheckboxIcon from "../../../../ui/icons/CheckboxIcon";
import CloseRIcon from "../../../../ui/icons/CloseRIcon";
import {addModal, removeModal} from "../../../../../reducers/appReducer";
import ContentLoader from "../../../../ui/contentLoader/ContentLoader";
import {useDispatch} from "react-redux";
import {setOfferItem} from "../../../../../reducers/offerReduser";
import {bySortFieldDESC,bySortFieldASC} from "../../../../../core/helpers";

const ConversionsList = ({conversionsData, loading}) => {
    const CreateModal = lazy(() => import("../../../../modals/conversionsCreateModal/ConversionsCreateModal"));
    const MassEditModal = lazy(() => import("../../../../modals/conversionsMassEditModal/ConversionsMassEditModal"));
    const dispatch = useDispatch();
    const [dropDown, setDropDown] = useState('');

    const [checked, setChecked] = useState({checked: false});
    const [editList, setEditList] = useState({items: []});
    const [sort,setSort] = useState({createAt:'',status:''});
    const EditAdvertiserModal = lazy(() => import("../../../../modals/conversionsEditStatusModal/ConversionsEditStatusModal"));
    const noFound = conversionsData ? conversionsData.filter((conversion) => (conversion.isIsset !== false)) : '';
    const activeList = editList.items.filter(list => list.checked);

    useEffect(() => {
        activateCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    function activateCheck(){
        const check = editList.items;
        if (editList.length !== 0) {

            check.map(list => {
                return list.checked = checked.checked;
            });
            setEditList({...editList, items: check});

        }

    }

    function addElem(noFound) {
        const ls = noFound.map(data => {
            const ls = {name: 'ConversionsMass-' + data.id, value: data.id, checked: false};
            return ls;
        });
        return ls;
    }

    function arrRes(arr, key) {
        if (arr.length !== 0) {
            let newArr = arr.map(item => {
                return item[key];
            });
            return newArr.join('');

        }
    }

    function editAdvertiserSum(conversionsData, editList, status) {

        dispatch(addModal({
            id: 'conversionsEditStatus',
            size: 'big',
            children:
                <Suspense fallback={<ContentLoader/>}>
                    {EditAdvertiserModal &&
                    <EditAdvertiserModal
                        onError={() => {
                            dispatch(removeModal('conversionsEditStatus'))
                        }}
                        data={addCheck(conversionsData, editList)}
                        status={status}

                    />}
                </Suspense>
        }));
    }

    const conversions = useMemo(() => {
        if (conversionsData.length !== 0 && noFound.length !== 0) {
            let index = 0;
            if (editList.items.length !== 0 && noFound.length !== 0) {
                const arr1 = arrRes(editList.items, 'value');
                const arr2 = arrRes(noFound, 'id');
                if (arr1 !== arr2) {
                    setEditList({...editList, items: addElem(noFound)});
                }
            }
            if (editList.items.length === 0) {
                setEditList({...editList, items: addElem(noFound)});
            }
            const res = conversionsData.map((data) => {
                if (data.isIsset !== false) {
                    index++;
                    return <ConversionsLi
                        conversions={data}
                        index={index}
                        key={index}
                        dropDown={dropDown}
                        setDropDown={(val) => setDropDown(val)}

                        editList={editList.items}
                        setEditList={(val) => setEditList({...editList, items: val})}
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        active={dropDown === index}
                    />
                }
                return false;
            }, index, editList, noFound);
            res.filter(n => n)
            return res;

        }
    }, [conversionsData, dropDown, editList, noFound]);

    function addSort(val){
        if(sort[val] ==='' || sort[val] === 2){
            conversionsData.sort(bySortFieldASC(val));
            setSort({[val]:1});
        }else if(sort[val] === 1){
            conversionsData.sort(bySortFieldDESC(val));
            setSort({[val]:2});
        }
        if(val !== Object.keys(sort)[0]){
            conversionsData.sort(bySortFieldASC(val));
            setSort({[val]:1});
        }
    }

    function create() {
        dispatch(setOfferItem({}));
        dispatch(addModal({
            id: 'conversionsCreate',
            size: 'big',
            children:
                <Suspense fallback={<ContentLoader/>}>
                    {CreateModal &&
                    <CreateModal
                        onError={() => {
                            dispatch(removeModal('conversionsCreate'))
                        }}
                        type='create'

                    />}
                </Suspense>
        }));
    }

    function editStatus(conversionsData, editList, status) {
        const activeList = editList.items.filter(list => list.checked);
        if (activeList.length !== 0) {
            editAdvertiserSum(conversionsData, editList, status);
        }

    }

    function addCheck(conversions, editList) {
        if (editList) {
            const dataActive = conversions.map(item => {
                let active = [];

                if(editList.items){
                     active = editList.items.find(dataItem => dataItem.value === item.id);
                }else{
                     active = editList.find(dataItem => dataItem.value === item.id);
                }
                if(active){
                    return active.checked === true ? item : '' ;
                }else{
                    return '';
                }

            }, editList);

            return dataActive.filter(n => n);

        } else {
            return [];
        }
    }

    function massEdit() {
        dispatch(addModal({
            id: 'conversionsMassEdit',
            size: 'middle',
            children:
                <Suspense fallback={<ContentLoader/>}>
                    {CreateModal &&
                    <MassEditModal
                        onError={() => {
                            dispatch(removeModal('conversionsMassEdit'))
                        }}
                        data={addCheck(conversionsData, editList)}
                    />}
                </Suspense>
        }));
    }

    return (
        <div className={styles.ConversionsList}>
            <div className={styles.Head}>
                <div className={styles.Title}>
                    <h3>Результаты поиска</h3>
                    <p className={styles.Desc}>Внимание: все изменения происходят не сразу,нужно подождать 1-5 минут для вступления в силу изменений </p>
                </div>

                <div className={styles.BtnBlock}>
                    <Button type='primary' onClick={create}>
                        <AddIcon/>&nbsp;Создать конверсию
                    </Button>
                    {(noFound.length !== 0) && (activeList.length !== 0) &&
                        <Button type='secondary' onClick={massEdit}>
                            <AddIcon/>&nbsp;Редактирование
                        </Button>
                    }
                    {(noFound.length !== 0) && (activeList.length !== 0) &&
                        <Button className={styles.BtnGray} onClick={() => editStatus(conversionsData, editList, 'pending')}>
                            <DesertClockIcon/>&nbsp;В обработке
                        </Button>
                    }
                    {(noFound.length !== 0) && (activeList.length !== 0) &&
                        <Button  className={styles.BtnGreen} onClick={() => editStatus(conversionsData, editList, 'confirmed')}>
                            <CheckboxIcon/>&nbsp;Принято
                        </Button>
                    }
                    {(noFound.length !== 0) && (activeList.length !== 0) &&
                        <Button className={styles.BtnRed} type='danger' onClick={() => editStatus(conversionsData, editList, 'declined')}>
                            <CloseRIcon/>&nbsp;Отклонено
                        </Button>
                    }
                </div>
            </div>

            <div className={styles.Content}>
                {loading ? <ContentLoader/> : (
                    (noFound.length !== 0) ?
                        <div>
                            <div className={styles.ContentHeader}>
                                <div className={`${styles.Th} ${styles.Checkbox}`}><InputCheckbox
                                    name='conversionsMassAll' value='conversionsMassAll' setValue={setChecked}/></div>
                                <div className={`${styles.Th} ${styles.Num}`}>№</div>
                                <div className={`${styles.Th} ${styles.Id}`}>Партнер</div>
                                <div className={`${styles.Th} ${styles.Offer}`}>Оффер</div>
                                <div className={`${styles.Th} ${styles.OrderId}`}>ID конверсии</div>
                                <div className={`${styles.Th} ${styles.ClickId}`}>Click ID</div>
                                <div className={`${styles.Th} ${styles.Revenue}`}>Доход</div>
                                <div className={`${styles.Th} ${styles.Payouts}`} >Выплаты</div>
                                <div className={ sort.status ? `${styles.Active} ${styles.Th} ${styles.Status}` :`${styles.Th} ${styles.Status}` }
                                     onClick={()=>addSort('status')}> Статус конверсии
                                    {sort.status === 2  && sort.status &&  <div className={`${styles.Icon} ${styles.Down}`}></div>}
                                    {sort.status === 1  && sort.status &&  <div className={`${styles.Icon} ${styles.Up}`}></div>}
                                </div>
                                <div className={ sort.createAt ? `${styles.Active} ${styles.Th} ${styles.Status}` :`${styles.Th} ${styles.Status}` } onClick={()=>addSort('createAt')} >Дата создания
                                    {sort.createAt === 2  && sort.createAt &&  <div className={`${styles.Icon} ${styles.Down}`}></div>}
                                    {sort.createAt === 1  && sort.createAt &&  <div className={`${styles.Icon} ${styles.Up}`}></div>}
                                </div>
                                <div className={`${styles.Th} ${styles.Btn}`}></div>
                            </div>
                            <ul>
                                {conversions}
                            </ul>
                        </div>
                        : (
                            <div className={styles.NoData}>
                                <h1>Ничего не найдено</h1>
                            </div>
                        )
                )}
            </div>

        </div>
    );
};

export default ConversionsList;
