import React from 'react';

const EqualIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9.5C4.44772 9.5 4 9.94771 4 10.5C4 11.0523 4.44772 11.5 5 11.5H19C19.5523 11.5 20 11.0523 20 10.5C20 9.94771 19.5523 9.5 19 9.5H5Z" fill="#202020"/>
            <path d="M5 13.5C4.44772 13.5 4 13.9477 4 14.5C4 15.0523 4.44772 15.5 5 15.5H19C19.5523 15.5 20 15.0523 20 14.5C20 13.9477 19.5523 13.5 19 13.5H5Z" fill="#202020"/>
        </svg>

    );
};

export default EqualIcon;