import React from 'react';
import styles from './OffersContent.module.css';
import OfferItem from "./offersContentLi/OffersContentLi";

const OffersContent = ({ data }) => {

    const dataList = data.map(item => (
        <OfferItem key={item.id} item={item} />
    ));

    return (
        <div className={styles.ListContainer}>
            <div className={`${styles.ListHeader} `}>
                <div className={`${styles.Id}`}><span className={styles.id_text}>ID</span></div>
                <div className={`${styles.Name}`}>Название</div>
                <div className={`${styles.Status}`}>Статус</div>
                <div className={`${styles.Privacy}`}>Приватность</div>
                <div className={`${styles.Advertiser}`}>Связанный рекламодатель</div>
                <div className={`${styles.Manager}`}>Менеджер оффера</div>
                <div className={`${styles.Btn}`}></div>
            </div>
            {data.length !== 0 ? (
                <ul className={styles.ListBody}>
                    {dataList}
                </ul>
            ) : (
                <div className={styles.NoData}>
                    <h1>Ничего не найдено</h1>
                </div>
            )}
        </div>
    );
};

export default OffersContent;