/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AppContent from "../../appContent/AppContent";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import styles from "./Partners.module.css";
import PartnersContent from "./content/PartnersContent";
import PartnersFilters from "./filters/PartnersFilters";
import { useDispatch, useSelector } from "react-redux";
import { MODULE_TYPE, getModuleRule, isRead } from "../../../core/helpers";
import { Redirect } from "react-router-dom";
import { fetchPartners } from "../../../actions/partners";
import Pagination from "../../ui/pagination/Pagination";
import { partnersFilterParams } from '../../../core/config';
import { setPartnerPaginationReset } from '../../../reducers/partnersReducer';


const Partners = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const partners = useSelector(state => state.partners.items);
    const pagination = useSelector(state => state.partners.pagination);
    const rule = getModuleRule(useSelector(state => state.user.currentUser.rules), MODULE_TYPE.PARTNERS);
    const sort = useSelector(state => state.partners.sort);
    const [sortData, setSortData] = useState({ name: "amount", value: 2 });
    const sumRevenue = useSelector(state => state.partners.sumRevenue);

    const page = pagination.page;

    let filters = JSON.parse(JSON.stringify(partnersFilterParams));

    let params = {
        filters: filters,
        page: page,
        sort: sort,
    };

    const [paramData, setParamData] = useState(params);

    useEffect(() => {
        return () => {
            dispatch(setPartnerPaginationReset());
        }
    }, []);

    useEffect(() => {
        setParamData({ ...paramData, sort: sortData });
    }, [sortData]);

    useEffect(() => {
        setLoading(true);
        dispatch(fetchPartners(paramData, setLoading));
    }, [dispatch, paramData]);


    if (!isRead(rule)) {
        return (
            <Redirect to='/' />
        );
    }

    return (
        <AppContent>
            <div className={`partnersContainer container wrapper`}>
                <div className={`${styles.TitleBlock}`}>
                    <h2>Вебмастера</h2>
                </div>
                <div className={`${styles.FiltersBlock}`}>
                    <PartnersFilters
                        partnersData={partners}
                        filterData={paramData.filters}
                        setFilterData={(val) => setParamData({ ...paramData, filters: val.filter, page: val.page })}
                        page={page} />
                </div>
                {loading ? <ContentLoader /> : (
                    <div className={styles.ContentBlock}>
                        {params.search && <div className={styles.DescSearch}>Результаты поиска</div>}
                        <PartnersContent
                            data={partners}
                            loading={loading}
                            setSortData={(val) => setSortData(val)}
                            sortData={sortData}
                            page={page}
                            sumRevenue={sumRevenue}
                        />
                    </div>
                )}

                <div className={styles.PaginationBlock}>
                    <Pagination paginationData={pagination} paramData={paramData}
                        setParamData={(val) => setParamData({ ...paramData, page: val })} />
                </div>
            </div>
        </AppContent>
    );
};

export default Partners;