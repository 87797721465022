import $api from "../core/api";
import {getDateString, getFirstDayOfMonth, getLastDayOfMonth, processError, shiftDateToUTC} from "../core/helpers";
import {createNotification} from "./app";

export const getIncomeCategoryList = (userEmail) => {
    return async ( dispatch ) => {
        try {
            let response;
            if (userEmail) {
                response = await $api.get(`/api/partners-income/categories?userEmail=${userEmail}`);
            } else {
                response = await $api.get(`/api/partners-income/categories`);
            }
            if ( response && response.data && response.data.success ) {
                return response.data.categories;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const getProjectIncome = ( searchParams ) => {
    return async ( dispatch ) => {
        try {
            const startDate = getDateString( shiftDateToUTC(getFirstDayOfMonth(searchParams.startDate)) );
            const endDate = getDateString( shiftDateToUTC(getLastDayOfMonth(searchParams.endDate)) );
            const params = {
                projectId: searchParams.projectId,
                startDate: startDate,
                endDate: endDate,
                incomeCategories: searchParams.incomeCategories
            };

            const response = await $api.get(`/api/partners-income/project-income`, {
                params: params
            });

            if ( response && response.data && response.data.success ) {
                return response.data.income;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const createPartnersIncomeCategory = ( categoryName, categoryDescription, partnerId ) => {
    return async ( dispatch ) => {
        try {
            if (!categoryName || categoryName.length < 3 || categoryName.length > 100) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Название статьи доходов должно быть не меньше 3 и не больше 100 символов'
                }));
                return;
            }

            if (categoryDescription && categoryDescription.length > 1000) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Описание статьи доходов должно быть не больше 1000 символов'
                }));
                return;
            }

            const response = await $api.post(`/api/partners-income/create-category`, {
                categoryName,
                categoryDescription,
                partnerId
            });
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Статья доходов ${response.data.category.name} успешно создана`
                }));
                return true;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const updatePartnersIncomeCategory = ( data ) => {
    return async ( dispatch ) => {
        try {
            if (!data.categoryName || data.categoryName.length < 3 || data.categoryName.length > 100) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Название статьи доходов должно быть не меньше 3 и не больше 100 символов'
                }));
                return;
            }

            if (data.categoryDescription && data.categoryDescription.length > 1000) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Описание статьи доходов должно быть не больше 1000 символов'
                }));
                return;
            }

            const response = await $api.post(`/api/partners-income/update-category`, data);
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Статья доходов успешно обновлена`
                }));
                return true;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const deletePartnersIncomeCategory = ( id ) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.post(`/api/partners-income/delete-category`, {id: id});
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Статья доходов удалена`
                }));
                return true;
            }
            dispatch(createNotification({
                type: 'error',
                children: `Ошибка удаление статьи доходов`
            }));
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const createProjectIncome = ( data ) => {
    return async ( dispatch ) => {
        try {
            if (!data.incomeCategory || data.offers.length === 0 || !data.amount || !data.currency ) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Заполните все обязательные поля'
                }));
                return;
            }

            const formattedDate = getDateString( shiftDateToUTC(getFirstDayOfMonth(data.date)) );
            data.year = formattedDate.substring(0, 4);
            data.month = formattedDate.substring(5, 7);

            const url = `/api/partners-income/${data.update ? 'update-income' : 'create-income'}`;

            const response = await $api.post(url, data);

            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: data.update ? 'Доходы обновлены' : 'Доходы добавлены'
                }));
                return true;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const deleteProjectIncome = ( id ) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.post(`/api/partners-income/delete-income`, {id});
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Доходы удалены`
                }));
                return true;
            }
            dispatch(createNotification({
                type: 'error',
                children: `Ошибка удаление доходов`
            }));
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}
