const SET_PAGE_CONVERSIONS =  "SET_PAGE_CONVERSIONS";
const SET_PAGES_CONVERSIONS =  "SET_PAGES_CONVERSIONS";
const SET_CONVERSIONS_ERRORS = "SET_CONVERSIONS_ERRORS";
const defaultState = {
    items: [],
    item: {},
    errors: false,
    pagination:'',
};
export default function conversionsReducer(state = defaultState, action){
    switch (action.type) {
        case SET_PAGES_CONVERSIONS:
            return {
                ...state,
                items: action.payload ,
            };
        case SET_PAGE_CONVERSIONS:
            return {
                ...state,
                items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
            };
        case SET_CONVERSIONS_ERRORS:
            return {
                ...state,
                errors: action.payload
            };
        default:
            return state
    }
}
export const setConversionsItem = (items) => ({type: SET_PAGE_CONVERSIONS, payload: items});
export const setConversionsItems = (items) => ({type: SET_PAGES_CONVERSIONS, payload: items});
export const setConversionsErrors = errors => ({type: SET_CONVERSIONS_ERRORS, payload: errors});