import React, {useEffect, useState} from 'react';
import ru from "date-fns/locale/ru";
import styles from './Reports.module.css';
import DatePicker, {registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.min.css';
import {useDispatch, useSelector} from "react-redux";
import AppContent from "../../appContent/AppContent";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import {getStats} from "../../../actions/reports";
import Button from "../../ui/button_v2/ButtonV2";
import ReportTableHeader from "./reportTableHeader/ReportTableHeader";
import ReportTableBody from "./reportTableBody/ReportTableBody";
import MultiSelect from "../../ui/multiSelect/MultiSelect";
import {getMbProjectsList, getMbProjectGroupList} from "../../../actions/mbprojects";
import {getProjectPartnersList} from "../../../actions/partners";
import {setThreeMonthsAgoDate, setOneMonthAgoDate} from "../../../core/helpers";
import {updateReportTotals} from "../../../reducers/reportsReducer";
import ReportChartsComponent from "../../../components/pages/reports/reportCharts/ReportChart";
import {createNotification} from "../../../actions/app";
import { getPartnerByUserId } from '../../../actions/partners';

const Reports = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(null);
    const [partnersTotalAmounts, setPartnersTotalAmounts] = useState([]);
    const [groupsTotalAmounts, setGroupsTotalAmounts] = useState([]);
    const [expensesData, setExpensesData] = useState([]);
    const [incomeData, setIncomeData] = useState([]);
    const [partnersList, setPartnersList] = useState([]);
    const [projectsList, setProjectsList] = useState([]);
    const [projectsGroupsList, setProjectsGroupsList] = useState([]);
    const [searchParams, setSearchParams] = useState({
      startDate: setThreeMonthsAgoDate(),
      endDate: setOneMonthAgoDate(),
      partners: [],
      projects: [],
      projectsGroups: [],
    });
    const currentUser = useSelector(state => state.user.currentUser);

    registerLocale('ru', ru);

    const onDataPickerChange = (dates) => {
        const [start, end] = dates;
        setSearchParams({
            ...searchParams,
            startDate: start,
            endDate: end
        });
    };

    const getReportData = async () => {
        try {
            setLoading(true);
            const reportData = await dispatch(getStats({
                startDate: searchParams.startDate,
                endDate: searchParams.endDate,
                partners: searchParams.partners.length > 0
                    ? searchParams.partners
                    : partnersList.map(partner => partner.value),
                projects: searchParams.projects.length > 0
                    ? searchParams.projects
                    : projectsList.map(projects => projects.value),
                projectsGroups: searchParams.projectsGroups.length > 0
                    ? searchParams.projectsGroups
                    : projectsGroupsList.map(projectsGroup => projectsGroup.value),
                partnersList: partnersList.length ? partnersList : []    
            }));
        if ( reportData && reportData.stats && reportData.stats.length ) {
            setReport( reportData );
            if (reportData.partnersTotalAmountsArr && reportData.partnersTotalAmountsArr.length) {
                setPartnersTotalAmounts(reportData.partnersTotalAmountsArr)
            }
            if (reportData.partnersGroupsTotalAmountsArr && reportData.partnersGroupsTotalAmountsArr.length) {
                setGroupsTotalAmounts(reportData.partnersGroupsTotalAmountsArr)
            }
            if (reportData.expensesData && reportData.expensesData.length) {
                setExpensesData(reportData.expensesData)
            }
            if (reportData.incomeData && reportData.incomeData.length) {
                setIncomeData(reportData.incomeData)
            }
            const { reportTotalAmounts, monthsArr } = reportData;
            if (reportTotalAmounts && monthsArr) {
                dispatch(updateReportTotals({reportTotalAmounts, monthsArr}));
            }
        } else if (report) {
            setReport({...report, stats: []})
        }
            setLoading( false);
        } catch(e) {
            dispatch(createNotification({
                type: 'secondary',
                children: 'Ошибка при получении статистики'
            }));
        }
    };

    const getProjectsList = async () => {
        try {
            const list = await dispatch(getMbProjectsList());
            if (list) {
                setProjectsList(list.map(project => { return {
                    value: project.id,
                    label: project.name
                }}));
            }
        } catch(e) {
            dispatch(createNotification({
                type: 'secondary',
                children: 'Ошибка при загрузке проектов'
            }));
        }
    };

    const getPartnersList = async () => {
        try {
            if (currentUser.capability >= 2) {
                setLoading(true)
                const partners = await dispatch(getPartnerByUserId(currentUser.email, setLoading));
                if (partners) {
                     if (currentUser.partnersIds && currentUser.partnersIds.length) {
                        const partnersIds = currentUser.partnersIds;
                        setPartnersList(partnersIds.map(id => { return {
                            value: Number(id),
                            label: `${currentUser.email} (${Number(id)})`
                        }}));

                        const list = await dispatch(getMbProjectsList(true));
                        if (list) {
                            setProjectsList(list.map(project => { return {
                                value: project.id,
                                label: project.name
                            }}));
                        }
                        setLoading(false)
                        
                    } else {
                            setPartnersList(partners.map(partner => { return {
                            value: partner.affiseId,
                            label: `${partner.email} (${partner.affiseId})`
                            }}));
                            setLoading(false)
                        }
                    }
                } else {
                    const list = await dispatch(getProjectPartnersList( true ));
                    if (list) {
                        setPartnersList(list.map(partner => { return {
                            value: partner.affiseId,
                            label: `${partner.email} (${partner.affiseId})`
                        }}));
                    }
                    setLoading(false)
            }
            
        } catch(e) {
            dispatch(createNotification({
                type: 'secondary',
                children: 'Ошибка при загрузке вебмастеров'
            }));
        }
    };

    const getProjectsGroupList = async () => {
        try {
                const list = await dispatch(getMbProjectGroupList( true ));
                if (list) {
                    setProjectsGroupsList(list.map(projectsGroup => { return {
                        value: projectsGroup.id,
                        label: projectsGroup.name
                    }}));
                }
            
        } catch(e) {
            dispatch(createNotification({
                type: 'secondary',
                children: 'Ошибка при загрузке групп проектов'
            }));
        }
    };

    useEffect(() => {
        getProjectsList().then(() => console.log('Projects list loaded'));

        getPartnersList().then(() => console.log('Partners list loaded'));

        getProjectsGroupList().then(() => console.log('Projects Groups list loaded'));
        
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (projectsList.length && partnersList.length && projectsGroupsList.length) {
            getReportData();
        } 
        // eslint-disable-next-line
    }, [projectsList, partnersList, projectsGroupsList]);

    return (
        <AppContent>
            <div  className="container wrapper">
                <div className={styles.TitleBlock}>
                    <h2>Отчеты медиабаинга</h2>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className={styles.SearchParamsBlock}>
                            <div className={styles.FilterItem}>
                                <label className={styles.Label}>Период</label>
                                <DatePicker
                                    className={`${styles.DatePicker} inputField`}
                                    selectsRange
                                    locale={ru}
                                    showMonthYearPicker
                                    dateFormat="MM/yyyy"
                                    startDate={searchParams.startDate}
                                    endDate={searchParams.endDate}
                                    onChange={onDataPickerChange}
                                />
                            </div>
                            <div className={styles.FilterItem}>
                                <label className={styles.Label}>Группа проектов</label>
                                <MultiSelect
                                    placeholder="Все"
                                    allOptions={projectsGroupsList}
                                    onChange={val => setSearchParams({
                                        ...searchParams, projectsGroups: val
                                    })}
                                />
                            </div>
                            <div className={styles.FilterItem}>
                                <label className={styles.Label}>Проекты</label>
                                <MultiSelect
                                    placeholder="Все"
                                    allOptions={projectsList}
                                    onChange={val => setSearchParams({
                                        ...searchParams, projects: val
                                    })}
                                />
                            </div>
                            <div className={styles.FilterItem}>
                                <label className={styles.Label}>Вебмастера</label>
                                <MultiSelect
                                    placeholder="Все"
                                    allOptions={partnersList}
                                    onChange={val => setSearchParams({
                                        ...searchParams, partners: val
                                    })}
                                />
                            </div>
                            <Button
                                className={styles.GetReportBtn}
                                type={'primary'}
                                onClick={getReportData}
                            >
                                Показать
                            </Button>
                        </div>
                    </div>
                    {/* {reportsList &&
                        <div className="col-4">
                            <label className={`${styles.Label} pl-3`}>Недавние отчеты</label>
                            <div className={styles.RecentReportList}>
                                <div className={styles.RecentReportDropDown}>
                                    <SelectUi
                                        options={reportsList}
                                        value={recentReport}
                                        onChange={val => setRecentReport(reportsList.find(item => item.value === val))}
                                        placeholder='Выбирете отчет'
                                    />
                                </div>
                                <Button
                                    className={"py-0"}
                                    type={'primary'}
                                    onClick={loadRecentReport}
                                    disabled={!recentReport}
                                >
                                    Загрузить отчет
                                </Button>
                            </div>
                        </div>
                    } */}
                </div>
                <div className={styles.ContentBlock}>
                    {loading
                        ? <ContentLoader /> :
                        <>
                            {report && report.stats && report.stats.length ?
                             <ReportChartsComponent/>: ''
                            }

                            {report && report.stats && report.stats.length ?
                            <div className={styles.TableReportBlock}>
                                <ReportTableHeader dateMatrix={report.dateMatrix}/>

                                <ReportTableBody 
                                    report={report} 
                                    incomeData={incomeData} 
                                    expensesData={expensesData} 
                                    partnersTotalAmounts={partnersTotalAmounts}
                                    groupsTotalAmounts={groupsTotalAmounts}
                                />
                            </div>:
                            <div className="mt-5 text-center">Данные не найдены</div> 
                            }
                        </>
                    }
                </div>
            </div>
        </AppContent>
    );
};

export default Reports;
