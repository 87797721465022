import React, {lazy, Suspense, useEffect, useState} from 'react';
import styles from './MbProjectsGroupRow.module.css';
import MbProjectRow from "./mbProjectRow/MbProjectRow";
import Button from "../../../../ui/button_v2/ButtonV2";
import EditIcon from "../../../../ui/icons/EditIcon";
import DeleteIcon from "../../../../ui/icons/DeleteIcon";
import {addModal, removeModal} from "../../../../../reducers/appReducer";
import ContentLoader from "../../../../ui/contentLoader/ContentLoader";
import {useDispatch, useSelector} from "react-redux";
import {createMbProjectGroup, deleteMbProjectGroup} from "../../../../../actions/mbprojects";
import { getPartnerByUserId } from '../../../../../actions/partners';

const ConfirmModal = lazy(() => import('../../../../modals/confirmModal/ConfirmModal'));

const MbProjectsGroupRow = ({name, projects, setLoading, getProjectsByGroup}) => {
    const dispatch = useDispatch();
    const [displayProjects, setDisplayProjects] = useState(true);
    const [editGroup, setEditGroup] = useState(false);
    const [newGroupName, setNewGroupName] = useState(name);
    const currentUser = useSelector(state => state.user.currentUser);

    useEffect(() => {
        if (name) {
            setNewGroupName(name);
        }
    }, [name]);

    const deleteGroup = async () => {
        setLoading(true);
        const result = await dispatch(deleteMbProjectGroup(name));
        if (result) {
            getProjectsByGroup();
        }
        setLoading(false);
        dispatch(removeModal('confirmActionModal'));
    };

    const updateGroup = async () => {
        setLoading(true);
        let result;
        if (currentUser.capability >= 2) {
            const partners = await dispatch(getPartnerByUserId(currentUser?.email, setLoading));
            if (partners && partners[0] && partners[0]._id) {
                result = await dispatch(createMbProjectGroup({
                    groupName: name,
                    newName: newGroupName,
                    email: currentUser?.email, 
                    capability: currentUser?.capability,
                    update: true
                }))
            }
        } else {
            result = await dispatch(createMbProjectGroup({
                    groupName: name,
                    newName: newGroupName,
                    update: true
                }))
        }
        if (result) {
            getProjectsByGroup();
        }
        setLoading(false);
    };

    const showDeleteConfirm = () => {
        dispatch(addModal({
            id: 'confirmActionModal',
            size: 'middle',
            children:
                <Suspense fallback={<ContentLoader />}>
                    {ConfirmModal &&
                        <ConfirmModal
                            onError={() => {
                                dispatch(removeModal('confirmActionModal'))
                            }}
                            // title={'Подтверждение удаления статьи расходов'}
                            message={'При удалении группы проектов также будут безвозвратно удалены все проекты этой группы и все расходы относящиеся к этим проектам. Вы уверены?'}
                            foo={deleteGroup}
                        />
                    }
                </Suspense>
        }));
    };

    return (
        <div className={styles.ProjectGroupRow}>
            <div className="d-flex">
                <div className="col-6 d-flex align-items-center">
                    <div className={styles.IconCollapse} onClick={() => setDisplayProjects(!displayProjects)}>
                        {displayProjects ? '-' : '+'}
                    </div>
                    <div className={styles.ProjectGroupIcon}>{name.substring(0,1)}</div>
                    <div>{name}</div>
                </div>
                <div className="col-6 d-flex justify-content-end">
                    {editGroup &&
                        <>
                            <input
                                type="text"
                                className={styles.Input}
                                value={newGroupName}
                                onChange={(e) => setNewGroupName(e.target.value)}
                                required={true}
                            />
                            <Button className="mx-3" type={'approve'} onClick={updateGroup}>Сохранить</Button>
                        </>
                    }
                    <Button
                        className={`${styles.Button} ${styles.Edit}`}
                        onClick={() => setEditGroup(!editGroup)}
                    >
                        {editGroup
                            ? 'Отмена'
                            :<>
                                <span className="pr-1"><EditIcon/></span>Редактировать
                            </>
                        }
                    </Button>
                    <Button
                        className={`${styles.Button} ${styles.Delete}`}
                        onClick={showDeleteConfirm}
                    >
                        <span className="pr-1"><DeleteIcon/></span>Удалить
                    </Button>
                </div>
            </div>

            {displayProjects &&
                <div className={styles.ProjectsList}>
                    {projects.length > 0 && projects.map((project, index) =>
                        <MbProjectRow
                            project={project}
                            setLoading={setLoading}
                            getProjectsByGroup={getProjectsByGroup}
                            key={index}
                        />
                    )}
                </div>
            }
        </div>
    );
};

export default MbProjectsGroupRow;
