import React, {lazy, Suspense, useEffect, useState} from 'react';
import styles from './ConversionsLi.module.css';
import InputCheckbox from "../../../../ui/input/InputCheckbox/InputCheckbox";
import ConversionsStatus from "../../conversionsStatus/ConversionsStatus";
import ArrowIcon from "../../../../ui/icons/ArrowIcon";
import SettingsIcon from "../../../../ui/icons/SettingsIcon";
import {getCurrencyNameSymbol} from "../../../../../core/helpers";
import {addModal, removeModal} from "../../../../../reducers/appReducer";
import ContentLoader from "../../../../ui/contentLoader/ContentLoader";
import {useDispatch} from "react-redux";

const ConversionsLi = ({conversions, active, index, setDropDown, setEditList,editList}) => {
    const EditModal = lazy(() => import("../../../../modals/conversionsEditModal/ConversionsEditModal"));
    const dispatch = useDispatch();
    const [checkbox,setCheckbox] = useState({name:'',value:'',checked:false});
    useEffect(()=>{
         const edt = editList.find(list=>list.value === conversions.id);
         if(edt){
             setCheckbox(edt);
         }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[editList,checkbox]);
    function addElement(value){
       const check = editList.map(list=>{
             if(value.value === list.value){
                 list = value;
             }
             return list;
            },checkbox
        );
        setEditList(check);
    }

    function settings(conversions) {
        dispatch(addModal({
            id: 'conversionsEdit',
            size: 'big',
            children:
                <Suspense fallback={<ContentLoader/>}>
                    {EditModal &&
                    <EditModal
                        onError={() => {
                            dispatch(removeModal('conversionsEdit'))
                        }}
                        conversionsData={conversions}
                    />}
                </Suspense>
        }));
    }
    return (
        <div className={(active === true) ? `${styles.Item} ${styles.Active}` : styles.Item}>
            <div className={`${styles.Td} ${styles.Checkbox}`}><InputCheckbox
                name={checkbox.name} value={checkbox.value}
                setValue={(val) => addElement(val)} check={checkbox.checked}/></div>
            <div className={`${styles.Td} ${styles.Num}`}>{index}</div>
            <div className={`${styles.Td} ${styles.Id}`}>
                <div className={styles.Email}>{conversions.partner.email}</div>
                <div className={styles.Login}>{conversions.partner.login} ID: {conversions.partner.id}</div>
            </div>
            <div className={`${styles.Td} ${styles.Offer}`}>{conversions.offer.title}({conversions.offer.id})</div>
            <div className={`${styles.Td} ${styles.OrderId}`}>{conversions.actionId}</div>
            <div className={`${styles.Td} ${styles.ClickId}`}>{conversions.clickId}</div>
            <div
                className={`${styles.Td} ${styles.Revenue}`}>{conversions.revenue} {getCurrencyNameSymbol(conversions.currency)}</div>
            <div
                className={`${styles.Td} ${styles.Payouts}`}>{conversions.payouts} {getCurrencyNameSymbol(conversions.currency)}</div>
            <div className={`${styles.Td} ${styles.Status}`}><ConversionsStatus status={conversions.status}/></div>
            <div className={`${styles.Td} ${styles.Date}`}>{conversions.createAt}</div>
            <div className={`${styles.Td} ${styles.Btn}`}>
                <div className={(active === true) ? `${styles.ActiveHidden} ${styles.Active}` : styles.ActiveHidden}
                     onClick={() => setDropDown(index)}><ArrowIcon/></div>
                <div className={styles.Settings} onClick={() => {
                    settings(conversions)
                }}><SettingsIcon/></div>
            </div>
            <div
                className={(active === true) ? `${styles.HiddenContainer} ${styles.Col} ${styles.Active}` : `${styles.HiddenContainer} ${styles.Col}`}>
                <div>
                    <div className={styles.Label}>Комментарий</div>
                    <div className={styles.Comment}>{conversions.comment}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConversionsLi;