/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./KnowledgeBaseSideBar.module.css";
import ArrowGray from "../../../../assets/img/arrow-gray.svg";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AccordionParentArticle from "./AccordionParentArticle";
import { setCurrentArticleData } from "../../../../reducers/knowledgeReducer";
import ContentLoader from "../../../ui/contentLoader/ContentLoader";

const AccordionItem = ({
    item,
    isSub = false,
    isFirst,
    activeSectionId,
    activeArticleId,
    setActiveParentArticle,
    activeItem,
    setActiveItem,
}) => {
    const [activeSection, setActiveSection] = useState(false);

    useEffect(() => {
        if (activeSectionId === item._id) {
            setActiveSection(true);
            setActiveParentArticle(false);
        } else {
            setActiveSection(false);
        }
    }, [activeSectionId]);

    const toggleSectionAccordion = () => {
        setActiveSection((prev) => !prev);
    };

    return (
        <div
            className={`${styles.accordion_item} ${!isFirst ? styles.accordion_sub_item : ''}`}
            style={{ margin: isSub ? "0px" : "unset" }}
        >
            <div
                className={`${styles.accordion_item_title} ${activeSection && isFirst ? styles.active : ""}`}
                style={{
                    justifyContent: item.sectionId ? "flex-start" : 'space-between'
                }}
            >
                {item.sectionId && <span className={styles.dot}></span>}
                {item.sectionId && item.isSub && <span className={styles.dot}></span>}
                <NavLink
                    className={styles.link}
                    to={
                        isFirst
                            ? `/knowledge/section/${item._id}`
                            : `/knowledge/article/${item._id}`
                    }
                    onClick={() => {
                        setActiveItem(item._id);
                    }}
                >
                    <span
                        className={`${isFirst
                            ? styles.accordion_item_title_text_first
                            : styles.accordion_item_title_text
                            }`}
                    >
                        {item?.title}
                    </span>
                </NavLink>
                {item?.articles?.length > 0 && <img
                    src={ArrowGray}
                    alt=""
                    className={styles.arrowIcon}
                    onClick={toggleSectionAccordion}
                    style={{ transform: (activeSection && isFirst) ? "rotate(0deg)" : "rotate(180deg)" }}
                />}
            </div>
            {item?.articles?.length > 0 && (
                <div
                    className={`${styles.accordion_item_content} ${(activeSection && isFirst) ? ` ${styles.active}` : ""}`}
                >
                    {item?.articles?.map((subItem) => (
                        <AccordionParentArticle
                            key={subItem._id}
                            item={subItem}
                            isFirst={false}
                            setActiveParentArticle={setActiveParentArticle}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            activeArticleId={activeArticleId}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const KnowledgeBaseSideBar = ({ sidebarLoading }) => {
    const dispatch = useDispatch();
    const [activeSectionId, setActiveSectionId] = useState(null);
    const [activeArticleId, setActiveArticleId] = useState(null);
    const [activeParentArticle, setActiveParentArticle] = useState(false);
    const [activeItem, setActiveItem] = useState(null);

    const data = useSelector((state) => state.knowledge.data);
    const currentSectionData = useSelector(
        (state) => state.knowledge.currentSectionData
    );
    const currentArticleData = useSelector(
        (state) => state.knowledge.currentArticleData
    );

    useEffect(() => {
        return () => {
            dispatch(setCurrentArticleData({}));
        }
    }, []);

    useEffect(() => {
        setActiveSectionId(currentSectionData?._id);
        setActiveArticleId(currentArticleData?._id);
    }, [currentSectionData, currentArticleData]);

    return (
        <div className={styles.container} style={{ position: 'relative' }}>
            {sidebarLoading ? <ContentLoader /> : <div className={styles.content} >
                {data.length > 0 &&
                    data?.map((item, index) => (
                        <AccordionItem
                            key={`${item._id}-${index}`}
                            item={item}
                            index={index}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            isOpen={item._id === currentSectionData._id}
                            isFirst={true}
                            currentSectionData={currentSectionData}
                            activeSectionId={activeSectionId}
                            activeArticleId={activeArticleId}
                            activeParentArticle={activeParentArticle}
                            setActiveParentArticle={setActiveParentArticle}
                        />
                    ))}
            </div>}

        </div>
    );
};

export default KnowledgeBaseSideBar;
