import React, {useEffect, useState} from 'react';
import styles from './ExpenseCategories.module.css';
import AppContent from "../../appContent/AppContent";
import Button from "../../ui/button_v2/ButtonV2";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import {useDispatch, useSelector} from "react-redux";
import ExpenseCategoryRow from "./expenseCategoryRow/ExpenseCategoryRow";
import {createPartnersExpenseCategory, getExpenseCategoryList} from "../../../actions/partners-expenses";
import { getPartnerByUserId } from '../../../actions/partners';

const ExpenseCategories = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [expenseCategories, setExpenseCategories] = useState([]);
    const [createCategory, setCreateCategory] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [partner, setPartner] = useState('');
    const currentUser = useSelector(state => state.user.currentUser);

    useEffect(() => {
        setLoading(true);
        getCategories().then(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [dispatch])

    const getCategories = async () => {
        if (currentUser.capability >= 2) {
            const partners = await dispatch(getPartnerByUserId(currentUser.email));
            if (partners && partners[0] && partners[0]._id) {
                setPartner(partners[0]._id)
            }
        } 
        const categories = await dispatch(getExpenseCategoryList());
        if (categories) {
            setExpenseCategories(categories);
        }
    };

    const createExpenseCategory = async () => {
        setLoading(true);
        let result;
        if (currentUser.capability >= 2) {
            const partners = await dispatch(getPartnerByUserId(currentUser.email));
            if (partners && partners[0] && partners[0]._id) {
                const partnerId = partners[0]._id.valueOf();
                result = await dispatch(createPartnersExpenseCategory(categoryName, categoryDescription, partnerId));
            } else {
                result = await dispatch(createPartnersExpenseCategory(categoryName, categoryDescription));
            }
        } else {
            result = await dispatch(createPartnersExpenseCategory(categoryName, categoryDescription));
        }
        if (result) {
                setCreateCategory(false);
                setCategoryName('');
                setCategoryDescription('');
                await getCategories();
            }
        setLoading(false);
    };

    return (
        <AppContent>
            <div className="container wrapper">
                <div className={styles.TitleBlock}>
                    <h2>Статьи расходов</h2>
                </div>
                <div className={styles.ContentBlock}>
                    <Button type={'approve'} className="my-3" onClick={() => setCreateCategory(!createCategory)} >
                        {createCategory ? 'Отмена' : 'Создать статью расходов'}
                    </Button>
                    {createCategory &&
                        <div className="row mb-3">
                            <div className="col-3 pr-0">
                                <label className={styles.Label}>Введите название статьи расходов*</label>
                                <input
                                    type="text"
                                    className={styles.Input}
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    required={true}
                                />
                            </div>
                            <div className="col-3 pr-0">
                                <label className={styles.Label}>Введите описание статьи расходов</label>
                                <input
                                    type="text"
                                    className={styles.Input}
                                    value={categoryDescription}
                                    onChange={(e) => setCategoryDescription(e.target.value)}
                                />
                            </div>
                            <div className="col-2 d-flex align-items-end">
                                <Button
                                    type={'primary'}
                                    onClick={createExpenseCategory}
                                >Создать</Button>
                            </div>
                        </div>
                    }
                    {loading
                        ? <ContentLoader/>
                        :
                        <>
                                {expenseCategories.length ? <label className={styles.Label}>
                                    <span className={styles.LabelName}>
                                        Название статьи расходов
                                    </span>
                                    <span className={styles.LabelDesc}>
                                        Описание статьи расходов
                                    </span>
                                </label> : ''
                                }
                                {expenseCategories.length > 0
                                    ? expenseCategories.map(item =>
                                        <ExpenseCategoryRow
                                            category={item}
                                            setLoading={setLoading}
                                            getCategories={getCategories}
                                            key={item.id}
                                            partner={partner}
                                        />
                                    )
                                    : <div>Статьи расходов не найдены</div>
                                }
                        </>
                    }
                </div>
            </div>
        </AppContent>
    );
};

export default ExpenseCategories;
