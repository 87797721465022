import React from 'react';
import styles from './PaginationLi.module.css';

const PaginationLi = ({ name, value, onClick, type }) => {
    let classNames = styles.Item;

    if (type === 'active') {
        classNames = `${styles.Item} ${styles.Active}`;
    } else if (type === 'btn') {
        classNames = `${styles.Item} ${styles.Btn}`;
    }

    return (
        <li className={classNames} onClick={() => onClick(value)}>
            {name}
        </li>
    );
};

export default PaginationLi;