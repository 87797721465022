import React from 'react';

const BillingStatusDeclined = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 10.9091C3.28878 10.9091 1.09091 8.71122 1.09091 6C1.09091 3.28878 3.28878 1.09091 6 1.09091C8.71122 1.09091 10.9091 3.28878 10.9091 6C10.9091 8.71122 8.71122 10.9091 6 10.9091Z" fill="#FF4040"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 10.9091C3.28878 10.9091 1.09091 8.71122 1.09091 6C1.09091 3.28878 3.28878 1.09091 6 1.09091C8.71122 1.09091 10.9091 3.28878 10.9091 6C10.9091 8.71122 8.71122 10.9091 6 10.9091ZM8.39408 3.7686C8.59947 3.98898 8.58733 4.33412 8.36695 4.53952L6.77088 6.0271L8.25846 7.62317C8.46386 7.84354 8.45171 8.18869 8.23134 8.39408C8.01097 8.59947 7.66582 8.58733 7.46043 8.36695L5.97285 6.77088L4.37678 8.25846C4.15641 8.46386 3.81126 8.45171 3.60587 8.23134C3.40048 8.01097 3.41262 7.66582 3.63299 7.46043L5.22906 5.97285L3.74148 4.37678C3.53609 4.15641 3.54823 3.81126 3.7686 3.60587C3.98898 3.40048 4.33412 3.41262 4.53952 3.63299L6.0271 5.22906L7.62317 3.74148C7.84354 3.53609 8.18869 3.54823 8.39408 3.7686Z" fill="#FF4040"/>
        </svg>

    );
};

export default BillingStatusDeclined;