import React from 'react';
import {format} from "date-fns";
import styles from "../../SingleBilling.module.css";
import Button from "../../../../../ui/button_v2/ButtonV2";
import {formatPayAccData} from "../../../../../../core/helpers";
import PayRequisitesList from "./payRequisitesItem/PayRequisitesList";

const InfoTab = ({ billing, payAllowed, timer, setBillingPaid }) => {
    let payAccountData = billing.payAcc;

    if ( billing.paySystem === 2 ) {
        payAccountData = formatPayAccData( billing.payAcc );
    }

    const setPaidAllowed = ( billing.status === 1 || billing.status === -1 )
        && !billing.archive && !billing.partner.archive
        && (billing.paySystem === 14 || billing.paySystem === 7);

    return (
        <div className={styles.TabContainer}>
            <div className="row">
                <div className="col-5">
                    <div className={styles.InfoBlock} style={{height: "100%"}}>
                        <label className={styles.Label}>Реквизиты</label>
                    </div>
                </div>
                <div className="col-7">
                    <div className={styles.InfoBlock}>
                        {typeof payAccountData  === 'object' && payAccountData !== null
                            ? <PayRequisitesList requisites={payAccountData} />
                            : payAccountData
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-5">
                    <div className={styles.InfoBlock}>
                        <label className={styles.Label}>Дата</label>
                    </div>
                </div>
                <div className="col-7">
                    <div className={styles.InfoBlock}>
                        {format(new Date(billing.postedDate), "dd.MM.yyyy HH:mm:ss")}
                    </div>
                </div>
            </div>
            {(( payAllowed && timer) || setPaidAllowed ) &&
                <div className="row mt-3">
                    <div className="col-6">
                        <Button
                            type={'secondary'}
                            className="mr-3"
                            onClick={setBillingPaid}
                        >
                            Отметить оплаченным
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};

export default InfoTab;
