import React, { useEffect } from "react";
import styles from "./KnowledgeRemoveModal.module.css";
import Button from "../../ui/button/Button";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../reducers/appReducer";
import './KnowledgeRemoveModal.css'

const KnowledgeRemoveModal = ({ data, onRemove, type }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.querySelector("body").style.overflow = "hidden";
        return () => {
            document.querySelector("body").style.overflow = "unset";
        };
    }, []);

    const onCancelHandler = () => {
        dispatch(closeModal("knowledgeRemoveModal"));
    };

    return (
        <div className={styles.ModalModule} id="ModalModule">
            <div className={styles.Header}>
                <h2 className={styles.title}>
                    {type === "article" ? "Удаление статьи" : "Удаление раздела "}
                </h2>
                <h2 className={styles.titleName}>{data?.title}</h2>
            </div>
            {data?.articles?.length > 0 && (
                <p className={styles.infoText}>
                    {`При удалении ${type === "article" ? "статьи" : "раздела"
                        } будут утеряны все его внутренние статьи.`}
                </p>
            )}
            <p className={styles.infoTextSecond}>
                Вы уверены, что хотите удалить{" "}
                {type === "article" ? "статью" : "раздел"}?
            </p>
            <div className={styles.btns}>
                <Button className={styles.viewBtn} onClick={onCancelHandler}>
                    отменить
                </Button>
                <Button className={styles.createArticle} onClick={onRemove}>
                    Удалить
                </Button>
            </div>
        </div>
    );
};

export default KnowledgeRemoveModal;
