import React from 'react';
import styles from "./OptionCheckbox.module.css";

const OptionCheckbox = ({ getClasses, checked }) => {
    return (
        <div className={`${styles.Checkbox} ${getClasses}`}>
            <input
                type='checkbox'
                checked={checked}
                readOnly
            />
        </div>
    );
};

export default OptionCheckbox;
