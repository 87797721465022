/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyByFilter, FilterPayAccount, FilterPaySystem, FilterPeriod, FilterStatusOptions, FilterTransactionType } from "../../../../core/config";
import { setFilterParam } from "../../../../core/helpers";
import { setDatePickerOpen, setFilterReset } from '../../../../reducers/billingReducer';
import Button from "../../../ui/button_v2/ButtonV2";
import InputRangeBilling from "../../../ui/input/inputRangeBilling/InputRangeBilling";
import MultiSelect from "../../../ui/multiSelect/MultiSelect";
import Search from "../../../ui/seacrh/Search";
import styles from './BillingFilter.module.css';
import BillingFilter from "./billingFilter/BillingFilter";
import FilterREsetIcon from "../../../../assets/img/filter-reset-icon.png";
import MultiSelectCustom from '../../../ui/multiSelect/multiSelectCustom/MultiSelectCustom';
import SearchPartner from '../../../ui/searchPartner/SearchPartner';

const BillingFilters = ({ filterData, setFilterData, onResetFilters }) => {
    const dispatch = useDispatch()
    const [fillData, setFillData] = useState({ period: 'day30', status: -2, payAgent: -2, paySystem: -2, transactionType: -2, currency: -2, transactionId: "", search: '' });
    const [rangeValue, setRangeValue] = useState({});
    const [openState, setOpenState] = useState(false);

    const datePickerOpen = useSelector(state => state.billing.datePickerOpen);
    const filterReset = useSelector(state => state.billing.filterReset);

    useEffect(() => {
        if (Object.keys(rangeValue).length !== 0) {
            setFillData({ ...fillData, period: rangeValue });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeValue.period, rangeValue.value]);

    useEffect(() => {
        return () => {
            dispatch(setDatePickerOpen(false));
        };
    }, []);

    useEffect(() => {
        if (fillData?.transactionId?.key) {
            fillData.transactionId = fillData?.transactionId?.value || "";
            setFillData({ ...fillData });
            requestFilterData(filterData)
        }

        if (fillData?.search?.key) {
            fillData.search = fillData?.search?.value || "";
            setFillData({ ...fillData });
            requestFilterData(filterData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fillData]);

    const requestFilterData = (filterData) => {
        setFilterData(
            {
                filter:
                    [
                        setFilterParam(filterData, 'period', fillData.period),
                        setFilterParam(filterData, 'status', fillData.status),
                        setFilterParam(filterData, 'payAgent', fillData.payAgent),
                        setFilterParam(filterData, 'paySystem', fillData.paySystem),
                        setFilterParam(filterData, 'transactionType', fillData.transactionType),
                        setFilterParam(filterData, 'currency', fillData.currency),
                        setFilterParam(filterData, 'search', fillData.search),
                        setFilterParam(filterData, 'transactionId', fillData.transactionId),
                    ],
                page: 1
            }
        );
    };

    const onHideDatePicker = () => {
        fillData.period === 'range' && setFillData({ ...fillData, period: 'day30' });
    };

    const onResetFilterHandler = () => {
        setFillData({ period: 'day30', status: -2, payAgent: -2, paySystem: -2, transactionType: -2, currency: -2, transactionId: "", search: '' });
        onResetFilters();
        dispatch(setFilterReset(!filterReset));
    };

    return (
        <div className={`${styles.Filters} row`}>
            <div className={`col-11 ${styles.pd}`}>
                <div className={`row ${styles.pd}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={`col-3 ${styles.filterWidth}`}>
                        <SearchPartner 
                            maxWidth='unset' 
                            placeholder='id, e-mail, login' 
                            label='Партнер' 
                            value={fillData.search}
                            setValue={(val) => setFillData({ ...fillData, search: val })} 
                            default={fillData} 
                            onClick={onHideDatePicker}
                            requestFilterData={requestFilterData}
                            filterData={filterData}  
                        />

                        {/* <Search 
                             maxWidth='unset' 
                            placeholder='id, e-mail, login' 
                            label='Партнер' 
                            value={fillData.search}
                            setValue={(val) => setFillData({ ...fillData, search: val })} 
                            default={fillData} 
                            onClick={onHideDatePicker} 
                        /> */}

                    </div>
                    <div className={`col-3 ${styles.filterWidth}`}>
                        <Search 
                            maxWidth='unset' 
                            placeholder='id' 
                            label='Транзакция' 
                            value={fillData.transactionId}
                            setValue={(val) => setFillData({ ...fillData, transactionId: val })} 
                            default={null} 
                            onClick={onHideDatePicker}
                            requestFilterData={requestFilterData}
                            filterData={filterData} 
                        />
                    </div>
                    <div className={`${styles.Period} col-3 ${styles.filterWidth}`}>
                        <BillingFilter type='period' label='Выбор периода' isOpen={fillData.period === 'range'} placeholder="Выбрать дату" fillData={fillData} openState={openState} setOpenState={setOpenState}
                            onChange={(val) => setFillData({ ...fillData, period: val })} def={fillData.period} onClick={onHideDatePicker} />
                        {datePickerOpen && <InputRangeBilling
                            value={fillData.period}
                            setValue={(val) => {
                                setRangeValue(val)
                            }}
                            dopValue={rangeValue}
                            maxDate={new Date()}
                            options={FilterPeriod}
                            active={fillData.period}
                            onClick={onHideDatePicker}
                        />}

                    </div>
                    <div className={`${styles.Status} col-3 ${styles.filterWidth}`}>
                        <label className={styles.Label}>Валюта</label>
                        <MultiSelect
                            placeholder="Все"
                            allOptions={CurrencyByFilter}
                            onChange={val => setFillData({ ...fillData, currency: val })}
                            onClick={onHideDatePicker}
                        />
                    </div>
                    <div className={`${styles.PaySystem} col-3 ${styles.filterWidth}`}>
                        <label className={styles.Label}>Тип транзакции</label>
                        <MultiSelect
                            placeholder="Все"
                            allOptions={[...FilterTransactionType]}
                            onChange={val => setFillData({ ...fillData, transactionType: val })}
                            onClick={onHideDatePicker}
                        />
                    </div>
                    <div className={`${styles.Status} col-3 ${styles.filterWidth}`}>
                        <label className={styles.Label}>Статус выплаты</label>
                        <MultiSelectCustom
                            placeholder="Все статусы"
                            allOptions={FilterStatusOptions}
                            onChange={val => setFillData({ ...fillData, status: val })}
                            onClick={onHideDatePicker}
                        />
                        {/*<BillingFilter type='status' label='Статус транзакции' placeholder="Все" fillData={fillData}*/}
                        {/*               onChange={(val) => setFillData({...fillData, status: val})}/>*/}
                    </div>
                    <div className={`${styles.PaySystem} col-3 ${styles.filterWidth}`}>
                        <label className={styles.Label}>Способ выплаты</label>
                        <MultiSelect
                            placeholder="Все"
                            allOptions={[...FilterPaySystem]}
                            onChange={val => setFillData({ ...fillData, paySystem: val })}
                            onClick={onHideDatePicker}
                        />
                    </div>
                    <div className={`${styles.PayAccount} col-3 ${styles.filterWidth}`}>
                        <label className={styles.Label}>Платежная система</label>
                        <MultiSelect
                            placeholder="Все"
                            allOptions={FilterPayAccount}
                            onChange={val => setFillData({ ...fillData, payAgent: val })}
                            onClick={onHideDatePicker}
                        />
                    </div>
                </div>
            </div>
            <div className='col-1'>
                <div className={`${styles.BtnBlock}  col-1 `}>
                    <Button type={'primary'} onClick={() => {
                        requestFilterData(filterData);
                    }}>Показать</Button>
                    <img className={styles.filterIcon} src={FilterREsetIcon} alt="" onClick={onResetFilterHandler} />
                </div>
            </div>




        </div>
    );
};

export default BillingFilters;
