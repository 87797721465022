import React from 'react';
import { OfferStatus } from '../../../../../../core/config';
import { getOptions } from '../../../../../../core/helpers';
import styles from './AdvertisersOffersLi.module.css';

const AdvertisersOffersLi = ({offer}) => {
    return (
        <li className={styles.Item}>
            <div className={`${styles.Number}`}>{offer.affiseId}</div>
            <div className={`${styles.Title}`}>{offer.title}</div>
            <div className={`${styles.Status}`}>{getOptions(OfferStatus, offer.status)}</div>
        </li>
    );
};

export default AdvertisersOffersLi;
