import React from "react";
import styles from "./SearchResultItem.module.css";
import { NavLink } from "react-router-dom";

const SearchResultItem = ({ data, isSection, isPos, onSearchBgHandler }) => {
    return (
        <div className={styles.searchResultWrapper} onClick={(e) => {
            onSearchBgHandler?.()
        }}>
            <p className={styles.searchResultTitle}>{`${isSection ? `Раздел` : `Статья: /${data.title}`
                }`}</p>
            <div className={styles.links}>
                {data.items.map((item) => {
                    return (
                        <NavLink
                            key={item._id}
                            className={styles.searchResultLink}
                            style={{
                                border: isPos ? '1px solid #7fb38e' : '1px solid #94d8a7',
                                backgroundPosition: `${isPos ? '98%' : '99%'} 50%`

                            }}
                            to={
                                isSection
                                    ? `/knowledge/section/${item._id}`
                                    : `/knowledge/article/${item._id}`
                            }
                        >
                            {item.title}
                        </NavLink>
                    );
                })}
            </div>
        </div>
    );
};

export default SearchResultItem;
