import React from 'react';
import styles from './BillingCreditStatus.module.css';

const BillingCreditStatus = ({ billing, className, size }) => {
    let statusClass = className ? `${styles.Status} ${className} ` : `${styles.Status} `;
    if(size === 'big'){
        statusClass += ` ${styles.Big} `
    }
    let icon = 'Выплата аванса';

    if ( billing.billingType === 'adjustment' ) {
        icon = 'Погашение аванса';
    }

    switch ( billing.status ) {
        case -1:
            statusClass += `${styles.Declined}`;
            break;
        case 1:
            statusClass += `${styles.Pending}`;
            break;
        case 2:
            statusClass += `${styles.Finished}`;
            break;
        case 3:
            statusClass += `${styles.PaidPartly}`;
            break;
        case 4:
            statusClass += `${styles.Waiting}`;
            break;
        case 99:
            statusClass += `${styles.Archive}`;
            break;
        default:
    }

    return (
        <span className={statusClass}>
            {icon}
        </span>
    );
};

export default BillingCreditStatus;
