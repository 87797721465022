/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from "react";
import styles from "./ArticleEditor.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    EditorState,
    ContentState,
    convertToRaw,
} from "draft-js";
import {
    articleImageRemove,
    fetchArticlesBySection,
    fetchArticlesBySectionFilterId,
    updateArticle,
} from "../../../../actions/knowledge";
import draftToHtml from "draftjs-to-html";
import Input from "../../../ui/input/Input";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Button from "../../../ui/button/Button";
import { useHistory } from "react-router-dom";
import SelectUiWiki from "../../../ui/select/SelectUiWiki";
import { fetchImageAsFile } from "../../../../utils/utils";
import htmlToDraft from 'html-to-draftjs';
import { addModal, closeModal, removeModal } from "../../../../reducers/appReducer";
import ArticleImageRemoveModal from "../../../modals/articleImageRemove/ArticleImageRemoveModal";
import ContentLoader from "../../../ui/contentLoader/ContentLoader";
import { createNotification } from "../../../../actions/app";


function getLast10Characters (inputString) {
    return inputString?.length > 15
        ? `...${inputString.slice(-15)}`
        : inputString;
}

const ArticleEditor = ({ data, setLoading, articleId }) => {
    const dispatch = useDispatch();
    const history = useHistory();


    const [sectionId, setSectionId] = useState(data?.sectionId);
    const [logo, setLogo] = useState("");
    const [articleTitle, setArticleTitle] = useState(data?.title);
    const [sectionsFilterData, setSectionsFilterData] = useState([]);
    const [editorState, setEditorState] = useState("");
    const sectionsData = useSelector((state) => state.knowledge.data);
    const [parentArticle, setParentArticle] = useState(data?.subSectionId || "");
    const [articlesData, setArticlesData] = useState([]);
    const [selectedArticlesData, setSelectedArticlesData] = useState([]);
    const [selectedArticleFlag, setSelectedArticlesFlag] = useState(true);
    const [isImage, setIsImage] = useState(false);
    const inputRef = useRef(null);


    useEffect(() => {
        if (selectedArticleFlag) {
            setSelectedArticlesData(
                articlesData?.find((item) =>
                    parentArticle
                        ? item.value === parentArticle
                        : item.value === data.subSectionId
                )
            );
        }
        setSelectedArticlesFlag(true)

    }, [articlesData, parentArticle]);

    useEffect(() => {
        if (data?.description) {
            const blocksFromHtml = htmlToDraft(data?.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);

            setEditorState(editorState)
        }

        // setEditorState(
        //     EditorState.createWithContent(
        //         ContentState.createFromBlockArray(
        //             convertFromHTML(data?.description).contentBlocks,
        //             convertFromHTML(data?.description).entityMap
        //         )
        //     )
        // );
    }, [data]);

    useEffect(() => {
        if (Object.keys(data)?.length > 0) {
            setData();
        }
    }, [data]);

    const setData = async () => {
        if (data.image) {
            setLogo(await fetchImageAsFile(data.image));
            setIsImage(true);
        }
    };

    useEffect(() => {
        dispatch(fetchArticlesBySection(sectionId));
        sectionId &&
            dispatch(
                fetchArticlesBySectionFilterId(sectionId, setArticlesData, articleId)
            );
    }, [sectionId]);

    useEffect(() => {
        dispatch(fetchArticlesBySection(sectionId));
    }, [sectionId]);

    const onSetFileHandler = () => {
        setIsImage(() => true);
        setLogo(inputRef.current.files[0]);
    };

    useEffect(() => {
        if (sectionsData.length > 0) {
            const data = sectionsData.reduce((acc, cur) => {
                acc.push({ label: `${cur.title}`, value: cur._id });

                return acc;
            }, []);

            data.sort((a, b) => a.label - b.label);

            setSectionsFilterData(data);
        }
    }, [sectionsData, sectionId]);

    const createNewArticle = () => {
        const editorContentHtml = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );

        const formData = new FormData();
        formData.append("id", data._id);
        formData.append("title", articleTitle);
        formData.append("description", editorContentHtml);
        formData.append("sectionId", sectionId);

        if (logo) {
            formData.append("logo", logo, logo.name);
        }
        if (parentArticle) {
            formData.append("parentArticle", parentArticle);
        }

        if (!articleTitle.trim() || articleTitle.trim().length < 2) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        "Название статьи должно быть не менее 2 и не более 30 символов",
                })
            );
        } else if (
            !editorState.getCurrentContent().getPlainText().trim() ||
            editorState.getCurrentContent().getPlainText().trim().length < 20
        ) {
            dispatch(
                createNotification({
                    type: "error",
                    children: "Описание статьи должно быть не менее 20 символов",
                })
            );
        } else {
            dispatch(updateArticle(formData, setLoading, onSuccess));
        }



    };

    const onSuccess = () => {
        history.push(`/knowledge/article/${articleId}`);
    };

    const onClearParentArticles = () => {
        setSelectedArticlesFlag(false);
        setParentArticle("");
        setSelectedArticlesData(null);
    };

    const getFileBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => callback(reader.result);
        reader.onerror = error => { };
    };

    const imageUploadCallback = file => new Promise(
        (resolve, reject) => getFileBase64(
            file,
            data => resolve({ data: { link: data } })
        )
    );

    const onArticleRemoveHandler = () => {
        dispatch(
            addModal({
                id: "articleImageRemoveModal",
                size: "bigger",
                children: (
                    <Suspense fallback={<ContentLoader />}>
                        {ArticleImageRemoveModal && (
                            <ArticleImageRemoveModal
                                type="article"
                                data={data}
                                onRemove={onRemove}
                                onError={() => {
                                    dispatch(removeModal("articleImageRemoveModal"));
                                }}
                            />
                        )}
                    </Suspense>
                ),
            })
        );
    };

    const onRemove = () => {
        dispatch(articleImageRemove(data._id, onRemoveSuccess));
    };

    const onRemoveSuccess = () => {
        inputRef.current.value = '';
        setLogo('');
        setIsImage(false);
        dispatch(closeModal('articleImageRemoveModal'));
    };

    return (
        <div className={`${styles.container} `}>
            <h2 className={`${styles.title} `}>Изменение статьи</h2>
            <div className={styles.filters_content}>
                <div className={`${styles.filters} fpl`}>
                    <div className={`${styles.input_block} `}>
                        <span className={styles.inputTitle}>Название</span>
                        <Input
                            style={{
                                border: "1px solid #383838",
                                height: "38px",
                                backgroundColor: "transparent",
                            }}
                            placeholder="Введите название статьи"
                            setValue={setArticleTitle}
                            value={articleTitle}
                        />
                    </div>
                </div>
                <div className={`${styles.filters} sections_filter_select`}>
                    <div className={`${styles.input_block} `}>
                        <SelectUiWiki
                            customKey={'section'}
                            placeholder="Выберите раздел"
                            label="Раздел"
                            options={[...sectionsFilterData]}
                            onChange={(val) => {
                                onClearParentArticles();
                                setSectionId(val);
                            }}
                            isSearchable
                            classes="sections_filter"
                            value={sectionsFilterData.find(
                                (item) => item.value === sectionId
                            )}
                        />
                    </div>
                </div>
                {data.isSub && <div className={`${styles.filters} sections_filter_select fpr`}>
                    <div className={styles.input_block}>
                        <SelectUiWiki
                            customKey={'article'}
                            placeholder="Все"
                            label="Родительская статья"
                            options={[...articlesData]}
                            onChange={(val) => {
                                setParentArticle(val);
                            }}
                            isClearable={true}
                            isSearchable
                            classes="sections_filter"
                            onClearParentArticles={onClearParentArticles}
                            value={selectedArticlesData}
                        />
                    </div>
                </div>}
            </div>
            <div className={`${styles.article_editor} row`}>
                <div className={`${styles.textEditorBlock} col-12`}>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={(newEditorState) => {
                            setEditorState(newEditorState);
                        }}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName articleEditor"
                        toolbar={{
                            image: {
                                uploadCallback: imageUploadCallback,
                                previewImage: true,
                                style: {
                                    display: 'inline-block',
                                    border: '1px solid red'
                                },
                            },
                        }}
                    />
                </div>
            </div>
            <div className={`${styles.btnContainer} row`}>
                <div className={`${styles.fileInputCol} col-6`}>
                    <div className={styles.sectionFileInputBlock}>
                        <label
                            className={styles.file_input_label}
                            htmlFor="file-input-logo"
                        >
                            <span>Загрузить фото</span>
                            <input
                                ref={inputRef}
                                accept="image/*"
                                id="file-input-logo"
                                type="file"
                                name="file"
                                onChange={() => onSetFileHandler()}
                                className={styles.fileInput}
                            />
                        </label>
                        <div className={styles.removeZone} onClick={onArticleRemoveHandler}>
                            {isImage && <span className={`${styles.fileName} ${data?.image ? styles.fileNameColor : ""}`}>
                                {getLast10Characters(!logo?.name ? data?.image : logo?.name)}
                            </span>}
                            {isImage && <div className={styles.removeIcon}></div>}
                        </div>

                    </div>
                </div>
                <div className={`${styles.btns} col-6`}>
                    <Button className={styles.createArticle} onClick={createNewArticle}>
                        опубликовать
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ArticleEditor;
