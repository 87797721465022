import $api from "../core/api";
import {getDateString, getFirstDayOfMonth, getLastDayOfMonth, processError, shiftDateToUTC} from "../core/helpers";
import {createNotification} from "./app";

export const getExpenseCategoryList = (userEmail) => {
    return async ( dispatch ) => {
        try {
            let response;
            if (userEmail) {
                response = await $api.get(`/api/partners-expenses/categories?userEmail=${userEmail}`);
            } else {
                response = await $api.get(`/api/partners-expenses/categories`);
            }
            
            if ( response && response.data && response.data.success ) {
                return response.data.categories;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const getProjectExpenses = ( searchParams ) => {
    return async ( dispatch ) => {
        try {
            const startDate = getDateString( shiftDateToUTC(getFirstDayOfMonth(searchParams.startDate)) );
            const endDate = getDateString( shiftDateToUTC(getLastDayOfMonth(searchParams.endDate)) );
            const params = {
                projectId: searchParams.projectId,
                startDate: startDate,
                endDate: endDate,
                expenseCategories: searchParams.expenseCategories
            };

            const response = await $api.get(`/api/partners-expenses/project-expenses`, {
                params: params
            });

            if ( response && response.data && response.data.success ) {
                return response.data.expenses;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const createPartnersExpenseCategory = ( categoryName, categoryDescription, partnerId ) => {
    return async ( dispatch ) => {
        try {
            if (!categoryName || categoryName.length < 3 || categoryName.length > 100) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Название статьи расходов должно быть не меньше 3 и не больше 100 символов'
                }));
                return;
            }

            if (categoryDescription && categoryDescription.length > 1000) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Описание статьи расходов должно быть не больше 1000 символов'
                }));
                return;
            }

            const response = await $api.post(`/api/partners-expenses/create-category`, {
                categoryName,
                categoryDescription,
                partnerId
            });
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Статья расходов ${response.data.category.name} успешно создана`
                }));
                return true;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const updatePartnersExpenseCategory = ( data ) => {
    return async ( dispatch ) => {
        try {
            if (!data.categoryName || data.categoryName.length < 3 || data.categoryName.length > 100) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Название статьи расходов должно быть не меньше 3 и не больше 100 символов'
                }));
                return;
            }

            if (data.categoryDescription && data.categoryDescription.length > 1000) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Описание статьи расходов должно быть не больше 1000 символов'
                }));
                return;
            }

            const response = await $api.post(`/api/partners-expenses/update-category`, data);
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Статья расходов успешно обновлена`
                }));
                return true;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const deletePartnersExpenseCategory = ( id ) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.post(`/api/partners-expenses/delete-category`, {id: id});
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Статья расходов удалена`
                }));
                return true;
            }
            dispatch(createNotification({
                type: 'error',
                children: `Ошибка удаление статьи расходов`
            }));
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const createProjectExpense = ( data ) => {
    return async ( dispatch ) => {
        try {
            if (!data.expensesCategory || data.offers.length === 0 || !data.amount || !data.currency ) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Заполниет все обязательные поля'
                }));
                return;
            }

            const formattedDate = getDateString( shiftDateToUTC(getFirstDayOfMonth(data.date)) );
            data.year = formattedDate.substring(0, 4);
            data.month = formattedDate.substring(5, 7);

            const url = `/api/partners-expenses/${data.update ? 'update-expense' : 'create-expenses'}`;

            const response = await $api.post(url, data);

            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: data.update ? 'Расходы обновлены' : 'Расходы добавлены'
                }));
                return true;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
};

export const deleteProjectExpense = ( id ) => {
    return async ( dispatch ) => {
        try {
            const response = await $api.post(`/api/partners-expenses/delete-expense`, {id: id});
            if ( response && response.data && response.data.success ) {
                dispatch(createNotification({
                    type: 'primary',
                    children: `Расходы удалены`
                }));
                return true;
            }
            dispatch(createNotification({
                type: 'error',
                children: `Ошибка удаление расходов`
            }));
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}
