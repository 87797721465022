/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from "react-select";
import styles from "./MultiSelectCustom.module.css";
import { useSelector } from 'react-redux';
import SelectCheckboxOption from '../selectCheckboxOption/SelectCheckboxOption';
import CustomValueContainer from '../customValueContainer/CustomValueContainer';

// Define your custom filter function
const customFilter = (option, rawInput) => {
  // Extract the text from the OptionColoredLabel component
  const text = option.label.props.text.toLowerCase();
  const input = rawInput.toLowerCase();
  return text.includes(input);
};

const MultiSelectCustom = ({className, placeholder, allOptions, onChange, onClick}) => {
  const filterReset = useSelector(state => state.billing.filterReset);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
    onChange(selected.map(item => item.value));
  };

  // Handle input change
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  useEffect(()=>{
    setSelectedOptions([]);
    setInputValue(''); // Clear input value when resetting
    onChange([]);
  }, [filterReset]);

  return (
    <div className={`${styles.MultiSelect} ${className ? className : ''}`} >
      <Select
        className='uiSelect'
        classNamePrefix='uiSelect'
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={handleSelectChange}
        options={allOptions}
        components={{
          Option: SelectCheckboxOption,
          MultiValue: CustomValueContainer
        }}
        onMenuOpen={onClick}
        placeholder={placeholder}
        value={selectedOptions}
        inputValue={inputValue} // Set the input value
        onInputChange={handleInputChange} // Handle input change
        filterOption={customFilter} // Apply custom filter
      />
    </div>
  );
};

export default MultiSelectCustom;
