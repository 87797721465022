/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./MultiSelect.module.css";
import SelectCheckboxOption from "./selectCheckboxOption/SelectCheckboxOption";
import CustomValueContainer from "./customValueContainer/CustomValueContainer";
import { useSelector } from "react-redux";

const MultiSelect = ({ className, placeholder, allOptions, onChange, onClick }) => {
    const filterReset = useSelector((state) => state.billing.filterReset);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
        onChange(selected.map((item) => item.value));
    };

    useEffect(() => {
        setSelectedOptions([]);
        onChange([]);
    }, [filterReset]);

    return (
        <div className={`${styles.MultiSelect} ${className ? className : ''}`}>
            <Select
                className="uiSelect"
                classNamePrefix="uiSelect"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={handleSelectChange}
                options={allOptions}
                components={{
                    Option: SelectCheckboxOption,
                    MultiValue: CustomValueContainer,
                }}
                onMenuOpen={onClick}
                placeholder={placeholder}
                value={selectedOptions}
            />
        </div>
    );
};

export default MultiSelect;
