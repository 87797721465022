/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from "./KnowledgeBaseSideBar.module.css";
import { NavLink } from "react-router-dom";
import ArrowGray from "../../../../assets/img/arrow-gray.svg";
import AccordionChildArticle from './AccordionChildArticle';

const AccordionParentArticle = ({
    item,
    setActiveParentArticle,
    activeItem,
    setActiveItem,
    activeArticleId,
}) => {


    const [parent, setParent] = useState(false)
    const [activeArticle, setActiveArticle] = useState(false);

    useEffect(() => {
        if (Object.keys(parent).length > 0) {
            if (parent._id === item._id) {
                setActiveArticle(true);
                setActiveParentArticle(true);
            }
        }
    }, [parent])

    useEffect(() => {
        if (activeArticleId === item._id) {
            setActiveArticle(true);
            setActiveParentArticle(true);
        }
    }, [activeArticleId]);


    const toggleArticleAccordion = () => {
        setActiveArticle((prev) => !prev);
    };

    return (
        <div
            className={`${styles.accordion_item}`}
            style={{ margin: item.isSub ? "0px" : "unset" }}
        >
            <div
                className={`${styles.accordion_item_title}  ${activeArticleId === item._id ? styles.active : ""}`}
                style={{
                    justifyContent: item.sectionId ? "flex-start" : 'space-between'
                }}
            >
                {item.sectionId && <span className={styles.dot}></span>}
                {item.sectionId && item.isSub && <span className={styles.dot}></span>}
                <NavLink
                    className={styles.link}
                    to={`/knowledge/article/${item._id}`}
                    onClick={() => {
                        setActiveItem(item._id);
                    }}
                >
                    <span
                        className={`${styles.accordion_item_title_text}`}
                    >
                        {item?.title}
                    </span>
                </NavLink>
                {item?.articles?.length > 0 && <img
                    src={ArrowGray}
                    alt=""
                    className={styles.arrowIcon}
                    onClick={toggleArticleAccordion}
                    style={{ transform: (activeArticle) ? "rotate(0deg)" : "rotate(180deg)" }}
                />}
            </div>
            {item?.articles?.length > 0 && (
                <div
                    className={`${styles.accordion_item_content} ${(activeArticle) ? ` ${styles.active}` : ""
                        }`}
                >
                    {item?.articles?.map((subItem) => (
                        <AccordionChildArticle
                            key={subItem._id}
                            item={subItem}
                            isFirst={false}
                            activeParentArticle={activeArticle}
                            setActiveParentArticle={setActiveParentArticle}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            activeArticleId={activeArticleId}
                            setParent={setParent}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default AccordionParentArticle;