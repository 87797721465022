import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SearchIcon from "../icons/SearchIcon";
import ClearIcon from "../icons/ClearIcon";
import styles from './SearchPartner.module.css';

const SearchPartner = ({ value, type, filterData, requestFilterData, setValue, isSearch, placeholder, label, onClick, maxWidth = "", style = {} }) => {
    const [isDisplay, setIsDisplay] = useState(false);
    const billings = useSelector(state => state.billing.items);
    const set = new Set([]);
    for (const billing of billings) {
        if (String(billing.partner?.affiseId) && String(billing.partner?.affiseId)?.includes(value)) {
            set.add(String(billing.partner?.affiseId));
        }
        if (billing.partner?.email && billing.partner?.email.includes(value)) {
            set.add(billing.partner?.email);
        }
        if (billing.partner?.login && billing.partner?.login.includes(value)) {
            set.add(billing.partner?.login);
        }
    };
    const filtered = [...set];

    const handleClick = (el) => {
        setValue(el);
        setIsDisplay?.(false);
    };

    const handleOnFocus = () => {
        setIsDisplay?.(true);
    }

    const handleClear = () => {
        setValue('');
        setIsDisplay?.(false);
    }

    // eslint-disable-next-line no-unused-vars
    const handleOnBlurs = (e) => {
        if (value?.length) {
            setIsDisplay?.(false);
        } 
    }

    const handleKeyPress = (event) => {
        if (event?.charCode === 13) {
            if (filterData && requestFilterData) {
                requestFilterData(filterData);
                setIsDisplay?.(false);
            } else if (requestFilterData) {
                requestFilterData(value);
                setValue(value);
                setIsDisplay?.(false);
            }
        }
    }

    return (
        <div {...style} 
            className={styles.SearchContainer} 
            // onClick={onClick}
        >
            {label &&
                <label>{label}</label>
            }

            <div className={styles.SearchBlock} style={{ maxWidth: maxWidth }}>
                <SearchIcon />
                <input
                    data-lpignore="true"
                    className={`inputField ${isSearch ? 'pd-left' : ''}`}
                    onChange={(event) => setValue(event.target.value)}
                    value={value}
                    placeholder={placeholder}
                    onFocus={handleOnFocus}
                    onKeyPress={(event) => handleKeyPress(event)}
                    // onBlur={handleOnBlurs}
                />
                {value && <span onClick={handleClear} className={styles.ClearIcon}>
                    <ClearIcon  />
                </span>
                }
            </div>
            {isDisplay && value ? <div 
                className={filtered?.length ? styles.AutoComplitBlock: styles.AutoComplitNone}
            >
                
                {filtered?.length ? filtered?.map((el, index) => {
                return (
                <span 
                    className={styles.AutoComplitElement}
                    key={index}
                    onClick={() => handleClick(el)}
                >
                    {el}
                </span>)}): ''}
            </div>: ''}
            
        </div>
    );
};

export default SearchPartner;