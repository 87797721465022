import React, {useState, useEffect} from 'react';
import  classes from'./InputFileMulti.module.css';
import InputError from '../inputError/InputError';
import UploadIcon from "../../icons/UploadIcon";
import {useDispatch} from "react-redux";
import {createNotification} from "../../../../actions/app";

const InputFileMulti = ({value, multiple = false, name, label, setValue,error,className, inactive, ...rest}) => {
    const dispatch = useDispatch();
    const [focused, setFocused] = useState(!!value);
    const onFocus = () => setFocused(true);
    const onBlur = (e) => {
        if (!e.target.files.file) {
            setFocused(false);
        }
    };
    if (!value) {
        value = '';
    }

    const onChange = (e) => {
        const file = [...e.target.files];
        if (file) {
            setFocused(true)
        } else {
            setFocused(false)
        }
        setValue(file);
    };

    useEffect(() => {
        if (value) {
            setFocused(true);
        } else {
            setFocused(false);
        }
    }, [value]);
    const inpLabelClasses = className ? `${className} ${classes.InpBlock}` : classes.InpBlock;

    return (
        <div className={inpLabelClasses}>
            <label>{label} <UploadIcon/>
            <input {...rest}
                   className={classes.InputField}
                   onClick={ e => {
                       if (inactive && inactive.value ) {
                           e.preventDefault();
                           dispatch(createNotification({
                               type: 'error',
                               children: inactive.message
                           }));
                       }
                   }}
                   onChange={(e) => {
                       onChange(e)
                   }}
                   onFocus={onFocus}
                   onBlur={(e) => {
                       onBlur(e)
                   }}
                   multiple={multiple}
                   type='file'
                   focused={focused.toString()}
            />
            </label>
            {error && <InputError error={error}/>}
        </div>
    );
};


export default InputFileMulti;
