import React, {useEffect, useState} from 'react';
import styles from './IncomeCategories.module.css';
import AppContent from "../../appContent/AppContent";
import Button from "../../ui/button_v2/ButtonV2";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import {useDispatch, useSelector} from "react-redux";
import IncomeCategoryRow from "./incomeCategoryRow/IncomeCategoryRow";
import {createPartnersIncomeCategory, getIncomeCategoryList} from "../../../actions/partners-income";
import {createNotification} from "../../../actions/app";
import { getPartnerByUserId } from '../../../actions/partners';

const IncomeCategories = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [incomeCategories, setIncomeCategories] = useState([]);
    const [createCategory, setCreateCategory] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [partner, setPartner] = useState('');
    const currentUser = useSelector(state => state.user.currentUser);

    useEffect(() => {
        setLoading(true);
        getCategories().then(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [dispatch])

    const getCategories = async () => {
        try {
            if (currentUser.capability >= 2) {
                const partners = await dispatch(getPartnerByUserId(currentUser.email));
                if (partners && partners[0] && partners[0]._id) {
                    setPartner(partners[0]._id)
                }
            } 
            const categories = await dispatch(getIncomeCategoryList());
            if (categories) {
                setIncomeCategories(categories);
            }
        } catch(e) {
            dispatch(createNotification({
                    type: 'secondary',
                    children: 'Ошибка при загрузке статей доходов'
                }));
        }
    };

    const createIncomeCategory = async () => {
        try {
            setLoading(true);
            if (currentUser.capability >= 2) {
                const partners = await dispatch(getPartnerByUserId(currentUser.email));
                const partnerId = partners[0]?._id?.valueOf();
                const result = await dispatch(createPartnersIncomeCategory(categoryName, categoryDescription, partnerId));
                if (result) {
                    setCreateCategory(false);
                    setCategoryName('');
                    setCategoryDescription('');
                    await getCategories();
                }
            } else {
                const result = await dispatch(createPartnersIncomeCategory(categoryName, categoryDescription));
                if (result) {
                    setCreateCategory(false);
                    setCategoryName('');
                    setCategoryDescription('');
                    await getCategories();
                }
            }
            setLoading(false);
        } catch(e) {
            dispatch(createNotification({
                    type: 'secondary',
                    children: 'Ошибка при создании статьи доходов'
                }));
        }
    };

    return (
        <AppContent>
            <div className="container wrapper">
                <div className={styles.TitleBlock}>
                    <h2>Статьи доходов</h2>
                </div>
                <div className={styles.ContentBlock}>
                    <Button type={'approve'} className="my-3" onClick={() => setCreateCategory(!createCategory)} >
                        {createCategory ? 'Отмена' : 'Создать статью доходов'}
                    </Button>
                    {createCategory &&
                        <div className="row mb-3">
                            <div className="col-3 pr-0">
                                <label className={styles.Label}>Введите название статьи доходов*</label>
                                <input
                                    type="text"
                                    className={styles.Input}
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    required={true}
                                />
                            </div>
                            <div className="col-3 pr-0">
                                <label className={styles.Label}>Введите описание статьи доходов</label>
                                <input
                                    type="text"
                                    className={styles.Input}
                                    value={categoryDescription}
                                    onChange={(e) => setCategoryDescription(e.target.value)}
                                />
                            </div>
                            <div className="col-2 d-flex align-items-end">
                                <Button
                                    type={'primary'}
                                    onClick={createIncomeCategory}
                                >Создать</Button>
                            </div>
                        </div>
                    }
                    {loading
                        ? <ContentLoader/>
                        :
                        <>
                                {incomeCategories.length ? <label className={styles.Label}>
                                    <span className={styles.LabelName}>
                                        Название статьи доходов
                                    </span>
                                    <span className={styles.LabelDesc}>
                                        Описание статьи доходов
                                    </span>
                                </label> : ''
                                }
                                {incomeCategories.length > 0
                                    ? incomeCategories.map(item =>
                                        <IncomeCategoryRow
                                            category={item}
                                            setLoading={setLoading}
                                            getCategories={getCategories}
                                            key={item.id}
                                            partner={partner}
                                        />
                                    )
                                    : <div>Статьи доходов не найдены</div>
                                }
                        </>
                    }
                </div>
            </div>
        </AppContent>
    );
};

export default IncomeCategories;
