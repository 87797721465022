import React, {useState} from 'react';
import { components } from "react-select";
import InputCheckboxFlex from "./optionCheckbox/OptionCheckbox";

const SelectCheckboxOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
    const [isActive, setIsActive] = useState(false);

    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    let color = "inherit";
    if ( isFocused ) bg = "#eee";
    if ( isActive ) bg = "#B2D4FF";
    if ( isSelected ) color = "#2D8546"

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: color,
        display: "flex "
    };

    const props = { ...innerProps, onMouseDown, onMouseUp, onMouseLeave, style };

    return (
        <components.Option
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
            {...rest}
        >
            <InputCheckboxFlex checked={isSelected} />
            {children}
        </components.Option>
    );
};

export default SelectCheckboxOption;
