import React from "react";
import styles from "./BillingFilter.module.css";
import "./BillingFilter.css";
import Select from "react-select";
import {
    FilterPeriod,
    FilterStatus,
    FilterPaySystem,
    FilterPayAccount,
    FilterCountry,
    FilterEntity,
} from "../../../../../core/config";
import { useDispatch } from "react-redux";
import { setDatePickerOpen } from "../../../../../reducers/billingReducer";
import { getFormatDate } from "../../../../../utils/formatDate";

const BillingFilter = ({
    type,
    label,
    onChange,
    placeholder,
    def,
    value,
    onClick,
    openState,
    setOpenState,
    fontSize = "",
}) => {
    const dispatch = useDispatch();

    const updatedFilteredPeriod = FilterPeriod.map((item) => {
        return {
            value: item.value,
            label: getFormatDate(item.value)
                ? `${item.label} ${getFormatDate(item.value)}`
                : item.label,
        };
    });

    let options = "";
    let defaultValue = "";

    switch (type) {
        case "period": {
            options = updatedFilteredPeriod;
            const df = updatedFilteredPeriod.find(
                (option) => option.value === def.value
            );
            if (df) {
                if (def.value === "range") {
                    df.label = def.period.join("/");
                }
                value = df;
            } else if (!value) {
                defaultValue = options[6];
            }
            break;
        }
        case "status": {
            options = FilterStatus;
            defaultValue = options[0];
            break;
        }
        case "payAccount": {
            options = FilterPayAccount;
            defaultValue = options[0];
            break;
        }
        case "paySystem": {
            options = FilterPaySystem;
            defaultValue = options[0];
            break;
        }
        case "country": {
            options = FilterCountry;
            defaultValue = options[1];
            break;
        }
        case "entity": {
            options = FilterEntity;
            defaultValue = options[0];
            break;
        }
        default: {
        }
    }
    return (
        <div onClick={onClick} className="date-picker-select">
            <div className={styles.Label} onClick={() => setOpenState?.(false)}>
                {label}
            </div>
            <Select
                menuIsOpen={openState}
                onMenuOpen={() => setOpenState?.(true)}
                onMenuClose={() => setOpenState?.(false)}
                options={options}
                defaultValue={defaultValue}
                value={value}
                onChange={(val) => {
                    dispatch(setDatePickerOpen(val.value === "range"));
                    onChange(val.value);
                }}
                placeholder={placeholder}
                isSearchable={false}
                className={`selectFilter ${type} ${fontSize}`}
                classNamePrefix="selPref"
            />
        </div>
    );
};

export default BillingFilter;
