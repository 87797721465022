import React from 'react';
import Rule from "../Rule/Rule";
import { Droppable } from "react-beautiful-dnd";



const Column = ({ column, rules }) => {
    return (
        <div className="column col-3">
            <h3>{column.title}</h3>
            <Droppable droppableId={column.id}>
                {(provided) => (
                    <div className="rulesList" key={column.id}{...provided.droppableProps} ref={provided.innerRef}>
                        {rules.map((rule, index) => (
                            <Rule key={rule.id} rule={rule} index={index} />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};
export default Column;