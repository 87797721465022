const SET_PAGE_PARTNERS = "SET_PARTNERS";
const SET_PAGE_PARTNER = "SET_PARTNER";
const SET_PARTNER_ERRORS = "SET_PARTNER_ERRORS";
const SET_VERIFICATION = "SET_VERIFICATION";
const SET_SORT = "SET_SORT";
const PARTNER_RESET_PAGINATION = "PARTNER_RESET_PAGINATION";
const SET_CURRENT_PARTNER = "SET_CURRENT_PARTNER";


const defaultState = {
    items: [],
    item: {},
    errors: false,
    verification: false,
    pagination:'',
    sort:{name:'amount',value:2},
    sumRevenue:'',
    currentPartner: ''
};

export default function partnersReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_PAGE_PARTNERS:
            const item = action.payload.docs;
            const sumRevenue = action.payload.sumRevenue;

            delete action.payload.docs;
            return {
                ...state,
                items:  item,
                pagination: action.payload,
                sumRevenue: sumRevenue,
            };
        case SET_PAGE_PARTNER: {
            return {
                ...state,
                items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
            }
        }

        case SET_VERIFICATION: {
            return {
                ...state,
                verification: action.payload,
            }
        }
        case SET_PARTNER_ERRORS:
            return {
                ...state,
                errors: action.payload
            };
        case SET_SORT:
            return {
                ...state,
                sort: action.payload,
            }
        case PARTNER_RESET_PAGINATION:
            return {
                ...state,
                pagination: {
                  ...state.pagination,
                  page: 1
                }
            };
        case SET_CURRENT_PARTNER:
            return {
                ...state,
                currentPartner: action.payload,
            }
        default:
            return state
    }
}

export const setPartnersItems = (items) => ({type: SET_PAGE_PARTNERS, payload: items});
export const setPartnersItem = (item) => ({type: SET_PAGE_PARTNER, payload: item});
export const setPartnersErrors = errors => ({type: SET_PARTNER_ERRORS, payload: errors});
export const setVerification = verification => ({type: SET_VERIFICATION, payload: verification});
export const setSort = sort => ({type: SET_SORT, payload: sort});
export const setPartnerPaginationReset = () => ({type: PARTNER_RESET_PAGINATION});
export const setCurrentPartner = (item) => ({ type: SET_CURRENT_PARTNER, payload: item });

