import React from 'react';
import  classes from'./InpuCheckbox.module.css';
const InputCheckbox = ({value,check, name, label,setValue,type,id,...props}) => {


    let btnClasses = props.className ? `${props.className} ` : '';

    switch (type) {
        case 'green':
            btnClasses += `${classes.Checkbox} ${classes.Green}`;
            break;
        case 'green active':
            btnClasses += `${classes.Checkbox} ${classes.Green} ${classes.Active}`;
            break;
        default:
            btnClasses += `${classes.Checkbox}`;
            break;
    }
    return (
        <div className={btnClasses} >
            <input
                type='checkbox'
                name={name}
                value={value}
                checked= {check}
                id={id}
                onChange={(e)=>setValue({name:name,value:e.target.value,checked:e.target.checked})}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default InputCheckbox;