import React from 'react';

const BillingStatusPaidParty = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7" cy="7" r="5.5" stroke="#0ECD21"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4 7.31417L6.31417 9.62833L10.1711 5.77139L9.39972 5L6.31417 8.08556L4.77139 6.54278L4 7.31417Z" fill="#0ECD21"/>
        </svg>

    );
};

export default BillingStatusPaidParty;