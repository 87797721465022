import './fonts.css';
import './App.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { checkAuth } from "../../actions/user";
import { setAppLoader } from "../../reducers/appReducer";
import Landing from "../landing/Landing";
import NotFound from "../pages/notFound/NotFound.jsx";
import Users from "../pages/users/Users.jsx";
import User from "../pages/user/User";
import PrivateRoute from '../privateRoute/PrivateRoute.jsx';
import AppLoader from "../ui/appLoader/AppLoader";
import Notifications from "../notifications/Notifications.jsx";
import CreateUser from "../pages/user/CreateUser/CreateUser";
import Billings from "../pages/billing/Billings";
import Modals from "../modals/Modals";
import Conversions from "../pages/conversions/Conversions";
import ConversionsReconciliation from "../pages/conversionsReconciliation/ConversionsReconciliation";
import ConversionsCSV from "../pages/consversionsCSV/ConversionsCSV";
import Partners from "../pages/partners/Partners";
import Offers from "../pages/offers/Offers";
import Advertisers from "../pages/advertisers/Advertisers";
import AdvertiserCard from "../pages/advertisers/advertiserCard/AdvertiserCard";
import OfferCard from "../pages/offers/offerCard/OfferCard";
import SingleBilling from "../pages/billing/singleBilling/SingleBilling";
import KnowledgeBasePage from '../pages/knowledgeBasePage/KnowledgeBasePage';
import KnowledgeDetail from '../pages/knowledgeBaseDetailPage/KnowledgeDetail';
import PayOrderLinkUpload from "../pages/payOrderLinkUpload/PayOrderLinkUpload";
import NewLogin from '../authorization/Login';
import RecoverPasswordPage from '../pages/recoverPassword/RecoverPasswordPage';
import UpdatePasswordPage from '../pages/updatePasswordPage/UpdatePasswordPage';
import KnowledgeEdit from '../pages/knowledgeEdit/KnowledgeEdit';
import KnowledgeCreate from '../pages/knowledgeCreate/KnowledgeCreate';
import KnowledgeSearchPage from '../pages/knowledgeBaseSearchPage/KnowledgeSearchPage';
import KnowledgeSection from '../pages/knowledgeBasePage/moderation/knowledgeSection/KnowledgeSection';
import Reports from "../pages/reports/Reports";
import MbProjects from "../pages/mbProjects/MbProjects";
import CreateMbProject from "../pages/mbProjects/createMbProject/CreateMbProject";
import SingleProject from "../pages/mbProjects/singleProject/SingleProject";
import CreateExpense from "../pages/mbProjects/createExpense/CreateExpense";
import CreateIncome from "../pages/mbProjects/createIncome/CreateIncome";
import PartnerCard from '../pages/partners/partnerCard/PartnerCard';
import ExpenseCategories from "../pages/expenseCategories/ExpenseCategories";
import IncomeCategories from "../pages/incomeCategories/IncomeCategories";
import CurrencyRates from "../pages/mbProjects/сurrencyRates/CurrencyRates.jsx";

function App () {
    const isAuth = useSelector(state => state.user.isAuth);
    const method = useSelector(state => state.user.method);
    const isLoading = useSelector(state => state.app.loader);
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(checkAuth());

        } else {
            dispatch(setAppLoader(false));
        }
    }, [dispatch]);

    if (isLoading) {
        return (
            <>
                <AppLoader />
                <Notifications />
            </>
        );
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={NewLogin} />
                <Route path="/recover" component={RecoverPasswordPage} />
                <Route path="/updatePassword/:id/:recoverId" component={UpdatePasswordPage} />

                <Route exact path="/upload-pay-order/:billingId" component={PayOrderLinkUpload} />
                {/* FrontPage */}

                <PrivateRoute exact method={method} auth={isAuth} path="/" component={Landing} />
                <PrivateRoute exact method={method} auth={isAuth} path="/users" component={Users} />
                <PrivateRoute exact method={method} auth={isAuth} path="/users/create" component={CreateUser} />
                <PrivateRoute exact method={method} auth={isAuth} path="/users/:userId" component={User} />
                <PrivateRoute exact method={method} auth={isAuth} path="/billing" component={Billings} />
                <PrivateRoute exact method={method} auth={isAuth} path="/billing/:billingId" component={SingleBilling} />
                <PrivateRoute exact method={method} auth={isAuth} path="/conversions" component={Conversions} />
                <PrivateRoute exact method={method} auth={isAuth} path="/conversionsreconciliation" component={ConversionsReconciliation} />
                <PrivateRoute exact method={method} auth={isAuth} path="/ConversionsCSV" component={ConversionsCSV} />
                <PrivateRoute exact method={method} auth={isAuth} path="/partners" component={Partners} />
                <PrivateRoute exact method={method} auth={isAuth} path="/partners/:partnerId" component={PartnerCard} />
                <PrivateRoute exact method={method} auth={isAuth} path="/offers" component={Offers} />
                <PrivateRoute exact method={method} auth={isAuth} path="/offers/:offerId" component={OfferCard} />
                <PrivateRoute exact method={method} auth={isAuth} path="/advertisers" component={Advertisers} />
                <PrivateRoute exact method={method} auth={isAuth} path="/advertisers/:advertiserId" component={AdvertiserCard} />
                <PrivateRoute exact method={method} auth={isAuth} path="/knowledge" component={KnowledgeBasePage} />
                <PrivateRoute exact method={method} auth={isAuth} path="/knowledge/article/:id" component={KnowledgeDetail} />
                <PrivateRoute exact method={method} auth={isAuth} path="/knowledge/section/:id" component={KnowledgeSection} />
                <PrivateRoute exact method={method} auth={isAuth} path="/knowledge/search/:searchData" component={KnowledgeSearchPage} />
                <PrivateRoute exact method={method} auth={isAuth} path="/knowledge/create" component={KnowledgeCreate} />
                <PrivateRoute exact method={method} auth={isAuth} path="/knowledge/article/edit/:id" component={KnowledgeEdit} />
                <PrivateRoute exact method={method} auth={isAuth} path="/reports" component={Reports} />
                <PrivateRoute exact method={method} auth={isAuth} path="/mb-projects" component={MbProjects} />
                <PrivateRoute exact method={method} auth={isAuth} path="/mb-projects/create" component={CreateMbProject} />
                <PrivateRoute exact method={method} auth={isAuth} path="/mb-projects/project" component={SingleProject} />
                <PrivateRoute exact method={method} auth={isAuth} path="/mb-projects/project/add-expenses" component={CreateExpense} />
                <PrivateRoute exact method={method} auth={isAuth} path="/mb-projects/project/add-income" component={CreateIncome} />
                <PrivateRoute exact method={method} auth={isAuth} path="/expense-categories" component={ExpenseCategories} />
                <PrivateRoute exact method={method} auth={isAuth} path="/income-categories" component={IncomeCategories} />
                <PrivateRoute exact method={method} auth={isAuth} path="/currency-rates" component={CurrencyRates} />

                {/* 404 */}
                <Route path="*" component={NotFound} />
            </Switch>
            <Modals />

            <Notifications />
        </BrowserRouter>
    );
}

export default App;
