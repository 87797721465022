import React, {lazy, Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import styles from "../AdvertiserCard.module.css";
import Button from "../../../../ui/button_v2/ButtonV2";
import ItemsList from "./itemsList/ItemsList";
import {addModal, removeModal} from "../../../../../reducers/appReducer";
import ContentLoader from "../../../../ui/contentLoader/ContentLoader";
import {getBillsByAdvId} from "../../../../../actions/bills";

const AdvertiserCreateBill = lazy(()=>import('../../../../modals/advertiserCreateBill/AdvertiserCreateBill'));

const BillsTab = ({ advertiser }) => {
    const dispatch = useDispatch();
    const bills = useSelector( state => state.bills.billsList);

    useEffect(() => {
        dispatch( getBillsByAdvId( advertiser.id ) );
    },[dispatch, advertiser]);


    const createBill = ( ) => {
        dispatch(addModal({
            id: 'createBill',
            size: 'big',
            children:
                <Suspense fallback={<ContentLoader/>}>
                    {AdvertiserCreateBill &&
                        <AdvertiserCreateBill
                            onError={() => {
                                dispatch(removeModal('createBill'))
                            }}
                            advId={advertiser.id}
                        />
                    }
                </Suspense>
        }));
    }

    return (
        <div className={styles.AdvTabContent}>
            <div className="p-2">
                <Button type={'primary'} onClick={createBill}>Создать счет</Button>
            </div>
            <div className={styles.DocList}>
                <ItemsList items={bills} />
            </div>
        </div>
    );
};

export default BillsTab;
