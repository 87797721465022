import React from 'react';
import styles from './AdvertisersContractsList.module.css';
import AdvertisersContractsLi from "./advertisersContractsLi/AdvertisersContractsLi";

const AdvertisersContractsList = ({contracts, advertiser}) => {
    return (
        <div className={styles.ListBlock}>
            <div className={styles.BillingPartLabel}>
                <div className={`${styles.Number}`}>Номер договора</div>
                <div className={`${styles.Date}`}>Дата договора</div>
                <div className={`${styles.Btn}`}></div>
            </div>
            <ul className={styles.HistoryList}>
                {contracts.length > 0
                    ? contracts.map((item, index) =>
                        <AdvertisersContractsLi item={item} key={index} data={advertiser}/> )
                    :  <li className="mt-3"><i>Договоры отсутствуют</i></li>
                }
            </ul>
        </div>
    );
};

export default AdvertisersContractsList;
