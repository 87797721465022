import React, {useEffect, useRef, useState} from 'react';
import styles from "./PayOrderLinkUpload.module.css";
import Button from "../../ui/button_v2/ButtonV2";
import {createNotification} from "../../../actions/app";
import {useDispatch} from "react-redux";
import {getBillingAffiseId, payOrderLinkUpload} from "../../../actions/billing";
import {useParams} from "react-router-dom";
import UploadIcon from "../../ui/icons/UploadIcon";

const PayOrderLinkUpload = ({location}) => {
    const dispatch = useDispatch();
    const {billingId} = useParams();
    const inputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [affiseId, setAffilseId] = useState(null);
    const [uploaded, setUploaded] = useState(false);
    const [successMessage, setSuccessMessage] = useState('Платежное поручение загружено!');

    const token = new URLSearchParams( location.search ).get('token');

    useEffect(() => {
        dispatch( getBillingAffiseId( billingId ) )
            .then( result => {
                if ( result ) {
                    setAffilseId( result );
                }
            });
    }, [dispatch, billingId]);

    const updateFileData = (e) => {
        setFile( e.target.files[0] );
    };

    const handleUploadClick = () => {
        // We redirect the click event onto the hidden input element
        inputRef.current?.click();
    };

    const uploadPayOrder = () => {
        if ( !file ) {
            dispatch( createNotification({
                type: 'error',
                children: 'Select file to upload!'
            }));
            return;
        }

        dispatch( payOrderLinkUpload( billingId, token, file ) )
            .then(( response ) => {
                if ( response ) {
                    if ( response === 'loadedBefore' ) {
                        dispatch(createNotification({
                            type: 'primary',
                            children: 'Payment Order have been already uploaded from billing page'
                        }));
                        setSuccessMessage('Платежное поручение уже было загружено менеджером через индивидуальну страницу биллинга')
                    } else {
                        dispatch(createNotification({
                            type: 'primary',
                            children: 'Payment Order successfully uploaded'
                        }));
                    }
                    setUploaded( true );
                }
            });
    };

    return (
        <div className={styles.UploadContent}>
            <div className={styles.UploadForm}>
                <div className={styles.ControlBlock}>
                    {!uploaded
                        ?
                        <>
                            <h4 className="mb-3">Select file to upload{affiseId && ` payment order for billing #${affiseId}`}:</h4>
                            <div className="my-3">
                                        <input
                                            ref={inputRef}
                                            onChange={ e => updateFileData(e) }
                                            type="file"
                                            id="upload"
                                            name="upload"
                                            accept=".pdf"
                                            style={{display: 'none'}}
                                        />
                                        <div className="d-flex align-items-center mb-3">
                                            <Button className={styles.SelectBtn} type="secondary" onClick={handleUploadClick}>Select file</Button>
                                            <div className={`${styles.UploadFileName}`}>{file && file.name ? `${file.name}` : 'file is not selected'}</div>
                                        </div>
                                        <Button className={styles.UploadBtn} type='primary' onClick={uploadPayOrder} >Upload file  <UploadIcon/></Button>
                            </div>
                        </>
                        : <div className={styles.Message}>{successMessage}</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PayOrderLinkUpload;
