import React, { lazy, Suspense } from 'react';
import { useDispatch } from "react-redux";
import { addModal, removeModal } from "../../../../../reducers/appReducer";
import Button from "../../../../ui/button_v2/ButtonV2";
import ContentLoader from "../../../../ui/contentLoader/ContentLoader";
import styles from "../AdvertiserCard.module.css";
import AdvertisersContractsList from '../advertisersContractsList/AdvertisersContractsList';

const AdvertisersCreateContract = lazy(()=>import('../../../../modals/advertisersCreateContract/AdvertisersCreateContract'));

const ContractsTab = ({ advertiser }) => {
    const dispatch = useDispatch();

    const createContract = ({advertiser, data}) => {
        dispatch(addModal({
            id: 'createContract',
            size: 'big',
            children:
                <Suspense fallback={<ContentLoader/>}>
                    {AdvertisersCreateContract &&
                        <AdvertisersCreateContract
                            onError={() => {
                                dispatch(removeModal('createContract'))
                            }}
                            data={data}
                            advertiser={advertiser}
                        />}
                </Suspense>
        }));
    };

    return (
        <div className={styles.AdvTabContent}>
            <div className="p-2">
                <Button type={'primary'} onClick={() => createContract({advertiser:advertiser, data:''}) }>Создать договор</Button>
            </div>
            <div className={styles.DocList}>
                <AdvertisersContractsList contracts={advertiser.contracts} advertiser={advertiser} />
            </div>
        </div>
    );
};

export default ContractsTab;
