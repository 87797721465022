/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { fetchBillings } from "../../../actions/billing";
import { filterParams } from "../../../core/config";
import { MODULE_TYPE, getModuleRule, isRead } from "../../../core/helpers";
import { delayByLoader } from "../../../utils/deleyByLoader";
import AppContent from "../../appContent/AppContent";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import Pagination from "../../ui/pagination/Pagination";
import styles from "./Billings.module.css";
import BillingContent from "./content/BillingContent";
import BillingFilters from "./filters/BillingFilters";
import { setBillingPaginationReset } from "../../../reducers/billingReducer";

const Billings = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const billings = useSelector((state) => state.billing.items);
    const pagination = useSelector((state) => state.billing.pagination);
    const sumTax = useSelector((state) => state.billing.sumTax);
    const sumRevenue = useSelector((state) => state.billing.sumRevenue);
    const sort = useSelector((state) => state.billing.sort);
    const page = pagination.page;
    const [sortData, setSortData] = useState({ name: "postedDate", value: 2 })


    let filters = JSON.parse(JSON.stringify(filterParams));
    let params = {
        filters: filters,
        page: page,
        sort: sort,
    };

    const [paramData, setParamData] = useState(params);

    const rule = getModuleRule(
        useSelector((state) => state.user.currentUser.rules),
        MODULE_TYPE.BILLING
    );

    useEffect(() => {
        return () => {
            dispatch(setBillingPaginationReset());
        };
    }, []);

    useEffect(() => {
        dispatch(fetchBillings(paramData, setLoading));
    }, [dispatch, paramData]);

    useEffect(() => {
        setParamData({ ...paramData, sort: sortData });
    }, [sortData]);

    if (!isRead(rule)) {
        return <Redirect to="/" />;
    }

    const onResetFilters = () => {
        delayByLoader(true, setLoading, 300);

        const resetData = {
            filters: [
                { name: "period", value: "day30" },
                { name: "status", value: -2 },
                { name: "payAgent", value: -2 },
                { name: "paySystem", value: -2 },
                { name: "currency", value: -2 },
                { name: "search", value: "" },
                { name: "transactionId", value: "" },
            ],
            page: 1,
            sort: { name: "postedDate", value: 2 },
        };

        setParamData(resetData);
        dispatch(fetchBillings(resetData));
    };

    return (
        <AppContent>
            <div className={`billingsContainer container wrapper`}>
                <div className={`${styles.TitleBlock} row`}>
                    <h2>Оплаты вебмастерам</h2>
                </div>
                <BillingFilters
                    billingData={billings}
                    filterData={paramData.filters}
                    setFilterData={(val) =>
                        setParamData({ ...paramData, filters: val.filter, page: val.page })
                    }
                    page={page}
                    onResetFilters={onResetFilters}
                />
                {loading ? (
                    <ContentLoader />
                ) : (
                    <div className={styles.ContentBilling}>
                        {billings.length !== 0 ? (
                            <BillingContent
                                billingData={billings}
                                setSortData={(val) => setSortData(val)}
                                sortData={sortData}
                                page={page}
                                sumTax={sumTax}
                                sumRevenue={sumRevenue}
                            />
                        ) : (
                            <div className={`${styles.NoData} row col-12`}>
                                Нет данных за выбранный период
                            </div>
                        )}
                    </div>
                )}
                <div className={styles.PaginationBlock}>
                    <Pagination
                        paginationData={pagination}
                        paramData={paramData}
                        setParamData={(val) => setParamData({ ...paramData, page: val })}
                    />
                </div>
            </div>
        </AppContent>
    );
};

export default Billings;
