/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AppContent from '../../../../appContent/AppContent';
import styles from './KnowledgeSection.module.css'
import ContentLoader from '../../../../ui/contentLoader/ContentLoader';
import KnowledgeModerationContent from './knowledgeModerationContent/KnowledgeModerationContent';
import KnowledgeBaseSideBar from '../../../knowledgeBaseDetailPage/knowledgeBaseSideBar/KnowledgeBaseSideBar';
import { fetchKnowledge, fetchKnowledgeById } from '../../../../../actions/knowledge';
import { setCurrentSectionsData, setSearchText, setSectionsData } from '../../../../../reducers/knowledgeReducer';
import KnowledgeNavigation from '../knowledgeNavigation/KnowledgeNavigation';
import KnowledgeBaseSearch from '../../../../knowledgeBase/knowledgeBaseSearch/KnowledgeBaseSearch';
import SearchBg from '../../../../knowledgeBase/searchBg/SearchBg';

const KnowledgeSection = ({ match }) => {
    const dispatch = useDispatch();
    const id = match.params.id;
    const [contentLoading, setContentLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState('');
    const [searchData, setSearchData] = useState([]);

    useEffect(() => {
        onSearchBgHandler();
        setContentLoading(true);
        dispatch(fetchKnowledgeById(id, setContentLoading));
        dispatch(fetchKnowledge(setContentLoading));

        return () => {
            dispatch(setSectionsData([]));
            dispatch(setCurrentSectionsData([]));
        }
    }, [id]);

    const onSearchBgHandler = () => {
        setValue('');
        dispatch(setSearchText(''));
        setSearchData([]);
    };

    const onSetSearchText = (value) => {
        setValue(value);
        dispatch(setSearchText(value));
    };

    return (
        <AppContent>
            <SearchBg value={value} setSearchData={setSearchData} setLoading={setLoading} onSearchBgHandler={onSearchBgHandler} />
            <div className={`container ${styles.container}`} >
                {contentLoading ? (
                    <ContentLoader />
                ) : (
                    <>
                        <KnowledgeNavigation />
                        <KnowledgeBaseSearch
                            searchId={id}
                            isPos={true}
                            value={value}
                            loading={loading}
                            setValue={onSetSearchText}
                            placeholder="Поиск"
                            searchData={searchData}
                            setSearchData={setSearchData}
                            style={{ zIndex: 3, width: "100%", borderRadius: "16px", border: "none", marginBottom: '35px' }}
                            onSearchBgHandler={onSearchBgHandler}
                        />
                        <div className={styles.wrapperContainer}>
                            <KnowledgeBaseSideBar />
                            <KnowledgeModerationContent loading={contentLoading} setLoading={setContentLoading} />
                        </div>
                    </>

                )}
            </div>
        </AppContent>
    )
}

export default KnowledgeSection