import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import styles from './AppLoader.module.css';
import imgAppLoaderLine from '../../../assets/img/appLoaderLine.svg';
import imgAppLoader from '../../../assets/img/loader.svg';


const AppLoader = () => {
    const isLoading = useSelector(state => state.app.loader);

    useEffect(()=>{
        document.body.classList.toggle('hidden', isLoading);
    },[isLoading]);

    return (
        <div className={styles.LoaderWrapper}>
            <div className={styles.AppSpinner}>
                <img src={imgAppLoader} alt="" />
                <div className={styles.Spinner}>
                    <img src={imgAppLoaderLine} alt="" />
                </div>
            </div>

        </div>
    );
};

export default AppLoader;
