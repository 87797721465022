import React, {useEffect, useState} from 'react';
import parentStyles from "../../../Reports.module.css";
import styles from "./ReportPartnerRow.module.css";
import ReportProjectRow from "../reportProjectRow/ReportProjectRow";
import TotalAmountsCols from "../totalAmountsCols/TotalAmountsCols";

const ReportPartnerRow = ({partnerData, partnerTotalAmounts, partnerGroupsTotalAmounts, monthsArr, partnerIncomeData, partnerExpensesData}) => {
    const partner = Object.keys(partnerData)[0];
    const groupsTotals = partnerGroupsTotalAmounts ? partnerGroupsTotalAmounts[partner] : '';
    const groupsData = partnerData[partner];
    const partnerTotals = partnerTotalAmounts ? partnerTotalAmounts[partner]?.partnerTotalAmounts : '';
    const partnerIncome = partnerIncomeData ? partnerIncomeData[partner] : '';
    const partnerExpenses = partnerExpensesData ? partnerExpensesData[partner] : '';
    const [partnerTotalsColumns, setPartnersTotalsColumns] = useState([]);
    const [groupsArr, setGroupsArr] = useState([]);
    const [showGroups, setShowGroups] = useState(false);

    useEffect(() => {
        const groupsArr = [];
        for (const group in groupsData) {
            const groupData = {};
            groupData[group] = groupsData[group];
            groupData['isOpen'] = false;
            groupsArr.push(groupData);
        }
        setGroupsArr(groupsArr);
    }, [partnerData, monthsArr, partner, groupsData]);

    useEffect(() => {
        if (partnerTotals) {
            if (Object.keys(partnerTotals).length > 0) {

                const totalsColumnsArr = monthsArr.map((date, index) => {
                    const amountData = partnerTotals[date.year][date.month];
                    return <TotalAmountsCols date={date} amountData={amountData} key={index}/>;
                });
                setPartnersTotalsColumns(totalsColumnsArr);
            }
        }
        // eslint-disable-next-line
    }, [partnerTotals]);

    const setShowGroupProjects = (index) => {
        const groupsArrUpdate = groupsArr.map((el, i) => {
            if (i === index) {
                el['isOpen'] = !el['isOpen'];
            }
            return el;
        })
        setGroupsArr(groupsArrUpdate);
    }
    

    return (
        <div>
            <div className="d-flex">
                <div className={parentStyles.firstColumn}>
                    <div className={styles.PartnerHeader}>
                        <div className={styles.SwitchBlock}>
                            <div className={styles.TableCell}>{partner}</div>
                            <div className={`${styles.TableCell} ${parentStyles.collapseIcon}`} onClick={() => setShowGroups(!showGroups)}>
                                <span className={parentStyles.lageFont}>{showGroups ? <span className={styles.TableSignMinus}>-</span> : <span className={styles.TableSignPlus}>+</span>}</span>
                            </div>
                        </div>
                        <div className={parentStyles.totalCell}>Total, RUR</div>
                    </div>
                </div>
                {partnerTotalsColumns.length > 0 && partnerTotalsColumns}
            </div>

            <div className={showGroups ? '' : 'd-none'}>
                {groupsArr && groupsArr.length ? groupsArr.map((group, index) => {
                    const groupName = Object.keys(group)[0];
                    let groupProjects = [];
                    let currentGroupProjects = [...groupProjects, group[groupName]];
                    let amountData;
                    return (
                        <div key={index}>
                            <div className="d-flex">
                                <div className={parentStyles.firstColumn}>
                                    <div className={styles.GroupHeader}>
                                        <div className={styles.GroupSwitchBlock}>
                                            <span className={styles.GroupName}>{groupName ? groupName : ''}</span>
                                            {showGroups && <div className={`${styles.TableCell} ${parentStyles.collapseIcon}`} onClick={() => setShowGroupProjects(index)}>
                                                <span className={parentStyles.lageFont}>{groupsArr[index].isOpen ? <span className={styles.TableSignGroupMinus}>-</span> : <span className={styles.TableSignGroupPlus}>+</span>}</span>
                                            </div>}
                                        </div>
                                        <div className={parentStyles.totalCell}>Total, RUR</div>
                                    </div>
                                </div>
                                    {groupsTotals && monthsArr.map((date, index) => {
                                        amountData = groupsTotals[groupName][date.year][date.month];
                                        return <TotalAmountsCols date={date} amountData={amountData} key={index}/>
                                    })}
                            </div>

                            <div className={group.isOpen ? '' : 'd-none'}>
                                {currentGroupProjects?.length ? currentGroupProjects.map((project, index) => {
                                    const projectName = Object.keys(project)[0];
                                    return (
                                         <ReportProjectRow
                                            projectData={project}
                                            projectIncome={partnerIncome ? partnerIncome[projectName] : ''}
                                            projectExpenses={partnerExpenses ? partnerExpenses[projectName] : ''}
                                            monthsArr={monthsArr}
                                            key={index} />
                                    );
                                }
                                ) : ''}
                            </div>
                        </div>
                    )
                    
                }) : ''
                }
            </div>
            
        </div>
    );
};

export default ReportPartnerRow;
