import React from 'react';
import styles from './Button.module.css';
import './Button.css';

const Button = ({children,onClick, ...props}) => {

    let btnClasses = props.className ? `${props.className} ` : '';

    switch (props.type) {
        case 'login':
            btnClasses += `${styles.btn} ${styles.Login}`;
            break;
        case 'login active':
            btnClasses += `${styles.btn} ${styles.login} ${styles.loginTab}`;
            break;
        case 'icon':
            btnClasses += `${styles.btn} ${styles.icon}`;
            break;
        case 'add':
            btnClasses += `${styles.btn} ${styles.add}`;
            break;
        case 'tab':
            btnClasses += `${styles.btn} ${styles.tab}`;
            break;
        case 'tab active':
            btnClasses += `${styles.btn} ${styles.tab} ${styles.tab.active}`;
            break;
            case 'billingPay':
            btnClasses += `${styles.btn} ${styles.BillingPay}`;
            break;
        case 'delete':
            btnClasses += `${styles.btn} ${styles.Delete}`;
            break;
        case 'deleteFill':
            btnClasses += `${styles.btn} ${styles.DeleteFill}`;
            break;
        case 'deleteRed':
            btnClasses += `${styles.btn} ${styles.DeleteRed}`;
            break;
        case 'line':{
            btnClasses +=  `${styles.btn} ${styles.Line}`;
            break;
        }
        case 'fiol':{
            btnClasses +=  `${styles.btn} ${styles.Fiol} ${styles.btnB} `;
            break;
        }
        case 'greyLight':{
            btnClasses +=  `${styles.btn} ${styles.GreyLight} ${styles.btnB}`;
            break;
        }
        case 'fiolNew':{

            btnClasses +=  `${styles.btn} ${styles.FiolNew}`;
            break;
        }
        case 'grey':{
            btnClasses +=  `${styles.btn} ${styles.Grey}  ${styles.btnB}`;
            break;
        }
        case 'green':{
            btnClasses +=  `${styles.btn} ${styles.Green} ${styles.btnB}`;
            break;
        }
        case 'red':{
            btnClasses +=  `${styles.btn} ${styles.Red} ${styles.btnB}`;
            break;
        }
        case 'no':{
            btnClasses +=  `${styles.btn} ${styles.No}`;
            break;
        }
        default:
            btnClasses += `${styles.btn}`;
            break;
    }

    return (
        <button {...props}   onClick={()=>{
            onClick();
        }} type='button' className={btnClasses}>
            {children}
        </button>
    );
};

export default Button;