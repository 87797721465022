import React, { useRef, useState } from "react";
import styles from "./PartnersContentLi.module.css";
import { format } from "date-fns";
import { getCurrencySymbol } from "../../../../../core/helpers";
import DetailsIcon from '../../../../../assets/img/details-icon.png'
import PartnersStoppedIcon from '../../../../../assets/img/partners-stopped.svg';
import PartnersStoppedHoverIcon from '../../../../../assets/img/partners-stopped-hover.svg';
import { useHistory } from "react-router-dom";


const PartnersContentLi = ({ item }) => {
    const history = useHistory()
    const requisites =
        item.paymentSystems.length !== 0
            ? item.paymentSystems
                .map((payment) => {
                    return payment.system;
                })
                .join(", ")
            : [];

    const [activeHover, setActiveHover] = useState(false);
    const lastPayments =
        item.billings && item.billings.length !== 0
            ? item.billings.filter((billing) => billing.status === 2)
            : [];
    const commentRef = useRef(null);

    return (
        <li className={`${styles.Item} row`}>
            <div className={`${styles.Id}`}>{item.affiseId}</div>
            <div className={`${styles.Name}`}>
                <div className={styles.Login}>{item.login}</div>
                <div className={styles.Email}>{item.email}</div>
            </div>

            <div className={`${styles.Manager} `}>
                {item.manager.firstName || item.manager.lastName ? `${item.manager.firstName} ${item.manager.lastName}`:
                <span className={styles.Dash}>-</span>}
            </div>

            {lastPayments.length !== 0 ? (
                <div className={`${styles.Payout} `}>
                    <div className={styles.Date}>
                        {format(new Date(lastPayments[0].postedDate), "dd.MM.yyyy")}
                    </div>
                    <div className={styles.Sum}>
                        {lastPayments[0].amount
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ")}{" "}
                        {getCurrencySymbol(lastPayments[0].currency)}
                    </div>
                </div>
            ) : <div className={styles.Dash}>-</div>}

            {item.revenue && item.revenue !== 0 ? (
                <div className={`${styles.Debt} `}>
                    <div className={styles.Date}>
                        {format(new Date(item.creditDate), "dd.MM.yyyy")}
                    </div>
                    <div
                        className={
                            item.revenue < 0 ? styles.Sum : `${styles.Sum} ${styles.Active}`
                        }
                    >
                        {item.revenue.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ")}{" "}
                        {getCurrencySymbol(item.currency)}
                    </div>
                </div>
            ) : <div className={styles.Dash}>-</div>}

            <div className={`${styles.Requisites} `}>
                {item?.paymentSystems?.length !== 0 ? (
                    <div className={styles.PaySystems}>
                        <div className={styles.Type}>{requisites}</div>
                    </div>
                ):<div className={styles.Dash}>-</div>}
            </div>

            <div className={styles.Status} style={{ display: 'flex', }}>
                {item?.archive ? <span className={styles.archive}>В архиве</span>:
                <span className={styles.Dash}>-</span>}
            </div>

            <div className={styles.Additionally}>
                {item?.stopped ? 
                <div style={{ position: "relative" }}>
                    <img src={activeHover ? PartnersStoppedHoverIcon : 
                        PartnersStoppedIcon} 
                        alt="" 
                        onMouseEnter={() => setActiveHover(true)} onMouseLeave={() => setActiveHover(false)} 
                    />
                    <div ref={commentRef} className={styles.tooltip} style={{
                        top: `${-commentRef?.current?.getBoundingClientRect()?.height * 0.99}px`,
                        visibility: activeHover ? 'visible' : 'hidden'
                    }}>
                        Запрет вывода средств
                    </div>
                </div>: 
                <div className={styles.Dash}>-</div>}
            </div>

            <div className={`${styles.Btn}`} >
                <div
                    className={styles.Settings}
                    onClick={() => {
                        history.push(`/partners/${item.id}`)
                    }}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                    {DetailsIcon ? <img src={DetailsIcon} alt="-" />:
                    <span className={styles.Dash}>-</span>}
                </div>
            </div>
        </li >
    );
};

export default PartnersContentLi;
