import React, {useEffect, useState} from 'react';
import styles from './ReportTableHeader.module.css';
import parentStyles from '../Reports.module.css';
import {MonthsName} from "../../../../core/config";
import {useDispatch, useSelector} from "react-redux";
import {
    addCollapsedExpensesColumns,
    addCollapsedIncomeColumns,
    addCollapsedMonthsColumns,
    deleteCollapsedExpensesColumns,
    deleteCollapsedIncomeColumns,
    deleteCollapsedMonthsColumns,
    resetCollapsedExpensesColumns,
    resetCollapsedIncomeColumns, resetCollapsedMonthsColumns, setMonthsCurrencyRates
} from "../../../../reducers/reportsReducer";
import iconGraph from '../../../../assets/img/icon-graph.svg';
import iconCollapse from '../../../../assets/img/icon-collaps.svg';
import iconSort from '../../../../assets/img/icon-dashed-piramid.svg';
import Button from "../../../ui/button_v2/ButtonV2";

const ReportTableHeader = ({ dateMatrix }) => {
    const dispatch = useDispatch();
    const collapsedIncome = useSelector(state => state.reports.collapsedIncomeColumns);
    const collapsedExpenses = useSelector(state => state.reports.collapsedExpensesColumns);
    const collapsedMonths = useSelector(state => state.reports.collapsedMonths);
    const [monthsArr, setMonthsArr] = useState([]);

    useEffect(() => {
        const arr = [];
        for (const year in dateMatrix) {
            for (const month in dateMatrix[year]) {
                arr.push({id: `${year.slice(2)}.${month}`, name:`${MonthsName[month]}.${year.slice(2)}`
            });
            }
        }
        setMonthsArr( arr );
        return(() => {
            dispatch(resetCollapsedIncomeColumns());
            dispatch(resetCollapsedExpensesColumns());
            dispatch(resetCollapsedMonthsColumns());
            dispatch(setMonthsCurrencyRates({}));
        })
    }, [dispatch, dateMatrix]);

    const switchColumnCollapse = (type, month) => {
        switch (type) {
            case 'income':
                if ( collapsedIncome.includes(month) ) {
                    dispatch( deleteCollapsedIncomeColumns(month) );
                } else {
                    dispatch( addCollapsedIncomeColumns(month) );
                }
                break;
            case 'expenses':
                if ( collapsedExpenses.includes(month) ) {
                    dispatch(deleteCollapsedExpensesColumns(month));
                } else {
                    dispatch(addCollapsedExpensesColumns(month));
                }
                break;
            default:
                return;
        }
    }

    const setHiddenMonths = ( hide ) => {
        const lastHiddenMonth = collapsedMonths.slice(-1)[0];
        if ( hide ) {
            const indexLastHiddenMonth = monthsArr.findIndex(item => lastHiddenMonth === item.id);
            dispatch( addCollapsedMonthsColumns(monthsArr[indexLastHiddenMonth + 1].id) );
        } else {
            dispatch( deleteCollapsedMonthsColumns(lastHiddenMonth) );
        }
    };

    return (
        <>
            <div className="d-flex justify-content-end mb-1">
                <Button
                    type={'secondary'}
                    className={styles.SwipePageBtn}
                    onClick={() => setHiddenMonths( false )}
                    disabled={collapsedMonths.length === 0}
                >&larr;</Button>
                <Button
                    type={'secondary'}
                    className={styles.SwipePageBtn}
                    onClick={() => setHiddenMonths( true )}
                    disabled={collapsedMonths.length === monthsArr.length -1}
                >&rarr;</Button>
            </div>
            <div className={styles.ReportTableHeader}>
            <div className={`${styles.TableHeaderCell} ${parentStyles.firstColumn}`}>
                <div className={`d-flex align-items-center p-1 ${styles.h100}`}>Вебмастер (ID)</div>
            </div>
            {monthsArr.length > 0 && monthsArr.map( (month, index) => {
                const hideIncome = collapsedIncome.includes(month.id);
                const hideExpenses = collapsedExpenses.includes(month.id);
                return (
                        <div
                            className={`${styles.TableHeaderCell} ${collapsedMonths.includes(month.id) 
                                ? styles.hiddenColumn: ''} ${hideIncome 
                                ? styles.hiddenIncome : ''} ${hideExpenses 
                                ? styles.hiddenExpenses : ''}`}
                            key={index}
                        >
                            <div className={`${styles.InnerRow} ${styles.bMainB} py-1`}>{month.name}</div>
                            <div className={styles.InnerRow}>
                                <div className={`${styles.brGreen2}`}>
                                    <div className={`${styles.InnerRow} ${styles.bbGreen3}`}>
                                        <div className={`${styles.InnerCell} d-flex align-items-center`}>
                                            Доход
                                            <img className={styles.SortIcon} src={iconSort} alt=""/>
                                            <img
                                                className={`ml-1 ${hideIncome ? styles.iconDown : ''}`}
                                                src={iconCollapse}
                                                onClick={() => switchColumnCollapse('income', month.id)}
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            className={`${styles.InnerCell} ${hideIncome ? 'd-none' : ''}`}>
                                            <img className={styles.IconGraph} src={iconGraph} alt=""/>
                                        </div>
                                    </div>
                                    <div className={styles.InnerRow}>
                                        <div
                                            className={`${styles.InnerCell} ${styles.smFont} ${styles.brGreen3} ${hideIncome ? 'd-none' : ''}`}>Валюта
                                        </div>
                                        <div className={`${styles.InnerCell} ${styles.smFont} ${styles.w106}`}>Конвертация,RUR
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.brGreen2}`}>
                                    <div className={`${styles.InnerRow} ${styles.bbGreen3}`}>
                                        <div className={`${styles.InnerCell} d-flex align-items-center`}>
                                            Расход
                                            <img className={styles.SortIcon} src={iconSort} alt=""/>
                                            <img className={`ml-1 ${hideExpenses ? styles.iconDown : ''}`} src={iconCollapse}
                                                 onClick={() => switchColumnCollapse('expenses', month.id)} alt=""/>
                                        </div>
                                        <div
                                            className={`${styles.InnerCell} ${hideExpenses ? 'd-none' : ''}`}>
                                            <img className={styles.IconGraph} src={iconGraph} alt=""/>
                                        </div>
                                    </div>
                                    <div className={styles.InnerRow}>
                                        <div
                                            className={`${styles.InnerCell} ${styles.smFont} ${styles.brGreen3} ${hideExpenses ? 'd-none' : ''}`}>Валюта
                                        </div>
                                        <div className={`${styles.InnerCell} ${styles.smFont} ${styles.w106}`}>Конвертация,RUR
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.brGreen2bg}`}>
                                    <div
                                        className={`${styles.InnerCell} ${styles.bbGreen3} d-flex align-items-center justify-content-center`}>
                                        Маржа <img className={styles.SortIcon} src={iconSort} alt=""/>
                                    </div>
                                    <div className={`${styles.InnerCell} ${styles.smFont} ${styles.w106}`}>Конвертация,RUR</div>
                                </div>
                            </div>
                        </div>
                );
            })}
            </div>
        </>
    );
};

export default ReportTableHeader;
