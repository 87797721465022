/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from "./KnowledgeBaseSideBar.module.css";
import { NavLink } from "react-router-dom";
import ArrowGray from "../../../../assets/img/arrow-gray.svg";
import { useSelector } from 'react-redux';

const AccordionChildArticle = ({
    item,
    isSub = false,
    activeArticleId,
    setActiveParentArticle,
    setActiveItem,
    setParent
}) => {

    const [activeArticle, setActiveArticle] = useState(false);

    const currentSectionData = useSelector(state => state.knowledge.currentSectionData);


    const currentArticleData = useSelector(
        (state) => state.knowledge.currentArticleData
    );

    const data = useSelector((state) => state.knowledge.data);

    useEffect(() => {
        const el = data.filter((item) => item._id === currentSectionData._id);

        if (el?.length > 0) {
            el[0].articles.forEach((item) => {
                if (item?.articles?.length > 0) {
                    item?.articles?.find((item2) => {
                        if (item2._id === currentArticleData._id) {
                            setParent(item)
                        }
                    })
                    return;
                }
            })
        }

    }, [currentArticleData, currentSectionData]);


    useEffect(() => {
        if (activeArticleId === item._id) {
            setActiveArticle(true);
            setActiveParentArticle(true);
        } else {
            setActiveArticle(false);
        }
    }, [activeArticleId]);

    useEffect(() => {
        if (currentArticleData._id === item._id) {
            setActiveArticle(true);
            setActiveParentArticle(true);
        }
    }, [currentArticleData]);



    const toggleArticleAccordion = () => {
        setActiveArticle((prev) => !prev);
    };

    return (
        <div
            className={`${styles.accordion_item}`}
            style={{ margin: isSub ? "0px" : "unset" }}
        >
            <div
                className={`${styles.accordion_item_title} ${activeArticleId === item._id ? styles.active : ""}`}
                style={{
                    justifyContent: item.sectionId ? "flex-start" : 'space-between'
                }}
            >
                {item.sectionId && <span className={styles.dot}></span>}
                {item.sectionId && item.isSub && <span className={styles.dot}></span>}
                <NavLink
                    className={styles.link}
                    to={`/knowledge/article/${item._id}`}
                    onClick={() => {
                        setActiveItem(item._id); // Устанавливаем активный элемент при клике
                    }}
                >
                    <span
                        className={`${styles.accordion_item_title_text}`}
                    >
                        {item?.title}
                    </span>
                </NavLink>
                {item?.articles?.length > 0 && <img
                    src={ArrowGray}
                    alt=""
                    className={styles.arrowIcon}
                    onClick={toggleArticleAccordion}
                    style={{ transform: (activeArticle) ? "rotate(0deg)" : "rotate(180deg)" }}
                />}
            </div>
        </div>
    );
}

export default AccordionChildArticle;