import React from 'react';
import "./SelectUi.css";
import Select from "react-select";
const SelectUi = ({ options, defaultValue, onChange, placeholder, value, label, classes = "", isSearchable }) => {
    const customFilter = (option, inputValue) => {
        if (!inputValue) {
            return true;
        }
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    return (
        <div>
            <div className='label'>{label}</div>
            <Select
                options={options}
                defaultValue={defaultValue}
                onChange={(val) => {
                    onChange(val.value)
                }}
                filterOption={isSearchable ? customFilter : null}
                placeholder={placeholder}
                isSearchable={isSearchable || false}
                className={`selectModal ${classes}`}
                classNamePrefix='selPref'
                value={value}
            />
        </div>
    );
};

export default SelectUi;
