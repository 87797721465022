import React, {useEffect, useState} from 'react';
import styles from './CreateIncome.module.css';
import AppContent from "../../../appContent/AppContent";
import {NavLink, useHistory} from "react-router-dom";
import SelectUi from "../../../ui/select/SelectUiv2";
import Button from "../../../ui/button_v2/ButtonV2";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import {useDispatch} from "react-redux";
import {createProjectIncome, getIncomeCategoryList} from "../../../../actions/partners-income";
import {CurrencyByFilter} from "../../../../core/config";
import {setOneMonthAgoDate} from "../../../../core/helpers";

const CreateIncome = ({ location }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const project = location.state.project;
    const income = location.state.data;
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [incomeData, setIncomeData] = useState({
        mbProject: project.id,
        incomeCategory: income ? income.incomeCategory.id : '',
        date: income ? setCurrentIncomeDate(income) : setOneMonthAgoDate(),
        offers: project.offers,
        amount: income ? income.amount : '',
        currency: income ? income.currency : 0
    });

    useEffect(() => {
        const getIncomeCategories = async () => {
            const categoryList = await dispatch(getIncomeCategoryList());
            if (categoryList) {
                setCategoryOptions(categoryList.map(category => { return {
                    value: category.id,
                    label: category.name
                }}));
            }
        };
        getIncomeCategories().then(() => console.log('Categories list loaded'));
    }, [dispatch, project]);

    const onDataPickerChange = (date) => {
        setIncomeData({ ...incomeData, date: date});
    };

    const createIncome = async () => {
        if (income) {
            incomeData.id = income.id;
            incomeData.update = true;
        }
        const result = await dispatch(createProjectIncome(incomeData));
        if ( result ) {
            history.push({
                pathname: `/mb-projects/project`,
                state: {project: project}
            });
        }
    };

    function setCurrentIncomeDate(income) {
        const now = new Date();
        now.setFullYear(income.year);
        return now.setMonth(income.month - 1);
    }

    return (
        <AppContent>
            <div className="container wrapper">
                <div className="row">
                    <div className="col-8 offset-2">
                        <div className='m-3'>
                            <NavLink to={{
                                pathname: `/mb-projects/project`,
                                state: {project: project}
                            }}>Назад</NavLink>
                        </div>
                        <div className={styles.CreateIncomeContent}>
                            <div className={`${styles.TitleBlock} d-flex justify-content-between`}>
                                <h2>{income ? 'Редактирование' :'Внесение'} дохода</h2>
                                <Button
                                    className={styles.Button}
                                    type={'primary'}
                                    onClick={createIncome}
                                >{income ? 'Обновить' : 'Внести'}</Button>
                            </div>
                            <div className={styles.TitleBlock}>
                                <h3>{project.name}</h3>
                                <div className="mt-1">{project.partner.email}</div>
                                <div className={styles.ID}>ID: {project.partner.affiseId}</div>
                            </div>
                            <div className={styles.FormBlock}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 pr-0">
                                                <SelectUi
                                                    label={'Статья дохода*'}
                                                    placeholder={'Выбрать...'}
                                                    options={categoryOptions}
                                                    required={true}
                                                    value={categoryOptions.find(item =>
                                                        item.value === incomeData.incomeCategory
                                                    )}
                                                    onChange={val => setIncomeData({
                                                        ...incomeData, incomeCategory: val
                                                    })}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className={styles.Label}>Месяц дохода*</label>
                                                <DatePicker
                                                    className={`${styles.DatePicker} inputField`}
                                                    locale={ru}
                                                    selected={incomeData.date}
                                                    showMonthYearPicker
                                                    dateFormat="MM.yy"
                                                    onChange={onDataPickerChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6 pr-0">
                                                <label className={styles.Label}>Сумма по доходу*</label>
                                                <input
                                                    type="number"
                                                    className={styles.Input}
                                                    value={incomeData.amount}
                                                    onChange={(e) => setIncomeData({
                                                        ...incomeData, amount: e.target.value
                                                    })}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <SelectUi
                                                    label={'Валюта*'}
                                                    placeholder={'Выбрать...'}
                                                    options={CurrencyByFilter}
                                                    required={true}
                                                    value={CurrencyByFilter.find(item =>
                                                        item.value === incomeData.currency
                                                    )}
                                                    onChange={val => setIncomeData({
                                                        ...incomeData, currency: val
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppContent>
    );
};

export default CreateIncome;
