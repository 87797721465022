import $api from '../core/api';
import {
    setCurrentOffer,
    setOfferErrors,
    setOfferPageItems,
    setOfferSingle
} from "../reducers/offerReduser";
import {processError} from "../core/helpers";
import {removeModal} from "../reducers/appReducer";
import { delayByLoader } from '../utils/deleyByLoader';

export const searchOfferId = (params) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/offers/search`, params);
            if(response.data){
                dispatch(setOfferSingle(response.data));
            }

        } catch (e) {
            dispatch(setOfferSingle(''));
            processError(dispatch, e);
        }
    }
}

export const fetchOffers = (params) => {
    return async dispatch => {

        try {
            const response = await $api.post(`/api/offers`, params);
            dispatch(setOfferPageItems(response.data));
            return {success: true};

        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const updateOfferPayment = ( updateData ) => {
    return async dispatch => {
        try {
            dispatch( setOfferErrors(false) );
            const response = await $api.post(`/api/offers/updateofferpayment`, updateData);

            if (response.data && response.data.success) {
                dispatch(setCurrentOffer(response.data.offer));
                dispatch(removeModal('editPaymentModal'));
            } else {
                if ( response.data.errors.length > 0 ) {
                    dispatch( setOfferErrors(response.data.errors) );
                }
            }
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const fetchOfferDataById = ( offerId ,setLoading) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/offers/${offerId}`, {withCredentials: true});
            if ( response.data && response.data.success ) {
                dispatch( setCurrentOffer( response.data.offer ) );
            }
        } catch (e) {
            processError(dispatch, e);
        }finally{
          delayByLoader(false,setLoading,600)
        }
    }
}

export const fetchOfferHistory = ( offerId ) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/offers/history/${offerId}`, {withCredentials: true});
            if (response.data && response.data.success) {
                return response.data.history;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getOffersList = ( params ) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/offers/get-list`, {
                params: params
            });
            if (response.data && response.data.success) {
                return response.data.offers;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getOffersCountryList = () => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/offers/get-offers-country-list`);
            if (response.data && response.data.success) {
                return response.data.countries;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const getOffersCategoryList = () => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/offers/get-offers-category-list`);
            if (response.data && response.data.success) {
                return response.data.categories;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }
    }
}
