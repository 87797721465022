import React from 'react';
import styles from "./AdvertisersContentLi.module.css";
import { getOptionsLabel } from "../../../../../core/helpers";
import { OptionsAdvertiserEntityType } from "../../../../../core/config";
import { NavLink } from "react-router-dom";
import DetailsIcon from '../../../../../assets/img/details-icon.png'

const AdvertisersContentLi = ({ item }) => {
    return (
        <li className={`${styles.Item} row`}>
            <div className={`${styles.Col} ${styles.Name}`}>{item.email ? item.email : <span className={styles.Dash}>-</span>}</div>
            <div className={`${styles.Col} ${styles.Id}`}>{item.title ? item.title : <span className={styles.Dash}>-</span>}</div>
            <div className={`${styles.Col} ${styles.Manager} `}>
                {item.manager.firstName || item.manager.lastName ? `${item.manager.firstName} ${item.manager.lastName}`:
                <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Col} ${styles.Site} `}> 
                {getOptionsLabel(OptionsAdvertiserEntityType, item.entity) ? getOptionsLabel(OptionsAdvertiserEntityType, item.entity): <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Col} ${styles.Offers} `}>{item.offers ? item.offers.length : 0}</div>
            <div className={`${styles.Col} ${styles.Country} `}>
                {item.country ? item.country:
                <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Col} ${styles.Status} `}>
                {item?.archive ? <span className={styles.archive}>В архиве</span>:
                <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Td} ${styles.Btn}`}><NavLink to={`/advertisers/${item.id}`}><img src={DetailsIcon} alt="" /></NavLink></div>
        </li>
    );
};

export default AdvertisersContentLi;
