/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import './User.css';
import styles from './User.module.css';
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, fetchUser, deleteUserById, updateUser } from "../../../actions/user";
import AppContent from "../../appContent/AppContent";
import { NavLink, useHistory, useParams } from "react-router-dom";
import Button from "../../ui/button_v2/ButtonV2";
import UsersContent from "../users/usersContent/UsersContent";
import EditForm from "./editForm/EditForm";
import AddIcon from "../../ui/icons/AddIcon";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import DragnDrop from "./DragnDrop/DragnDrop";
import { getModuleRule, isEdit, isRead, MODULE_TYPE } from "../../../core/helpers";
import { Redirect } from 'react-router-dom';
import { deleteUser } from "../../../reducers/userReducer";
import { addModal, removeModal } from "../../../reducers/appReducer";
import { delayByLoader } from '../../../utils/deleyByLoader';
import { RulesData } from '../../../core/config';

const ConfirmModal = lazy(() => import('../../modals/confirmModal/ConfirmModal'));

const User = () => {
    let { userId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const users = useSelector(state => state.user.items);
    const user = useSelector(state => state.user.item);
    const currentUser = useSelector(state => state.user.currentUser);
    const userErrors = useSelector(state => state.user.errors);
    const [tab, setTabs] = useState('InfoTab');
    const [userData, setUserData] = useState({
        firstName: { errors: false, value: '' },
        lastName: { errors: false, value: '' },
        email: { errors: false, value: '' },
        phone: { errors: false, value: '' },
        password: { errors: false, value: '' },
        partnersIds: { errors: false, value: [] },
        capability: '2'
    });
    const [loading, setLoading] = useState(true);
    const [rightBlockLoader, setRightBlockLOader] = useState(false);
    const rule = getModuleRule(currentUser.rules, MODULE_TYPE.USERS);
    const [formClicked, setFormClicked] = useState(false);

    useEffect(() => {
        delayByLoader(true, setRightBlockLOader, 300);
    }, [userId])

    useEffect(() => {
        dispatch(fetchUsers(setLoading));
        if (userId) {
            dispatch(fetchUser(userId));
        }
    }, [dispatch, userId, users.length]);

    useEffect(() => {
        if (userData.capability === 1) {
            setUserData({ ...userData, rules: RulesData.default.admin });
        } else if (userData.capability === 2) {
            setUserData({ ...userData, rules: RulesData.default.manager });
        } else if (userData.capability === 3) {
            setUserData({ ...userData, rules: RulesData.default.guest });
        }
    }, [formClicked]);

    useEffect(() => {
        if (user) {
            setUserData({
                firstName: { errors: false, value: user.firstName },
                lastName: { errors: false, value: user.lastName },
                email: { errors: false, value: user.email },
                phone: { errors: false, value: user.phone },
                password: { errors: false, value: user.password },
                partnersIds: { errors: false, value: user.partnersIds || [] },
                rules: user.rules,
                capability: user.capability
            });
        }
    }, [user]);

    useEffect(() => {
        return () => {
            setUserData(null);
            setTabs(null);
            setLoading(null);
        };
    }, []);

    useEffect(() => {
        if (userErrors) {
            let theFields = { ...userData };
            userErrors.map((error) => {
                if (error.param in userData) {
                    theFields[error.param] = {
                        value: userData[error.param].value,
                        error: error.msg
                    };
                }
                return error;
            });
            setUserData({ ...theFields });
        }
        // eslint-disable-next-line
    }, [userErrors]);

    if (!isRead(rule)) {
        return (
            <Redirect to='/' />
        );
    }

    const deleteCurrentUser = () => {
        setLoading(true);
        dispatch(removeModal('confirmActionModal'));
        dispatch(deleteUserById(userId))
            .then(result => {
                if (result) {
                    history.push('/users');
                    dispatch(deleteUser(userId));
                }
            });
        setLoading(false);
    };

    const showDeleteConfirm = () => {
        dispatch(addModal({
            id: 'confirmActionModal',
            size: 'middle',
            children:
                <Suspense fallback={<ContentLoader />}>
                    {ConfirmModal &&
                        <ConfirmModal
                            onError={() => {
                                dispatch(removeModal('confirmActionModal'))
                            }}
                            title={'Подтверждение удаления пользователя'}
                            message={'Вы уверены, что хотите удалить пользователя?'}
                            foo={deleteCurrentUser}
                        />
                    }
                </Suspense>
        }));
    };

    const onUpdateHandler = () => {
        let partnersIds = userData.partnersIds?.value;
        if (partnersIds && !Array.isArray(partnersIds)) {
            partnersIds = partnersIds.split(',');
        }
        const body = {
            id: user.id,
            firstName: userData.firstName.value,
            lastName: userData.lastName.value,
            email: userData.email.value,
            phone: userData.phone.value,
            password: userData.password.value,
            rules: userData.rules,
            capability: userData.capability,
            partnersIds: partnersIds || []
        };

        dispatch(updateUser(body, setLoading));
    };

    return (
        <AppContent>
            {loading ? <ContentLoader /> : (
                <div className="container wrapper">
                    <div className="row">
                        <div className={`${styles.LeftBlock} col-4`}>
                            <UsersContent users={users} current={userId} />
                        </div>
                        <div className={`${styles.RightBlock} col-8`}>
                            {
                                rightBlockLoader && <ContentLoader />
                            }
                            <div className={styles.BtnSection}>
                                {isEdit(rule) && <NavLink to="/users/create" className="add"><AddIcon /></NavLink>}
                            </div>
                            <div className={styles.UserContentBlock}>
                                <div className={`${styles.ControlPanel} row`}>
                                    <div className={`${styles.TabBlock} col-7`}>
                                        <Button className={(tab === 'InfoTab') ? 'tab active' : 'tab'}
                                            onClick={async () => {
                                                setTabs('InfoTab');
                                            }}>Профиль пользователя</Button>

                                        {isEdit(rule) && user?.capability !== 3 &&
                                            <Button className={(tab === 'AccessTab') ? 'tab active' : 'tab'}
                                                onClick={() => {
                                                    setTabs('AccessTab');
                                                }}>Права доступа
                                            </Button>
                                        }
                                    </div>
                                    <div className={`${styles.BtnBlock} col-5`}>
                                        {isEdit(rule) &&
                                            <Button type={'primary'} onClick={onUpdateHandler}
                                                className='save'>
                                                Сохранить
                                            </Button>}
                                        <Button className={styles.DeleteBtn} type={'danger'} onClick={showDeleteConfirm}>Удалить</Button>
                                    </div>
                                </div>
                                <div className={styles.TitleBlock}>
                                    <h1>{user.firstName} {user.lastName}</h1>
                                </div>
                                <div
                                    className={(tab === 'InfoTab') ? ` ${styles.UserFieldBlock} ${styles.Active}` : `${styles.UserFieldBlock}`}>
                                    {userData && (<EditForm
                                        user={userData}
                                        rule={rule}
                                        formClicked={formClicked}
                                        setFormClicked={setFormClicked}
                                        onChange={(data) => {
                                            setUserData({ ...data });
                                        }} />)}
                                </div>
                                <div
                                    className={(tab === 'AccessTab') ? ` ${styles.UserFieldBlock} ${styles.Active}` : `${styles.UserFieldBlock}`}>
                                    <DragnDrop userData={userData} setUserData={(data) => {
                                        setUserData({ ...data });
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppContent>
    );
};

export default User;
