/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import AppContent from '../../appContent/AppContent';
import styles from './KnowledgeEdit.module.css'
import ContentLoader from '../../ui/contentLoader/ContentLoader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArticlesById, fetchKnowledge, fetchKnowledgeById } from '../../../actions/knowledge';
import KnowledgeBaseSideBar from '../knowledgeBaseDetailPage/knowledgeBaseSideBar/KnowledgeBaseSideBar';
import { MODULE_TYPE, getModuleRule, isEdit } from '../../../core/helpers';
import ArticleEditor from './articleEditor/ArticleEditor';

const KnowledgeEdit = ({ match }) => {
    const dispatch = useDispatch();
    const id = match.params.id;
    const [loading, setLoading] = useState(true);

    const currentArticleData = useSelector(state => state.knowledge.currentArticleData);
    const currentUser = useSelector(state => state.user.currentUser);
    const rule = getModuleRule(currentUser.rules, MODULE_TYPE.KNOWLEDGE);

    useEffect(() => {
        currentArticleData?.sectionId && dispatch(fetchKnowledgeById(currentArticleData?.sectionId));
    }, [currentArticleData]);

    useEffect(() => {
        dispatch(fetchKnowledge(setLoading));
    }, []);

    useEffect(() => {
        id && dispatch(fetchArticlesById(id));
    }, [id]);


    return (
        <AppContent>
            <div className={`partnersContainer container`}>
                {loading ? (
                    <ContentLoader />
                ) : (
                    <div className={styles.wrapperContainer}>
                        <KnowledgeBaseSideBar />
                        <ArticleEditor data={currentArticleData} setLoading={setLoading} isEdit={isEdit(rule)} articleId={id} />
                    </div>
                )}
            </div>
        </AppContent>
    )
}

export default KnowledgeEdit