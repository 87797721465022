/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppContent from "../../appContent/AppContent";
import styles from "./KnowledgeCreate.module.css";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import KnowledgeBaseSideBar from "../knowledgeBaseDetailPage/knowledgeBaseSideBar/KnowledgeBaseSideBar";
import AddSectionEditor from "../knowledgeBasePage/moderation/section/addSectionEditor/AddSectionEditor";
import { useDispatch } from "react-redux";
import { fetchKnowledge } from "../../../actions/knowledge";

const KnowledgeCreate = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchKnowledge(setLoading));
    }, []);

    return (
        <AppContent>
            <div className={`container ${styles.container}`}>
                {loading ? (
                    <ContentLoader />
                ) : (
                    <div className={styles.wrapperContainer}>
                        <KnowledgeBaseSideBar />
                        <div style={{ paddingLeft: "36px", overflow: "hidden" }}>
                            <AddSectionEditor setLoading={setLoading} isNew={true} />
                        </div>
                    </div>
                )}
            </div>
        </AppContent>
    );
};

export default KnowledgeCreate;
