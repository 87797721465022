import React from 'react';
import parentStyles from "../../../../Reports.module.css";
import {formatAmountByTriplets} from "../../../../../../../core/helpers";
import {useSelector} from "react-redux";

const ReportCurrencyColumn = ({month, data}) => {
    const collapsedIncome = useSelector(state => state.reports.collapsedIncomeColumns).includes(month);
    const collapsedExpenses = useSelector(state => state.reports.collapsedExpensesColumns).includes(month);
    const collapsedMonth = useSelector(state => state.reports.collapsedMonths).includes(month);

    return (
        <div
            className={`${parentStyles.reportColumn} ${collapsedMonth
                ? parentStyles.hiddenColumn: ''} ${collapsedIncome
                ? parentStyles.hiddenIncome : ''} ${collapsedExpenses
                ? parentStyles.hiddenExpenses : ''}`}
        >
            {Array.isArray(data) && data?.length ?
                data.map((el, index) => <div className="d-flex" key={index}>
                            <div className={`${parentStyles.currencyAmount} ${collapsedIncome ? 'd-none' : ''}`}>{formatAmountByTriplets(el?.income)}</div>
                            <div className={parentStyles.convertedAmount}>{formatAmountByTriplets(el?.currency === 'RUB' ? el?.income : el?.incomeRUR)}</div>

                            <div className={`${parentStyles.currencyAmount} ${collapsedExpenses ? 'd-none' : ''}`}>{formatAmountByTriplets(el?.expenses)}</div>
                            <div className={parentStyles.convertedAmount}>{formatAmountByTriplets(el?.currency === 'RUB' ? el?.expenses : el?.expensesRUR)}</div>

                            <div className={parentStyles.marginIncomeAmount}></div>
                        </div>
                ): 
                <div className="d-flex">
                    <div className={`${parentStyles.currencyAmount} ${collapsedIncome ? 'd-none' : ''}`}>{formatAmountByTriplets(data?.income)}</div>
                    <div className={parentStyles.convertedAmount}>{formatAmountByTriplets(data?.currency === 'RUB' ? data?.income : data?.incomeRUR)}</div>

                    {/* <div className={`${parentStyles.currencyAmount} ${collapsedExpenses ? 'd-none' : ''}`}>{formatAmountByTriplets(data.expenses)}</div>
                    <div className={parentStyles.convertedAmount}>{formatAmountByTriplets(data.expensesRUR)}</div> */}
                    <div className={`${parentStyles.currencyAmount} ${collapsedExpenses ? 'd-none' : ''}`}>-</div>
                    <div className={parentStyles.convertedAmount}>-</div>

                    <div className={parentStyles.marginIncomeAmount}>-</div>
                </div>
            }
        </div>
    );
};

export default ReportCurrencyColumn;
