import React, { useState } from 'react';
import styles from './InpuRadio.module.css';
import InputError from "../inputError/InputError";

const InputRadio = ({ value, name, check, label, id, setValue, disabled, error, type, active, ...props }) => {

    const [checked, setChecked] = useState(value);
    let btnClasses = props.className ? `${props.className} ` : '';

    const checkboxClick = () => {
        setChecked(checked);
        setValue(checked);

    };
    switch (type) {
        case 'green':
            if (check) {
                btnClasses += `${styles.InputRadio} ${styles.Green} ${styles.Active}`;
            } else {
                btnClasses += `${styles.InputRadio} ${styles.Green}`;
            }
            break;
        default:
            btnClasses += `${styles.InputRadio}`;
            break;
    }


    return (
        <div className={btnClasses}>
            <input
                type='radio'
                name={name}
                value={value}
                id={id}
                checked={check}
                onChange={(e) => {
                    checkboxClick(!checked)
                }}
                disabled={disabled}
            />
            <label htmlFor={id}>{label}</label>
            {error && <InputError error={error} />}
        </div>
    );
};

export default InputRadio;