import React, {useEffect, useRef, useState} from 'react';
import styles from "./ConversionsCSV.module.css";
import {importCsvData, deleteImportedCsv} from "../../../actions/conversions";
import AppContent from "../../appContent/AppContent";
import Button from "../../ui/button_v2/ButtonV2";
import {useDispatch} from "react-redux";
import {createNotification} from "../../../actions/app";
import InputRadio from "../../ui/input/InputRadio/InputRadio";
import imgConvTable from "../../../assets/img/img-table-conversion.png";
import imgClickTable from "../../../assets/img/img-table-click.png";
import ContentLoader from '../../ui/contentLoader/ContentLoader';
import { delayByLoader } from '../../../utils/deleyByLoader';

const ConversionsCSV = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [result, setResult] = useState({});
    const [idType, setIdType] = useState('action');
    const [delimiter, setDelimiter] = useState(',');
    const inputRef = useRef(null);

    useEffect(() => {
      delayByLoader(true,setLoading,200)
    }, []);

    
    const csvTableExample = idType === 'action' ? imgConvTable : imgClickTable;

    useEffect(() => {
        if ( result.filepath ) {
            setFile( result.filepath );
        }
    }, [result.filepath] );

    const importCSV = async ( confirm = false ) => {
        if ( !confirm ) {
            inputRef.current.value = null;
        }
        if ( !file ) {
            dispatch( createNotification({
                type: 'primary',
                children: 'Загрузите CSV файл'
            }));
            return;
        }
        const response = await importCsvData( dispatch, file, idType, delimiter, confirm );
        if ( response ) {
            setResult( response );
        }
    };

    const cancelImport = async () => {
        await deleteImportedCsv( dispatch, file );
        setResult({});
        setFile(null);
    };

    const updateFileData = (e) => {
        setFile( e.target.files[0] );
        setResult({});
    };

    const handleUploadClick = () => {
        inputRef.current?.click();
    };

    return (
        <AppContent>
          <div className='wrapper'>
            {loading ? <ContentLoader /> :  <div className={`${styles.ConversionsCSV} container px-3 wrapper`}>
                <div className="row">
                    <div className="col-xl-7">
                        <h2 className={styles.title}>Обновление конверсий CSV файлом</h2>
                        {!result.filepath &&
                            <>
                                <h5>Выберите тип:</h5>
                                <div className={styles.RadioBlock}>
                                    <InputRadio
                                        id={'actionId'}
                                        value={'action'}
                                        check={idType === 'action'}
                                        label={'По ID конверсии'}
                                        setValue={(val) => setIdType(val)}
                                    />
                                    <InputRadio
                                        id={'clickId'}
                                        value={'click'}
                                        check={idType === 'click'}
                                        label={'По ID клика'}
                                        setValue={(val) => setIdType(val)}
                                    />
                                </div>

                                <h5 className="mt-5 mb-3">Выберете csv файл для импорта:</h5>
                                <div className={styles.ImportNotice}>
                                    Тип разделителей в CSV-файле:
                                    <div className={styles.RadioBlock}>
                                        <InputRadio
                                            id={'commaDlm'}
                                            value={','}
                                            check={delimiter === ','}
                                            label={'« , » (запятая)'}
                                            setValue={(val) => setDelimiter(val)}
                                        />
                                        <InputRadio
                                            id={'semicolonDlm'}
                                            value={';'}
                                            check={delimiter === ';'}
                                            label={'« ; » (точка с запятой)'}
                                            setValue={(val) => setDelimiter(val)}
                                        />
                                    </div>
                                </div>

                                <div className="my-3">
                                    <input
                                        ref={inputRef}
                                        onChange={ e => updateFileData(e) }
                                        type="file"
                                        id="upload"
                                        name="upload"
                                        accept=".csv"
                                        style={{display: 'none'}}
                                    />
                                    <div className="d-flex align-items-center mb-3">
                                        <Button type="secondary" onClick={handleUploadClick}>Выбрать файл</Button>
                                        <div className={`${styles.UploadFileName} ${ (file && file.name) && styles.Uploaded}`}>{file && file.name ? `${file.name}` : 'Файл не выбран'}</div>
                                    </div>
                                    <Button type='primary' onClick={() => importCSV( false ) } >Импорт CSV</Button>
                                </div>
                            </>
                        }
                        {result.preview &&
                            <>
                                <h4 className="mt-5">Проверьте правильность соответсвия столбцов импортируемой таблицы. Если есть какие то ошибки - проверьте исходную таблицу.</h4>
                                <div className="my-3">
                                    <div className={styles.PreviewTable} dangerouslySetInnerHTML={{__html: result.preview }} />
                                </div>
                                <div className={styles.ButtonBlock}>
                                    <Button type='primary' onClick={() => importCSV( true ) }>Подтвердить импорт</Button>
                                    <Button type='danger' onClick={cancelImport}>Отмена</Button>
                                </div>
                            </>
                        }
                    </div>
                    <div className="col-xl-5">
                        {!result.message &&
                            <>
                                <h4 className="mb-3">Пример корректного CSV файла</h4>
                                <img className={styles.TableImg} src={csvTableExample} alt=""/>

                                <h4 className="mt-5 mb-3">Значения статусов:</h4>
                                <div className="d-flex">
                                    <div className={styles.Legend}><span>1</span> - Approved</div>
                                    <div className={styles.Legend}><span>2</span> - Pending</div>
                                    <div className={styles.Legend}><span>3</span> - Declined</div>
                                    <div className={styles.Legend}><span>5</span> -  Hold</div>
                                </div>
                            </>
                        }
                    </div>
                </div>

                <div className={`${styles.ResultRow} row`}>
                    <div className="col-xl-6 offset-lg-1">
                        {result.message &&
                            <>
                                <h4 className="mt-5">Импорт завершен</h4>
                                <div className={styles.ResultMessage}>{result.message}</div>
                            </>
                        }
                    </div>
                </div>
            </div>}
          </div>           
        </AppContent>
    );
};

export default ConversionsCSV;
