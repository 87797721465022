import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { MODULE_TYPE, getModuleRule, isEdit } from '../../core/helpers';

const PrivateRoute = ({ auth, method, component: Component, ...rest }) => {
    const currentUser = useSelector(state => state.user.currentUser);
    const rule = getModuleRule(currentUser.rules, MODULE_TYPE.KNOWLEDGE);
    const RedirectPaths = ["/", "/knowledge/create", "/knowledge/article/edit"];

    const user = useSelector(state => state.user.currentUser);
    const history = useHistory();

    if (RedirectPaths.includes(rest.path) && user?.capability === 3 && !isEdit(rule)) {
        history.push('/knowledge');
    }

    return (
        <Route
            {...rest}
            render={props =>
                auth ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )

            }
        />
    );
};

export default PrivateRoute;