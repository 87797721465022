import React, { Suspense, useRef, useState } from "react";
import styles from "./AddSectionEditor.module.css";
import { useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import Input from "../../../../../ui/input/Input";
import Button from "../../../../../ui/button/Button";
import { createNotification } from "../../../../../../actions/app";
import {
    createKnowledgeSection,
    fetchKnowledge,
} from "../../../../../../actions/knowledge";
import { useHistory } from "react-router-dom";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";
import { addModal, closeModal, removeModal } from "../../../../../../reducers/appReducer";
import ArticleImageRemoveModal from "../../../../../modals/articleImageRemove/ArticleImageRemoveModal";

function getLast10Characters (inputString) {
    return inputString?.length > 15
        ? `...${inputString.slice(-15)}`
        : inputString;
}

const AddSectionEditor = ({ isNew = false }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [logo, setLogo] = useState("");
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(""))
        )
    );
    const [isImage, setIsImage] = useState();
    const inputRef = useRef(null);


    const onSetFileHandler = () => {
        setLogo(inputRef.current.files[0]);
        setIsImage(true);
    };

    const createSectionHandler = () => {
        const editorContentHtml = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );

        const logoFile = inputRef.current.files[0];
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", editorContentHtml);

        if (logoFile) {
            formData.append("logo", logoFile, logoFile.name);
        }


        if (!title.trim() || title.trim().length < 2 || title.trim().length > 50) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        "Название раздела должно быть не менее 2 и не более 50 символов",
                })
            );
        } else if (
            !editorState.getCurrentContent().getPlainText().trim() ||
            editorState.getCurrentContent().getPlainText().trim().length < 3 ||
            editorState.getCurrentContent().getPlainText().trim().length > 100
        ) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        "Описание раздела должно быть не менее 3 и не более 100 символов",
                })
            );
        } else {
            dispatch(createKnowledgeSection(formData, setLoading, onSuccess));
        }
    };

    const onSuccess = (data) => {
        dispatch(fetchKnowledge(setLoading));
        isNew && history.push(`/knowledge/section/${data._id}`);
    };

    const onArticleRemoveHandler = () => {
        dispatch(
            addModal({
                id: "articleImageRemoveModal",
                size: "bigger",
                children: (
                    <Suspense fallback={<ContentLoader />}>
                        {ArticleImageRemoveModal && (
                            <ArticleImageRemoveModal
                                type="article"
                                onRemove={onRemove}
                                onError={() => {
                                    dispatch(removeModal("articleImageRemoveModal"));
                                }}
                            />
                        )}
                    </Suspense>
                ),
            })
        );
    }

    const onRemove = () => {
        inputRef.current.value = '';
        setLogo('');
        setIsImage((prev) => (prev = false));
        dispatch(closeModal('articleImageRemoveModal'));
    }

    return (
        <>
            {loading ? (
                <ContentLoader />
            ) : (
                <div className={`${styles.container} row`}>
                    <div className="col-12">
                        <h1 className={`${styles.title}`}>Новый раздел</h1>
                    </div>
                    <div className="col-6">
                        <div className={styles.inputBlock}>
                            <span className={styles.inputTitle}>Название</span>
                            <Input
                                style={{
                                    border: "1px solid #383838",
                                    height: "38px",
                                    backgroundColor: "transparent",
                                }}
                                placeholder="Введите название раздела"
                                setValue={setTitle}
                                value={title}
                            />
                        </div>
                    </div>
                    <div className={`${styles.fileInputCol} col-6`}>
                        <div className={styles.fileInputBlock}>
                            <label
                                className={styles.file_input_label}
                                htmlFor="file-input-logo"
                            >
                                <span>Добавить фото</span>
                                <input
                                    ref={inputRef}
                                    accept="image/*"
                                    id="file-input-logo"
                                    type="file"
                                    name="file"
                                    onChange={onSetFileHandler}
                                    className={styles.fileInput}
                                />
                            </label>
                            <div className={styles.removeZone} onClick={onArticleRemoveHandler}>
                                {isImage && <span className={`${styles.fileName} ${logo.name ? styles.fileNameColor : ""}`}>
                                    {getLast10Characters(logo.name || "Фото не выбрано")}
                                </span>}
                                {isImage && <div className={styles.removeIcon}></div>}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${styles.textEditorBlock} col-12 sectionTextEditor`}
                        style={{ width: "100%" }}
                    >
                        <h5 className={styles.textEditorTitle}>
                            Описание раздела (до 100 символов)
                        </h5>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={(newEditorState) => {
                                setEditorState(newEditorState);
                            }}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                        />
                    </div>
                    <div className={`${styles.updateSection} col-12`}>
                        <Button
                            className={styles.viewBtn}
                            onClick={() => history.push('/knowledge')}
                        >
                            отменить
                        </Button>
                        <Button
                            className={styles.updateSectionBtn}
                            onClick={createSectionHandler}
                        >
                            сохранить
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddSectionEditor;
