import React from "react";
import styles from "./AddNewSection.module.css";
import { NavLink } from "react-router-dom";

const AddNewSection = () => {
  return (
    <div className={`${styles.wrapperContainer} col-3`} draggable={false}>
      <NavLink to="/knowledge/create" className={styles.container}></NavLink>
    </div>
  );
};

export default AddNewSection;
