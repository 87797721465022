import React from "react";
import "./SelectUi.css";
import Select from "react-select";
import CloseIcon from '../../../assets/img/comment-remove-icon.png';
import styles from './SelectUiWiki.module.css';



const NoOptionsMessage = () => {
    return (
        <div className={styles.msgStyles}>
            <span className="custom-css-class">Статей не найдено</span>
        </div>
    );
};


const SelectUiWiki = ({
    options,
    defaultValue,
    onChange,
    placeholder,
    value,
    label = true,
    classes = "",
    isSearchable,
    isClearable = false,
    onClearParentArticles,
    customKey
}) => {
    const customFilter = (option, inputValue) => {
        if (!inputValue) {
            return true;
        }
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    const handleClear = () => {
        onClearParentArticles?.();
    };

    return (
        <div >
            {label && <div className="label">{label}</div>}
            <div className={styles.wrapper}>
                <Select
                    key={customKey}
                    options={options}
                    defaultValue={defaultValue}
                    onChange={(val) => {
                        onChange(val?.value);
                    }}
                    components={{ NoOptionsMessage }}
                    filterOption={isSearchable ? customFilter : null}
                    placeholder={placeholder}
                    isSearchable={isSearchable || false}
                    className={`selectModal ${classes}`}
                    classNamePrefix="selPref"
                    value={value}

                />
                {isClearable && value && Object.keys(value)?.length > 0 && <img className={styles.icon} src={CloseIcon} alt="" onClick={(e) => handleClear(e)} />}
            </div>

        </div>
    );
};

export default SelectUiWiki;
