import React, { useEffect, useState } from "react";
import styles from "./RecoverPasswordPage.module.css";
import AppContent from "../../appContent/AppContent";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import Logo from "../../../assets/img/header-logo.png";
import InputLabel from "../../ui/input/inputLabel/InputLabel";
import Button from "../../ui/button/Button";
import { recoverPassword } from "../../../actions/user";
import { delayByLoader } from "../../../utils/deleyByLoader";

const RecoverPasswordPage = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);
  const userErrors = useSelector((state) => state.user.errors);
  const [loading, setLoading] = useState(true);
  const [isSend, setIsSend] = useState(false);

  const [authData, setAuthData] = useState({
    email: { value: "", errors: false },
  });

  useEffect(() => {
    delayByLoader(true, setLoading, 300);
  }, []);

  useEffect(() => {
    if (userErrors) {
      let theFields = { ...authData };
      userErrors.map((error) => {
        if (error.param in authData) {
          theFields[error.param] = {
            value: authData[error.param].value,
            error: error.msg,
          };
        }
        return error;
      });
      setAuthData({ ...theFields });
    }
    // eslint-disable-next-line
  }, [userErrors]);

  const onRecoverHandler = () => {
    dispatch(recoverPassword(authData.email.value,setLoading, setIsSend));
  };

  if (isAuth) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <AppContent paddingReset>
      <div className={styles.container}>
        <div className={styles.content}>
          {loading && <ContentLoader />}
          <div className={styles.logo}>
            <img src={Logo} alt="" />
          </div>
          {!isSend && (
            <>
              <div className={styles.inputs}>
                <InputLabel
                  type="email"
                  value={authData.email.value}
                  placeholder="E-mail"
                  setValue={(val) => {
                    setAuthData({
                      ...authData,
                      email: { value: val, error: false },
                    });
                  }}
                  className={styles.email_input}
                />
                {authData.email.error && (
                  <span className={styles.error_text}>
                    {authData.email.error}
                  </span>
                )}

                <Button className={styles.login_btn} onClick={onRecoverHandler}>
                  далее
                </Button>
              </div>
            </>
          )}
        
        </div>
          {
            isSend && <p className={styles.is_send_text}>
              Ссылка для восстановления пароля отправлена на указанную почту.
            </p>
          }
      </div>
    </AppContent>
  );
};

export default RecoverPasswordPage;
