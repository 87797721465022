import React from 'react';
import styles from "../../SingleBilling.module.css";
import {FilterStatusOptions} from "../../../../../../core/config";
import {format} from "date-fns";

const HistoryItem = ({history}) => {
    const billingStatusObj = FilterStatusOptions.find( status => status.value === history.status );

    return (
        <div className={styles.InfoBlock}>
            <div className="row">
                <div className="col-2 d-flex align-items-center">
                    {billingStatusObj
                        ? billingStatusObj.label
                        : <div className={styles.ErrorLabel}>ошибка</div>
                    }
                </div>
                <div className={`col-7 ${history.error ? styles.Red : ''}`}>{history.message}</div>
                <div className="col-3">{format(new Date(history.date), "dd.MM.yyyy HH:mm:ss")}</div>
            </div>
        </div>
    );
};

export default HistoryItem;
