/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./MultiSelectV3.module.css";
import SelectCheckboxOption from "./selectCheckboxOption/SelectCheckboxOption";
import CustomValueContainer from "./customValueContainer/CustomValueContainer";

const MultiSelectV3 = ({ className, placeholder, allOptions, onChange, onClick, initial, refresh, onInputChange, inputValue }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
        onChange(selected.map((item) => item.value));
    };

    useEffect(() => {
        if (initial && allOptions.length > 0) {
            const filtered = allOptions.filter(item => initial.includes(item.value));
            setSelectedOptions( filtered );
            onChange(filtered.map((item) => item.value));
        }
    }, [allOptions, refresh]);

    return (
        <div className={`${styles.MultiSelect} ${className ? className : ''}`}>
            <Select
                className="uiSelect"
                classNamePrefix="uiSelect"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={handleSelectChange}
                options={allOptions}
                components={{
                    Option: SelectCheckboxOption,
                    MultiValue: CustomValueContainer,
                }}
                onMenuOpen={onClick}
                placeholder={placeholder}
                value={selectedOptions}
                onInputChange={onInputChange}
                inputValue={inputValue}
            />
        </div>
    );
};

export default MultiSelectV3;
