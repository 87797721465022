import React from 'react';
import {useSelector} from "react-redux";
import Modal from "./modal/Modal";

const Modals = () => {
    const modals = useSelector(state=>state.app.modals);

    const theModals = modals.map((modal)=>{
        return (
            <Modal key={modal.id} {...modal}>
                {modal.children}
            </Modal>
        );
    });

    return (
        <div id='modalWrapper'>
            {theModals}
        </div>
    );
};

export default Modals;