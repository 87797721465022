const SET_BILLS_LIST = 'SET_BILLS_LIST';
const SET_BILL_ERRORS = 'SET_BILLS_ERRORS';

const defaultState = {
    billsList: [],
    errors: false
}

export default function billsReducer(state = defaultState, action){
    switch (action.type) {
        case SET_BILLS_LIST:
            return {
                ...state,
                billsList:  action.payload
            };
        case SET_BILL_ERRORS:
            return {
                ...state,
                errors:  action.payload
            };
        default:
            return state
    }
}

export const setBillsList = ( items ) => ({type: SET_BILLS_LIST, payload: items});
export const setBillErrors = ( items ) => ({type: SET_BILL_ERRORS, payload: items});
