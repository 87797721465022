import React, {useEffect, useState} from 'react';
import styles from './ReportProjectRow.module.css';
import {ReportCurrencyRow, ReportCurrencyRowExpenses, ReportCurrencyRowIncome} from "../reportCurrencyRow/ReportCurrencyRow";
import parentStyles from "../../../Reports.module.css";
import {useSelector} from "react-redux";
import TotalAmountsCols from "../totalAmountsCols/TotalAmountsCols";

const ReportProjectRow = ({projectData, monthsArr, projectIncome, projectExpenses}) => {
    const project = Object.keys(projectData)[0];
    const monthsCurrencyRates = useSelector(state => state.reports.monthsCurrencyRates);
    const [projectAmounts, setProjectAmounts] = useState(null);
    const [currencyRowsOffers, setCurrencyRowsOffers] = useState([]);
    const [currencyRowsIncome, setCurrencyRowsIncome] = useState([]);
    const [currencyRowsExpenses, setCurrencyRowsExpenses] = useState([]);
    const [incomeNames, setIncomeNames] = useState([]);
    const [expensesNames, setExpensesNames] = useState([]);
    const [projectTotalsColumns, setProjectTotalsColumns] = useState([]);
    const [displayOffers, setDisplayOffers] = useState(false);
    const [displayCurrencyOffers, setDisplayCurrencyOffers] = useState(false);
    const [displayCurrencyIncome, setDisplayCurrencyIncome] = useState(false);
    const [displayCurrencyExpenses, setDisplayCurrencyExpenses] = useState(false);

    useEffect(() => {
        if (Object.keys(monthsCurrencyRates).length > 0) {
            const totalAmounts = projectData[project].projectTotalAmounts;
            const currencyDataArr = projectData[project].rateRowData || [];
            const currencyRowArr = currencyDataArr.map((currencyData, index) => {
                const currency = Object.keys(currencyData)[0];
                const currencyRowData = currencyData[currency];
                return <ReportCurrencyRow
                    currency={currency}
                    projectIncome={projectIncome}
                    currencyRowData={currencyRowData}
                    monthsArr={monthsArr}
                    key={index}
                />
            });
            setCurrencyRowsOffers(currencyRowArr);
            setProjectAmounts(totalAmounts);
        }
        // eslint-disable-next-line
    }, [projectData, monthsArr, monthsCurrencyRates]);

    useEffect(() => {
        if (Object.keys(monthsCurrencyRates).length > 0) {
        const currencyArr = projectExpenses ? Object.keys(projectExpenses) : []
        // eslint-disable-next-line array-callback-return
        const expensesCurrencyRowArr = currencyArr?.map((currency, index) => {
                let arr = [];
                const currencyRowData = projectExpenses ? projectExpenses[currency] : '';
                if (currencyRowData) {
                    // eslint-disable-next-line array-callback-return
                    monthsArr.map((date) => {
                        let columnData = currencyRowData[date.year][date.month]
                            if (columnData && columnData.length) {
                                for (let i = 0; i < columnData.length; i++) {
                                    if (arr && arr.length > 0) {
                                            // if (!arr.includes(`${columnData[i].name}_${currency}`)) {
                                            //     arr.push(`${columnData[i].name}_${currency}`)
                                            //     setExpensesNames((expensesNames) => [...expensesNames, columnData[i].name])
                                            // }
                                            if (arr.some(el => el === `${columnData[i].name}_${currency}`)) {
                                                //
                                            } else {
                                                arr.push(`${columnData[i].name}_${currency}`)
                                                setExpensesNames((expensesNames) => [...expensesNames, columnData[i].name])
                                            }
                                    } else {
                                        arr.push(`${columnData[i].name}_${currency}`)
                                        setExpensesNames((expensesNames) => [...expensesNames, columnData[i].name])
                                    }   
                                }
                            }
                    });
                    return <ReportCurrencyRowExpenses
                        currency={currency}
                        currencyRowData={currencyRowData}
                        monthsArr={monthsArr}
                        key={index}
                        display={displayCurrencyExpenses}
                        expensesNames={expensesNames}
                        arr={arr}
                    />
                } 
            });
            if (expensesCurrencyRowArr.length) {
                setCurrencyRowsExpenses(expensesCurrencyRowArr)
            }
        }    
        // eslint-disable-next-line
    }, [projectData, monthsArr, monthsCurrencyRates]);

    useEffect(() => {
        if (Object.keys(monthsCurrencyRates).length > 0) {
        const currencyArr = projectIncome ? Object.keys(projectIncome) : []
        // eslint-disable-next-line array-callback-return
        const incomeCurrencyRowArr = currencyArr.map((currency, index) => {
                let arr = [];
                const currencyRowData = projectIncome ? projectIncome[currency] : '';
                if (currencyRowData) {
                    // eslint-disable-next-line array-callback-return
                    monthsArr.map((date) => {
                        let columnData = currencyRowData[date.year][date.month]
                            if (columnData && columnData.length) {
                                for (let i = 0; i < columnData.length; i++) {
                                        if (arr && arr.length > 0) {
                                                // if (!arr.includes(`${columnData[i].name}_${currency}`)) {
                                                //     arr.push(`${columnData[i].name}_${currency}`)
                                                //     setIncomeNames((incomeNames) => [...incomeNames, columnData[i].name])
                                                // }
                                                if (arr.some(el => el === `${columnData[i].name}_${currency}`)) {
                                                //
                                                } else {
                                                    arr.push(`${columnData[i].name}_${currency}`)
                                                    setIncomeNames((incomeNames) => [...incomeNames, columnData[i].name])
                                                }
                                        } else {
                                            arr.push(`${columnData[i].name}_${currency}`)
                                            setIncomeNames((incomeNames) => [...incomeNames, columnData[i].name])
                                        }   
                                    }
                        }
                    });
                    return <ReportCurrencyRowIncome
                        currency={currency}
                        currencyRowData={currencyRowData}
                        monthsArr={monthsArr}
                        key={index}
                        display={displayCurrencyIncome}
                        arr={arr}
                    />
                } 
            });
            setCurrencyRowsIncome(incomeCurrencyRowArr);
        }    
        // eslint-disable-next-line
    }, [projectData, monthsArr, monthsCurrencyRates]);

    useEffect(() => {
        if (projectAmounts) {
            const totalsColumnsArr = monthsArr.map((date, index) => {
                const amountData = projectAmounts[date.year][date.month];
                return <TotalAmountsCols date={date} amountData={amountData} key={index}/>;
            });
            setProjectTotalsColumns(totalsColumnsArr);
        }
        // eslint-disable-next-line
    }, [projectAmounts]);

    return (
        <div className={styles.ReportProjectRow}>
            <div className="d-flex">
                <div className={parentStyles.firstColumn}>
                    <div className={styles.ProjectHeader}>
                        <div className={styles.SwitchBlock}>
                            <div className={styles.TableCell}>{project}</div>
                            <div className={`${styles.TableCell} ${parentStyles.collapseIcon}`} onClick={() => setDisplayOffers(!displayOffers)}>
                                <span className={parentStyles.lageFont}>{displayOffers ? <span className={styles.TableSignMinus}>-</span> : <span className={styles.TableSignPlus}>+</span>}</span>
                            </div>
                        </div>
                        <div className={`${parentStyles.totalCell} ${styles.bbGrey}`}>Total, RUR</div>
                    </div>
                </div>
                {projectTotalsColumns.length > 0 && projectTotalsColumns}
            </div>

            <div className="d-flex">
                <div className={displayOffers ? styles.Offers : 'd-none'}>
                    <span className={styles.TableOffersCell}>
                        <span className={styles.TableOffers}>Офферы Xpartners</span>
                        <span className={styles.TableCell} onClick={() => setDisplayCurrencyOffers(!displayCurrencyOffers)}>
                        {displayCurrencyOffers ? <span className={styles.TableSignMinusXPOffers}>-</span> :
                        <span className={styles.TableSignPlusOffers}>+</span>}
                        </span>
                    </span>
                    {displayCurrencyOffers && <span className={styles.CurrencyOffers}>
                        {currencyRowsOffers.length ? currencyRowsOffers:
                            <ReportCurrencyRow
                                monthsArr={monthsArr}
                                currencyRowData={''}
                                currency={''} 
                        />}
                    </span>}
                </div>
            </div>

            <div className={displayOffers ? styles.Offers : 'd-none'}>
                <span className={styles.TableOffersCell}>
                    <span className={styles.TableOffers}>Статьи Дохода:</span>
                    {/* <span className={styles.TableOffersBeforeSign}></span> */}

                    <span>
                        {displayCurrencyIncome && incomeNames?.length ? 
                        incomeNames.map((name, index) => <span className={styles.IncomeNames} key={index}>
                            {name}
                        </span>): <span className={styles.IncomeNames}></span>}
                    </span>
                        
                    <span className={styles.TableCell} onClick={() => setDisplayCurrencyIncome(!displayCurrencyIncome)}>
                            {displayCurrencyIncome ? <span className={styles.TableSignMinusIncome}>-</span> :
                        <span className={styles.TableSignPlusIncome}>+</span>}
                    </span>
                </span>

                {displayCurrencyIncome && <span className={styles.CurrencyOffers}>
                    {currencyRowsIncome.length ? currencyRowsIncome:
                    <ReportCurrencyRowIncome
                        monthsArr={monthsArr}
                        currencyRowData={''}
                        currency={''} 
                    />}
                </span>}
            </div>

            <div className={displayOffers ? styles.Offers : 'd-none'}>
                <span className={styles.TableOffersCell}>
                    <span className={styles.TableOffers}>Статьи Расхода:</span>

                    <span>
                        {displayCurrencyExpenses && expensesNames?.length ? 
                        expensesNames.map((name, index) => <span className={styles.ExpensesNames} key={index}>
                            {name}
                        </span>): <span className={styles.ExpensesNames}></span>}
                    </span>        

                    <span className={styles.TableCell} onClick={() => setDisplayCurrencyExpenses(!displayCurrencyExpenses)}>
                        {displayCurrencyExpenses ? <span className={styles.TableSignMinusOffers}>-</span> :
                        <span className={styles.TableSignPlusExpenses}>+</span>}
                    </span>
                </span>

                {displayCurrencyExpenses && <span className={styles.CurrencyOffers}>
                    {currencyRowsExpenses.length ? currencyRowsExpenses:
                    <ReportCurrencyRowExpenses
                        monthsArr={monthsArr}
                        currencyRowData={''}
                        currency={''} 
                    />}
                </span>}
            </div>
        </div>
    );
};
export default ReportProjectRow;









