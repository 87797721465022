import React from 'react';
import './Icons.css';
const AvatarIcon = () => {
    return (
        <svg className="avatarIcon" width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
            <path  fillRule="evenodd" clipRule="evenodd"
                  d="M15.999 9.10266C15.999 11.3118 14.2082 13.1027 11.999 13.1027C9.78988 13.1027 7.99902 11.3118 7.99902 9.10266C7.99902 6.89352 9.78988 5.10266 11.999 5.10266C14.2082 5.10266 15.999 6.89352 15.999 9.10266ZM13.999 9.10266C13.999 10.2072 13.1036 11.1027 11.999 11.1027C10.8945 11.1027 9.99902 10.2072 9.99902 9.10266C9.99902 7.99809 10.8945 7.10266 11.999 7.10266C13.1036 7.10266 13.999 7.99809 13.999 9.10266Z"
                  />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.999 9.10266C15.999 11.3118 14.2082 13.1027 11.999 13.1027C9.78988 13.1027 7.99902 11.3118 7.99902 9.10266C7.99902 6.89352 9.78988 5.10266 11.999 5.10266C14.2082 5.10266 15.999 6.89352 15.999 9.10266ZM13.999 9.10266C13.999 10.2072 13.1036 11.1027 11.999 11.1027C10.8945 11.1027 9.99902 10.2072 9.99902 9.10266C9.99902 7.99809 10.8945 7.10266 11.999 7.10266C13.1036 7.10266 13.999 7.99809 13.999 9.10266Z"
                   fillOpacity="0.2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.999 1.10266C5.92389 1.10266 0.999023 6.02753 0.999023 12.1027C0.999023 18.1778 5.92389 23.1027 11.999 23.1027C18.0742 23.1027 22.999 18.1778 22.999 12.1027C22.999 6.02753 18.0742 1.10266 11.999 1.10266ZM2.99902 12.1027C2.99902 14.1929 3.71157 16.1167 4.90701 17.6444C6.55147 15.4916 9.14529 14.1027 12.0635 14.1027C14.9439 14.1027 17.5082 15.4557 19.1555 17.561C20.3121 16.0469 20.999 14.155 20.999 12.1027C20.999 7.1321 16.9696 3.10266 11.999 3.10266C7.02846 3.10266 2.99902 7.1321 2.99902 12.1027ZM11.999 21.1027C9.84879 21.1027 7.87468 20.3486 6.3267 19.0905C7.59254 17.2839 9.69008 16.1027 12.0635 16.1027C14.4075 16.1027 16.4823 17.2547 17.7528 19.0235C16.1929 20.3218 14.1871 21.1027 11.999 21.1027Z"
                  />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.999 1.10266C5.92389 1.10266 0.999023 6.02753 0.999023 12.1027C0.999023 18.1778 5.92389 23.1027 11.999 23.1027C18.0742 23.1027 22.999 18.1778 22.999 12.1027C22.999 6.02753 18.0742 1.10266 11.999 1.10266ZM2.99902 12.1027C2.99902 14.1929 3.71157 16.1167 4.90701 17.6444C6.55147 15.4916 9.14529 14.1027 12.0635 14.1027C14.9439 14.1027 17.5082 15.4557 19.1555 17.561C20.3121 16.0469 20.999 14.155 20.999 12.1027C20.999 7.1321 16.9696 3.10266 11.999 3.10266C7.02846 3.10266 2.99902 7.1321 2.99902 12.1027ZM11.999 21.1027C9.84879 21.1027 7.87468 20.3486 6.3267 19.0905C7.59254 17.2839 9.69008 16.1027 12.0635 16.1027C14.4075 16.1027 16.4823 17.2547 17.7528 19.0235C16.1929 20.3218 14.1871 21.1027 11.999 21.1027Z"
                  />
        </svg>
    );
};

export default AvatarIcon;