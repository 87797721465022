import React from 'react';
import styles from './UserListItem.module.css';
import { NavLink } from "react-router-dom";
import AvatarIcon from "../../../../ui/icons/AvatarIcon";
import SettingsIcon from "../../../../ui/icons/SettingsIcon";

const UserListItem = ({ user, current }) => {
    return (
        <li className={`${styles.Item} ${(current === user.id) ? styles.Active : ''}`}>
            <NavLink to={`/users/${user.id}`}>
                <div className={styles.Person}>
                    <div className={styles.Avatar}>
                        <AvatarIcon />
                    </div>
                    <div className={styles.UserName}>
                        {user.firstName} {user.lastName}
                    </div>
                </div>
                {!current && <div className={styles.Role}>{user.capability === 1 ? `Администратор` : 'Аккаунт Менеджер'}</div>}
                {!current &&
                    <div className={styles.Settings}>
                        <div className={styles.Icon}>
                            <SettingsIcon />
                        </div>
                    </div>}
            </NavLink>
        </li>
    );
};

export default UserListItem;