/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from "react";
import styles from "./AddArticleEditor.module.css";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../ui/input/Input";
import "./AddArticleEditor.css";
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from "draft-js";
import Button from "../../../../../ui/button/Button";
import draftToHtml from "draftjs-to-html";
import {
    createArticle,
    fetchArticlesBySection,
    fetchArticlesBySectionFilterId,
    fetchKnowledge,
} from "../../../../../../actions/knowledge";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";
import { createNotification } from "../../../../../../actions/app";
import SelectUiWiki from "../../../../../ui/select/SelectUiWiki";
import { addModal, closeModal, removeModal } from "../../../../../../reducers/appReducer";
import ArticleImageRemoveModal from "../../../../../modals/articleImageRemove/ArticleImageRemoveModal";

function getLast10Characters (inputString) {
    return inputString?.length > 15
        ? `...${inputString.slice(-15)}`
        : inputString;
}

const AddArticleEditor = ({ setArticleBtn }) => {
    const dispatch = useDispatch();
    const [sectionId, setSectionId] = useState("");
    const [contentLoading, setContentLoading] = useState(false);

    const [parentArticle, setParentArticle] = useState([]);
    const [articlesData, setArticlesData] = useState([]);

    const [logo, setLogo] = useState("");
    const [articleTitle, setArticleTitle] = useState("");
    const [sectionsFilterData, setSectionsFilterData] = useState([]);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(""))
        )
    );
    const sectionsData = useSelector((state) => state.knowledge.data);
    const currentSectionData = useSelector(
        (state) => state.knowledge.currentSectionData
    );
    const [selectedArticlesData, setSelectedArticlesData] = useState([]);
    const [selectedArticleFlag, setSelectedArticlesFlag] = useState(true);
    const [isImage, setIsImage] = useState();
    const inputRef = useRef(null);




    const onSetFileHandler = () => {
        setLogo(inputRef.current.files[0]);
        setIsImage(true);
    };

    useEffect(() => {
        if (selectedArticleFlag) {
            setSelectedArticlesData(
                articlesData?.find((item) =>
                    parentArticle
                        ? item.value === parentArticle
                        : null
                )
            );
        }
        setSelectedArticlesFlag(true)

    }, [articlesData, parentArticle]);


    useEffect(() => {
        sectionId && dispatch(fetchArticlesBySectionFilterId(sectionId, setArticlesData));
    }, [sectionId])


    useEffect(() => {
        if (sectionsData.length > 0) {
            const data = sectionsData.reduce((acc, cur) => {
                acc.push({ label: `${cur.title}`, value: cur._id });

                return acc;
            }, []);

            data.sort((a, b) => a.label - b.label);

            setSectionsFilterData(data);
        }
    }, [sectionsData]);

    const createNewArticle = () => {
        const editorContentHtml = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );

        const formData = new FormData();
        formData.append("title", articleTitle);
        formData.append("description", editorContentHtml);
        formData.append("sectionId", sectionId);

        if (logo) {
            formData.append("logo", logo, logo.name);
        }

        if (parentArticle) {
            formData.append('parentArticle', parentArticle);
        }

        if (!articleTitle.trim() || articleTitle.trim().length < 2) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        "Название статьи должно быть не менее 2 и не более 30 символов",
                })
            );
        } else if (!sectionId) {
            dispatch(
                createNotification({
                    type: "error",
                    children: "Для создания статьи необходимо выбрать раздел",
                })
            );
        } else if (
            !editorState.getCurrentContent().getPlainText().trim() ||
            editorState.getCurrentContent().getPlainText().trim().length < 20
        ) {
            dispatch(
                createNotification({
                    type: "error",
                    children: "Описание статьи должно быть не менее 20 символов",
                })
            );
        } else {
            dispatch(createArticle(formData, setContentLoading, onSuccess));
        }
    };

    const onSuccess = () => {
        setArticleBtn(false);
        dispatch(fetchKnowledge(setContentLoading));
        dispatch(fetchArticlesBySection(currentSectionData._id, setContentLoading));
    };

    const onClearParentArticles = () => {
        setSelectedArticlesFlag(false);
        setParentArticle("");
        setSelectedArticlesData(null);
    };

    const getFileBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => callback(reader.result);
        reader.onerror = error => { };
    };

    const imageUploadCallback = file => new Promise(
        (resolve, reject) => getFileBase64(
            file,
            data => resolve({ data: { link: data } })
        )
    );

    const onArticleRemoveHandler = () => {
        dispatch(
            addModal({
                id: "articleImageRemoveModal",
                size: "bigger",
                children: (
                    <Suspense fallback={<ContentLoader />}>
                        {ArticleImageRemoveModal && (
                            <ArticleImageRemoveModal
                                type="article"
                                onRemove={onRemove}
                                onError={() => {
                                    dispatch(removeModal("articleImageRemoveModal"));
                                }}
                            />
                        )}
                    </Suspense>
                ),
            })
        );
    }

    const onRemove = () => {
        inputRef.current.value = '';
        setLogo('');
        setIsImage((prev) => (prev = false));
        dispatch(closeModal('articleImageRemoveModal'));
    }

    return (
        <div className={`${styles.container} `}>
            {contentLoading ? (
                <ContentLoader />
            ) : (
                <>
                    <h2 className={`${styles.title} `}>Добавление статьи</h2>
                    <div className={styles.filters_content}>
                        <div className={`${styles.filters} fpl`}>
                            <div className={`${styles.input_block} `}>
                                <span className={styles.inputTitle}>Название</span>
                                <Input
                                    style={{
                                        border: "1px solid #383838",
                                        height: "38px",
                                        backgroundColor: "transparent",
                                    }}
                                    placeholder="Введите название статьи"
                                    setValue={setArticleTitle}
                                    value={articleTitle}
                                />
                            </div>
                        </div>
                        <div className={`${styles.filters} sections_filter_select`}>
                            <div className={`${styles.input_block} `}>
                                <SelectUiWiki
                                    customKey='section'
                                    placeholder="Выберите раздел"
                                    label="Раздел"
                                    options={[...sectionsFilterData]}
                                    onChange={(val) => {
                                        setSectionId(val);
                                    }}
                                    isSearchable
                                    classes="sections_filter"
                                    value={sectionsFilterData.find(
                                        (item) => item.value === sectionId
                                    )}
                                />
                            </div>
                        </div>
                        <div className={`${styles.filters} sections_filter_select fpr`}>
                            <div className={styles.input_block}>
                                <SelectUiWiki
                                    customKey='article'
                                    placeholder='Все'
                                    label='Родительская статья'
                                    options={[...articlesData]}
                                    onChange={val => {
                                        setParentArticle(val);
                                    }}
                                    isSearchable
                                    classes='sections_filter'
                                    isClearable={true}
                                    onClearParentArticles={onClearParentArticles}
                                    value={selectedArticlesData}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.article_editor} row`}>
                        <div className={`${styles.textEditorBlock} col-12`}>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={(newEditorState) => {
                                    setEditorState(newEditorState);
                                }}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName articleEditor"
                                toolbar={{
                                    image: {
                                        uploadCallback: imageUploadCallback,
                                        previewImage: true,
                                        style: {
                                            display: 'inline-block',
                                        },
                                    },
                                }}
                                editorStyle={{
                                    fontFamily: 'Inter',
                                }}
                            />
                        </div>
                    </div>
                    <div className={`${styles.btnContainer} row`}>
                        <div className={`${styles.fileInputCol} col-6`}>
                            <div className={styles.sectionFileInputBlock}>
                                <label
                                    className={styles.file_input_label}
                                    htmlFor="file-input-logo"
                                >
                                    <span>Загрузить фото</span>
                                    <input
                                        ref={inputRef}
                                        accept="image/*"
                                        id="file-input-logo"
                                        type="file"
                                        name="file"
                                        onChange={onSetFileHandler}
                                        className={styles.fileInput}
                                    />
                                </label>
                                <div className={styles.removeZone} onClick={onArticleRemoveHandler}>
                                    {isImage && <span className={`${styles.fileName} ${logo.name ? styles.fileNameColor : ""}`}>
                                        {getLast10Characters(logo.name || "Фото не выбрано")}
                                    </span>}
                                    {isImage && <div className={styles.removeIcon}></div>}
                                </div>

                            </div>
                        </div>
                        <div className={`${styles.btns} col-6`}>
                            <Button
                                className={styles.viewBtn}
                                onClick={() => setArticleBtn(false)}
                            >
                                отменить
                            </Button>
                            <Button
                                className={styles.createArticle}
                                onClick={createNewArticle}
                            >
                                опубликовать
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default AddArticleEditor;
