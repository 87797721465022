import React, { useEffect, useState } from "react";
import styles from "./PopularSectionItem.module.css";
import { NavLink } from "react-router-dom";

const PopularSectionItem = ({ data }) => {
    const [articlesData, setArticlesData] = useState([]);

    const flattenArticles = (data) => {
        const flatArticles = [];

        const traverse = (node) => {
            if (node?.articles) {
                node.articles.forEach((article) => {
                    traverse(article);
                });
            }
            if (node && node.sectionId) {
                flatArticles.push(node);
            }
        };

        traverse(data);

        return flatArticles;
    };

    useEffect(() => {
        const flatArticles = flattenArticles(data);
        flatArticles.sort((a, b) => b.clickedCount - a.clickedCount);
        setArticlesData(flatArticles.slice(0, 5));
    }, [data]);

    return (
        <div className={styles.container}>
            <div className={styles.title_block}>
                <NavLink to={`/knowledge/section/${data._id}`}>
                    <h1 className={styles.title}>{data.title}</h1>
                </NavLink>
            </div>
            <div className={styles.links}>
                {articlesData?.map((item) => {
                    return (
                        <div key={item._id} className={styles.link}>
                            <NavLink
                                className={styles.link}
                                to={`/knowledge/article/${item._id}`}
                            >
                                {item.title}
                            </NavLink>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PopularSectionItem;
