import React from 'react';

const LinkArrow = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3785 7.99522L12.5453 5.17157L11.6041 6.11597L12.8277 7.33544L0.621636 7.33411L0.62149 8.66744L12.8246 8.66878L11.6103 9.88719L12.5547 10.8284L15.3785 7.99522Z" fill="#2D8546"/>
        </svg>

    );
};

export default LinkArrow;
