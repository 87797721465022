import React, { useState, useEffect } from 'react';
import styles from './InputLabel.module.css';
import InputError from '../inputError/InputError';

const InputLabel = ({ value, required, placeholder, type, name, label, setValue, error, disabled, className, step, icon, ...rest }) => {

    const [focused, setFocused] = useState(!!value);

    if (required) {
        label = label + '*';
        required = 'required';
    }
    const onFocus = () => setFocused(true);
    const onBlur = (e) => {
        if (!e.target.value) {
            setFocused(false);
        }
    };
    if (!value) {
        value = '';
    }
    if (type === 'numberWallet') {
        value = value.toString().replace(/\s/g, '').replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');
    }
    const onChange = (e) => {

        if (e.target.value) {
            setFocused(true)
        } else {
            setFocused(false)
        }
        setValue(e.target.value);
    };


    useEffect(() => {
        if (value) {
            setFocused(true);
        } else {
            setFocused(false);
        }
    }, [value]);
    let inpLabelClasses = className ? `${className} ` : '';
    switch (className) {
        case 'login': {
            inpLabelClasses += `${styles.InpBlock} ${styles.Login}`;
            break;
        }
        default: {
            inpLabelClasses += `${styles.InpBlock}`;
        }
    }


    return (
        <div className={inpLabelClasses}>
            <label>{label}</label>
            <input {...rest}
                className={styles.InputField}
                onChange={(e) => {
                    onChange(e)
                }}
                onFocus={onFocus}
                onBlur={(e) => {
                    onBlur(e)
                }}
                value={value}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
                focused={focused.toString()}
                step={step}
            />
            {icon}
            {error && <InputError error={error} />}
        </div>
    );
};

export default InputLabel;