import React from 'react';

const MutiplayIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="18.2969" width="18.0975" height="2" rx="1" transform="rotate(-45 5 18.2969)" fill="#202020"/>
            <rect x="17.7969" y="19.7111" width="18.0975" height="2" rx="1" transform="rotate(-135 17.7969 19.7111)" fill="#202020"/>
        </svg>
    );
};

export default MutiplayIcon;