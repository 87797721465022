import React from "react";
import styles from "./PopularSection.module.css";
import PopularSectionItem from "./popularSectionItem/PopularSectionItem";
import FileDocument from '../../../assets/img/file-document.svg'


const PopularSection = ({data}) => {
    return (
      <div className={styles.container}>
      <div className={styles.titleContent}>
          <img src={FileDocument} alt="FileDocument" />
          <h1 className={styles.title}>Популярные документы</h1>
        </div>
        {data?.length > 0 && data.map((item, index) => {
          return item?.articles?.length > 0 ? <PopularSectionItem data={item} key={index} /> : null;
        })}
      </div>
    );
};

export default PopularSection;
