import React from 'react';

const UploadIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33317 9.99068C7.33317 10.3589 7.63165 10.6573 7.99984 10.6573C8.36803 10.6573 8.6665 10.3589 8.6665 9.99068V5.21883L10.8283 7.38067L11.7712 6.43786L7.99991 2.66663L4.22868 6.43786L5.17149 7.38067L7.33317 5.21899V9.99068Z" fill="#7D40FF"/>
            <path d="M2.6665 9.33329H3.99984V12H11.9998V9.33329H13.3332V12C13.3332 12.7363 12.7362 13.3333 11.9998 13.3333H3.99984C3.26346 13.3333 2.6665 12.7363 2.6665 12V9.33329Z" fill="#7D40FF"/>
        </svg>

    );
};

export default UploadIcon;