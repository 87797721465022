/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./SectionArticles.module.css";
import SortByNameIconActive from "../../../../../../../assets/img/article-sort-by-name-active.svg";
import SortByNameIconPassive from "../../../../../../../assets/img/article-sort-by-name-passive.svg";
import SortByDateIconActive from "../../../../../../../assets/img/article-sort-by-date-active.svg";
import SortByDateIconPassive from "../../../../../../../assets/img/article-sort-by-date-passive.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticlesBySection } from "../../../../../../../actions/knowledge";
import SectionArticleItem from "./sectionArticleItem/SectionArticleItem";
import { setArticlesParamsData } from "../../../../../../../reducers/knowledgeReducer";
import ContentLoader from "../../../../../../ui/contentLoader/ContentLoader";

const SectionArticles = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const currentSectionData = useSelector(
        (state) => state.knowledge.currentSectionData
    );

    const articlesSortParams = useSelector(
        (state) => state.knowledge.articlesSortParams
    );

    const knowledgeData = useSelector((state) => state.knowledge.data);

    useEffect(() => {
        const newData = knowledgeData.find(
            (item) => item._id === currentSectionData._id
        );

        onSortByNameHandler(articlesSortParams, newData?.articles)
    }, [knowledgeData]);

    useEffect(() => {
        currentSectionData._id &&
            dispatch(fetchArticlesBySection(currentSectionData._id, setLoading));
    }, []);

    const onSortByNameHandler = (sortData, propsData = null) => {
        dispatch(setArticlesParamsData({ ...articlesSortParams, ...sortData }));
        const sortedData = JSON.parse(JSON.stringify(propsData || data?.articles));

        const firstSort = sortedData.reduce((acc, cur) => {
            acc.push({
                ...cur,
                articles:
                    sortData.name && cur?.articles?.length > 0
                        ? cur?.articles
                            ?.slice()
                            .sort((a, b) => a.title.localeCompare(b.title))
                        : cur?.articles
                            ?.slice()
                            .sort((a, b) => b.title.localeCompare(a.title)),
            });

            return acc;
        }, []);

        const secondSort = sortData.name
            ? firstSort.slice().sort((a, b) => a.title.localeCompare(b.title))
            : firstSort.slice().sort((a, b) => b.title.localeCompare(a.title));

        setData({ articles: [...secondSort] });
    };

    const onSortByDateHandler = (sortData) => {
        dispatch(setArticlesParamsData({ ...articlesSortParams, ...sortData }));
        const sortedData = JSON.parse(JSON.stringify(data?.articles));

        const firstSort = sortedData.reduce((acc, cur) => {
            acc.push({
                ...cur,
                articles:
                    sortData.date && cur?.articles?.length > 0
                        ? cur?.articles
                            ?.slice()
                            .sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
                        : cur?.articles
                            ?.slice()
                            .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt)),
            });

            return acc;
        }, []);

        const secondSort = sortData.date
            ? firstSort.slice().sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
            : firstSort.slice().sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));

        setData({ articles: [...secondSort] });
    };

    return (
        <div className={styles.container}>
            {loading ? (
                <ContentLoader />
            ) : (
                <>
                    {data?.articles.length > 0 && (
                        <div className={styles.sort}>
                            <span className={styles.text}>Сортировка:</span>
                            <img
                                src={
                                    articlesSortParams.name
                                        ? SortByNameIconActive
                                        : SortByNameIconPassive
                                }
                                alt=""
                                onClick={(e) =>
                                    onSortByNameHandler({
                                        name: !articlesSortParams.name,
                                        date: false,
                                    })
                                }
                            />
                            <img
                                src={
                                    articlesSortParams.date
                                        ? SortByDateIconActive
                                        : SortByDateIconPassive
                                }
                                alt=""
                                onClick={(e) =>
                                    onSortByDateHandler({
                                        date: !articlesSortParams.date,
                                        name: false,
                                    })
                                }
                            />
                        </div>
                    )}
                    {data?.articles.length > 0 && (
                        <div className={styles.items}>
                            {data?.articles?.map((item) => {
                                return <SectionArticleItem key={item._id} data={item} />;
                            })}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SectionArticles;
