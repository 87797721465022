import React from 'react';
import styles from './DashboardCurrencyRatesItem.module.css';
import EqualIcon from "../../../ui/icons/EqualIcon";

const formatRate = (rate, type) => {
    if (type === 2) {
        return (1 / rate).toFixed(4).replace('.', ',');
    }
    return rate.toString().replace('.', ',');
};

const DashboardCurrencyRatesItem = ({ data, className }) => {
    const formattedRate = formatRate(data.rate, data.type);

    return (
        <div className={`${styles.Item} ${className}`}>
            <div className={styles.Num}>1</div>
            <div className={styles.Currency}>{data.from}</div>
            <div className={styles.Equal}><EqualIcon /></div>
            <div className={styles.Num}>{formattedRate}</div>
            <div className={styles.Currency}>{data.to}</div>
        </div>
    );
};

export default DashboardCurrencyRatesItem;
