import React, {lazy, Suspense} from 'react';
import styles from './ProjectRow.module.css';
import {formatCurrencyAmount} from "../../../../../core/helpers";
import {MonthsName} from "../../../../../core/config";
import Button from "../../../../ui/button_v2/ButtonV2";
import {useDispatch} from "react-redux";
import {deleteProjectExpense} from "../../../../../actions/partners-expenses";
import {deleteProjectIncome} from "../../../../../actions/partners-income";
import {addModal, removeModal} from "../../../../../reducers/appReducer";
import ContentLoader from "../../../../ui/contentLoader/ContentLoader";
import {NavLink} from "react-router-dom";
import DeleteIcon from "../../../../ui/icons/DeleteIcon";
import EditIcon from "../../../../ui/icons/EditIcon";

const ConfirmModal = lazy(() => import('../../../../modals/confirmModal/ConfirmModal'));

const ProjectRow = ({project, data, getExpenses, getIncome, getExpensesIncome }) => {
    const dispatch = useDispatch();

    const deleteExpense = () => {
        dispatch(deleteProjectExpense(data.id))
            .then(result => {
                dispatch(removeModal('confirmActionModal'));
                if (result) {
                    getExpensesIncome();
                }
            });
    };

    const deleteIncome = () => {
        dispatch(deleteProjectIncome(data.id))
            .then(result => {
                dispatch(removeModal('confirmActionModal'));
                if (result) {
                    getExpensesIncome();
                }
            });
    };

    const showDeleteExpenseConfirm = () => {
        dispatch(addModal({
            id: 'confirmActionModal',
            size: 'middle',
            children:
                <Suspense fallback={<ContentLoader />}>
                    {ConfirmModal &&
                        <ConfirmModal
                            onError={() => {
                                dispatch(removeModal('confirmActionModal'))
                            }}
                            // title={'Подтверждение удаления расходов'}
                            message={'Расходы будут безвозвратно удалены. Вы уверены?'}
                            foo={deleteExpense}
                        />
                    }
                </Suspense>
        }));
    };

    const showDeleteIncomeConfirm = () => {
        dispatch(addModal({
            id: 'confirmActionModal',
            size: 'middle',
            children:
                <Suspense fallback={<ContentLoader />}>
                    {ConfirmModal &&
                        <ConfirmModal
                            onError={() => {
                                dispatch(removeModal('confirmActionModal'))
                            }}
                            // title={'Подтверждение удаления доходов'}
                            message={'Доходы будут безвозвратно удалены. Вы уверены?'}
                            foo={deleteIncome}
                        />
                    }
                </Suspense>
        }));
    };

    return (
        <div>
            {data.expensesCategory && <div className={styles.Row}>
                <div className={styles.ExpensesCategoryName}>{data && data.expensesCategory.name}</div>
                <div className="col-2"></div>
                <div className="col-1-6-p-left-i text-center">{data && formatCurrencyAmount(data.amount, data.currency)}</div>
                <div className="col-1-6-p-left text-center"></div>
                <div className="col-1-6-p-left-date">{data && `${MonthsName[data.month].substring(0,3)}.${data.year}`}</div>
                <div className="col-1-6-p-left"></div>
                {data && <div className="col-2-5 d-flex justify-content-end">
                    <NavLink
                        className={styles.EditLink}
                        to={{
                            pathname:`/mb-projects/project/add-expenses`,
                            state: {project, data}
                        }}
                    >
                        <span className="pr-1"><EditIcon/></span>Редактировать расход
                    </NavLink>
                    
                    <Button type={'secondary'} className={styles.Button} onClick={showDeleteExpenseConfirm} >
                        <span className="pl-1"><DeleteIcon/></span>Удалить расход
                    </Button>
                </div>}
            </div>}

            {data.incomeCategory && <div className={styles.Row}>
                <div className="col-2"></div>
                <div className={styles.IncomeCategoryName}>{data && data.incomeCategory.name}</div>
                <div className="col-1-6-p-left text-center"></div>
                <div className="col-1-6-p-left-i text-center">{data && formatCurrencyAmount(data.amount, data.currency)}</div>
                <div className="col-1-6-p-left"></div>
                <div className="col-1-6-p-left-i">{data && `${MonthsName[data.month].substring(0,3)}.${data.year}`}</div>
                {data && <div className="col-2-5 d-flex justify-content-end">
                    <NavLink
                        className={styles.EditLink}
                        to={{
                            pathname:`/mb-projects/project/add-income`,
                            state: {project, data}
                        }}
                    >
                        <span className="pr-1"><EditIcon/></span>Редактировать доход
                    </NavLink>
                    <Button type={'secondary'} className={styles.Button} onClick={showDeleteIncomeConfirm} >
                        <span className="pl-1"><DeleteIcon/></span>Удалить доход
                    </Button>
                </div>}
            </div>}
        </div>
    );
};

export default ProjectRow;
