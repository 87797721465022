import React, { Suspense } from 'react';
import styles from './SectionShow.module.css';
import EditIcon from '../../../../../../../assets/img/knowledge-edit.svg'
import RemoveIcon from '../../../../../../../assets/img/comment-remove-icon.png'
import { useDispatch } from 'react-redux';
import { sectionRemove } from '../../../../../../../actions/knowledge';
import { addModal, closeModal, removeModal } from '../../../../../../../reducers/appReducer';
import KnowledgeRemoveModal from '../../../../../../modals/knowledgeRemoveModal/KnowledgeRemoveModal';
import ContentLoader from '../../../../../../ui/contentLoader/ContentLoader';
import { useHistory } from 'react-router-dom';
import SectionDefault from '../../../../../../../assets/img/section-default.png'


const SectionShow = ({ data, setEdit, edit, isEdit, imageRemove }) => {
    const dispatch = useDispatch();
    const history = useHistory();


    const onArticleRemoveHandler = () => {
        dispatch(
            addModal({
                id: "knowledgeRemoveModal",
                size: "bigger",
                children: (
                    <Suspense fallback={<ContentLoader />}>
                        {KnowledgeRemoveModal && (
                            <KnowledgeRemoveModal
                                type="section"
                                data={data}
                                onRemove={onRemove}
                                onError={() => {
                                    dispatch(removeModal("knowledgeRemoveModal"));
                                }}
                            />
                        )}
                    </Suspense>
                ),
            })
        );
    };

    const onRemove = () => {
        dispatch(sectionRemove(data._id, onSuccess));
    };

    const onSuccess = (data) => {
        dispatch(closeModal('knowledgeRemoveModal'));
        if (data.length > 0) {
            history.push(`/knowledge/section/${data[0]._id}`);
        } else {
            history.push(`/knowledge`);
        }
    };

    return (
        <div className={`${styles.container} row`}>
            <div className={`${styles.imgAndTitleBlock} col-11`}>
                <div className={styles.imgAndTitle}>
                    <img src={data?.logo && !imageRemove ? data?.logo : SectionDefault} alt="" />
                    <h2>{data?.title}</h2>
                </div>
            </div>
            <div className={`${styles.editBtn} col-1`}>
                {isEdit && <img onClick={() => setEdit(!edit)} src={EditIcon} alt="" />}
                {isEdit && <img className={styles.removeIcon} onClick={onArticleRemoveHandler} src={RemoveIcon} alt="" />}
            </div>
            <div className='col-12'>
                {data?.description && <span dangerouslySetInnerHTML={{ __html: data?.description }} className={styles.description}></span>}
            </div>
        </div>
    )
}

export default SectionShow