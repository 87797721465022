import React, { useEffect, useState } from 'react';
import AppContent from "../../appContent/AppContent";
import styles from "./Conversions.module.css";
import ConversionsFilters from "./filters/ConversionsFilters";
import ConversionsContent from "./content/ConversionsContent";
import { useSelector } from "react-redux";
import { delayByLoader } from '../../../utils/deleyByLoader';
import ContentLoader from '../../ui/contentLoader/ContentLoader';

const Conversions = () => {
    const [loading, setLoading] = useState(false);
    const [componentLoader, setComponentLoader] = useState(false);
    const conversionsData = useSelector(state => state.conversions.items);

    const [data, setData] = useState(
        {
            type: 'order',
            search: '',
        }
    );

    useEffect(() => {
        delayByLoader(true, setLoading, 200);
    }, []);


    return (
        <AppContent>
            <div className={`conversionsContainer container wrapper`}>
                <div className={`${styles.TitleBlock}`}>
                    <h2>Управление конверсиями</h2>
                </div>
                {
                    loading ? <ContentLoader /> : <>
                        <div className={`${styles.FiltersBlock}`}>
                            <ConversionsFilters data={data} setData={setData} conversionsData={conversionsData} loading={componentLoader} setLoading={setComponentLoader} />
                        </div>
                        <div className={styles.ContentBlock}>
                            <ConversionsContent conversionsData={conversionsData} loading={componentLoader} />
                        </div>
                        <div className={styles.PaginationBlock}>

                        </div>
                    </>
                }
            </div>
        </AppContent>
    );
};

export default Conversions;