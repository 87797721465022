import React, {useState, useEffect} from 'react';
import  classes from'./InputFile.module.css';
import InputError from '../inputError/InputError';
import UploadIcon from "../../icons/UploadIcon";

const InputFile = ({value, name, label, setValue,error,className, ...rest}) => {

    const [focused, setFocused] = useState(!!value);
    const onFocus = () => setFocused(true);
    const onBlur = (e) => {
        if (!e.target.files.file) {
            setFocused(false);
        }
    };
    if (!value) {
        value = '';
    }

    const onChange = (e) => {
        const file = [...e.target.files][0];
        if (file) {
            setFocused(true)
        } else {
            setFocused(false)
        }
        setValue(file);
    };

    useEffect(() => {
        if (value) {
            setFocused(true);
        } else {
            setFocused(false);
        }
    }, [value]);
    const inpLabelClasses = className ? `${className} ${classes.InpBlock}` : classes.InpBlock;

    return (
        <div className={inpLabelClasses}>
            <label>{label} <UploadIcon/>
            <input {...rest}
                   className={classes.InputField}
                   onChange={(e) => {
                       onChange(e)
                   }}
                   onFocus={onFocus}
                   onBlur={(e) => {
                       onBlur(e)
                   }}
                   type='file'
                   focused={focused.toString()}
            />
            </label>
            {error && <InputError error={error}/>}
        </div>
    );
};


export default InputFile;