import React, { useMemo, useState } from 'react';
import styles from './ConversionsFilters.module.css';
import Button from "../../../ui/button_v2/ButtonV2";
import Textarea from "../../../ui/textarea/Textarea";
import InputRadio from "../../../ui/input/InputRadio/InputRadio";
import { useDispatch } from "react-redux";
import { searchConversions } from "../../../../actions/conversions";
import ReloadIcon from "../../../ui/icons/ReloadIcon";

const ConversionsFilters = ({ conversionsData, data, setData, loading, setLoading }) => {
    const [filter, setFilter] = useState();
    const dispatch = useDispatch();
    const noFound = conversionsData ? conversionsData.filter((conversion) => (conversion.isIsset === false)) : '';

    const noFoundResult = useMemo(() => {
        let noFoundNoMess = { noMess: noFound.filter(data => !data.message) };
        noFoundNoMess = { ...noFoundNoMess, mess: noFound.filter(data => data.message) };
        noFoundNoMess.mess = noFoundNoMess.mess.map((no, index) => {
            if (data.type === 'click') {
                return <div className={styles.Span} key={index}><span>{no.clickId}</span></div>;
            }
            return <div className={styles.Span} key={index}><span>{no.actionId}</span></div>;
        });
        noFoundNoMess.noMess = noFoundNoMess.noMess.map((no, index) => {
            if (data.type === 'click') {
                return <div className={styles.Span} key={index}><span>{no.clickId}</span></div>;
            }
            return <div className={styles.Span} key={index}><span>{no.actionId}</span></div>;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return noFoundNoMess;
    }, [noFound, data]);

    const onSearchHandler = () => {
        dispatch(searchConversions(data, setLoading));
        setFilter('');
    }

    return (
        <div className={styles.Filters} filter={filter}>
            <div className={`${styles.Left}`}>
                <div className={styles.Top}>
                    <h3>Поиск</h3>
                    <div className={styles.CheckboxBlock}>
                        <InputRadio
                            name='type'
                            type='green'
                            value='order'
                            id='conversionId'
                            label='По ID конверсии'
                            setValue={(val) => {
                                setData({ ...data, type: val })
                            }}
                            check={data.type === 'order'}
                        />
                        <InputRadio
                            name='type'
                            type='green'
                            value='click'
                            id='byClick'
                            label='По ID клика'
                            setValue={(val) => {
                                setData({ ...data, type: val })
                            }}
                            check={data.type === 'click'}
                        />
                    </div>
                    <Textarea
                        name='search'
                        placeholder="Введите данные"
                        value={data.search}
                        setValue={(val) => setData({ ...data, search: val })} />
                    <div className={styles.BtnBlock}>
                        {!loading &&
                            <Button type='primary' onClick={onSearchHandler}>Найти</Button>}
                        {!loading && data.search && <Button type='line' onClick={onSearchHandler}><ReloadIcon />Обновить</Button>}
                    </div>
                </div>
            </div>
            {conversionsData.length !== 0 &&
                <div className={styles.Right}>
                    <div className={styles.SearchResultTitle}>
                        Найдено {conversionsData.length - (noFoundResult.mess.length + noFoundResult.noMess.length)} из {conversionsData.length}
                    </div>
                    {(noFoundResult.length !== 0) && (
                        <div className={styles.SearchResultContent}>
                            <p>Конверсии не найдены</p>
                            <div className={styles.Result}>
                                {noFoundResult.noMess}
                            </div>
                            <p>Статус платежа close</p>
                            <div className={styles.Result}>
                                {noFoundResult.mess}
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default ConversionsFilters;
