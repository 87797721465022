import React from 'react';
import './InputMasked.css';



const InputMasked = ({value, setValue}) => {
    if(value.length === 8){
      const formattedInput = value.substring(0, 2) + "-" + value.substring(2, 4) + "-" + value.substring(4, 8);
      setValue(formattedInput)
    }

    return (
        <div className='inputMasked'>
         <input type="text" className='inputField ' 
           value={value}
            onChange={(e)=> setValue(e.target.value)}
            onKeyDown={(e) => {
                if (e.keyCode === 8 &&  value.length === 10) {
                  setValue('')
                }
              }
            }
          />
        </div>

    );
};

export default InputMasked;
