import React, {lazy, Suspense} from 'react';
import {useDispatch} from "react-redux";
import {format} from "date-fns";
import Button from "../../../../../../ui/button/Button";
import SettingsIcon from "../../../../../../ui/icons/SettingsIcon";
import styles from './Item.module.css';
import {addModal, removeModal} from "../../../../../../../reducers/appReducer";
import ContentLoader from "../../../../../../ui/contentLoader/ContentLoader";

const AdvertiserCreateBill = lazy(()=>import('../../../../../../modals/advertiserCreateBill/AdvertiserCreateBill'));

const Item = ({item}) => {
    const dispatch = useDispatch();

    const updateBill = ( ) => {
        dispatch(addModal({
            id: 'createBill',
            size: 'big',
            children:
                <Suspense fallback={<ContentLoader/>}>
                    {AdvertiserCreateBill &&
                        <AdvertiserCreateBill
                            onError={() => {
                                dispatch(removeModal('createBill'))
                            }}
                            advId={item.advertiser}
                            bill={item}
                        />}
                </Suspense>
        }));
    };

    return (
        <div  className={styles.Item}>
            <div className={`${styles.Number}`}>{item.number}</div>
            <div className={`${styles.Date}`}>{format(new Date(item.billDate), 'dd.MM.yyyy HH:mm:ss')} GMT+3</div>
            <div className={`${styles.Btn}`}>
                <Button  type='no' onClick={updateBill}><SettingsIcon/></Button>
            </div>
        </div>
    );
};

export default Item;
