export const getFormatDate = (period) => {
  const currentDate = new Date();

  let startDate, endDate;

  switch (period) {
    case 'day7':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6);
      endDate = currentDate;
      break;
    case 'previousWeek':
      const previousWeekStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
      const previousWeekEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
      startDate = new Date(previousWeekStartDate.getFullYear(), previousWeekStartDate.getMonth(), previousWeekStartDate.getDate() - previousWeekStartDate.getDay()+1);
      endDate = new Date(previousWeekEndDate.getFullYear(), previousWeekEndDate.getMonth(), previousWeekEndDate.getDate() - previousWeekEndDate.getDay());
      break;
    case 'currentWeek':
      const currentWeekStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - (currentDate.getDay() - 1));
      startDate = new Date(currentWeekStartDate.getFullYear(), currentWeekStartDate.getMonth(), currentWeekStartDate.getDate() - currentWeekStartDate.getDay()+1);
      endDate = currentDate;
      break;
    case 'day30':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 29);
      endDate = currentDate;
      break;
    case 'currentMonth':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      endDate = currentDate;
      break;
    case 'previousMonth':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      break;
    default:
      return '';
  }

  const startDay = String(startDate.getDate()).padStart(2, '0');
  const startMonth = String(startDate.getMonth() + 1).padStart(2, '0');
  const startYear = startDate.getFullYear();
  const endDay = String(endDate.getDate()).padStart(2, '0');
  const endMonth = String(endDate.getMonth() + 1).padStart(2, '0');
  const endYear = endDate.getFullYear();

  const formattedStartDate = `${startDay}-${startMonth}-${startYear}`;
  const formattedEndDate = `${endDay}-${endMonth}-${endYear}`;

  return `(${formattedStartDate}/${formattedEndDate})`;
};


export const formatNumber = ( number ) => {
    return number || number === 0
        ? number.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')
        : '';
};