import React, { useState } from "react";
import styles from "./SectionShowAndEdit.module.css";
import SectionEdit from "./sectionEdit/SectionEdit";
import SectionShow from "./sectionShow/SectionShow";
import { useSelector } from "react-redux";
import Button from "../../../../../ui/button/Button";
import AddArticleEditor from "../../article/addArticleEditor/AddArticleEditor";
import SectionArticles from "../knowledgeModerationContent/sectionArticles/SectionArticles";
import {
    MODULE_TYPE,
    getModuleRule,
    isEdit,
} from "../../../../../../core/helpers";
import AddIcon from "../../../../../../assets/img/add-articles-icon.png";
import { useHistory } from "react-router-dom";

const SectionShowAndEdit = ({ setLoading }) => {
    const history = useHistory();
    const [edit, setEdit] = useState(false);
    const [articleBtn, setArticleBtn] = useState(false);
    const currentSectionData = useSelector(
        (state) => state.knowledge.currentSectionData
    );
    const currentUser = useSelector((state) => state.user.currentUser);
    const rule = getModuleRule(currentUser.rules, MODULE_TYPE.KNOWLEDGE);
    const [imageRemove, setImageRemove] = useState(false);

    const addArticleHandler = () => {
        setArticleBtn((prev) => !prev);

        setTimeout(() => {
            const maxScroll =
                document.documentElement.scrollHeight - window.innerHeight;

            window.scrollTo({
                top: maxScroll,
                behavior: "smooth",
            });
        }, 0);
    };

    const addSectionHandler = () => {
        history.push("/knowledge/create");
    };

    return (
        <div className={styles.container}>
            {isEdit(rule) && (
                <div className="row">
                    <div className={`${styles.addSection} col-12`}>
                        <Button
                            className={styles.addSectionBtn}
                            onClick={addSectionHandler}
                        >
                            <img className={styles.plusBtn} src={AddIcon} alt="" />
                            Добавить Раздел
                        </Button>
                        <Button
                            className={styles.addArticleBtn}
                            onClick={addArticleHandler}
                        >
                            <img className={styles.plusBtn} src={AddIcon} alt="" />
                            Добавить статью
                        </Button>
                    </div>
                </div>
            )}
            <SectionShow
                data={currentSectionData}
                edit={edit}
                setEdit={setEdit}
                isEdit={isEdit(rule)}
                imageRemove={imageRemove}

            />
            {edit && isEdit(rule) && (
                <SectionEdit
                    data={currentSectionData}
                    setEdit={setEdit}
                    setLoading={setLoading}
                    imageRemove={imageRemove}
                    setImageRemove={setImageRemove}
                />
            )}
            <SectionArticles />
            {/* 
            {isEdit(rule) && (
                <div className="row">
                    <div className={`${styles.addArticle} col-12`}>
                        <Button
                            className={styles.addArticleBtn}
                            onClick={addArticleHandler}
                        >
                            <img className={styles.plusBtn} src={AddIcon} alt="" />
                            Добавить статью
                        </Button>
                    </div>
                </div>
            )} */}
            {articleBtn && (
                <AddArticleEditor
                    setArticleBtn={setArticleBtn}
                    setLoading={setLoading}
                />
            )}
        </div>
    );
};

export default SectionShowAndEdit;
