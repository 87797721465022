import React from 'react';

const CloseRIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.815 2.38747C14.3316 2.90409 14.3316 3.74169 13.815 4.25831L9.87087 8.20242L13.4102 11.7417C13.9268 12.2583 13.9268 13.0959 13.4102 13.6126C12.8935 14.1292 12.0559 14.1292 11.5393 13.6126L8.00003 10.0733L4.46074 13.6126C3.94411 14.1292 3.10651 14.1292 2.58989 13.6126C2.07327 13.0959 2.07327 12.2583 2.58989 11.7417L6.12918 8.20242L2.18507 4.25831C1.66845 3.74169 1.66845 2.90409 2.18507 2.38747C2.70169 1.87084 3.5393 1.87084 4.05592 2.38747L8.00003 6.33157L11.9441 2.38747C12.4608 1.87084 13.2984 1.87084 13.815 2.38747Z" />
        </svg>
    );
};

export default CloseRIcon;