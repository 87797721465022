/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import AppContent from "../../../appContent/AppContent";
import styles from "./CreateMbProject.module.css";
import {NavLink, useHistory} from "react-router-dom";
import Button from "../../../ui/button_v2/ButtonV2";
import SelectUi from "../../../ui/select/SelectUiv2";
import {useDispatch, useSelector} from "react-redux";
import {getProjectPartnersList, getPartnerByUserId} from "../../../../actions/partners";
import {createMbProject, getMbProjectGroupList} from "../../../../actions/mbprojects";
import MultiSelectV3 from "../../../ui/multiSelectV3/MultiSelectV3";
import {getOffersCategoryList, getOffersCountryList, getOffersList} from "../../../../actions/offer";
import {CountryList} from "../../../../core/config";

const CreateMbProject = ({location}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const project = location.state ? location.state.project : null;
    const [partnersOptions, setPartnersOptions] = useState([]);
    const [projectGroupOptions, setProjectGroupOptions] = useState([]);
    const [offersOptions, setOffersOptions] = useState([]);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [partner, setPartner] = useState(project ? project.partner.id : null);
    const [projectGroup, setProjectGroup] = useState(project ? project.projectGroup : null);
    const [projectName, setProjectName] = useState(project ? project.name : '');
    const [projectOffers, setProjectOffers] = useState(project ? project.offers : []);
    const [offersCountry, setOffersCountry] = useState('');
    const [offersCategory, setOffersCategory] = useState('');
    const [allOffers, setAllOffers] = useState([]);
    const [refresh, setRefresh] = useState(1);
    const currentUser = useSelector(state => state.user.currentUser);
    const [inputValue, setInputValue] = useState('');
    const [ value, setValue ] = useState();
    // eslint-disable-next-line
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        getPartners().then(() => console.log('Partners list loaded'));
        getProjectGroups().then(() => console.log('Categories list loaded'));
        getOffers().then(() => console.log('Offers list loaded'));
        getCountries().then(() => console.log('Countries list loaded'));
        getCategories().then(() => console.log('Categories list loaded'));
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if ( offersCountry || offersCategory ) {
            getOffers({country: offersCountry, category: offersCategory}).then(() => console.log('Offers list updated'));
        }
        // eslint-disable-next-line
    }, [dispatch, offersCountry, offersCategory]);

    const getPartners = async () => {
        if (currentUser.capability >= 2) {
                const partners = await dispatch(getPartnerByUserId(currentUser.email));
                if (currentUser.partnersIds && currentUser.partnersIds.length) {
                        const partnersIds = currentUser.partnersIds;
                        setPartnersOptions(partnersIds.map(id => { return {
                            value: partners[0]?._id,
                            label: `${currentUser.email} (${Number(id)})`
                        }}));

                        // const list = await dispatch(getProjectPartnersList(true));
                        // if (list) {
                        //     setPartnersOptions(list.map(project => { return {
                        //         value: project.id,
                        //         label: project.name
                        //     }}));
                        // }
                        
                    } else {
                            setPartnersOptions(partners.map(partner => { return {
                            value: partners[0]._id,
                            label: `${partner.email} (${partner.affiseId})`
                            }}));
                        }
        } else {
            const partnersList = await dispatch(getProjectPartnersList());
            if (partnersList) {
                setPartnersOptions(partnersList.map(partner => { return {
                    value: partner.id,
                    label: `(${partner.affiseId}) ${partner.email}`
                }}));
            }
        }
    };

    const createProject = async () => {
        const projectData = {
            name: projectName,
            projectGroup: projectGroup,
            partner: partner,
            offers: projectOffers,
        };
        if (project) {
            projectData.id = project.id;
            projectData.update = true;
        }
        const result = await dispatch(createMbProject(projectData));
        if (result) {
            history.push("/mb-projects");
        }
    };

    const getCountries = async () => {
        const countriesId = await dispatch(getOffersCountryList());
        if (countriesId) {
            const filteredCountriesData = CountryList.filter(item => countriesId.includes(item.alpha2));
            const optionList = filteredCountriesData.map(country => { return {
                value: country.alpha2,
                label: country.name
            }});
            optionList.unshift({value: 'all', label: 'Все страны'});

            setCountriesOptions(optionList);
        }
    };

    const getCategories = async () => {
        const categories = await dispatch(getOffersCategoryList());
        if (categories) {
            const optionList = categories.map(category => { return {
                value: category,
                label: category
            }});
            optionList.unshift({value: 'all', label: 'Все категории'});

            setCategoriesOptions(optionList);
        }
    };

    const getOffers = async ( params ) => {
        const offers = await dispatch(getOffersList( params ));
        if (offers) {
            const optionList = offers.map(offer => { return {
                value: offer.affiseId,
                label: `(${offer.affiseId}) ${offer.title}`
            }});
            setOffersOptions(optionList);
            if (!params) {
                setAllOffers(optionList);
            }
        }
    };

    const getProjectGroups = async () => {
        if (currentUser.capability >= 2) {
                const partners = await dispatch(getPartnerByUserId(currentUser.email));
                if (partners && partners.length) {
                    // if (partners[0].groups && partners[0].groups.length) {
                        // const groups = partners[0].groups;
                        const list = await dispatch(getMbProjectGroupList( true ));
                        if (list) {
                            // eslint-disable-next-line array-callback-return
                            const partnerGroups = list.filter((group) => {
                                if (group.partner) {
                                    return {
                                        value: group.id,
                                        label: group.name
                                    };
                                }
                            });

                            setProjectGroupOptions(partnerGroups.map(projectsGroup => { return {
                                value: projectsGroup.id,
                                label: projectsGroup.name
                            }}));
                        }
                    // }
                }
            } else {
                    const projectGroupList = await dispatch(getMbProjectGroupList());
                    if (projectGroupList) {
                        setProjectGroupOptions(projectGroupList.map(group => { return {
                            value: group.id,
                            label: group.name
                        }}));
                    }
            }
    };

    const onInputChange = (inputValue) => {
        // setInputValue(value ? value.label : '');
        setInputValue(inputValue);
    };

    const updateProjectOffers = (val) => {
        const add = val.filter(el => !projectOffers.includes(el) && !!el);
        setProjectOffers([...projectOffers, ...add]);
    };    

    const onChange = options => {
        setSelectedOptions(options);
        setInputValue(inputValue);
        setValue(options);
        updateProjectOffers(options);
    }

    // const onClick = selected => {
    //     setSelectedOptions(selected);
    //     setInputValue(inputValue);
    //     setValue(selected);
    // }

    const removeProjectOffer = (id) => {
        setProjectOffers(projectOffers.filter(offerId => offerId !== id));
        setRefresh(-refresh);
    };

    return (
        <AppContent>
            <div className="container wrapper">
                <div className="row">
                    <div className="col-8 offset-2">
                        <div className='m-3'><NavLink className={styles.BackLink} to="/mb-projects">Назад</NavLink></div>
                        <div className={styles.CreateProjectContent}>
                            <div className={styles.TitleBlock}>
                                <h2>{project ? "Редактирование" : "Создание"} проекта</h2>
                                <Button
                                    className={styles.Button}
                                    type={'primary'}
                                    onClick={createProject}
                                >{project ? 'Сохранить' : 'Создать'}</Button>
                            </div>
                            <div className={styles.CreateEntityBlock}>
                                <h3>Создать проект</h3>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 pr-0">
                                                <SelectUi
                                                    label={'Выберите группу*'}
                                                    placeholder={'Выбрать...'}
                                                    options={projectGroupOptions}
                                                    value={projectGroupOptions.find(item => item.value === projectGroup )}
                                                    onChange={val => setProjectGroup(val)}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <SelectUi
                                                    label={'Выберите вебмастера*'}
                                                    placeholder={'Выбрать...'}
                                                    options={partnersOptions}
                                                    value={partnersOptions.find(item => item.value === partner )}
                                                    onChange={val => setPartner(val)}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6 pr-0">
                                                <SelectUi
                                                    label={'Выберите страну'}
                                                    placeholder={'Выбрать...'}
                                                    options={countriesOptions}
                                                    value={countriesOptions.find(item => item.value === offersCountry )}
                                                    onChange={val => setOffersCountry(val)}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-6 pr-0">
                                                <SelectUi
                                                    label={'Выберите категорию'}
                                                    placeholder={'Выбрать...'}
                                                    options={categoriesOptions}
                                                    value={categoriesOptions.find(item => item.value === offersCategory )}
                                                    onChange={val => setOffersCategory(val)}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6 pr-0">
                                                <label className={styles.Label}>Выберите офферы*</label>
                                                <MultiSelectV3
                                                    className={styles.OffersSelect}
                                                    placeholder="Выбрать..."
                                                    allOptions={offersOptions}
                                                    initial={projectOffers}
                                                    refresh={refresh}
                                                    required={true}
                                                    inputValue={inputValue}
                                                    onInputChange={onInputChange}
                                                    onChange={onChange}
                                                    value={value}
                                                    setSelectedOptions={setSelectedOptions}
                                                    // onClick={onClick}
                                                />
                                                {projectOffers.length > 0 &&
                                                    <div className="mt-1 ml-2">
                                                        {projectOffers.map((offerId, index) =>
                                                            <div className={styles.SelectedOffer} key={index}>
                                                                { allOffers.find(item => item.value === offerId)
                                                                    ? allOffers.find(item => item.value === offerId).label
                                                                    : ''
                                                                }
                                                                <span
                                                                    className={styles.DeleteOfferIcon}
                                                                    onClick={() => removeProjectOffer(offerId)}
                                                                >
                                                                    <svg height="14" width="14" viewBox="0 0 20 20"
                                                                         aria-hidden="true" focusable="false"
                                                                         className="css-tj5bde-Svg">
                                                                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-6 pr-0">
                                                <label className={styles.Label}>Введите название проекта*</label>
                                                <input
                                                    type="text"
                                                    className={styles.Input}
                                                    value={projectName}
                                                    onChange={(e) => setProjectName(e.target.value)}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex align-items-start">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppContent>
    );
};

export default CreateMbProject;
