import React, {useState} from 'react';
import styles from '../AdvertiserCard.module.css';
import {OptionsAdvertiserEntityType} from "../../../../../core/config";
import SelectUi from "../../../../ui/select/SelectUi";
import InputLabel from "../../../../ui/input/inputLabel/InputLabel";
import InputRadio from "../../../../ui/input/InputRadio/InputRadio";
import imgEmptyLogo from "../../../../../assets/img/img-empty-logo.svg";
import {update} from "../../../../../actions/advertisers";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../../ui/button_v2/ButtonV2";

const GeneralAdvTab = ({ advertiser }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector( state => state.user.currentUser );

    const contract = advertiser.creative.contract
        ? advertiser.contracts.find( item => item.id === advertiser.creative.contract )
        : advertiser.contracts[0] ?? false;

    const offer = advertiser.creative.offer
        ? advertiser.offers.find( item => item.id === advertiser.creative.offer )
        : advertiser.offers[0] ?? false;

    const contractNumber = contract ? contract.number : '';

    const offerAffiseId = offer ? offer.affiseId : '';

    const logoImg = offer ? offer.logo : imgEmptyLogo;

    const [fields, setFields] = useState({
        id: advertiser.id,
        entity: advertiser.entity,
        url: advertiser.url,
        inn: advertiser.inn,
        nds: advertiser.nds,
        creative: {
            description: advertiser.creative.description,
            isSocial: advertiser.creative.isSocial,
            isNativeCr: advertiser.creative.isNativeCr,
            isReadyForErir: advertiser.creative.isReadyForErir,
            mediaExampleUrl: advertiser.creative.mediaExampleUrl ?? logoImg,
            urls: advertiser.creative.urls ? advertiser.creative.urls : `https://trk.xplink.io/click?pid=108&offer_id=${offerAffiseId}`,
            contractId: contract ? contract.id : null,
            offerId: offer ? offer.id : null
        }
    });

    const updateAdv =  () => {
         dispatch( update( fields ) );
    };

    return (
        <div className={styles.AdvTabContent}>

            <Button type={'primary'} className={styles.Save} onClick={updateAdv} >Сохранить</Button>

            <div className="row pt-1">
                <div className="col-4">
                    <label className={styles.FieldLabel}>Рекламодатель</label>
                    <div className={`${styles.LightBold} mt-3`}>{advertiser.title}</div>
                    <div className={`${styles.LightBold} mt-1`}>{advertiser.email}</div>
                </div>
                <div className="col-4">
                    <label className={styles.FieldLabel}>Менеджер</label>
                    <div className={`${styles.LightBold} mt-3`}>{`${advertiser.manager.firstName} ${advertiser.manager.lastName}`}</div>
                </div>
                <div className="col-4">
                    <div>ID: {advertiser.id}</div>
                    <div className={`${styles.FieldLabel} mt-1`}>ОРД ID: {advertiser.ordId ?? 'Не отправлен в ОРД'}</div>
                    <div className={`${styles.FieldLabel} mt-1`}>{advertiser.creative.erid ? `ОРД токен: ${advertiser.creative.erid}` : ''}</div>
                </div>
            </div>

            <div className={`${styles.AdvCommonData} row mt-5`}>
                <div className="col-6 d-flex align-items-center justify-content-between">
                    <div className={styles.FieldLabel}>Юридическое лицо</div>
                    <div className={styles.InputBlock}>
                        <SelectUi
                            options={ OptionsAdvertiserEntityType }
                            defaultValue={ OptionsAdvertiserEntityType.find( item => item.value === fields.entity ) }
                            onChange={ val => setFields({ ...fields, entity: val }) }
                        />
                    </div>
                </div>
                <div className="col-5 d-flex align-items-center">
                    <div className={`${styles.FieldLabel} mr-4`}>Сайт</div>
                    <div className={styles.InputBlock}>
                        <InputLabel
                            className={styles.AdvCardInput}
                            value={fields.url}
                            setValue={ val => setFields({ ...fields, url: val }) }
                        />
                    </div>
                </div>
            </div>

            <div className={`${styles.AdvCommonData} row mt-3`}>
                <div className="col-6 d-flex align-items-center justify-content-between">
                    <div className={styles.FieldLabel}>ИНН</div>
                    <div className={styles.InputBlock}>
                        <InputLabel
                            className={styles.AdvCardInput}
                            value={fields.inn}
                            setValue={ val => setFields({ ...fields, inn: val }) }
                        />
                    </div>
                </div>
                <div className="col-5 d-flex align-items-center">
                    <div className={`${styles.FieldLabel} mr-3`}>Страна </div>
                    <div className="d-flex align-items-center" style={{height: 40}}>{advertiser.country}</div>
                </div>
            </div>

            {advertiser.country === 'RU' &&
                <div className="row mt-3">
                    <div className="col-6 d-flex justify-content-end">
                        <div className={styles.RadioBlockFlex}>
                            <InputRadio
                                value={false}
                                check={ fields.nds === false }
                                label='Без НДС'
                                name='NDS'
                                required={true}
                                id='NDS2'
                                setValue={ val => setFields({ ...fields, nds: val }) }

                            />
                            <InputRadio
                                value={true}
                                check={ fields.nds === true }
                                label='С НДС'
                                required={true}
                                name='NDS'
                                id='NDS1'
                                setValue={ val => setFields({ ...fields, nds: val }) }
                            />
                        </div>
                    </div>
                </div>
            }

            <div className={styles.SectionDivider}></div>

            <h3 className="mb-3">Креатив</h3>

            <div className="row">
                <div className="col-8">
                    <textarea rows="5"
                        value={fields.creative.description}
                        onChange={ e => setFields(
                            { ...fields, creative: { ...fields.creative, description: e.target.value } }
                        )}
                        placeholder="Введите описание креатива"
                    />
                    <div className="row">
                        <div className="col-6">
                            <div className="mt-4 mb-2 pl-1">Признак социальной рекламы</div>
                            <div className={styles.RadioBlockFlex}>
                                <InputRadio
                                    value={false}
                                    check={fields.creative.isSocial === false}
                                    label='Нет'
                                    name='isSocial'
                                    required={true}
                                    id='isSocial2'
                                    setValue={ val => setFields({ ...fields,
                                        creative: { ...fields.creative, isSocial: val }
                                    })}
                                />
                                <InputRadio
                                    value={true}
                                    check={fields.creative.isSocial === true}
                                    label='Да'
                                    required={true}
                                    name='isSocial'
                                    id='isSocial1'
                                    setValue={ val => setFields({ ...fields,
                                        creative: { ...fields.creative, isSocial: val }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mt-4 mb-2 pl-1">Признак нативной рекламы</div>
                            <div className={styles.RadioBlockFlex}>
                                <InputRadio
                                    value={false}
                                    check={fields.creative.isNativeCr === false}
                                    label='Нет'
                                    name='isNativeCr'
                                    required={true}
                                    id='isNativeCr2'
                                    setValue={ val => setFields({ ...fields,
                                        creative: { ...fields.creative, isNativeCr: val }
                                    })}
                                />
                                <InputRadio
                                    value={true}
                                    check={fields.creative.isNativeCr === true}
                                    label='Да'
                                    required={true}
                                    name='isNativeCr'
                                    id='isNativeCr1'
                                    setValue={ val => setFields({ ...fields,
                                        creative: { ...fields.creative, isNativeCr: val }
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mt-4 mb-2 pl-1">Признак социальной рекламы</div>
                            <div className={styles.RadioBlock}>
                                <InputRadio
                                    value={false}
                                    check={fields.creative.isReadyForErir === false}
                                    label='Нет, объект будет исключен из экспорта в ЕРИР'
                                    name='isReadyForErir'
                                    required={true}
                                    id='isReady2'
                                    setValue={ val => setFields({ ...fields,
                                        creative: { ...fields.creative, isReadyForErir: val }
                                    })}
                                />
                                <InputRadio
                                    value={true}
                                    check={fields.creative.isReadyForErir === true}
                                    label='Да, если модель не будет изменена в дальнейшем и готова к экспорту в ЕРИР'
                                    required={true}
                                    name='isReadyForErir'
                                    id='isReady1'
                                    setValue={ val => setFields({ ...fields,
                                        creative: { ...fields.creative, isReadyForErir: val }
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className={styles.AdvLogo}>
                        <img src={fields.creative.mediaExampleUrl} alt=""/>
                    </div>

                    <div className={`${styles.FieldLabel} mt-3 mb-2`}>Ссылка на логотип</div>
                    <InputLabel
                        disabled={true}
                        className={styles.AdvCardInput}
                        value={fields.creative.mediaExampleUrl}
                        setValue={ val => setFields({ ...fields,
                            creative: { ...fields.creative, mediaExampleUrl: val }
                        })}
                    />
                </div>
            </div>

            {currentUser.capability === 1 &&
                <>
                    <div className={styles.SectionDivider}></div>

                    <div className="row">
                        <div className="col-6">
                            <InputLabel
                                label='Договор'
                                disabled={true}
                                name='contractId'
                                value={contractNumber}
                            />
                        </div>
                        <div className="col-6">
                            <InputLabel
                                label='Оффер'
                                disabled={true}
                                name='contractId'
                                value={offerAffiseId}
                            />
                        </div>
                        <div className="col-12">
                            <InputLabel
                                label="Целевая ссылка"
                                disabled={true}
                                type="text"
                                value={advertiser.creative.urls  ? advertiser.creative.urls : `https://trk.xplink.io/click?pid=108&offer_id=${offerAffiseId}`}
                                name='urls'
                                setValue={val => setFields({
                                    ...fields,
                                    creative: {...fields.creative, urls: val}
                                })}
                            />
                        </div>
                    </div>
                </>
            }

        </div>
    );
};

export default GeneralAdvTab;
