import React, {lazy, Suspense} from 'react';
import {useDispatch} from "react-redux";
import styles from './MbProjectRow.module.css';
import {NavLink, useHistory} from "react-router-dom";
import Button from "../../../../../ui/button_v2/ButtonV2";
import EditIcon from "../../../../../ui/icons/EditIcon";
import DeleteIcon from "../../../../../ui/icons/DeleteIcon";
import {addModal, removeModal} from "../../../../../../reducers/appReducer";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";
import {deleteMbProject} from "../../../../../../actions/mbprojects";

const ConfirmModal = lazy(() => import('../../../../../modals/confirmModal/ConfirmModal'));

const MbProjectRow = ({project, setLoading, getProjectsByGroup}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const deleteProject = async () => {
        setLoading(true);
        const result = await dispatch(deleteMbProject(project.id));
        if (result) {
            await getProjectsByGroup();
        }
        setLoading(false);
        dispatch(removeModal('confirmActionModal'));
    };

    const showDeleteConfirm = () => {
        dispatch(addModal({
            id: 'confirmActionModal',
            size: 'middle',
            children:
                <Suspense fallback={<ContentLoader />}>
                    {ConfirmModal &&
                        <ConfirmModal
                            onError={() => {
                                dispatch(removeModal('confirmActionModal'))
                            }}
                            // title={'Подтверждение удаления расходов'}
                            message={'При удалении проекта, также будут безвозвратно удалены все расходы относящиеся к этому проекту. Вы уверены?'}
                            foo={deleteProject}
                        />
                    }
                </Suspense>
        }));
    };

    return (
        <div className={styles.ProjectRow} >
            <div className="col-6 d-flex align-items-center" style={{cursor: "pointer"}}
                 onClick={() => history.push({
                     pathname:`/mb-projects/project`,
                     state: {project: project}
                 })}
            >
                <div className={styles.ProjectIcon}>{project.name.substring(0,1)}</div>
                <div>{project.name}</div>
                <div className={styles.PartnerData}>
                    <div className="mb-1">
                        {project.partner.email} ( <span className={styles.ID}>ID: {project.partner.affiseId}</span> )
                    </div>
                </div>
            </div>
            <div className="col-6 d-flex justify-content-end">
                <NavLink
                    className={styles.EditLink}
                    to={{
                        pathname:`/mb-projects/create`,
                        state: {project:project}
                    }}
                >
                    <span className="pr-1"><EditIcon/></span>Редактировать
                </NavLink>
                <Button type={'secondary'} className={styles.Button} onClick={showDeleteConfirm} >
                    <span className="pr-1"><DeleteIcon/></span>Удалить
                </Button>
            </div>

        </div>
    );
};

export default MbProjectRow;
