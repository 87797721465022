import { format } from "date-fns";
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addModal, removeModal } from "../../../../../../reducers/appReducer";
import Button from "../../../../../ui/button/Button";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";
import SettingsIcon from "../../../../../ui/icons/SettingsIcon";
import styles from './AdvertisersContractsLi.module.css';

const ContractModal = lazy(()=>import("../../../../../modals/advertisersCreateContract/AdvertisersCreateContract"));

const AdvertisersContractsLi = ({item, data}) => {
    const [active,setActive] = useState(false);
    const dispatch = useDispatch();
    const modals = useSelector(state => state.app.modals);
    useEffect(()=> {
        if(active){
            if(modals.length !==0){
                modals.forEach(modal=>{
                    if(modal.id === 'createContract'){
                        dispatch(removeModal(modal.id));
                    }

                });
            }
           dispatch(addModal({
                id: 'createContract',
                size: 'big',
                children:
                    <Suspense fallback={<ContentLoader/>}>
                        {ContractModal &&
                        <ContractModal
                            onError={() => {
                                dispatch(removeModal('createContract'))
                            }}
                            data={item}
                            advertiser={data}
                        />}
                    </Suspense>
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[active,item]);

    useEffect(()=> {
        if (active && modals.length === 1){
            setActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[modals]);
    return (


        <li className={styles.Item}>
            <div className={`${styles.Number}`}>{item.number}</div>
            <div className={`${styles.Date}`}>{format(new Date(item.contractDate), 'dd.MM.yyyy HH:mm:ss')} GMT+3</div>
            <div className={`${styles.Btn}`}>
                <Button  type='no' onClick={()=>{
                    setActive(true);
                }}><SettingsIcon/></Button>
            </div>
        </li>
    );
};

export default AdvertisersContractsLi;
