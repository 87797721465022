import React, {useEffect, useState} from 'react';
import styles from './Modal.module.css';
import { CSSTransition } from 'react-transition-group';
import {useDispatch} from "react-redux";
import {removeModal} from "../../../reducers/appReducer";

const Modal = (props) => {
    const [show, setShow] = useState(true);
    const dispatch = useDispatch();
    const {id, children, size, close} = props;
    let popupClasses = [styles.Popup];

    if(size){
        switch (size) {
            case 'small':
                popupClasses.push(styles.Small);
                break;
            case 'middle':
                popupClasses.push(styles.Middle);
                break;
            case 'middle-lg':
                popupClasses.push(styles.MiddleLg);
                break;
            case 'big':
                popupClasses.push(styles.Big);
                break;
            case 'bigger':
                popupClasses.push(styles.Bigger);
                break;
            default:
                break;
        }
    }

    useEffect(()=>{
        if(!show){
            let timeOutClose = setTimeout(() => {
                dispatch(removeModal(id));
            }, 250);
            return ()=>{
                clearTimeout(timeOutClose);
            };
        }
    },[show, dispatch, id]);

    useEffect(()=>{
        if(close){closeModal()}
    },[close]);

    const closeModal = () => {
        setShow(false);
    };

    return (
        <div className={`${styles.ModalWrapper} ${styles.Open}`}>
            <CSSTransition
                in={show}
                timeout={200}
                classNames={{
                    enterActive: styles.OverlayEnterActive,
                    enterDone: styles.OverlayEnterDone,
                    exitActive: styles.OverlayExit,
                    exitDone: styles.OverlayExitActive
                }}
                appear={true}
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <div className={`${styles.Overlay}`}
                     onClick={()=>{closeModal()}}>
                </div>
            </CSSTransition>
            <div className={popupClasses.join(' ')}>
                <CSSTransition
                    in={show}
                    timeout={400}
                    classNames={{
                        enterActive: styles.PopupDataEnterActive,
                        enterDone: styles.PopupDataEnterDone,
                        exitActive: styles.PopupDataExit,
                        exitDone: styles.PopupDataExitActive
                    }}
                    appear={true}
                    unmountOnExit
                    onEnter={() => {}}
                    onExited={() => {}}
                >
                        <div className={styles.PopupData} id='popupModal'>
                            <span className={styles.PopupClose} onClick={()=>{closeModal()}}>
                                &times;
                            </span>
                            {children}
                        </div>
                </CSSTransition>
            </div>
        </div>
    );
};

export default Modal;
