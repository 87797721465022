import React from 'react';

const DesertClockIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66667 3.99998H7.33333V4.66665C7.33333 5.03484 7.63181 5.33331 8 5.33331C8.36819 5.33331 8.66667 5.03484 8.66667 4.66665V3.99998Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4 1.33331V2.66665H4.66667V4.66665C4.66667 6.5076 6.15905 7.99998 8 7.99998C6.15905 7.99998 4.66667 9.49236 4.66667 11.3333V13.3333H4V14.6666H12V13.3333H11.3333V11.3333C11.3333 9.49236 9.84095 7.99998 8 7.99998C9.84095 7.99998 11.3333 6.5076 11.3333 4.66665V2.66665H12V1.33331H4ZM6 2.66665H10V4.66665C10 5.77122 9.10457 6.66665 8 6.66665C6.89543 6.66665 6 5.77122 6 4.66665V2.66665ZM6 11.3333V13.3333H10V11.3333C10 10.2287 9.10457 9.33331 8 9.33331C6.89543 9.33331 6 10.2287 6 11.3333Z" />
        </svg>

    );
};

export default DesertClockIcon;