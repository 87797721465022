import $api from "../core/api";
import { processError } from "../core/helpers";
import {
    setCurrentArticleData,
    setCurrentSectionsData,
    setSectionsData,
    setArticlesData,
} from "../reducers/knowledgeReducer";
import { delayByLoader } from "../utils/deleyByLoader";
import { createNotification } from "./app";

export const fetchKnowledge = (setLoading) => {
    return async (dispatch) => {
        try {
            const response = await $api.get(`/api/knowledge`);
            const data = response.data.reduce((acc, cur) => {
                acc.push({
                    ...cur,
                    articles: cur.articles.filter((item) => !item.isSub),
                });

                return acc;
            }, []);

            dispatch(setSectionsData(data));
        } catch (e) {
            processError(dispatch, e);
        } finally {
            delayByLoader(false, setLoading, 300);
        }
    };
};

export const fetchArticlesBySection = (id, setLoading) => {
    setLoading?.(true);
    return async (dispatch) => {
        try {
            const response = await $api.get(`/api/knowledge/section-articles/${id}`);

            dispatch(
                setArticlesData(
                    response.data.slice().sort((a, b) => a.title.localeCompare(b.title))
                )
            );
            !!setLoading && delayByLoader(false, setLoading, 300);
        } catch (e) {
            processError(dispatch, e);
            setLoading?.(false);
        }
    };
};

export const fetchArticlesBySectionFilterId = (
    id,
    setArticlesData,
    articleId
) => {
    return async (dispatch) => {
        try {
            const response = await $api.post(
                `/api/knowledge/section-articles-filter`,
                { sectionId: id, articleId }
            );
            const data = response.data.reduce((acc, cur) => {
                if (articleId) {
                    if (articleId !== cur._id) {
                        acc.push({ label: `${cur.title}`, value: cur._id });
                    }
                } else {
                    acc.push({ label: `${cur.title}`, value: cur._id });
                }
                return acc;
            }, []);

            setArticlesData(data);
        } catch (e) {
            processError(dispatch, e);
        }
    };
};

export const fetchArticlesById = (id) => {
    return async (dispatch) => {
        try {
            const response = await $api.get(`/api/knowledge/article/${id}`);

            dispatch(setCurrentArticleData(response.data));
        } catch (e) {
            processError(dispatch, e);
        }
    };
};

export const fetchKnowledgeById = (id, setLoading) => {
    return async (dispatch) => {
        try {
            const response = await $api.get(`/api/knowledge/${id}`);
            dispatch(setCurrentSectionsData(response.data));
        } catch (e) {
            processError(dispatch, e);
        } finally {
            // delayByLoader(false, setLoading, 200);
        }
    };
};

export const createKnowledgeSection = (formData, setLoading, onSuccess) => {
    return async (dispatch) => {
        try {
            setLoading(true);

            const response = await $api.post(
                `/api/knowledge/create-section`,
                formData
            );

            onSuccess(response.data);
            delayByLoader(false, setLoading, 600);
        } catch (e) {
            setLoading(false);
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        e?.response?.data?.errors[0]?.msg || e?.response?.data?.errors,
                })
            );
        }
    };
};

export const updateKnowledgeSection = (formData, setLoading, onSuccess) => {
    return async (dispatch) => {
        setLoading(true);
        try {
            const response = await $api.post(
                `/api/knowledge/update-section`,
                formData
            );
            dispatch(setCurrentSectionsData(response.data));
        } catch (e) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors,
                })
            );
        } finally {
            delayByLoader(false, setLoading, 200);
        }
    };
};

export const createArticle = (formData, setLoading, onSuccess) => {
    setLoading(true);
    return async (dispatch) => {
        try {
            await $api.post(`/api/knowledge/create-article`, formData);
            onSuccess?.();
            delayByLoader(false, setLoading, 200);
        } catch (e) {
            setLoading(false);
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors,
                })
            );
        }
    };
};

export const updateArticle = (formData, setLoading, onSuccess) => {
    return async (dispatch) => {
        setLoading(true);
        try {
            await $api.post(`/api/knowledge/update-article`, formData);
            onSuccess();
        } catch (e) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors,
                })
            );
        } finally {
            delayByLoader(false, setLoading, 200);
        }
    };
};

export const sectionRemove = (id, onSuccess) => {
    return async (dispatch) => {
        try {
            const response = await $api.delete(
                `/api/knowledge/section-remove/?id=${id}`
            );

            // response.data.reverse();
            onSuccess?.(response.data);
        } catch (e) {
            processError(dispatch, e);
        }
    };
};

export const articleRemove = (id, onSuccess) => {
    return async (dispatch) => {
        try {
            await $api.delete(`/api/knowledge/article-remove/?id=${id}`);
            onSuccess?.();
        } catch (e) {
            processError(dispatch, e);
        }
    };
};

export const searchPost = (title, setSearchData, setLoading) => {
    setLoading?.(true);
    return async (dispatch) => {
        try {
            const response = await $api.post(`/api/knowledge/search-post`, { title });

            setSearchData(response.data);
        } catch (e) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors,
                })
            );
        } finally {
            !!setLoading && delayByLoader(false, setLoading, 200);
        }
    };
};

export const changeSectionPosition = (startId, endId, onSuccess) => {
    return async (dispatch) => {
        try {
            await $api.post(`/api/knowledge/section-position`, {
                startId,
                endId,
            });

            onSuccess();
        } catch (e) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors,
                })
            );
        }
    };
};

export const articleImageRemove = (id, onSuccess) => {
    return async (dispatch) => {
        try {
            await $api.delete(`/api/knowledge/article-image-remove/?id=${id}`);
            onSuccess?.();
        } catch (e) {
            processError(dispatch, e);
        }
    };
};

export const sectionImageRemove = (id, onSuccess) => {
    return async (dispatch) => {
        try {
            await $api.delete(`/api/knowledge/section-image-remove/?id=${id}`);
            onSuccess?.();
        } catch (e) {
            processError(dispatch, e);
        }
    };
};

export const changeArticlePosition = (id, startId, endId, onRedirect, setLoading) => {
    setLoading(true)
    return async (dispatch) => {
        try {
            const response = await $api.post(`/api/knowledge/article-position`, {
                id,
                startId,
                endId,
            });

            dispatch(setCurrentArticleData(response.data));

            onRedirect?.(response?.data?._id);
        } catch (e) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors,
                })
            );
        } finally {
            delayByLoader(false, setLoading, 200);
        }
    };
};