import React from 'react';
import styles from './PartnersCreditLi.module.css';
import {getCurrencySymbol} from "../../../../../../core/helpers";
import {format} from "date-fns";
import {FilterStatusOptions} from "../../../../../../core/config";

const PartnersCreditLi = ({item,index}) => {
    const credit = item.amount;
    const currencySymbol = getCurrencySymbol(parseInt(item.currency)) ? getCurrencySymbol(parseInt(item.currency)) : '';
    const billingStatusObj = FilterStatusOptions.find( status => status.value === item.status );

    return (
        <li className={styles.Item}>
            <div className={styles.Num}>{index+1}</div>
            <div className={styles.Credit}>{credit ?  credit.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ') : ''} {currencySymbol}</div>
            <div className={styles.Debt}><span className="d-inline-block">{billingStatusObj.label}</span></div>
            <div className={styles.Comment}>{item.comment}</div>
            <div className={styles.Date}>{format(new Date(item.postedDate), 'dd.MM.yyyy')}</div>
        </li>
    );
};

export default PartnersCreditLi;
