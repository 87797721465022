import React from 'react';
import './Icons.css';
const AddIcon = () => {
    return (
        <svg className="addIcon" width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 5.33333C13.3333 4.59695 12.7364 4 12 4C11.2636 4 10.6667 4.59695 10.6667 5.33333V10.6667H5.33333C4.59695 10.6667 4 11.2636 4 12C4 12.7364 4.59695 13.3333 5.33333 13.3333H10.6667V18.6667C10.6667 19.403 11.2636 20 12 20C12.7364 20 13.3333 19.403 13.3333 18.6667V13.3333H18.6667C19.403 13.3333 20 12.7364 20 12C20 11.2636 19.403 10.6667 18.6667 10.6667H13.3333V5.33333Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 5.33333C13.3333 4.59695 12.7364 4 12 4C11.2636 4 10.6667 4.59695 10.6667 5.33333V10.6667H5.33333C4.59695 10.6667 4 11.2636 4 12C4 12.7364 4.59695 13.3333 5.33333 13.3333H10.6667V18.6667C10.6667 19.403 11.2636 20 12 20C12.7364 20 13.3333 19.403 13.3333 18.6667V13.3333H18.6667C19.403 13.3333 20 12.7364 20 12C20 11.2636 19.403 10.6667 18.6667 10.6667H13.3333V5.33333Z" />
        </svg>
    );
};

export default AddIcon;