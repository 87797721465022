/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from "react";
import styles from "./SectionEdit.module.css";
import Input from "../../../../../../ui/input/Input";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "../../../../../../ui/button_v2/ButtonV2";
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useDispatch } from "react-redux";
import { sectionImageRemove, updateKnowledgeSection } from "../../../../../../../actions/knowledge";
import { createNotification } from "../../../../../../../actions/app";
import { fetchImageAsFile } from "../../../../../../../utils/utils";
import { addModal, closeModal, removeModal } from "../../../../../../../reducers/appReducer";
import ContentLoader from "../../../../../../ui/contentLoader/ContentLoader";
import ArticleImageRemoveModal from "../../../../../../modals/articleImageRemove/ArticleImageRemoveModal";

function getLast10Characters (inputString) {
    return inputString?.length > 15
        ? `...${inputString.slice(-15)}`
        : inputString;
}

const SectionEdit = ({ data, setLoading, setImageRemove }) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState(data?.title);
    const [logo, setLogo] = useState("");
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(data?.description || "")
            )
        )
    );
    const [isImage, setIsImage] = useState(false);
    const inputRef = useRef(null);



    useEffect(() => {
        if (Object.keys(data)?.length > 0) {
            setData();
        }
    }, [data]);

    const setData = async () => {
        if (data.logo) {
            setLogo(await fetchImageAsFile(data.logo));
            setIsImage(true);
        }
    };

    const onSetFileHandler = () => {
        setIsImage(() => true);
        setLogo(inputRef.current.files[0]);
    };

    const updateSection = () => {
        const editorContentHtml = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );

        const formData = new FormData();
        formData.append("id", data._id);
        formData.append("title", title);
        formData.append("description", editorContentHtml);

        if (logo) {
            formData.append("logo", logo, logo.name);
        }

        if (!title.trim() || title.trim().length < 2 || title.trim().length > 50) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        "Название раздела должно быть не менее 2 и не больше 30 символов",
                })
            );
        } else if (
            !editorState.getCurrentContent().getPlainText().trim() ||
            editorState.getCurrentContent().getPlainText().trim().length < 3 ||
            editorState.getCurrentContent().getPlainText().trim().length > 100
        ) {
            dispatch(
                createNotification({
                    type: "error",
                    children:
                        "Описание раздела должно быть не менее 3 и не больше 100 символов",
                })
            );
        } else {
            dispatch(updateKnowledgeSection(formData, setLoading));
        }
    };

    const onArticleRemoveHandler = () => {
        dispatch(
            addModal({
                id: "articleImageRemoveModal",
                size: "bigger",
                children: (
                    <Suspense fallback={<ContentLoader />}>
                        {ArticleImageRemoveModal && (
                            <ArticleImageRemoveModal
                                type="article"
                                data={data}
                                onRemove={onRemove}
                                onError={() => {
                                    dispatch(removeModal("articleImageRemoveModal"));
                                }}
                            />
                        )}
                    </Suspense>
                ),
            })
        );
    };

    const onRemove = () => {
        dispatch(sectionImageRemove(data._id, onRemoveSuccess));
    };

    const onRemoveSuccess = () => {
        inputRef.current.value = '';
        setLogo('');
        setIsImage(false);
        dispatch(closeModal('articleImageRemoveModal'));
        setImageRemove(true);
    };


    return (
        <div className={`${styles.container} row`}>
            <div className="col-12">
                <h1 className={`${styles.title}`}>Редактирование раздела</h1>
            </div>
            <div className="col-6">
                <div className={styles.inputBlock}>
                    <span className={styles.inputTitle}>Название</span>
                    <Input
                        style={{
                            border: "1px solid #383838",
                            height: "38px",
                            backgroundColor: "transparent",
                        }}
                        placeholder="Введите название раздела"
                        setValue={setTitle}
                        value={title}
                    />
                </div>
            </div>
            <div className={`${styles.fileInputCol} col-6`}>
                <div className={styles.fileInputBlock}>
                    <label className={styles.file_input_label} htmlFor="file-input-logo">
                        <span>изменить фото</span>
                        <input
                            ref={inputRef}
                            accept="image/*"
                            id="file-input-logo"
                            type="file"
                            name="file"
                            onChange={onSetFileHandler}
                            className={styles.fileInput}
                        />
                    </label>
                    <div className={styles.removeZone} onClick={onArticleRemoveHandler}>
                        {isImage && <span className={`${styles.fileName} ${data?.logo ? styles.fileNameColor : ""}`}>
                            {getLast10Characters(!logo?.name ? data?.logo : logo?.name)}
                        </span>}
                        {isImage && <div className={styles.removeIcon}></div>}
                    </div>

                </div>
            </div>
            <div
                className={`${styles.textEditorBlock} col-12 sectionTextEditor`}
                style={{ width: "100%" }}
            >
                <h5 className={styles.textEditorTitle}>
                    Описание раздела (до 100 символов)
                </h5>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={(newEditorState) => {
                        setEditorState(newEditorState);
                    }}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                />
            </div>
            <div className={`${styles.updateSection} col-12`}>
                <Button className={styles.updateSectionBtn} onClick={updateSection}>
                    сохранить
                </Button>
            </div>
        </div>
    );
};

export default SectionEdit;
