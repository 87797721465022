import React, { lazy, Suspense } from 'react';
import { useDispatch } from "react-redux";
import { deleteCredit } from "../../../../../actions/partners";
import { BillingCurrency } from "../../../../../core/config";
import { addModal, removeModal } from "../../../../../reducers/appReducer";
import { setVerification } from "../../../../../reducers/billingReducer";
import Button from "../../../../ui/button_v2/ButtonV2";
import ContentLoader from "../../../../ui/contentLoader/ContentLoader";
import styles from './PartnersCredit.module.css';
import PartnersCreditLi from "./partnersCreditLi/PartnersCreditLi";

const PartnersCreateCredit = lazy(() => import("../../../../modals/partnersCreateCredit/PartnersCreateCredit"));
const PartnerModalVerification = lazy(() => import("../../../../modals/billingModalVerification/BillingModalVerification"));


const PartnersCredit = ({ data, verify, setVerify, loading, setLoading }) => {
    const dispatch = useDispatch();
    const type = 'partnersDeleteCredit';
    const totalCurrencySymbol = data.currency ? BillingCurrency.find(item => item.value === data.currency).symbol : '';

    async function createCredit (data) {
        await dispatch(addModal({
            id: 'createCredit',
            size: 'big',
            children:
                <Suspense fallback={<ContentLoader />}>
                    {PartnersCreateCredit &&
                        <PartnersCreateCredit
                            onError={() => {
                                dispatch(removeModal('createCredit'))
                            }}
                            data={data}

                        />}
                </Suspense>
        }));
    }

    return (

        <div className={styles.Payments}>
            <div className={styles.Title}><h3>Аванс</h3></div>
            <div className={`${styles.ListHeader}`} style={{marginTop:'25px'}}>
                <div className={styles.Num}>№</div>
                <div className={styles.Credit}>Сумма</div>
                <div className={styles.Debt}>Статус</div>
                <div className={styles.Comment}>Комментарий</div>
                <div className={styles.Date}>Дата</div>
            </div>
            {data && data.credits.length !== 0 ?
                <ul className={styles.ListBody}>
                    {
                        data && data.credits.length !== 0 ?
                            data.credits.map((item, index) => {
                                return <PartnersCreditLi item={item} index={index} key={index} />
                            })
                            : []
                    }
                </ul>


                :
                <div className={styles.NoData}>
                    <h1>Авансы отсутствуют</h1>
                </div>
            }
            <div className="my-4">
                {data.paymentSystems.length !== 0 &&
                    <Button type={'primary'} className={styles.BtnCredit}
                        onClick={() => {
                            createCredit(data)
                        }}
                    >Создать заявку на аванс</Button>}
            </div>
            <div className={styles.FullCreditInfo}>

                <div className="row my-4">
                    <div className="col-5">
                        <label>Текущий баланс <span>(на {new Date().toLocaleDateString()})</span></label>
                    </div>
                    <div className="col-7">
                        <span className={data.revenue < 0 ? styles.IsCredit : styles.IsDebit}>
                            {data.revenue ? data.revenue.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ') + ' ' + totalCurrencySymbol : 0}
                        </span>
                    </div>
                </div>

                {data.revenue && data.revenue < 0 ?
                    <Button type='danger'
                        className={styles.BtnCredit}
                        onClick={() => {
                            setLoading(true);
                            const fetchData = async () => {
                                const result = await dispatch(deleteCredit(data.id));
                                if (result === 'verify') {
                                    dispatch(addModal({
                                        id: 'partnerModalVerification',
                                        size: 'big',
                                        children:
                                            <Suspense fallback={<ContentLoader />}>
                                                {PartnerModalVerification &&
                                                    <PartnerModalVerification
                                                        billing={data}
                                                        type={type}
                                                        params={data.id}
                                                        onError={() => {
                                                            dispatch(removeModal('partnerModalVerification'))
                                                        }
                                                        }
                                                    />
                                                }
                                            </Suspense>
                                    }));
                                    dispatch(setVerification(false));
                                }
                            }
                            fetchData().then(() => {
                                setLoading(false);
                            });
                        }}
                    >Погасить аванс</Button>
                    : ''
                }
            </div>
        </div>

    );
};

export default PartnersCredit;
