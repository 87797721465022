import React, { useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from "./Column/Column";
import './DragnDrop.css';
import { RulesData } from "../../../../core/config";
const DragnDrop = ({ userData, setUserData }) => {
    const [rulesData, setRulesData] = useState( // незнаю стоит ли выносить в отдельный файл
        { ...RulesData.structure }
    );

    useMemo(() => {
        if (userData && userData.rules && userData.rules.length) {
            Object.keys(rulesData.columns).map((col) => {  // очистка  ролей
                rulesData.columns[col].ruleIds = [];
                return rulesData;
            });

            userData.rules.map(rule => {  // загрузка данных
                const newInitData = rulesData.columns[rule.value];
                newInitData.ruleIds.push(rule.name);
                return newInitData;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData.capability, userData]);


    function onDragEnd (result) {

        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }
        if (destination.droppaleId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        const start = rulesData.columns[source.droppableId];
        const finish = rulesData.columns[destination.droppableId];

        if (start === finish) { //  срабатывает если при перемещении элементов происходит внутри колонки
            const newRuleIds = Array.from(start.ruleIds);

            newRuleIds.splice(source.index, 1);
            newRuleIds.splice(destination.index, 0, draggableId);
            const newColumn = {
                ...start,
                ruleIds: newRuleIds
            };
            const newState = {
                ...rulesData,
                columns: {
                    ...rulesData.columns,
                    [newColumn.id]: newColumn,
                }
            };
            setRulesData(newState);
            return;
        }

        const startRuleIds = Array.from(start.ruleIds);
        startRuleIds.splice(source.index, 1);
        const newStart = {
            ...start,
            ruleIds: startRuleIds,
        };

        const finishRuleIds = Array.from(finish.ruleIds);
        finishRuleIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            ruleIds: finishRuleIds,
        };

        const newState = {
            ...rulesData,
            columns: {
                ...rulesData.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            }
        };
        const rules = [];
        Object.keys(newState.columns).forEach(key => {
            const ruleIds = Array.from(newState.columns[key].ruleIds);
            if (ruleIds.length !== 0) {
                ruleIds.forEach((ruleId) => {
                    rules.push({ name: ruleId, value: key });
                });
            }

        });
        setUserData({ ...userData, rules: rules });
    }

    return (
        <div className="dragnDrop row">
            <DragDropContext onDragEnd={onDragEnd}>
                {rulesData.columnOrder.map(columnId => {
                    const column = rulesData.columns[columnId];
                    const rules = column.ruleIds.map(ruleId => rulesData.rules[ruleId]);
                    return <Column column={column} rules={rules} key={column.id} />
                })}

            </DragDropContext>
        </div>
    );

}
    ;

export default DragnDrop;