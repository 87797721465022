/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './OffersFilters.module.css';
import Search from "../../../ui/seacrh/Search";
import Button from "../../../ui/button_v2/ButtonV2";
import { useDispatch } from 'react-redux';
import { fetchOffers } from '../../../../actions/offer';

const OffersFilters = ({ params, setParams }) => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (search.key) {
            setSearch(search.value);
            requestFilterData(search.value);
        }
    }, [search]);

    const requestFilterData = (pm) => {
        setParams({ ...params, search: pm })
    };

    const onSearchVAlueUpdate = (value) => {
        if (!value) {
            dispatch(fetchOffers({
                search: '',
                page: '',
            }));
        }
        setSearch(value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.input_block} >
                <Search 
                    fontSize={14} 
                    maxWidth='unset' 
                    placeholder='id, назание' 
                    value={search} 
                    type='search' 
                    setValue={onSearchVAlueUpdate} 
                    label='Поиск'
                    requestFilterData={requestFilterData}  
                />
            </div>
            <div className={`${styles.BtnBlock} `}>
                <Button 
                    disabled={!search}
                    onClick={() => requestFilterData(search)} 
                    type='primary'
                >
                    Найти
                </Button>
            </div>
        </div>
    );
};

export default OffersFilters;
