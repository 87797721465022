import React, {lazy, Suspense} from 'react';
import styles from "../../OfferCard.module.css";
import {getCurrencyNameSymbol, getModuleRule, isEdit, MODULE_TYPE} from "../../../../../../core/helpers";
import EditIcon from "../../../../../../assets/img/edit-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {addModal, removeModal} from "../../../../../../reducers/appReducer";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";
import {CountryList} from "../../../../../../core/config";
import {createNotification} from "../../../../../../actions/app";
import { formatNumber } from '../../../../../../utils/formatDate';

const EditPaymentModal = lazy( () => import ("../../../../../modals/editPaymentModal/EditPaymentModal") );

const PaymentItem = ({payment, personal}) => {
    const dispatch = useDispatch();
    const user = useSelector( state => state.user.currentUser );
    const ruleOffersPayments = getModuleRule(user.rules,MODULE_TYPE.OFFERS_PAYMENTS);

    let currencySymbolTotal;
    let currencySymbolPartner;
    if(payment.type ==='fixed' || payment.type ==='cpc' ){
        currencySymbolTotal = payment.currency ? getCurrencyNameSymbol( payment.currency ) : '';
        currencySymbolPartner = payment.currency ? getCurrencyNameSymbol( payment.currency ) : '';
    }else if(payment.type ==='mixed'){
        currencySymbolTotal = '%';
        currencySymbolPartner = payment.currency ? getCurrencyNameSymbol( payment.currency ) : '';
    } else{
        currencySymbolTotal = '%';
        currencySymbolPartner = '%';
    }

    const dayStart = payment.dateActiveStart
        && `с ${new Date(payment.dateActiveStart).toLocaleString("ru",{dateStyle: "short", timeStyle: "short"})}`;

    const dayEnd = payment.dateActiveEnd
        && ` до ${new Date(payment.dateActiveEnd).toLocaleString("ru",{dateStyle: "short", timeStyle: "short"})}`;

  

    const editPayment = () => {
        if ( isEdit(ruleOffersPayments) ) {
            dispatch(addModal({
                id: 'editPaymentModal',
                size: 'middle-lg',
                children:
                    <Suspense fallback={<ContentLoader/>}>
                        <EditPaymentModal
                            onError={() => dispatch(removeModal('editPaymentModal'))}
                            payment={payment}
                            personal={personal}
                        />
                    </Suspense>
            }));
        } else {
            dispatch(createNotification({
                type: 'error',
                children: 'Недостаточно прав для редактирования выплат'
            }));
        }
    };

    return (
        <div className={`${styles.Payment} row my-3`} key={payment.id}>
            {personal
                ? <div className="col-3">
                    {payment.partners.length > 0 && payment.partners.map( item =>
                        <div key={item.id}><small>({item.affiseId}) {item.email}</small></div>
                    )}
                  </div>
                : <>
                    <div className="col-2"><small>{payment.goal}</small></div>
                    <div className="col-2"><small>{payment.title}</small></div>
                  </>
            }
            <div className={personal ? "col-3" : "col-2"}>
                <small>
                    {payment.countries.map( item => {
                        const country = CountryList.find( country => country.alpha2 === item.toLowerCase() );
                        return country.name;
                    }).join(', ')}
                </small>
            </div>
            <div className="col-2">{payment.type}</div>
            <div className="col-1">
                <div className={styles.PayAmountBlock}>
                    {payment.total ? `${formatNumber(payment.total)} ${currencySymbolTotal}` : '-'}
                    {(payment.totalNew || payment.totalNew === 0) &&
                        <>
                            <div className={styles.newValueIcon}>&bull;</div>
                            <div className={styles.newPayAmount}>
                                <span className="text-right"> {dayStart}<br/>{dayEnd} </span>
                                <span>{formatNumber(payment.totalNew)}{currencySymbolTotal}</span>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="col-1">
                <div className={styles.PayAmountBlock}>
                    {payment.revenue ? `${formatNumber( payment.revenue )} ${currencySymbolPartner}` : '-'}
                    {(payment.revenueNew || payment.revenueNew === 0) &&
                        <>
                            <div className={styles.newValueIcon}>&bull;</div>
                            <div className={styles.newPayAmount}>
                                <span className="text-right"> {dayStart}<br/>{dayEnd} </span>
                                <span>{formatNumber(payment.revenueNew)}{currencySymbolPartner}</span>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="col-1 text-right">
                <span className={styles.EditIcon} onClick={editPayment}>
                    <img src={EditIcon} alt="edit"/>
                </span>
            </div>
        </div>
    );
};

export default PaymentItem;
