import React, {lazy, Suspense, useState} from 'react';
import styles from './IncomeCategoryRow.module.css';
import EditIcon from "../../../ui/icons/EditIcon";
import Button from "../../../ui/button_v2/ButtonV2";
import DeleteIcon from "../../../ui/icons/DeleteIcon";
import {addModal, removeModal} from "../../../../reducers/appReducer";
import ContentLoader from "../../../ui/contentLoader/ContentLoader";
import {useDispatch, useSelector} from "react-redux";
import {deletePartnersIncomeCategory, updatePartnersIncomeCategory} from "../../../../actions/partners-income";
import {createNotification} from "../../../../actions/app";
import { getPartnerByUserId } from '../../../../actions/partners';

const ConfirmModal = lazy(() => import('../../../modals/confirmModal/ConfirmModal'));

const IncomeCategoryRow = ({category, setLoading, getCategories, partner}) => {
    const dispatch = useDispatch();
    const [editCategory, setEditCategory] = useState(false);
    const [categoryName, setCategoryName] = useState(category.name);
    const [categoryDescription, setCategoryDescription] = useState(category.description);
    const currentUser = useSelector(state => state.user.currentUser);

    const updateCategory = async () => {
        try {
            setLoading(true);
            if (currentUser.capability >= 2) {
                const partners = await dispatch(getPartnerByUserId(currentUser.email));
                // eslint-disable-next-line no-unused-vars
                const partnerId = partners[0]?._id?.valueOf();
                const result = await dispatch(updatePartnersIncomeCategory({
                    id: category.id,
                    categoryName,
                    categoryDescription,
                    // partnerId
                }));
                if (result) {
                    await getCategories();
                }
            } else {
                const result = await dispatch(updatePartnersIncomeCategory({
                    id: category.id,
                    categoryName,
                    categoryDescription
                }));
                if (result) {
                    await getCategories();
                }
            }
            setLoading(false);
        } catch(e) {
            dispatch(createNotification({
                    type: 'secondary',
                    children: 'Ошибка при обновлении статьи доходов'
                }));
        }
    }

    const deleteCategory = async () => {
        try {
            setLoading(true);
            const result = await dispatch(deletePartnersIncomeCategory(category.id));
            if (result) {
                await getCategories();
            }
            dispatch(removeModal('confirmActionModal'));
            setLoading(false);
        } catch(e) {
            dispatch(createNotification({
                    type: 'secondary',
                    children: 'Ошибка при удалении статьи доходов'
                }));
        }
    };

    const showDeleteConfirm = () => {
        dispatch(addModal({
            id: 'confirmActionModal',
            size: 'middle',
            children:
                <Suspense fallback={<ContentLoader />}>
                    {ConfirmModal &&
                        <ConfirmModal
                            onError={() => {
                                dispatch(removeModal('confirmActionModal'))
                            }}
                            // title={'Подтверждение удаления статьи доходов'}
                            message={'При удалении статьи доходов, все доходы относящиеся к этой статье будут безвозвратно удалены для всех проектов. Вы уверены?'}
                            foo={deleteCategory}
                        />
                    }
                </Suspense>
        }));
    };

    return (
        <div className={!editCategory ? styles.ProjectGroupRow : styles.ProjectGroupRowEdit}>
            <div className="row">
                <div className={!editCategory ? "col-9" : ''}>
                    <div className="d-flex align-items-center pl-5">
                        <div className={styles.ProjectGroupIcon}>{category.name.substring(0,1)}</div>
                            {!editCategory && <div className={!editCategory ? styles.CategoryName : ''}>
                                {category.name}
                            </div>}
                            {!editCategory && <div className={!editCategory ? styles.CategoryDescription : ''}>
                                {category.description}
                            </div>}
                    </div>
                </div>
                <div className={editCategory ? "col-9 d-flex justify-content-start" : ''}>
                    <div className="d-flex align-items-start">
                        {editCategory &&
                        <div className={styles.EditInputs}>
                            <input
                                type="text"
                                className={styles.InputName}
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                                required={true}
                            />
                            <input
                                type="text"
                                className={styles.InputDescription}
                                value={categoryDescription}
                                onChange={(e) => setCategoryDescription(e.target.value)}
                            />
                            <Button 
                                className="mx-3" 
                                type={'approve'} 
                                onClick={updateCategory}>
                                    Сохранить
                            </Button>
                        </div>}
                        {currentUser.capability === 1 ?
                        <Button
                            className={`${styles.Button} ${styles.Edit}`}
                            onClick={() => setEditCategory(!editCategory)}
                        >
                            {editCategory
                                ? 'Отмена'
                                :<>
                                    <span className="pr-1"><EditIcon/></span>Редактировать
                                </>
                            }
                        </Button>:

                        category?.partner?.valueOf() === partner && <Button
                            className={`${styles.Button} ${styles.Edit}`}
                            onClick={() => setEditCategory(!editCategory)}
                        >
                            {editCategory
                                ? 'Отмена'
                                :<>
                                    <span className="pr-1"><EditIcon/></span>Редактировать
                                </>
                            }
                        </Button>}
                        
                        {currentUser.capability === 1 ?     
                        <Button
                            className={`${styles.Button} ${styles.Delete}`}
                            onClick={showDeleteConfirm}
                        >
                            <span className="pr-1"><DeleteIcon/></span>Удалить
                        </Button>:

                        category?.partner?.valueOf() === partner && <Button
                            className={`${styles.Button} ${styles.Delete}`}
                            onClick={showDeleteConfirm}
                        >
                            <span className="pr-1"><DeleteIcon/></span>Удалить
                        </Button>}    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IncomeCategoryRow;
