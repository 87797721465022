/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import HomeIcon from '../../../../../assets/img/home-icon.svg';
import styles from './KnowledgeNavigation.module.css'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchKnowledgeById } from '../../../../../actions/knowledge';

const KnowledgeNavigation = ({ data, isArticle = false }) => {
    const dispatch = useDispatch();

    const currentSectionData = useSelector(state => state.knowledge.currentSectionData);

    useEffect(() => {
        isArticle && dispatch(fetchKnowledgeById(data.sectionId));
    }, [data]);

    return (
        <div className={styles.container}>
            <NavLink className={styles.link} to="/"> <img src={HomeIcon} alt="" /></NavLink>
            <span className={styles.slash}>/</span>
            <NavLink className={styles.link} to="/knowledge">База знаний</NavLink>
            <span className={styles.slash}>/</span>
            <NavLink className={styles.link} to={`/knowledge/section/${currentSectionData?._id}`}>{currentSectionData?.title}</NavLink>
            {isArticle && <>
                <span className={styles.slash}>/</span>
                <NavLink className={styles.link} to={`/knowledge/article/${data?._id}`}>{data?.title}</NavLink>
            </>}
        </div>
    )
}

export default KnowledgeNavigation;