/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./DocumentByTheme.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticlesBySection } from "../../../../../actions/knowledge";

const DocumentByTheme = ({ articleData, setLoading }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState([]);

    const articlesData = useSelector((state) => state.knowledge.articlesData);

    useEffect(() => {
        articleData?.sectionId &&
            dispatch(fetchArticlesBySection(articleData?.sectionId));
    }, [articleData]);

    useEffect(() => {
        const newData = articlesData.filter((item) => item._id !== articleData._id);
        setData(newData)
    }, [articlesData]);

    return (
        <div className={styles.container}>
            {
                data.length > 0 && <>
                    <h3 className={styles.title}>Документы по теме</h3>
                    {data.map((item, index) => {
                        return (
                            <div key={item._id} className={styles.link} >
                                <NavLink key={index} to={`/knowledge/article/${item._id}`} >
                                    {item.title}
                                </NavLink>
                            </div>
                        );
                    })}
                </>
            }
        </div>
    );
};

export default DocumentByTheme;
