import React from 'react';
import styles from './button.module.css';

const Button = ({ type, children, className, onClick, ...props }) => {
    let btnClasses = `${styles.Btn}`;

    if (className) {
        btnClasses += ` ${className}`;
    }

    switch (type) {
        case 'primary':
            btnClasses += ` ${styles.Primary}`;
            break;
        case 'secondary':
            btnClasses += ` ${styles.Secondary}`;
            break;
        case 'danger':
            btnClasses += ` ${styles.Danger}`;
            break;
        case 'warning':
            btnClasses += ` ${styles.Warning}`;
            break;
        case 'approve':
            btnClasses += ` ${styles.Approve}`;
            break;
        default:
            break;
    }

    return (
        <button className={btnClasses} onClick={() => onClick()}  {...props} type='button' >
            {children}
        </button>
    );
};

export default Button;
