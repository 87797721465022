const ADD_COLLAPSED_INCOME_COLUMN = 'ADD_COLLAPSED_INCOME_COLUMN';
const ADD_COLLAPSED_EXPENSES_COLUMN = 'UPDATE_COLLAPSED_EXPENSES_COLUMN';
const ADD_COLLAPSED_MONTHS_COLUMN = 'ADD_COLLAPSED_MONTHS_COLUMN';
const DELETE_COLLAPSED_INCOME_COLUMN = 'DELETE_COLLAPSED_INCOME_COLUMN';
const DELETE_COLLAPSED_EXPENSES_COLUMN = 'DELETE_COLLAPSED_EXPENSES_COLUMN';
const DELETE_COLLAPSED_MONTHS_COLUMN = 'DELETE_COLLAPSED_MONTHS_COLUMN';
const RESET_COLLAPSED_INCOME_COLUMN = 'RESET_COLLAPSED_INCOME_COLUMN';
const RESET_COLLAPSED_EXPENSES_COLUMN = 'RESET_COLLAPSED_EXPENSES_COLUMN';
const RESET_COLLAPSED_MONTHS_COLUMN = 'RESET_COLLAPSED_MONTHS_COLUMN';
const SET_MONTHS_CURRENCY_RATES = 'SET_MONTHS_CURRENCY_RATES';
const UPDATE_REPORT_TOTALS = 'UPDATE_REPORT_TOTALS';
const RESET_REPORT_TOTALS = 'RESET_REPORT_TOTALS';

const defaultState = {
    collapsedIncomeColumns: [],
    collapsedExpensesColumns: [],
    collapsedMonths: [],
    monthsCurrencyRates: {},
    reportTotals: {}
};

export default function reportsReducer(state = defaultState, action) {
    switch (action.type) {
        case ADD_COLLAPSED_INCOME_COLUMN:
            return {...state, collapsedIncomeColumns: [...state.collapsedIncomeColumns, action.payload]}
        case ADD_COLLAPSED_EXPENSES_COLUMN:
            return {...state, collapsedExpensesColumns: [...state.collapsedExpensesColumns, action.payload]}
        case ADD_COLLAPSED_MONTHS_COLUMN:
            return {...state, collapsedMonths: [...state.collapsedMonths, action.payload]}
        case DELETE_COLLAPSED_INCOME_COLUMN:
            return {...state, collapsedIncomeColumns: state.collapsedIncomeColumns.filter(item => item !== action.payload)}
        case DELETE_COLLAPSED_EXPENSES_COLUMN:
            return {...state, collapsedExpensesColumns: state.collapsedExpensesColumns.filter(item => item !== action.payload)}
        case DELETE_COLLAPSED_MONTHS_COLUMN:
            return {...state, collapsedMonths: state.collapsedMonths.filter(item => item !== action.payload)}
        case RESET_COLLAPSED_INCOME_COLUMN:
            return {...state, collapsedIncomeColumns: []}
        case RESET_COLLAPSED_EXPENSES_COLUMN:
            return {...state, collapsedExpensesColumns: []}
        case RESET_COLLAPSED_MONTHS_COLUMN:
            return {...state, collapsedMonths: []}
        case SET_MONTHS_CURRENCY_RATES:
            return {...state, monthsCurrencyRates: action.payload}
        case UPDATE_REPORT_TOTALS:
            const { reportTotalAmounts, monthsArr } = action.payload;
            let reportTotals = {};

            if (Object.keys(reportTotalAmounts).length > 0) {
                for (const date of monthsArr) {
                    reportTotals = {...reportTotals, [`${date.year}.${date.month}`]: reportTotalAmounts[date.year][date.month]};
                }
            }

            return {...state, reportTotals}
        case RESET_REPORT_TOTALS:
            return {...state, reportTotals: {}}
        default:
            return state;
    }
}

export const addCollapsedIncomeColumns = (col) => ({type: ADD_COLLAPSED_INCOME_COLUMN, payload: col});
export const addCollapsedExpensesColumns = (col) => ({type: ADD_COLLAPSED_EXPENSES_COLUMN, payload: col});
export const addCollapsedMonthsColumns = (col) => ({type: ADD_COLLAPSED_MONTHS_COLUMN, payload: col});
export const deleteCollapsedIncomeColumns = (col) => ({type: DELETE_COLLAPSED_INCOME_COLUMN, payload: col});
export const deleteCollapsedExpensesColumns = (col) => ({type: DELETE_COLLAPSED_EXPENSES_COLUMN, payload: col});
export const deleteCollapsedMonthsColumns = (col) => ({type: DELETE_COLLAPSED_MONTHS_COLUMN, payload: col});
export const resetCollapsedIncomeColumns = () => ({type: RESET_COLLAPSED_INCOME_COLUMN, payload: null});
export const resetCollapsedExpensesColumns = () => ({type: RESET_COLLAPSED_EXPENSES_COLUMN, payload: null});
export const resetCollapsedMonthsColumns = () => ({type: RESET_COLLAPSED_MONTHS_COLUMN, payload: null});
export const setMonthsCurrencyRates = (data) => ({type: SET_MONTHS_CURRENCY_RATES, payload: data});
export const updateReportTotals = (data) => ({type: UPDATE_REPORT_TOTALS, payload: data});
export const resetReportTotals = () => ({type: RESET_REPORT_TOTALS, payload: null});
