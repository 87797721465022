import React from 'react';
import styles from './DashboardBillingLi.module.css';
import {format} from "date-fns";
import {getCurrencySymbol} from "../../../../core/helpers";
import {FilterStatusOptions} from "../../../../core/config";

const DashboardBillingLi = ({billing}) => {
    const currencySymbol = getCurrencySymbol(billing.currency) ? getCurrencySymbol(billing.currency) : '';
    const billingStatusObj = FilterStatusOptions.find( status => status.value === billing.status );

    return (
        <li className={styles.Item}>
            <div className={styles.AffiseId}>{billing.affiseId}</div>
            <div className={styles.PartneeId}>ID: {billing.partner.affiseId}</div>
            <div className={styles.PartneeLogin}>{billing.partner.login}</div>
            <div className={styles.Revenue}>{billing.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')} {currencySymbol}</div>
            <div className={styles.Status}><span className="d-inline-block">{billingStatusObj?.label}</span></div>
            <div className={styles.Date}>{format(new Date(billing.postedDate), 'dd.MM.yyyy')}</div>
        </li>
    );
};

export default DashboardBillingLi;
