import React, {useEffect, useState} from 'react';
import styles from './ReportTableBody.module.css';
import ReportTableRow from "./reportTableRow/ReportTableRow";
import {useDispatch} from "react-redux";
import {getMonthsCurrencyRates} from "../../../../actions/currency";
import ReportTotalsRow from "./reportTableRow/reportTotalsRow/ReportTotalsRow";

const ReportTableBody = ({report, partnersTotalAmounts, groupsTotalAmounts, expensesData, incomeData}) => {
    const dispatch = useDispatch();
    const [monthsArr, setMonthsArr] = useState([]);
    
    useEffect(() => {
        if (report && report.monthsArr) {
            setMonthsArr( report.monthsArr );
        }
        dispatch(getMonthsCurrencyRates());
    }, [dispatch, report]);

    return (
        <div className={styles.ReportTableBody}>
            {monthsArr.length > 0 &&
                <>
                    {report.resultGroupsPartnersArr.length > 0 && report.resultGroupsPartnersArr.map((item, index) => {
                        const partner = Object.keys(item)[0];
                        const partnerTotal = partnersTotalAmounts.find((el) => Object.keys(el)[0] === partner);
                        const partnerGroupsTotalAmounts = groupsTotalAmounts.find((el) => Object.keys(el)[0] === partner);
                        const partnerIncomeData = incomeData.find((el) => Object.keys(el)[0] === partner);
                        const partnerExpensesData = expensesData.find((el) => Object.keys(el)[0] === partner);
                        return <ReportTableRow 
                            rowType={'partner'} 
                            rowData={item} 
                            partnerTotalAmounts={partnerTotal} 
                            partnerGroupsTotalAmounts={partnerGroupsTotalAmounts} 
                            partnerIncomeData={partnerIncomeData ? partnerIncomeData : ''} 
                            partnerExpensesData={partnerExpensesData ? partnerExpensesData : ''} 
                            monthsArr={monthsArr} 
                            key={index}
                        />
                    }
                    )}
                    <ReportTotalsRow monthsArr={monthsArr} />
                </>
            }
        </div>
    );
};

export default ReportTableBody;
