import React from 'react';
import styles from './BillingStatus.module.css';
import BillingStatusDeclined from "../../../ui/icons/BillingStatusDeclined";
import BillingStatusPaidParty from "../../../ui/icons/BillingStatusPaidParty";
import BillingStatusFinished from "../../../ui/icons/BillingStatusFinished";
import BillingStatusPending from "../../../ui/icons/BillingStatusPending";
import BillingStatusWaiting from "../../../ui/icons/BillingStatusWaiting";
import BillingStatusArchive from "../../../ui/icons/BillingStatusArchive";
const BillingStatus = ({status, className}) => {
    let statusClass = className ? `${styles.Status} ${className} ` : `${styles.Status} `;
    let name = '';
    let icon = '';
    switch (status) {
        case -1: {
            name = 'Отклонено';
            statusClass += `${styles.Declined}`;
            icon = <BillingStatusDeclined/>;
            break;

        }
        case 1: {
            name = 'Ожидает';
            statusClass += `${styles.Pending}`;
            icon = <BillingStatusFinished/>;
            break;

        }
        case 2: {
            name = 'Оплачено';
            statusClass += `${styles.Finished}`;
            icon = <BillingStatusPending/>;
            break;

        }
        case 3: {
            name = 'Частично оплачено';
            statusClass += `${styles.PaidPartly}`;
            icon = <BillingStatusPaidParty/>;
            break;

        }
        case 4: {
            name = 'В обработке';
            statusClass += `${styles.Waiting}`;
            icon = <BillingStatusWaiting/>;
            break;

        }
        case 99: {
            name = 'В архиве';
            statusClass += `${styles.Archive}`;
            icon = <BillingStatusArchive/>;
            break;

        }
        default:{

        }
    }

    return (
        <span className={statusClass}>
            {icon}  {name}
        </span>
    );
};

export default BillingStatus;