import React, { useEffect, useState } from "react";
import Button from "../button/Button";
import styles from "./TabsModal.module.css";
import ContentLoader from "../contentLoader/ContentLoader";
import { delayByLoader } from "../../../utils/deleyByLoader";
import { useDispatch, useSelector } from "react-redux";
import { updatePartnersStopped } from "../../../actions/partners";
import { MODULE_TYPE, getModuleRule, isEdit } from "../../../core/helpers";


const TabsModal = ({ data, controls, contents }) => {
    const dispatch = useDispatch();
    const [tab, setTabs] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    const currentUser = useSelector(state => state.user.currentUser);
    const rule = getModuleRule(currentUser.rules, MODULE_TYPE.PARTNERS);


    const onTabHandler = (value) => {
        delayByLoader(true, setLoading, 300);
        setTabs(value);
    };

    const onUpdateStopped = (status) => {
        // setIsStopped(status);
        dispatch(updatePartnersStopped(data.id, status,onSuccess));
    };

    const onSuccess = () => {
      setIsStopped(!isStopped);
    };

    useEffect(() => {
        if (Object.keys(data)?.length > 0) {
            if ( data.stopped !== undefined ) {
                setIsStopped(data.stopped)
            }
        }
    }, [data]);


    const controlsList = (
        controls && controls.length !== 0
            ? controls.map((control, index) => (
                <Button
                    type="no"
                    className={
                        tab === index ? `${styles.Control} ${styles.Active}` : styles.Control
                    }
                    onClick={() => onTabHandler(index)}
                    key={index}
                >
                    {control}
                </Button>
            ))
            : <Button
                type="no"
                className={tab === 0 ? `${styles.Control} ${styles.Active}` : styles.Control}
                onClick={() => onTabHandler(0)}
            >
                Данные отсутствуют
            </Button>
    );

    const contentList = (
        contents && contents.length !== 0
            ? contents.map((content, index) => (
                <div
                    className={
                        tab === index ? `${styles.Content} ${styles.Active}` : styles.Content
                    }
                    key={index}
                >
                    {content}
                </div>
            ))
            : <div
                className={tab === 0 ? `${styles.Content} ${styles.Active}` : styles.Content}
            >
                Данные отсутствуют
            </div>
    );

    return (
        <div className={styles.TabsContainer}>
            <div className={`${styles.LeftBlock} ${styles.Controls}`}>
                {controlsList}
            </div>
            <div className={`${styles.RightBlock} ${styles.Container}`}>
                {
                    loading ? <div className={styles.contentLoader}>
                        <ContentLoader />
                    </div> : contentList
                }
                {tab === 0 && isEdit(rule) &&
                    <div className={styles.switchBlock}>
                        <span className={styles.switchText}>Запретить вывод средств</span>
                        <input
                            type="checkbox"
                            checked={isStopped}
                            onChange={() => onUpdateStopped(!isStopped)}
                            id="switch"
                            className={styles.switchInput}
                        />
                        <label htmlFor="switch" className={styles.switchLabel}>Toggle</label>
                    </div>
                }

            </div>
        </div>
    );
};

export default TabsModal;
